import React from 'react';
import './App.css';
import Login from './login';
import Faq from './faq';
import Dashboard from './dashboard';
import Redirecting from './redirect';
import { Redirect, Switch, Route } from "react-router-dom";
import AppManager from './appManager';
import ResetPassword from './resetPassword';
import Logout from './logout';
import { useIdleTimer } from 'react-idle-timer'
import { useHistory  } from "react-router-dom";
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';



function App() {
  (window.location.pathname === "/login") ? document.body.style = 'background:#fff;': document.body.style = 'background:#f1f7ff;';
  
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime());
    Cookies.remove('state');
    Cookies.remove('travelworks-username');
    Cookies.remove('travelworks-password');
    Cookies.remove('travelworks-email');
    localStorage.clear();
    history.push('/logout');
    setOpen(false);
  }

  const logout = ()=>{
    Cookies.remove('state');
    Cookies.remove('travelworks-username');
    Cookies.remove('travelworks-password');
    Cookies.remove('travelworks-email');
    localStorage.clear();
    history.push('/logout');
    setOpen(false);
  }
  

  /*const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    console.log('user did something', event)
  }*/

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    //onActive: handleOnActive,
    //onAction: handleOnAction,
    debounce: 500
  });
  return (
    <div>
      {(window.location.pathname !== '/providerportal' && window.location.pathname !== '/workerportal') ? <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <DialogTitle id="alert-dialog-title">{" Session Timed out! Please Logout and Login."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" onClick={logout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog> : null}
        <Switch>
          <Route exact path="/" render={()=> <Redirect to ="/workerportal"/>}/>
          <Route exact path="/workerportal" component={Login}/>
          <Route exact path="/resetPassword" component={ResetPassword}/>
          <Route exact path="/providerportal" component={Login}/>
          <Route exact path="/faq" component={Faq}/>
          <Route exact path="/dashboard" render={()=> (Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <Dashboard />}/>
          <Route exact path="/appManager" render={()=>(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <AppManager />}/>
          <Route path="/tr1" >
            <Dashboard />
          </Route>
          <Route path="/reservation" >
            <Dashboard />
          </Route>
          <Route path="/preApproval" >
            <Dashboard />
          </Route>
          <Route  path="/config" >
          <Dashboard />
          </Route>
          <Route  path="/reports" >
          <Dashboard />
          </Route>
          <Route  path="/search" >
          <Dashboard />
          </Route>
          <Route  path="/logout" >
          <Logout />
          </Route>
          <Route exact path="/redirect" component={Redirecting}/>
        </Switch>
    </div>
    
  );
}

export default App;