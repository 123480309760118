import React, {useState} from 'react';
import './App.css';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import DenseAppBar from './header';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {postFetch} from './http';
import Loader from './loader';
import {useHistory} from 'react-router-dom';
import footer from './imgs/footer.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from './assets/config';



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    "& .MuiButton-containedPrimary":{
        marginRight:15
    }
  },
  input:{
    width:'95%'
  },
  submit:{
      textAlign:'center'
  },
  logo:{
    textAlign:'center'
  },
  forgotPass:{
    textDecoration: 'underline',
    marginLeft: '20px',
    cursor: 'pointer'
  },
  card:{
    height:'100%',
    padding:"10px 0"
  },
  list:{
    fontSize:'11px'
  },
  listItem:{
    padding:'5px'
  },
  icon:{
    fontSize: '20px',
    position: 'relative',
    top: '3px',
    marginRight: '5px'
  },
  footer:{
    minHeight: '80px',
    zIndex: 1350,
    bottom: 0,
    top: 'auto',
    textAlign:'center'
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },
  textLabel:{
    fontSize: '16px',
    fontWeight: '500'
  }
}));

const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordFormat = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W)/;



function ResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = useState({loader:false,notification:false,message:"success",validationMessage:"Success"});
  const [user, setUser] = useState({currentPassword:'',newPassword:'',userId:(Cookies.get("state") === undefined) ? null : JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).userId,role:'PR',email:(Cookies.get("state") === undefined) ? '' : JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).username,confirmPassword:''})

  const handleChange=(event)=>{
    let updatedDetails = {...user};
    updatedDetails[event.target.name] = event.target.value;
    setUser(updatedDetails);
  }

  const cancel = ()=>{
    (Cookies.get("state") === undefined) ? history.push('/providerportal') : history.push('/dashboard');
  }
  
  const sendEmail= async ()=>{
    setLoader({...loader,loader:true,notification:false});
    const request= {...user};
    await postFetch("/userManagement/sendEmail",request).then((data)=>{
        if(!data.message){
            let updatedUser = {...user};
            updatedUser.userId = data.id;
            updatedUser.email = data.email;
            setUser(updatedUser);
            setTimeout(()=>{
                setLoader({...loader,loader:false,notification:true,message:"success",validationMessage:"Submission successfull! A password reset link has been sent to your email"});
            },1000);
        }
        else{
            setTimeout(()=>{
                setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message});
            },1000);
        }
    }).catch((e)=>{
      setTimeout(()=>{
        setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk."});
      },1000);
    });
  }

  const savePassword= async ()=>{
    if(user.newPassword !== user.confirmPassword){
        setTimeout(()=>{
            setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"Passwords doesn't match"});
          },1000);
    }
    else if ((!passwordFormat.test(user.newPassword)) || (user.newPassword.length > 20)){
        setTimeout(()=>{
            setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"Passwords doesn't meet at least one of the requirements"});
          },1000);
    }
    else{
        setLoader({...loader,loader:true,notification:false});
        const request= {...user};
        await postFetch("/userManagement/resetPassword",request).then((data)=>{
            if(!data.message){
                setTimeout(()=>{
                    setLoader({...loader,loader:false,notification:true,message:"success",validationMessage:"Password Saved Successfully"});
                },1000);
                (Cookies.get("state") === undefined) ? history.push('/providerportal') : history.push('/dashboard');
            }
            else{
                setTimeout(()=>{
                    setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message});
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{
            setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk."});
          },1000);
        });
    }
  }

  const loaderAction = async()=>{
      setLoader({...loader,notification:false})
  }
  return (
    <Box   style={{backgroundColor:"#fff"}}>
    <Loader loader={loader.loader} notification = {loader.notification} message={loader.message} action={loaderAction} validationMessage = {loader.validationMessage}/>
    <div>
      <DenseAppBar></DenseAppBar>
      <Paper elevation={2} style={{padding:"10px 5px"}}>
      <Grid container direction="row"  spacing={0} style={{margin:"40px 0",minHeight:"calc(100vh - 246px)"}}>
        <Grid item xs={12} md={6}>
        <ValidatorForm className={classes.root} onSubmit={(user.userId !== null) ? savePassword : sendEmail}>
              <Box className={classes.input}>
              {(user.userId !== null) ? <Typography className={classes.header}>Reset Password</Typography> : <Typography className={classes.header}>Forgot Password</Typography>}
              </Box>
              {(user.userId !== null) ? <div>
                <span className={classes.textLabel}>Current Password</span>
                <TextValidator
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
                    onChange={handleChange}
                    name="currentPassword"
                    type="password"
                    id="currentPassword"
                    autoComplete="current-password"
                    size="small"
                    error={!(user.currentPassword.length > 0)}
                    className={`${classes.input} required`}
                    value={user.currentPassword}
                    validators={['required']}
                    errorMessages={['Required field']}
                /><br/>
                <span className={classes.textLabel}>New Password</span>
                <TextValidator
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
                    onChange={handleChange}
                    name="newPassword"
                    type="password"
                    id="newPassword"
                    autoComplete="new-password"
                    size="small"
                    error={!(user.newPassword.length > 0)}
                    className={`${classes.input} required`}
                    value={user.newPassword}
                    validators={['required']}
                    errorMessages={['Required field']}
                />
                <span className={classes.textLabel}>Confirm New Password</span>
                <TextValidator
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
                    onChange={handleChange}
                    name="confirmPassword"
                    type="password"
                    id="confirmPassword"
                    autoComplete="confirm-password"
                    size="small"
                    error={!(user.confirmPassword.length > 0)}
                    className={`${classes.input} required`}
                    value={user.confirmPassword}
                    validators={['required']}
                    errorMessages={['Required field']}
                />
              </div> : <div>
                    <span className={classes.textLabel}>Email Address</span>
                    <TextValidator
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
                        onChange={handleChange}
                        id="email"
                        name="email"
                        autoComplete="email"
                        size="small"
                        error={!emailFormat.test(user.email.toLowerCase())}
                        className={`${classes.input} required`}
                        value={user.email}
                        validators={['required', 'isEmail']}
                        errorMessages={['Required field', 'Email is not valid']}
                    />
                  </div>}
              
              
              {(user.userId !== null) ?
              <div className={classes.submit}>
              <Button
                    type="button"
                    
                    variant="contained"
                    color="primary"
                    onClick={()=>cancel()}
                    
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    
                    variant="contained"
                    color="primary"
                    
                >
                    Save
                </Button>
                </div> : 
                <div className={classes.submit}>
                <Button
                    type="submit"
                    
                    variant="contained"
                    color="primary"
                    
                >
                    Reset Password
                </Button>
                </div> }
              
              </ValidatorForm>
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="button" className="forgotPasswordText" >
                Password must:
            </Typography>
            <Typography variant="button" className="forgotPasswordText" >
                Be between 8-20 characters in length
            </Typography>
            <Typography variant="button" className="forgotPasswordText" >
                Include one upper case character
            </Typography>
            <Typography variant="button" className="forgotPasswordText" >
                Include one lower case character
            </Typography>
            <Typography variant="button" className="forgotPasswordText" >
                Include one numeric character
            </Typography>
            <Typography variant="button" className="forgotPasswordText" >
                Include one special character
            </Typography>
            <Typography variant="button" className="forgotPasswordText" >
                Not be one of the last 6 passwords used
            </Typography>
            <Typography variant="button" className="forgotPasswordText" >
                Not contain your first or last name
            </Typography>
        </Grid>
      </Grid>
      </Paper>
      <AppBar position="relative"  className={classes.footer}>
          <Toolbar style={{padding:0}}>
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <img className="footer" src={footer} height='60px' alt="footer"/>
                </Grid>
                <Grid item xs={12} sm={10}>
                <Grid container direction="row" style={{paddingTop:20}}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="button" className="decoratedfooterText" onClick={()=>window.open(window.location.origin+"/policy.pdf")} tabIndex="0">
                        Travel Policy Guidelines
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#privacy")} tabIndex="0">
                        Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#security")} tabIndex="0">
                        Security Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use")} tabIndex="0">
                        Acceptable Use
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://transparency.arkansas.gov/")} tabIndex="0">
                        Transparency
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:10}}>
                    <Typography variant="button" className="footerText" tabIndex="0">
                      Ⓒ{new Date().getFullYear()} State of Arkansas.All Rights Reserved.
                    </Typography>
                  </Grid>
                  
              </Grid>
              </Grid>
              </Grid>
          </Toolbar>
      </AppBar>
    </div>
    </Box>
  );
}

export default ResetPassword;
