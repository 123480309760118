import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';*/
import Typography from '@material-ui/core/Typography';
import {postFetch} from '../http';
import { useHistory,useLocation  } from "react-router-dom";
import Button from '@material-ui/core/Button';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MileageExp from './mileageExp';
import LodgingExpense from './lodgingExp';
import MealsExp from './mealsExp';
import CommonCarrierExp from './carrierExp';
import IncidentalExp from './incidentalExp';
import SummaryReconcile from './summaryReconcile';
import moment from "moment";
import '../App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected":{
        fontWeight:600
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'20px 0',
    padding:'0 10px'
  },
  textfield:{
      padding:'10px 10px 10px 0'
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  datepicker:{
    position: 'relative',
    bottom: 7,
    padding:'0 10px 0 0'
  }
}));

export default function TravelInfo(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [error,setError] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [onInit, setOnInit] = React.useState(true);
  const [dialogSw, setDialogSw] = React.useState(null);
  const [reimbursementDetails, setReimbursementDetails] = React.useState({id:null,expenseStartDate:null,expenseEndDate:null,explanation:null,aasisId:null,vendorId:null,division:null,internalOrder:null,costCenter:null,mileageSw:'N',lodgingSw:'N',mealsSw:'N',carrierSw:'N',incidentalSw:'N',userId:null,assignedTo:null,status:null});
  const [resetDetails, setResetDetails] = React.useState({id:null,expenseStartDate:null,expenseEndDate:null,explanation:null,aasisId:null,cendorId:null,division:null,internalOrder:null,costCenter:null,mileageSw:'N',lodgingSw:'N',mealsSw:'N',carrierSw:'N',incidentalSw:'N',userId:null,assignedTo:null,status:null})
  const [currentFiscalBeginDate, setCurrentFiscalBeginDate] = React.useState(new Date);
  const [previousFiscalBeginDate, setPreviousFiscalBeginDate] = React.useState(new Date);


  


 React.useEffect(() => {
    const loadReimbursement =  async ()=>{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setOnInit(false);
      const request = { 
        id:parseInt(localStorage.getItem("requestId"))
      } 
      if(request.id != null)
      {
       await postFetch("/reimbursement/loadById",request).then((data)=>{ 
            if(!data.message){
              data.expenseStartDate = convertDateFromServer(data.expenseStartDate);
              data.expenseEndDate = convertDateFromServer(data.expenseEndDate);
                setResetDetails(data);
                //data.internalOrder = props.travellerInfo.empInternalOrder === null ? null : props.travellerInfo.empInternalOrder.toString().trim();
                //data.costCenter = props.travellerInfo.empCostCenter === null ? null : props.travellerInfo.empCostCenter.toString().trim();
                //data.aasisId = props.travellerInfo.aasisempNum === null ? null : props.travellerInfo.aasisempNum.toString().trim();
                //data.vendorId = props.travellerInfo.vendorNum.toString().trim();
                setReimbursementDetails(data);
                localStorage.setItem("dates",null);
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });                                
                },1000);                
                if(data.status !== 'IP' && data.status !== 'RR'){
                  setReadOnly(true);
                  localStorage.setItem("readOnly",true);
                }
                else{
                  localStorage.removeItem("readOnly");
                }
                GetCurrentFiscalYear();
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });        
        
      }
    }    

    if(onInit && (localStorage.getItem("requestId") !== undefined) && (localStorage.getItem("requestId") !== null)){
      loadReimbursement();        
    }

    if(onInit && ((localStorage.getItem("requestId") === null) )){
      let newDetails = {...reimbursementDetails};
     // newDetails.internalOrder = props.travellerInfo.empInternalOrder === null ? null : props.travellerInfo.empInternalOrder.toString().trim();
     // newDetails.costCenter = props.travellerInfo.empCostCenter === null ? null : props.travellerInfo.empCostCenter.toString().trim();
      newDetails.aasisId = props.travellerInfo.aasisempNum === null ? null : props.travellerInfo.aasisempNum.toString().trim();
      newDetails.vendorId = props.travellerInfo.vendorNum.toString().trim();
      newDetails.division = props.travellerInfo.divisionCode === null ? props.userInfo.division: props.travellerInfo.divisionCode.toString().trim();
      newDetails.userId = props.userInfo.id;
      newDetails.status = 'IP';
      setReimbursementDetails(newDetails);
      setOnInit(false);
      localStorage.setItem("dates",null);     
      GetCurrentFiscalYear(); 
    }
  },[props,onInit,reimbursementDetails,location]);
  

  const changeReimbursementDetails = (key,value,section)=>{
    if(key === 'mealsSw' && reimbursementDetails.mealsSw === 'N' && reimbursementDetails.lodgingSw === 'N'){
      value=null;
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:'Must have a lodging expense to request meals expense reimbursements.' });                                 
      },1000);
    }
    else if(key === 'lodgingSw' && reimbursementDetails.lodgingSw === 'Y' && reimbursementDetails.mealsSw === 'Y'){
      value=null;
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:'Must have a lodging expense to request meals expense reimbursements.' });                                 
      },1000);
    }
    else if(key === 'lodgingSw' && (reimbursementDetails.lodgingSw === 'N'||reimbursementDetails.lodgingSw === 'Y') && (reimbursementDetails.expenseStartDate === null||reimbursementDetails.expenseEndDate === null) ){
      value = null;
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:'Must enter Expense Start and End Dates prior to adding expenses.' });                                 
      },1000);
    } 
    else if (key === 'mealsSw' && (reimbursementDetails.mealsSw === 'N'||reimbursementDetails.mealsSw === 'Y') && (reimbursementDetails.expenseStartDate === null||reimbursementDetails.expenseEndDate === null) ){
      value = null;
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:'Must enter Expense Start and End Dates prior to adding expenses.' });                                 
      },1000);
    } 
    else if (key === 'mileageSw' && (reimbursementDetails.mileageSw === 'N'||reimbursementDetails.mileageSw === 'Y') && (reimbursementDetails.expenseStartDate === null||reimbursementDetails.expenseEndDate === null) ){
      value = null;
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:'Must enter Expense Start and End Dates prior to adding expenses.' });                                 
      },1000);
    } 
    else if (key === 'carrierSw' && (reimbursementDetails.carrierSw === 'N'||reimbursementDetails.carrierSw === 'Y') && (reimbursementDetails.expenseStartDate === null||reimbursementDetails.expenseEndDate === null) ){
      value = null;
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:'Must enter Expense Start and End Dates prior to adding expenses.' });                                 
      },1000);
    } else if (key === 'incidentalSw' && (reimbursementDetails.incidentalSw === 'N'||reimbursementDetails.incidentalSw === 'Y') && (reimbursementDetails.expenseStartDate === null||reimbursementDetails.expenseEndDate === null) ){
      value = null;
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:'Must enter Expense Start and End Dates prior to adding expenses.' });                                 
      },1000);
    } 

    else{
      if((key === 'expenseStartDate') && (value!== null) && (value.setHours(0,0,0) > new Date(new Date().toDateString()).setHours(0,0,0))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense Start Date and Expense End Dates must be past or current dates.' });                                 
        },1000);
      }
      if((key === 'expenseEndDate') && (value!== null) && (value.setHours(0,0,0) > new Date(new Date().toDateString()).setHours(0,0,0))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense Start Date and Expense End Dates must be past or current dates.' });                                 
        },1000);
      }
      if((key === 'expenseEndDate') && (value!== null) && (reimbursementDetails.expenseStartDate !== null) && (value < new Date(new Date(reimbursementDetails.expenseStartDate).toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense End Date cannot be prior to Expense Start Date.' });                                 
        },1000);
      }
      if((key === 'expenseStartDate') && (value!== null) && (reimbursementDetails.expenseEndDate !== null) && (value > new Date(new Date(reimbursementDetails.expenseEndDate).toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense End Date cannot be prior to Expense Start Date.' });                                 
        },1000);
      }
      if((key === 'expenseEndDate') && (value!== null) && (reimbursementDetails.expenseStartDate !== null) && ((value.getMonth() > 8 && new Date(reimbursementDetails.expenseStartDate).getMonth() < 9) || (new Date(reimbursementDetails.expenseStartDate).getMonth() < 9) && value.getFullYear()>new Date(reimbursementDetails.expenseStartDate).getFullYear())){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense Start Date and Expense End Date range are not too cross between Federal Fiscal Years.' });                                 
        },1000);
      }

      if((key === 'expenseStartDate') && (value!== null) && (reimbursementDetails.expenseEndDate !== null) && ((new Date(reimbursementDetails.expenseEndDate).getMonth() > 8 &&  value.getMonth()< 9) || (value.getMonth() < 9) && value.getFullYear()<new Date(reimbursementDetails.expenseEndDate).getFullYear())){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense Start Date and Expense End Date range are not too cross between Federal Fiscal Years.' });                                 
        },1000);
      }
      if((key === 'expenseEndDate') && (value!== null) && (reimbursementDetails.expenseStartDate !== null) && ((value.getMonth() > 5 && new Date(reimbursementDetails.expenseStartDate).getMonth() < 6) || (new Date(reimbursementDetails.expenseStartDate).getMonth() < 6) && value.getFullYear()>new Date(reimbursementDetails.expenseStartDate).getFullYear())){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense Start Date and Expense End Date range are not too cross between State Fiscal Years.' });                                 
        },1000);
      }

      if((key === 'expenseStartDate') && (value!== null) && (reimbursementDetails.expenseEndDate !== null) && ((new Date(reimbursementDetails.expenseEndDate).getMonth() > 5 &&  value.getMonth()< 6) || (value.getMonth() < 6) && value.getFullYear()<new Date(reimbursementDetails.expenseEndDate).getFullYear())){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense Start Date and Expense End Date range are not too cross between State Fiscal Years.' });                                 
        },1000);
      }

      if((key === 'expenseStartDate') && (value!== null) && (value < previousFiscalBeginDate)){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The Expense Reimbursement not allowed past previous State Fiscal Year' });                                 
        },1000);
      }    
      let newDetails = {...reimbursementDetails};
      if(section === 2){
        if(newDetails[key] === 'Y'){
          setDialogSw(key);
          setOpen(true);
        }
        else{
          newDetails[key] = 'Y';
        }
      }
      else{
        setOpen(false);
        newDetails[key] = value;
      }
      if(newDetails.id === null && value === 'N' ){
        /*if(key === 'mileageSw'){
          newDetails.mileageSw = 'Y';
        }else if(key === 'lodgingSw'){
          newDetails.lodgingSw = 'Y';
        }else if(key === 'mealsSw'){
          newDetails.mealsSw = 'Y';
        } else if( key === 'carrierSw'){
          newDetails.carrierSw = 'N';
        }else if( key === 'incidentalSw'){
          newDetails.incidentalSw = 'Y';
        }*/
        newDetails.id = localStorage.getItem("requestId");
      }
      if(value === 'N' && newDetails.id != null)
      {        
        newDetails.expenseStartDate = new Date(newDetails.expenseStartDate);
        newDetails.expenseEndDate = new Date(newDetails.expenseEndDate);
        updateDWSwitch(newDetails);
      }
      setReimbursementDetails(newDetails);
      
    }
}

function GetCurrentFiscalYear()
{
  let today = new Date();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  if(month < 7)
  {
    setCurrentFiscalBeginDate(new Date("07/01/"+ (year-1)));
    setPreviousFiscalBeginDate(new Date("07/01/"+ (year-2)));
  }else{
    setCurrentFiscalBeginDate(new Date("07/01/"+ (year)));
    setPreviousFiscalBeginDate(new Date("07/01/"+ (year-1)));
  }
}


  const reset = async()=>{    
    setReimbursementDetails({...resetDetails});
   }

  const updateDWSwitch = async (request)=>
  {   
      await postFetch("/reimbursement/updatestatus",request).then((data)=>{ 
        console.log(data);
      }).catch((e)=>{
        console.log(e);
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
    });
  }
  function convertDateFromServer(value){
    if(value !== null && value !== undefined){
      let serverDateArray = [];
       value.indexOf('-') === -1 ?  serverDateArray = value.split("/") :  serverDateArray = value.split("-");
        if(serverDateArray.length > 1){
          return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
        }
        else{
          return serverDateArray[0];
        }
      }
      else{
        return null;
      }
    
  }

  const continueAction = async ()=>{
    let startDate = new Date(reimbursementDetails.expenseStartDate).setHours(0,0,0);
    let endDate = new Date(reimbursementDetails.expenseEndDate).setHours(0,0,0);
    let inboundDates = (localStorage.getItem("dates") !== null && localStorage.getItem("dates") !== undefined) ? localStorage.getItem("dates").split(",") : [];
    let datesCheck = true;
    inboundDates.forEach((date)=>{
      date = date.replace("mi","");
      date = date.replace("me","");
      date = date.replace("ic","");
      date = date.replace("lo","");
      date = date.replace("ca","");
      if(date !== undefined && date !== null && date !== '' && (new Date(convertDateFromServer(date)).setHours(0,0,0) < startDate || new Date(convertDateFromServer(date)).setHours(0,0,0) > endDate)){
        datesCheck = false;
      }
    })
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
    }
    else if (!datesCheck){
      props.loader({loader:false,notification:true,message:"error",validationMessage:"All Expenses should be in between Total Reimbursement Expense Start Date and End Date." });
    }
    else{
      props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
      let request = {...reimbursementDetails};

      request.expenseStartDate = new Date(request.expenseStartDate);
      request.expenseEndDate = new Date(request.expenseEndDate);
      request.status = request.status === null ? 'IP': request.status;
      if(request.id === null){
        request.id = parseInt(localStorage.getItem("requestId"));
      }
      if(request.recordVersion === undefined){
        request.recordVersion = parseInt(localStorage.getItem("recordVersion"));
      }
      await postFetch("/reimbursement/save",request).then((data)=>{ 
          if(!data.message){
            
                data.expenseStartDate = convertDateFromServer(data.expenseStartDate);
                data.expenseEndDate = convertDateFromServer(data.expenseEndDate);
                setReimbursementDetails(data);     
                localStorage.setItem("requestId",data.id); 
                
                setTimeout(()=>{    
                  props.loader({loader:false,notification:true,message:"success",validationMessage:"Travel information saved successfully." });                         
              },0);
              setTimeout(() => {
                history.push("tr1/summary");  
              }, 500);
          }
          else{
              setTimeout(()=>{                         
                  props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
              },1000);
          }
      }).catch((e)=>{
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
      });
    }
  }






  return (
    <div className={classes.root}>
      {(window.location.pathname === '/tr1') ? <div>
        <Dialog
          open={open}
          onClose={()=>changeReimbursementDetails(dialogSw,'Y',1)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Unchecking expense type will delete all related expenses already added. Do you want to delete those expenses ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>changeReimbursementDetails(dialogSw,'Y',1)} color="primary">
              No
            </Button>
            <Button onClick={()=>changeReimbursementDetails(dialogSw,'N',1)} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
      </Dialog>
     <Typography className={classes.header}>Travel Information</Typography>
        <div>
            <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                      <span className="datepickerLabel">Expense Start Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="expense-begin-date"
                            autoOk={true}
                            className = {readOnly ? "disabled" : "required"}
                            disabled = {readOnly}
                            size="small"
                            fullWidth
                            value={reimbursementDetails.expenseStartDate}
                            error={(reimbursementDetails.expenseStartDate === null) || (!moment(reimbursementDetails.expenseStartDate, 'MM/DD/YYYY').isValid())}
                            maxDate={new Date()}
                            onChange={(date)=> changeReimbursementDetails('expenseStartDate',date,1)}
                            placeholder="MM/DD/YYYY"
                            KeyboardButtonProps={{
                                'aria-label': 'start date',
                            }}
                            inputVariant="outlined"
                            helperText={(reimbursementDetails.expenseStartDate === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(reimbursementDetails.expenseStartDate !== null && !moment(reimbursementDetails.expenseStartDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null}
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Expense End Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="expense_end-date"
                            autoOk={true}
                            className = {readOnly ? "disabled" : "required"}
                            disabled = {readOnly}
                            size="small"
                            fullWidth
                            value={reimbursementDetails.expenseEndDate}
                            error={(reimbursementDetails.expenseEndDate === null) || (!moment(reimbursementDetails.expenseEndDate, 'MM/DD/YYYY').isValid())}
                            onChange={(date)=> changeReimbursementDetails('expenseEndDate',date,1)}
                            minDate={reimbursementDetails.expenseStartDate === null ? new Date() : reimbursementDetails.expenseStartDate}
                            placeholder="MM/DD/YYYY"
                            KeyboardButtonProps={{
                                'aria-label': 'end date',
                            }}
                            inputVariant="outlined"
                            helperText={(reimbursementDetails.expenseEndDate === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(reimbursementDetails.expenseEndDate !== null && !moment(reimbursementDetails.expenseEndDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null}
                    </Grid>
                </Grid>
            <Grid container className={classes.filters}>
            <Grid item xs={10} className={classes.textfield}>
                <span className={classes.textLabel}>Trip Cost Assignment Explanation </span>
                <TextField
                    id="tripExp"
                    multiline
                    value={reimbursementDetails.explanation === null ? '' : reimbursementDetails.explanation}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                      }}
                    size="small"
                    fullWidth
                    className = {readOnly ? "disabled" : null}
                    disabled = {readOnly}
                    onChange={(e)=> changeReimbursementDetails("explanation",e.target.value,1)}
                    inputProps={{
                        maxLength: 4000
                      }}
                />
                <FormHelperText>{reimbursementDetails.explanation === null ? 0 :reimbursementDetails.explanation.length}/4000 Characters</FormHelperText>
            </Grid>
            </Grid>
            <Grid container className={classes.filters}>
                        {(props.pageInfo.find((page)=> page.pageId === 2).pageSw === 'Y') ? <Grid item xs={12} sm={2} className={classes.textfield}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={reimbursementDetails.mileageSw === 'Y'}
                                    onChange={(e)=> changeReimbursementDetails('mileageSw',e.target.value,2)}
                                    name="Mileage Expense"
                                    color="primary"
                                    className = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 2).permissionSw !== 'Y')) ? "disabled" : null}
                                    disabled = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 2).permissionSw !== 'Y'))}
                                />
                            }
                            label="Mileage Expense"
                            labelPlacement="end"
                            />
                        </Grid> : null}
                        {(props.pageInfo.find((page)=> page.pageId === 3).pageSw === 'Y') ? <Grid item xs={12} sm={2} className={classes.textfield}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={reimbursementDetails.lodgingSw === 'Y'}
                                    onChange={(e)=> changeReimbursementDetails('lodgingSw',e.target.value,2)}
                                    name="Lodging Expense"
                                    color="primary"
                                    className = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 3).permissionSw !== 'Y')) ? "disabled" : null}
                                    disabled = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 3).permissionSw !== 'Y'))}
                                />
                            }
                            label="Lodging Expense"
                            labelPlacement="end"
                            />
                        </Grid> : null}
                        {(props.pageInfo.find((page)=> page.pageId === 1).pageSw === 'Y') ? <Grid item xs={12} sm={2} className={classes.textfield}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={reimbursementDetails.mealsSw === 'Y'}
                                    onChange={(e)=> changeReimbursementDetails('mealsSw',e.target.value,2)}
                                    name="Meals Expense"
                                    color="primary"
                                    className = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 1).permissionSw !== 'Y')) ? "disabled" : null}
                                    disabled = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 1).permissionSw !== 'Y'))}
                                />
                            }
                            label="Meals Expense"
                            labelPlacement="end"
                            />
                        </Grid>:null}
                        {(props.pageInfo.find((page)=> page.pageId === 4).pageSw === 'Y') ?<Grid item xs={12} sm={2} className={classes.textfield}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={reimbursementDetails.carrierSw === 'Y'}
                                    onChange={(e)=> changeReimbursementDetails('carrierSw',e.target.value,2)}
                                    name="Carrier Expense"
                                    color="primary"
                                    className = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 4).permissionSw !== 'Y')) ? "disabled" : null}
                                    disabled = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 4).permissionSw !== 'Y'))}
                                />
                            }
                            label="Carrier Expense"
                            labelPlacement="end"
                            />
                        </Grid> : null}
                        {(props.pageInfo.find((page)=> page.pageId === 5).pageSw === 'Y') ? <Grid item xs={12} sm={2} className={classes.textfield}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={reimbursementDetails.incidentalSw === 'Y'}
                                    onChange={(e)=> changeReimbursementDetails('incidentalSw',e.target.value,2)}
                                    name="Incidental Expense"
                                    color="primary"
                                    className = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 5).permissionSw !== 'Y')) ? "disabled" : null}
                                    disabled = {(readOnly || (props.pageInfo.find((page)=> page.pageId === 5).permissionSw !== 'Y'))}
                                />
                            }
                            label="Incidental Expense"
                            labelPlacement="end"
                            />
                        </Grid> : null}
                      </Grid>
                      </div>
                      </div> : null}
                        {(reimbursementDetails.mileageSw === 'Y') ? 
                        <div>
                          <MileageExp pageInfo={props.pageInfo} staticData = {props.staticData} loader={props.loader} reimbursement={reimbursementDetails} userInfo={props.userInfo} travellerInfo={props.travellerInfo}/>
                        </div> : null}
                        {(reimbursementDetails.lodgingSw === 'Y') ? <LodgingExpense  pageInfo={props.pageInfo} staticData = {props.staticData} loader={props.loader} reimbursement={reimbursementDetails} userInfo={props.userInfo} travellerInfo={props.travellerInfo}/> : null}
                        {(reimbursementDetails.mealsSw === 'Y') ? <MealsExp  pageInfo={props.pageInfo} staticData = {props.staticData} loader={props.loader} reimbursement={reimbursementDetails} userInfo={props.userInfo} travellerInfo={props.travellerInfo}/> : null}
                        {(reimbursementDetails.carrierSw ==='Y') ? <CommonCarrierExp  pageInfo={props.pageInfo} staticData = {props.staticData} loader={props.loader} reimbursement={reimbursementDetails} userInfo={props.userInfo} travellerInfo={props.travellerInfo} /> : null}
                        {(reimbursementDetails.incidentalSw === 'Y') ? <IncidentalExp  pageInfo={props.pageInfo} staticData = {props.staticData} loader={props.loader} reimbursement={reimbursementDetails} userInfo={props.userInfo} travellerInfo={props.travellerInfo}/> : null}      
                    {(window.location.pathname === '/tr1') ? <div style={{textAlign:'center',paddingBottom:10}}>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={()=>reset()}>Reset</Button>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={()=>continueAction()}>Continue</Button>
                    </div> : null}
          {(window.location.pathname === '/tr1/summary') ? <SummaryReconcile staticData={props.staticData} loader={props.loader} reimbursement={reimbursementDetails} userInfo={props.userInfo} travellerInfo={props.travellerInfo}/> : null}
    </div>
  );
}
