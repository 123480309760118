import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import logo from './imgs/logo.png';
import Button from '@material-ui/core/Button';
import { useHistory  } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import cardImage1 from './imgs/card1.jpg';
import cardImage2 from './imgs/card2.jpg';
import Grid from '@material-ui/core/Grid';

import Config from './assets/config';
import GA4React from "ga-4-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiAppBar-root":{
        height:'175px'
    },
    "& .MuiToolbar-root":{
        height: '100%',
        display:'block',
        textAlign:'center'
    },
    "& .MuiGrid-item":{
        transition: 'all 0.3s ease-out',
        "&:hover":{
            transform: 'translateY(-15px) scale(1.015) translateZ(0)',
            border:'none'
        }
    },
    "& .MuiPaper-root":{
        backgroundColor:'transparent'
    },
    "& .MuiPaper-elevation3":{
        margin:" 0 0 0 20px"
    },
    [theme.breakpoints.down('sm')]:{
        "& .MuiPaper-elevation3":{
            margin:" 0 10px 20px 10px"
        },
        "& .makeStyles-logoutButton-4":{
            bottom: '0px'
        }
    }
  },
  grid:{
    position: 'relative',
    top: '50px'
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  logoutButton:{
    float: 'right',
    position: 'relative',
    bottom: '30px',
    color: '#fff',
    border: '1px solid #fff',
    "&:hover":{
        border: '1px solid #fff'
    }
  },
  media: {
    minHeight: "250px",
    backgroundColor: '#fff'
  },
  cardText:{
    textAlign: 'center',
    padding:"15px 5px",
    background: '#fff',
    fontSize: '17px',
    fontWeight: 600,
    borderTop: '3px solid #1a75c9'
  },
}));

export default function AppManager() {
  
  const classes = useStyles();
  const history = useHistory();

  const logoff = ()=>{
      Cookies.remove('state');
      localStorage.clear();
      history.push('/logout');
  }

  const travelReimbursement = (value)=>{
      localStorage.setItem('appId', value);
      if(value===1){
      history.push('/dashboard');
      }
      if(value===2){
      history.push('/dashboard');
      }
  }
  
  try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-511XD17SWX");
      ga4react.initialize().then((ga4) => {
        ga4.pageview(window.location.pathname)        
        //ga4.gtag('event','pageview','path') // or your custom gtag event
      },(err) => {
        console.error(err)
      })
    }, 4000);
  } catch (err) {
        console.error(err);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="logo">
            <img src={logo} height='30px' width='30px' alt="Logo"/>
          </IconButton>
          <Typography variant="h6" color="inherit">
            Welcome {(Cookies.get("state") === undefined) ? "": JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).firstName} {(Cookies.get("state") === undefined) ? "": JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).lastName}
          </Typography>
          <Button variant="outlined" className={classes.logoutButton} onClick={logoff}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container direction="row" spacing={0} className={classes.grid}>
      <Grid item xs={12} sm={2}></Grid>
      <Grid item xs={12} sm={4}>
      <Paper elevation={3}>
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                className={classes.media}
                image={cardImage1}
                title="Vehicle Maintenance"
                />
            </CardActionArea>
                <div className={classes.cardText}>
                    <span>
                        Reserve or Manage Vehicle
                    </span><br/><br/>
                    <Button variant="contained" color="primary" onClick={()=> travelReimbursement(1)}>
                        Enter
                    </Button>
                </div>
        </Card>
      </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
      <Paper elevation={3}>
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                className={classes.media}
                image={cardImage2}
                title="Travel Reimbursement"
                />
                </CardActionArea>
                <div className={classes.cardText}>
                    <span>
                        Submit, Review or Approve Expense
                    </span><br/><br/>
                    <Button variant="contained" color="primary" onClick={()=> travelReimbursement(2)}>
                        Enter
                    </Button>
                </div>
        </Card>
      </Paper>
      </Grid>
      <Grid item xs={12} sm={2}></Grid>
      </Grid>
      
    </div>
  );
}