import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import loginHeader from './imgs/loginHeader.png';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useHistory  } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiButton-startIcon":{
      marginRight:2,
      position: "relative",
      bottom: 1
    }
  },
  menuButton: {
    marginRight: 0,
    paddingTop:0,
    paddingRight:0
  },
  fabIcon:{
    position: 'absolute',
    right: 5,
    top: 0,
    fontSize:18,
    textTransform: 'capitalize'
  }
}));

export default function DenseAppBar() {
  const classes = useStyles();
  const history = useHistory();


  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense" style={{paddingLeft:0}}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="header">
              <img className="" src={loginHeader} height='55px' alt="header"/>
          </IconButton>
          <Button className={classes.fabIcon} color="inherit" startIcon={<HelpOutlineIcon /> } onClick={()=>history.push("/faq")} >Help</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}