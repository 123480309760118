import React from 'react';
import './App.css';
import Config from './assets/config';
import Typography from '@material-ui/core/Typography';


function Logout() {
  return (
    <div style={{textAlign:'center',marginTop:50}}>
            <Typography variant="h6" className="logout" >
                You have been signed out of your account 
            </Typography>
            <Typography variant="h6" className="logout" >
                It's a good idea to close all browser windows.
            </Typography>
            <Typography variant="h6" className="logout" >
            To sign back in, please navigate to:
            </Typography>
            <Typography variant="h6" className="logout" >            
            <a href={Config.logoutUrl}>{Config.logoutUrl}</a>
            </Typography>
            <Typography variant="h6" className="logout" >
            If you are a foster parent, please navigate back to the DCFS Provider Portal for login.
            </Typography>
        </div>
  );
}

export default Logout;
