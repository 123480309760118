import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Typography from '@material-ui/core/Typography';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from '../assets/config';*/
import {postFetch} from '../http';
import { useHistory  } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import '../App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected":{
        fontWeight:600
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'20px 0',
    padding:'0 10px'
  }
}));

export default function AccessControl(props) {
  const classes = useStyles();
  const history = useHistory();
  const [summaryData, setSummaryData]=React.useState([]);
  const [onInit, setOnInit] = React.useState(true);
  const [roleDetails, setRoleDetails] = React.useState([]);
  const [roleData, setRoleData] = React.useState([]);
  const [title, setTitle] = React.useState(null);

  



  React.useEffect(() => {
    window.scrollTo(0,0);
    const loadRoles =  async ()=>{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setOnInit(false);
        history.push("/config/accesscontrol");
        const request = {
          appId:parseInt(localStorage.getItem('appId'))
        }  
       await postFetch("/roles/loadByAppId",request).then((data)=>{ 
            if(!data.message){
                setSummaryData(data);
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });                                
                },1000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
    const loadPageDetails =  async ()=>{
      props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
      setOnInit(false);
      const request = {
        appId:parseInt(localStorage.getItem('appId'))
      }  
     await postFetch("/accesscontrol/loadByAppId",request).then((data)=>{ 
          if(!data.message){
              setRoleData(data.pageInfo);
              setTimeout(()=>{                         
                  props.loader({loader:false,notification:false });                                
              },1000);
          }
          else{
              setTimeout(()=>{                         
                  props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
              },1000);
          }
      }).catch((e)=>{
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
      });
  }
    if(onInit){
        loadRoles();
        loadPageDetails();
    }
  },[props,onInit,history,roleDetails]);
  

  const renderTitleView = (row)=>{
    return (<div>{row.pageDescription}</div>);
  }

  const save =async ()=>{
    props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
    let request = {pageInfo: [...roleDetails]};
    await postFetch("/accesscontrol/save",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"success",validationMessage:"Access Control saved successfully" });                                
            },1000);
            setTimeout(()=>{                         
              setOnInit(true);                               
          },3000);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
    });
  }

  const renderPageViewable = (row)=>{
    return (<div>
        <FormControl component="fieldset">
            <RadioGroup row aria-label="pageview-sw" name={"pageview-sw" + row.pageDescription}  value={row.pageSw} onChange={(e)=> changecontrol(row,'pageSw',e.target.value)}>
                    <div>
                        <FormControlLabel value="Y" control={<Radio  color="primary" />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio  color="primary" />} label="No" />
                    </div>
            </RadioGroup>
        </FormControl>
    </div>);
  }
  
  const renderPermissions = (row)=>{
    return (<div>
        <FormControl component="fieldset">
            <RadioGroup row aria-label="permission-sw" name={"permission-sw" + row.pageDescription}  value={row.permissionSw} onChange={(e)=> changecontrol(row,'permissionSw',e.target.value)}>
                    <div>
                        <FormControlLabel value="N" control={<Radio  color="primary" />} label="Read-Only" />
                        <FormControlLabel value="Y" control={<Radio  color="primary" />} label="Read/Write" />
                    </div>
            </RadioGroup>
        </FormControl>
    </div>);
  }

  const changecontrol = (row,key,value)=>{
    let newDetails = roleDetails.slice(0);
    const index = newDetails.findIndex((page)=> page.id === row.id);
    newDetails[index][key] = value;
    setRoleDetails(newDetails);
  }

  const renderActionButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/></div>);
  }
  const viewFunction = (value)=>{
      let details =roleData.slice(0);
      setRoleDetails(details.filter((role)=> role.roleId === value.id));
      setTitle(value);
      history.push("/config/accesscontrol/details");
  }

  const reset = async ()=>{
    props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
    const request = {
      appId:parseInt(localStorage.getItem('appId'))
    }  
   await postFetch("/accesscontrol/loadByAppId",request).then((data)=>{ 
        if(!data.message){
          setRoleDetails(data.pageInfo.filter((role)=> role.roleId === title.id));
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false });                                
            },1000);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
    });
}


  const summaryRoleColumns = [
    {
        name:"Role",
        field:"roleDescription",
        type:"text"
    },
    {
        name:"Action",
        field:"action",
        type:"input",
        renderView: renderActionButton
    }
  ]

  const roleDetailsColumns = [
    {
        name:"Page",
        field:"pageDescription",
        type:"input",
        renderView:renderTitleView
    },
    {
        name:"Page Viewable",
        field:"pageSw",
        type:"input",
        renderView:renderPageViewable
    },
    {
        name:"Permissions",
        field:"permissionSw",
        type:"input",
        renderView: renderPermissions
    }
  ]

  return (
    <div className={classes.root}>
     {window.location.pathname === '/config/accesscontrol' ?
                <div>
                        <Typography className={classes.header}>Access Control Summary</Typography>
                        <Paper elevation={4}>
                            <Box p={"10px"}>
                                <Gridtable columns={summaryRoleColumns} data={summaryData}></Gridtable>
                            </Box> 
                        </Paper>
                        
                </div> : null}
    {window.location.pathname === '/config/accesscontrol/details' ?
                <div>
                <Typography className={classes.header}>Access Control Details: {title.roleDescription}</Typography>
                    <Paper elevation={0}>
                                <Box p={"10px"}>
                                    <Gridtable columns={roleDetailsColumns} data={roleDetails} rowsperpage={5}></Gridtable>
                                </Box> 
                    </Paper>
                    <div style={{textAlign:'center',paddingBottom:10}}>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={reset}>Reset</Button>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={save}>Save</Button>
                    </div>
                </div> : null}
    </div>
  );
}
