import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {postFetch} from '../http';
import { useHistory  } from "react-router-dom";
import Button from '@material-ui/core/Button';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Gridtable from '../gridtable';
import Cities from '../assets/cities';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from "moment";
import TextField from '@material-ui/core/TextField';
import '../App.css';
import NumberFormat from 'react-number-format';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {postFileDownload} from '../http';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Config from '../assets/config';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected":{
        fontWeight:600
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'20px 0',
    padding:'0 10px'
  },
  textfield:{
      padding:'10px 10px 10px 0'
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  datepicker:{
    position: 'relative',
    bottom: 7,
    padding:'0 10px 0 0'
  }
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="(###)-###-####"
        mask="_"
      />
    );
  }

  function NumberCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="##########"
      />
    );
  }

  function AmountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}
        prefix="$"
      />
    );
  }

  function CPGallonAmountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        decimalScale={3}
        fixedDecimalScale={true}
        allowNegative={false}
        prefix="$"
      />
    );
  }
  

  function GallonFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        decimalScale={3}
        fixedDecimalScale={true}
        allowNegative={false}
        
      />
    );
  }

  const Subheader = ({children}) =>{
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
  }

export default function Request(props) {
  const classes = useStyles();
  const history = useHistory();
  const [error,setError] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(false);
  const [onInit, setOnInit] = React.useState(true);
  const [maintenanceData, setMaintenanceData] = React.useState([]);
  const [reservationDetails, setReservationDetails] = React.useState({id:null,destinationState:null,destinationCity:null,reason:null,decNotes:null,status:null,userId:props.userInfo.id,officialStation:null,vehicleType:null,vehicleId:null,reservationStartDate:null,reservationStartTime:null,reservationEndDate:null,reservationEndTime:null,division:props.travellerInfo.divisionCode,mileage:null,reasonForCan:null})
  const [usageInfo, setUsageInfo] = React.useState({id:null,reservationId:reservationDetails.id,placeParked:null,parkingSpaceNum:null,beginMileage:null,endMileage:null,gallonsUsed:null,remFuel:null,fuelMileage:null,costPerGal:null,fuelCost:null,maintMileage:null,totalCost:parseFloat(0),isGasPurchased:'N'})
  const [usageResetInfo, setUsageResetInfo] = React.useState({id:null,reservationId:reservationDetails.id,placeParked:null,parkingSpaceNum:null,beginMileage:null,endMileage:null,gallonsUsed:null,remFuel:null,fuelMileage:null,costPerGal:null,fuelCost:null,maintMileage:null,totalCost:parseFloat(0),isGasPurchased:'N'});
  const [filters, setFilters] = React.useState({startDate:null,startTime:null,endDate:null,endTime:null,station:null,vehicleType:null,vehicleId:null,division:props.travellerInfo.divisionCode});
  const [summaryData, setSummaryData] = React.useState([]);
  const [resetDetails] = React.useState({id:null,destinationState:null,destinationCity:null,reason:null,decNotes:null,status:null,userId:props.userInfo.id,officialStation:null,vehicleType:null,vehicleId:null,reservationStartDate:null,reservationStartTime:null,reservationEndDate:null,reservationEndTime:null,division:props.travellerInfo.divisionCode,mileage:null,reasonForCan:null})
  const [usageDetails, setUsageDetails] = React.useState(false);
  const [oldRes, setOldRes] = React.useState(false);
  const [saveSw, setSaveSw] = React.useState(false);
  const [auditDialog,setAuditDialog] = React.useState(false);
  const [auditData,setAuditData] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [isSearched, setSearchedSw] = React.useState(false);
  const [readOnlyApp, setReadOnlyApp] = React.useState(false);
  const [readOnlyCancl,setReadOnlyCancl] = React.useState(true);
  const [tripFuelData, setTripFuelData] = React.useState([]);
  const [documents,setDocuments] = React.useState([]);
  const [tripFuelResetData, setTripFuelResetData] = React.useState([]);
  const [documentsReset,setDocumentsReset] = React.useState([]);
  const [maintenanceResetData,setMaintenanceResetData] = React.useState([]);
  const [readOnlyDoc, setReadOnlyDoc] = React.useState(false);
  const [readOnlyDpoc, setReadOnlyDpoc] = React.useState(false);
  const [fuelCheck,setFuelCheck] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  
  const changeDocumentDetails = (index,key,value)=>{
    let updatedDocs = [...documents].filter((doc)=> doc.delSw !== 'Y');
    updatedDocs[index][key] = value;
    setDocuments(updatedDocs);
    }

    const documentActionButton = (row,column,index)=>{
        return (<div style={{cursor:'pointer'}}><VisibilityRoundedIcon onClick={()=>viewDocument(row,index)} style={{fontSize:20}}/>{readOnlyDoc ? null : <DeleteIcon onClick={()=> changeDocumentDetails(index,'delSw','Y')} style={{fontSize:20}}/>}</div>);
    }

 React.useEffect(() => {
    const loadReservation =  async ()=>{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setOnInit(false);
      const request = { 
        id:parseInt(localStorage.getItem("reservationId"))
      } 
      
      if(request.id != null)
      {
       await postFetch("/vehicleReservations/findById",request).then((data)=>{ 
            if(!data.message){
                let newFilters = {...filters};
                newFilters.startDate = convertDateFromServer(data.reservationStartDate);
                newFilters.endDate = convertDateFromServer(data.reservationEndDate);
                newFilters.startTime = moment(data.reservationStartTime);
                newFilters.endTime = moment(data.reservationEndTime);
                newFilters.station = data.officialStation;
                newFilters.vehicleType = data.vehicleType;
                newFilters.vehicleId = data.vehicleId;
                setFilters(newFilters);
                getCities(data.destinationState);
                setReservationDetails({...data});
                data.vehicles.make = props.staticData.reference.makes.value.find((config)=> config.value === data.vehicles.make).name;
                data.vehicles.year = props.staticData.reference.VehicleYears.value.find((config)=> config.value === data.vehicles.year).name;
                data.vehicles.division = props.staticData.reference.DIVISIONS.value.find((config)=> config.value === data.vehicles.division).name;
                data.vehicles.station = props.staticData.reference.OfficialStations.value.find((config)=> config.value === data.vehicles.station).name;
                data.vehicles.status = props.staticData.reference.vehicleStatus.value.find((config)=> config.value === data.vehicles.status).name;
                data.vehicles.model = props.staticData.reference[data.vehicles.make].value.find((config)=> config.value === data.vehicles.model).name;
                data.vehicles.vehicleType = props.staticData.reference.vehicleTypes.value.find((config)=> config.value === data.vehicles.vehicleType).name;
                data.vehicles.poolVehicle = (data.vehicles.poolSw === null || data.vehicles.poolSw === 'N') ? 'No' : 'Yes';
                let newSummaryData = [...summaryData];
                newSummaryData.push(data.vehicles);
                setSummaryData(newSummaryData);
                if(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.ss") < moment(data.reservationEndTime).format("YYYY-MM-DDTHH:mm:ss.ss") 
                && (data.status === 'PC') ){
                    setUsageDetails(true);
                    setReadOnly(false);
                    localStorage.setItem("readOnly",true);
                    setReadOnlyCancl(false);
                    if((props.userInfo.role === 'VA' || props.userInfo.role === 'DP') && data.status === 'PC')
                    {
                        setReadOnlyApp(true);     
                                                                   
                    }
                } else  if(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.ss") > moment(data.reservationEndTime).format("YYYY-MM-DDTHH:mm:ss.ss") 
                && (data.status === 'PC') ){
                    setUsageDetails(true);
                    setOldRes(true);
                    setReadOnly(false);
                    localStorage.setItem("readOnly",true);
                    setReadOnlyCancl(false);
                    if((props.userInfo.role === 'VA' || props.userInfo.role === 'DP') && data.status === 'PC')
                    {
                        setReadOnlyApp(true);     
                                                                   
                    }
                }
                if(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.ss") < moment(data.reservationEndTime).format("YYYY-MM-DDTHH:mm:ss.ss") 
                && ( data.status === 'IP') ){
                    setUsageDetails(false);
                    setReadOnly(true);
                    localStorage.setItem("readOnly",true);
                    setReadOnlyCancl(false);
                    if((props.userInfo.role === 'VA' || props.userInfo.role === 'DP') && data.status === 'PC')
                    {
                        setReadOnlyApp(true);     
                                                                   
                    }
                }else if(moment(data.reservationEndTime).format("YYYY-MM-DDTHH:mm:ss.ss") < moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.ss")
                && (data.status === 'IP') && (props.userInfo.role === 'VA' || props.userInfo.role === 'DP')){
                    setReadOnlyCancl(false);                       
                }
                else if (data.status === 'CA')
                {
                    setUsageDetails(false);
                    setReadOnly(true);
                    setReadOnlyApp(true);
                    localStorage.setItem("readOnly",true);
                }
                else if(data.status === 'PC' || data.status === 'SC' || data.status === 'CO'){
                    setUsageDetails(true);
                }
                if( data.status === 'SC' || data.status === 'CO'){
                setOldRes(true); 
                }
                if(data.reservationDetails !== null){
                    setMaintenanceData(data.maintenances);
                    setMaintenanceResetData(data.maintenances);
                    setUsageInfo(data.reservationDetails);
                    setUsageResetInfo(data.reservationDetails);
                    setTripFuelData(data.fuelDetails);                    
                    //setTripFuelResetData(data.fuelDetails);
                    setTripFuelResetData(JSON.parse(JSON.stringify(data.fuelDetails)));
                    setDocuments(data.documents);
                    setDocumentsReset(data.documents);
                }
                else{
                    let updatedUsageInfo = {...usageInfo};
                    //updatedUsageInfo.beginMileage = data.vehicles.mileage;
                    setUsageInfo(updatedUsageInfo);
                }
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });                                
                },1000);
                if((data.status !== null && data.status === 'SC') && (props.userInfo.role === 'VA' || props.userInfo.role === 'DP'))
                {
                    setReadOnly(false);
                    localStorage.removeItem("readOnly");
                    setReadOnlyDoc(false);
                }else if(data.status !== null && data.status !== 'PC'){
                    setReadOnly(true);
                    localStorage.setItem("readOnly",true);
                }
                else{
                    localStorage.removeItem("readOnly");
                }

                if(props.userInfo.role === 'DP' && props.userInfo.division !== data.division && props.userInfo.id !== data.userId )
                {
                    setReadOnly(true);
                    setReadOnlyApp(true);     
                    setReadOnlyCancl(true);
                    setReadOnlyDoc(true);
                    setReadOnlyDpoc(true);
                }
                if(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.ss") < moment(data.reservationEndTime).format("YYYY-MM-DDTHH:mm:ss.ss") 
                && (data.status === 'PC') && (props.userInfo.role === 'VA' || props.userInfo.role === 'DP') && (props.userInfo.username !== data.createdBy) ){
                    setUsageDetails(false);
                }
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{           
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1500);
        });
      }
    }
    if(onInit && (localStorage.getItem("reservationId") !== undefined) && (localStorage.getItem("reservationId") !== null)){
        loadReservation();
        getStates();
    }

  },[props,onInit]);
  


const renderSelection = (row)=>{
    return (<div>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={"select"+row.id}
                            checked={filters.vehicleId === row.id}
                            disabled={readOnlyApp}
                            onChange={(e)=> changeSearchFilters('vehicleId',(filters.vehicleId === row.id) ? null : row.id)}
                            name="selection"
                            color="primary"
                        />
                    }
                    labelPlacement="end"
                /> 
    </div>);
  }

  const upload = async (target)=>{
    if(target.files.length === 0){
        props.loader({loader:false,notification:true,message:"error",validationMessage:"No File Selected" });
    }
    else if (target.files[0].type.length === 0) {
        target.value = null;
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Upload Failed. Please upload a document with a valid file extension such as .doc,.docx, .pdf, .txt, .jpeg etc." });
    }
    else{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        var fileByteArray=[];
        const fileReader = new FileReader();
        fileReader.onloadend= (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                var arrayBuffer = evt.target.result,
                array = new Uint8Array(arrayBuffer);
                for (var i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                 }
             }
        }
        fileReader.readAsArrayBuffer(target.files[0]);
        setTimeout(() => {
                const request = {};
                request.DocumentName = target.files[0].name;
                request.DocumentTitle = target.files[0].name + "_" + props.userInfo.id;
                request.DocumentID = null;
                request.DatabaseName = Config.edocutusDatabase;
                request.DocumentTypeID = Config.edocutusDocumentTypeId;
                request.DocumentData = fileByteArray;
                postFetch("/document",request).then((data)=>{ 
                    if(data.documentID !== null && !data.message){
                        let updatedDocs = [...documents];
                        updatedDocs.push({id:null,fileType:target.files[0].type, fileName:"Reservation Details - "+target.files[0].name,size:(target.files[0].size / 1048576).toFixed(2) + " MB",edocId:data.DocumentID,incidentalId:null,delSw:'N'});
                        setDocuments(updatedDocs);
                        target.value=null;
                        setTimeout(()=>{                         
                            props.loader({loader:false,notification:true,message:"success",validationMessage:"Document uploaded successfully" });                                
                        },1000);
                    }
                    else{
                        target.value = null;
                        setTimeout(()=>{                         
                            props.loader({loader:false,notification:true,message:"error",validationMessage:"Document upload failed" });                                
                        },1000);
                    }
                }).catch(()=>{
                    target.value = null;
                    setTimeout(()=>{                          
                        props.loader({loader:false,notification:true,message:"error",validationMessage:"Document upload failed." });                                 
                    },1000);
                });
        }, 1000);
    }
  }

  const viewDocument = (row,index)=>{
      props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
              postFileDownload("/document?DocumentID="+row.edocId+"&DatabaseName="+Config.edocutusDatabase+"&DocumentTypeID="+Config.edocutusDocumentTypeId).then((data)=>{ 
                  if(!data.message){
                    var fileUrl = "data:" + row.fileType + ";base64," + data;
                    fetch(fileUrl)
                        .then(response => response.blob())
                        .then(blob => {
                            var link = window.URL.createObjectURL(blob, { type: row.fileType });
                            window.open(link);
                        });
                      setTimeout(()=>{                         
                          props.loader({loader:false,notification:false});                                
                      },1000);
                  }
                  else{
                      setTimeout(()=>{                         
                          props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                      },1000);
                  }
              }).catch((e)=>{
                setTimeout(()=>{                          
                  props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
                },1000);
              });
  }

  const reset = async()=>{    
    setReservationDetails({...resetDetails});
   }

   const resetUsage = async()=>{    
    setUsageInfo({...usageResetInfo});
    setTripFuelData([...tripFuelResetData]);
    setMaintenanceData([...maintenanceResetData]);
    setDocuments([...documentsReset]);
   }

   const getCities = async (value)=>{
    props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
    await postFetch("/reimbursement/loadCities",value).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false,message:"success"});
                setCities([...data]);                      
            },1000);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }

  const getStates = async ()=>{
    let details = {id:null};
    props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
    await postFetch("/reimbursement/loadStates",details).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false,message:"success"});
                setStates([...data]);                      
            },1000);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }


  function convertDateFromServer(value){
    if(value !== null && value !== undefined){
      let serverDateArray = [];
       value.indexOf('-') === -1 ?  serverDateArray = value.split("/") :  serverDateArray = value.split("-");
        if(serverDateArray.length > 1){
          return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
        }
        else{
          return serverDateArray[0];
        }
      }
      else{
        return null;
      }
    
  }

  const changeSearchFilters = (key,value)=>{
    if((key === 'startDate') && (filters.startDate !== null) && (new Date(new Date(value).toDateString()) < new Date(new Date().toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'Start Date must be a current or future date' });                                 
        },1000);
    }
    if((key === 'startDate') && (filters.endDate !== null) && (new Date(new Date(value).toDateString()) > new Date(new Date(filters.endDate).toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'Start Date cannot be greater than the End Date' });                                 
        },1000);
    }
    if((key === 'endDate') && (filters.endDate !== null) &&(new Date(new Date(value).toDateString()) < new Date(new Date().toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'End Date must be a current or future date' });                                 
        },1000);
    }
    if((key === 'endDate') && (filters.startDate !== null) && (new Date(new Date(value).toDateString()) < new Date(new Date(filters.startDate).toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The End Date cannot be prior to Start Date.' });                                 
        },1000);
    }
    if(key === 'vehicleId' && value !== null){
        setSaveSw(false);
        getStates();
    }        
    // if(key === 'endDate' && filters.startTime != null && filters.endTime !=null){
    //     let startDateTime = new Date(filters.startDate.getFullYear(), filters.startDate.getMonth(), filters.startDate.getDate(), filters.startTime.getHours(), filters.startTime.getMinutes(),0);
    //     let endDateTime = new Date(filters.endDate.getFullYear(), filters.endDate.getMonth(), filters.endDate.getDate(), filters.endTime.getHours(), filters.endTime.getMinutes(),0);
    //     if(moment(endDateTime).isBefore(moment(startDateTime)))
    //     {
    //         filters.endTime = null;
    //         setTimeout(()=>{  
    //             props.loader({loader:false,notification:true,message:"error",validationMessage:"Reservation End Date/Time cannot be prior to Start Date/Time"});
    //             },1000);
    //         //newDetails.endDate = null;
    //     }            
    // }
    if(key === 'startDate'|| key === 'startTime'|| key === 'endDate'|| key === 'endTime'|| key === 'station')
    {
        setSummaryData([]);
        filters.vehicleId = null;
    }
    setSearchedSw(false);    
    setSaveSw(false);
    let newDetails = {...filters};
    newDetails[key] = value;
    if(key === 'endTime' && !value){
        newDetails.endTime=null;
    }
    setFilters(newDetails);
  }

  const validateEndTime = (key) => {
        let newDetails = {...filters};
        
        if( newDetails.startTime != null && newDetails.endTime !=null){
            let startDateTime = new Date(newDetails.startDate.getFullYear(), newDetails.startDate.getMonth(), newDetails.startDate.getDate(), newDetails.startTime.getHours(), newDetails.startTime.getMinutes(),0);
            let endDateTime = new Date(newDetails.endDate.getFullYear(), newDetails.endDate.getMonth(), newDetails.endDate.getDate(), newDetails.endTime.getHours(), newDetails.endTime.getMinutes(),0);
            if(moment(endDateTime).isBefore(moment(startDateTime)))
            {
                newDetails.endTime = null;
                setTimeout(()=>{  
                    props.loader({loader:false,notification:true,message:"error",validationMessage:"Reservation End Date and Time cannot be before the Start Date and Time"});
                    },1000);
                //newDetails.endDate = null;
            }            
        }
        setFilters(newDetails);
    }
  const changeReservationDetails = (key,value)=>{
    if(key === 'destinationState' && value !== null){
        getCities(value);
    }
    let newDetails = {...reservationDetails};
    newDetails[key] = value;
    setReservationDetails(newDetails);
  }
  const changeUsageInfo = (key,value)=>{
    let newDetails = {...usageInfo};
    newDetails[key] = value;    
    if(key === 'isGasPurchased' && value === 'N')
    {
        let cost = parseFloat(0);
        tripFuelData.forEach((item)=>{
            cost = (item.totalCost !== null && item.totalCost !== '') ? parseFloat(cost+parseFloat(item.totalCost)) : parseFloat(cost);
        });
        setTripFuelData([]);
        
        newDetails['totalCost'] = parseFloat(newDetails['totalCost']).toFixed(2) - parseFloat(cost).toFixed(2);
    }
    setUsageInfo(newDetails);
  }

  const calculateMileage = (key,value)=>{
    let newDetails = {...usageInfo};
    if(key === 'endMileage' && parseFloat(newDetails.beginMileage) > parseFloat(value)){
        value = null;
        setTimeout(()=>{  
        props.loader({loader:false,notification:true,message:"error",validationMessage:"End Mileage Cannot be less than the Beginning Vehicle Mileage"});
        },1000); 
    }
    else if(key === 'fuelMileage' && (parseFloat(newDetails.beginMileage) > parseFloat(value) || parseFloat(newDetails.endMileage) < parseFloat(value))){
        value = null;
        setTimeout(()=>{  
        props.loader({loader:false,notification:true,message:"error",validationMessage:"Fuel Mileage Must be between the beginning vehicle mileage and ending vehicle mileage"});
        },1000);
    }
    else if (key === 'maintMileage' && (parseFloat(newDetails.beginMileage) > parseFloat(value) || parseFloat(newDetails.endMileage) < parseFloat(value))){
        value = null;
        setTimeout(()=>{  
        props.loader({loader:false,notification:true,message:"error",validationMessage:"Maintenance Mileage Must be between the beginning vehicle mileage and ending vehicle mileage"});
        },1000);
    }
    newDetails[key] = value;
    setUsageInfo(newDetails);
  }

  const calculateTotalCost = ()=>{
    let cost = parseFloat(0);
    
    maintenanceData.forEach((item)=>{
        cost= (item.cost !== null && item.cost !== '') ? parseFloat(cost+parseFloat(item.cost)) : parseFloat(cost);
    });
    tripFuelData.forEach((item)=>{
        cost= (item.totalCost !== null && item.totalCost !== '') ? parseFloat(cost+parseFloat(item.totalCost)) : parseFloat(cost);
    });
    let newDetails = {...usageInfo};
    newDetails.totalCost = (newDetails.fuelCost !== null && newDetails.fuelCost !== '') ? parseFloat(parseFloat(newDetails.fuelCost) + parseFloat(cost)).toFixed(2) : parseFloat(cost).toFixed(2);
    setUsageInfo(newDetails);
  }

  const search = async ()=>{
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
      }
    else{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        const request = {...filters};
        if(reservationDetails.status !== null){
            let startDate = new Date(filters.startDate);
            let endDate = new Date(filters.endDate);
            let startTime = new Date(filters.startTime);
            let endTime = new Date(filters.endTime);
            request.startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes(),0);
            request.endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes(),0);
        }
        else{
            request.startTime = new Date(request.startDate.getFullYear(), request.startDate.getMonth(), request.startDate.getDate(), request.startTime.getHours(), request.startTime.getMinutes(),0);
            request.endTime = new Date(request.endDate.getFullYear(), request.endDate.getMonth(), request.endDate.getDate(), request.endTime.getHours(), request.endTime.getMinutes(),0);
        }
        request.startTime = moment(request.startTime).format("YYYY-MM-DDTHH:mm:ss.ss");
        request.endTime = moment(request.endTime).format("YYYY-MM-DDTHH:mm:ss.ss");
        request.vehicleType = (request.vehicleType === 0) ? null : request.vehicleType
        await postFetch("/vehicleReservations/load",request).then((data)=>{ 
            if(!data.message){
                setSearchedSw(true);
                let newData = [...summaryData].filter((vehicle)=> vehicle.id === filters.vehicleId);
                data.forEach((vehicle)=>{
                    if(filters.vehicleId != vehicle.id ){
                    vehicle.make = props.staticData.reference.makes.value.find((config)=> config.value.toString() === vehicle.make.toString()).name;
                    vehicle.year = props.staticData.reference.VehicleYears.value.find((config)=> config.value.toString() === vehicle.year.toString()).name;
                    vehicle.division = props.staticData.reference.DIVISIONS.value.find((config)=> config.value.toString() === vehicle.division.toString()).name;
                    vehicle.station = props.staticData.reference.OfficialStations.value.find((config)=> config.value.toString() === vehicle.station.toString()).name;
                    vehicle.status = props.staticData.reference.vehicleStatus.value.find((config)=> config.value.toString() === vehicle.status.toString()).name;
                    vehicle.model = props.staticData.reference[vehicle.make].value.find((config)=> config.value.toString() === vehicle.model.toString()).name;
                    vehicle.vehicleType = props.staticData.reference.vehicleTypes.value.find((config)=> config.value.toString() === vehicle.vehicleType.toString()).name;
                    vehicle.poolVehicle = (vehicle.poolSw === null || vehicle.poolSw === 'N') ? 'No' : 'Yes';
                    vehicle.vin = vehicle.vin;
                    newData.push(vehicle);
                    }
                });
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });
                    setSummaryData([...newData]);
                },1000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch(()=>{
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
        });
    }
  }

  const save = async (status)=>{
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
      }
      else if(new Date(convertDateFromServer(props.travellerInfo.licenseExpirationDate)) < new Date()){
        props.loader({loader:false,notification:true,message:"error",validationMessage:"Driver's license is expired. A vehicle cannot be reserved at this time." });
      }
      else if(status === 'CA' && (reservationDetails.reasonForCan === null || reservationDetails.reasonForCan === '')){
        props.loader({loader:false,notification:true,message:"error",validationMessage:"Please enter Reason for Cancellation" });
      }
    else{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        const request = {...reservationDetails};
        request.status = status;
        request.officialStation = filters.station;
        request.vehicleType = filters.vehicleType;
        request.vehicleId = filters.vehicleId;
        request.reservationStartDate = moment(filters.startDate).format("YYYY-MM-DD");
        request.reservationEndDate = moment(filters.endDate).format("YYYY-MM-DD");
        if(request.status !== 'IP'){
            let startDate = new Date(filters.startDate);
            let endDate = new Date(filters.endDate);
            let startTime = new Date(filters.startTime);
            let endTime = new Date(filters.endTime);
            request.reservationStartTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes(),0);
            request.reservationEndTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes(),0);
        }
        else{
            request.reservationStartTime = new Date(filters.startDate.getFullYear(), filters.startDate.getMonth(), filters.startDate.getDate(), filters.startTime.getHours(), filters.startTime.getMinutes(),0);
            request.reservationEndTime = new Date(filters.endDate.getFullYear(), filters.endDate.getMonth(), filters.endDate.getDate(), filters.endTime.getHours(), filters.endTime.getMinutes(),0);
        }
        request.reservationStartTime = moment(request.reservationStartTime).format("YYYY-MM-DDTHH:mm:ss.ss");
        request.reservationEndTime = moment(request.reservationEndTime).format("YYYY-MM-DDTHH:mm:ss.ss");
        
        await postFetch("/vehicleReservations/save",request).then((data)=>{ 
            if(!data.message){
                let message = (status === 'IP') ? "Reservation submitted successfully" : ((status === 'PC') ? "Reservation Approved" : "Reservation Cancelled");
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"success",validationMessage:message });                        
                },0);
                setTimeout(() => {
                  localStorage.removeItem("reservationId");
                  localStorage.removeItem("readOnly");
                  if(status === 'IP'){
                    setError(false);
                    setReadOnly(false);
                    setReservationDetails({id:null,destinationState:null,destinationCity:null,reason:null,decNotes:null,status:null,userId:props.userInfo.id,officialStation:null,vehicleType:null,vehicleId:null,reservationStartDate:null,reservationStartTime:null,reservationEndDate:null,reservationEndTime:null,division:props.travellerInfo.divisionCode,reasonForCan:null})
                    setFilters({startDate:null,startTime:null,endDate:null,endTime:null,station:null,vehicleType:null,vehicleId:null,division:props.travellerInfo.divisionCode});
                    setSummaryData([]);
                  }
                  else{
                    history.push("/dashboard");
                  }
                }, 3000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch(()=>{
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
        });
    }
  }

  const saveReservationDetails = async (status)=>{
    const maintenances = {...usageInfo}
    const fuelDetails = [...tripFuelData];
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
    }
    else if (usageInfo.isGasPurchased === 'Y' && [...tripFuelData].filter((item)=> item.fueldelSw !== 'Y').length === 0){
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"Gas purchase details are required to submit Vehicle Usage" });
        },1000);        
    }
    else if (usageInfo.isGasPurchased === 'Y' && [...documents].filter((doc)=> doc.delSw !== 'Y').length === 0){    
       setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:"At least one document is required to submit Vehicle Usage" });
        },1000);
    } 
   else if(fuelDetails !== null && fuelDetails.length > 0 && validateFuel(fuelDetails)){
     for(let i = 0; i < fuelDetails.length; i ++ ){
         if(parseFloat(maintenances.beginMileage) > parseFloat(fuelDetails[i].vehMilAtFuel) || parseFloat(maintenances.endMileage) < parseFloat(fuelDetails[i].vehMilAtFuel)){
          fuelDetails[i].vehMilAtFuel = null;
          setTimeout(()=>{  
              props.loader({loader:false,notification:true,message:"error",validationMessage:"Fuel Mileage Must be between the beginning vehicle mileage and ending vehicle mileage"});
              },1000);
         } 
        
      } 
  } 
   else if(maintenances.maintMileage !== null && (parseFloat(maintenances.beginMileage) > parseFloat(maintenances.maintMileage) || parseFloat(maintenances.endMileage) < parseFloat(maintenances.maintMileage))){
        usageInfo.maintMileage = null;
        setTimeout(()=>{  
        props.loader({loader:false,notification:true,message:"error",validationMessage:"Maintenance Mileage Must be between the beginning vehicle mileage and ending vehicle mileage"});
        },1000);
    } 
     
     
    else{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setSubmitDisabled(true);
        const request = {...usageInfo};
        request.reservationStatus = status;
        request.vehicles = reservationDetails.id;
        request.maintenances = [...maintenanceData];
        request.fuelDetails = [...tripFuelData];
        request.reservationId = reservationDetails.id;
        request.documents = [...documents];
        request.reservationEndDate = moment(filters.endDate).format("YYYY-MM-DD");
        let endDate = new Date(filters.endDate);
        let endTime = new Date(filters.endTime);
        request.endTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes(),0);
        request.reservationEndTime = moment(request.endTime).format("YYYY-MM-DDTHH:mm:ss.ss");  
        await postFetch("/reservationDetails/save",request).then((data)=>{ 
            if(!data.message){
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"success",validationMessage:"Usage Information submitted successfully" });                        
                },0);
                setTimeout(() => {
                    history.push("/dashboard");
                  }, 3000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch(()=>{
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
        });
    }
  }

  function validateFuel(data){
    const maintenances = {...usageInfo}
      let counter = 0;
      if(data !== null && data.length > 0){
        for(let i = 0; i < data.length; i ++ ){
            if(parseFloat(maintenances.beginMileage) > parseFloat(data[i].vehMilAtFuel) || parseFloat(maintenances.endMileage) < parseFloat(data[i].vehMilAtFuel)){
                return true;
            }else{
                counter++;
                if(data.length === counter){
                 return false;
                }
        }
      }
      return false;
    }
  }

  const saveSearch = async()=>{
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
    }
    else{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        const request = [...summaryData];
        request.forEach((history) => {
            history.reservationStartDate = moment(filters.startDate).format("YYYY-MM-DD");
            history.reservationEndDate = moment(filters.endDate).format("YYYY-MM-DD");
            history.type = history.vehicleType;
            history.userId = props.userInfo.id;
            history.attestationSw = saveSw === true ? "Y" : "N" ;
        });
        if (request.length === 0) {
            let history = {
                reservationStartDate : moment(filters.startDate).format("YYYY-MM-DD"),
                reservationEndDate : moment(filters.endDate).format("YYYY-MM-DD"),
                division : props.staticData.reference.DIVISIONS.value.find((config) => config.value.toString() === filters.division.toString()).name,
                station : props.staticData.reference.OfficialStations.value.find((config) => config.value.toString() === filters.station.toString()).name,
                type : (filters.vehicleType !== null && filters.vehicleType !== 0) ? props.staticData.reference.vehicleTypes.value.find((config) => config.value.toString() === filters.vehicleType.toString()).name : null,
                userId : props.userInfo.id,
                attestationSw : saveSw === true ? "Y" : "N" 
            };
            request.push(history);
        }
        await postFetch("/vehicleSearch/save",request).then((data)=>{ 
            if(!data.message){
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"success",validationMessage:"Search history saved successfully" });                        
                },0);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch(()=>{
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
        });
    }
  }

  const addMaintenance = ()=>{
    let newDetails = [...maintenanceData];
    newDetails.push({maintNum:'Maintenance Item ',id:null,vehicleId:reservationDetails.vehicles.id,maintenanceItem:null,cost:null,reservationId:reservationDetails.id,delSw:'N',dueDate:moment(reservationDetails.reservationEndDate).format("YYYY-MM-DD"),status:'CP'});
    
    setMaintenanceData(newDetails);
  }

  const addTripFuel = ()=>{
    let newFuelDetails = [...tripFuelData];
    newFuelDetails.push({id:null,vehMilAtFuel:null,gallonsPurchased:null,costPerGallon:null,totalCost:null,reservationId:reservationDetails.id,fueldelSw:'N'});
    setTripFuelData(newFuelDetails);
  }
  const renderMaintenanceItem = (row,column,index)=>{
    return (<div>
                <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={row.maintenanceItem === 0 || row.maintenanceItem === null}>
                    <Select
                        id={"maintenanceItem"+row.id}
                        className = {readOnly ? "disabled" : "required"}
                        disabled = {readOnly}
                        value={row.maintenanceItem === null ? 0 : row.maintenanceItem}
                        onChange={(event)=>changeMaintenanceDetails(column,event.target.value,index)}
                    >
                        <MenuItem value={0}>Pick an Option</MenuItem>
                        {props.staticData.dropdown.maintenanceItems.value.map((method)=>(
                            <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                        ))}
                    </Select>
                    {((row.maintenanceItem === 0 || row.maintenanceItem === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}                                               
                </FormControl> 
    </div>);
  }
  const renderCost = (row,column,index)=>{
    return (<div>
                <Grid item xs={12} sm={12} className={classes.textfield}>
                    <TextField
                        id={"unitnum"+row.id}
                        value={(row.cost === null || row.cost === '') ? '' : parseFloat(row.cost).toFixed(2)}
                        className = {readOnly ? "disabled" : null}
                        disabled = {readOnly}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputComponent: AmountFormatCustom,
                            maxLength: 25
                        }}
                        size="small"
                        fullWidth
                        onChange={(e)=>changeMaintenanceDetails(column,e.target.value,index)}
                        onBlur = {()=> calculateTotalCost()}
                    />
                </Grid> 
    </div>);
  }
  const renderActionDelete = (row,column,index)=>{
    return (<div id={row.id+"delSw"} style={{cursor:'pointer'}}><DeleteIcon onClick={()=>readOnly ? null : changeMaintenanceDetails(column,'Y',index)} style={{fontSize:20}}/></div>);
  }

  const renderMaintenanceNum = (row,column,index)=>{
    return (<div>
                Maintenance Item {(index+1)}
    </div>);
  }

  const renderVehicleMilAtFuel = (row,column,index) =>{
    return (<div> <Grid item xs={12} sm={12} className={classes.textfield}>   
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={(row.vehMilAtFuel === null || row.vehMilAtFuel === '')}>
            <TextField
                id={"fuel-mil"+row.id}
                value={row.vehMilAtFuel === null ? '' : parseInt(row.vehMilAtFuel)}
                className = {readOnly ? "disabled" : "required"}
                disabled = {readOnly}
                error={row.vehMilAtFuel === null || row.vehMilAtFuel === ''}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputComponent: NumberCustom,
                    maxLength: 25
                }}
                size="small"
                fullWidth
                onChange={(e)=> changeTripFuelDetails('vehMilAtFuel',e.target.value,index)}
                onBlur={(e)=> validateMileage('vehMilAtFuel',e.target.value,index)}                
            />
                {((row.vehMilAtFuel === null || row.vehMilAtFuel === '') && error) ? <FormHelperText>Required Field</FormHelperText>
                        : null}   
            </FormControl>     
        </Grid></div>);
  }

  const renderGallonsPurchased = (row,column,index) =>{
      return (<div> <Grid item xs={12} sm={12} className={classes.textfield}>        
       <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={row.gallonsPurchased === null || row.gallonsPurchased === ''}>
            <TextField
                id={"gal-used"+row.id}
                //value={row.gallonsPurchased === null ? '' :row.gallonsPurchased}
                value={(row.gallonsPurchased === null || row.gallonsPurchased === '') ? '' :parseFloat(row.gallonsPurchased).toFixed(3)}
                className = {readOnly ? "disabled" : "required"}
                disabled = {readOnly}
                error={row.gallonsPurchased === null || row.gallonsPurchased === ''}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputComponent: GallonFormatCustom,
                    maxLength: 25
                }}
                size="small"
                fullWidth
                onChange={(e)=> changeTripFuelDetails('gallonsPurchased',e.target.value,index)}
                onBlur={(e)=> validateMileage('gallonsPurchased',e.target.value,index)}                
            />            
            {((row.gallonsPurchased === null || row.gallonsPurchased === '') && error) ? <FormHelperText>Required Field</FormHelperText>
                        : null}   
            </FormControl> 
        </Grid></div>);
  }
  const renderCostPerGallon = (row,column,index) =>{
    return (<div>  <Grid item xs={12} sm={12} className={classes.textfield}>  
     <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={row.costPerGallon === null || row.costPerGallon === ''}>
            <TextField
                id={"gal-cost"+row.id}
                value={(row.costPerGallon === null || row.costPerGallon === '') ? '' :parseFloat(row.costPerGallon).toFixed(3)}
                className = {readOnly ? "disabled" : "required"}
                disabled = {readOnly}
                error={row.costPerGallon === null || row.costPerGallon === ''}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputComponent: CPGallonAmountFormatCustom,
                    maxLength: 25
                }}
                size="small"
                fullWidth
                onChange={(e)=> changeTripFuelDetails('costPerGallon',e.target.value,index)}
                onBlur={(e)=> validateMileage('costPerGallon',e.target.value,index)}                
            />
             {((row.costPerGallon === null || row.costPerGallon === '') && error) ? <FormHelperText>Required Field</FormHelperText>
                        : null}   
            </FormControl> 
        </Grid></div>);
  }

  const renderTotalFuelCost = (row,column,index) =>{
      return (<div><Grid item xs={12} sm={12} className={classes.textfield}>       
       <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
            <TextField
                id={"fuel-cost"+row.id}
                value={(row.totalCost === null || row.totalCost === '') ? '' :parseFloat(row.totalCost).toFixed(2)}
                //className = {"disabled"}
                
                //error={row.totalCost === null || row.totalCost === ''}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputComponent: AmountFormatCustom,
                    maxLength: 25
                }}
                size="small"
                fullWidth
                onChange={(e)=> changeTripFuelDetails('totalCost',e.target.value,index)}
                onBlur = {()=> calculateTotalCost()}
            />
             {/* {((row.totalCost === null || row.totalCost === '') && error) ? <FormHelperText>Required Field</FormHelperText>
                        : null}    */}
            </FormControl> 
        </Grid></div>);
}

const renderActionFuelDelete = (row,column,index)=>{
    return (<div id={row.id+"fueldelSw"} style={{cursor:'pointer'}}><DeleteIcon onClick={()=>readOnly ? null : changeTripFuelDetails(column,'Y',index)} style={{fontSize:20}}/></div>);
  }

  const changeMaintenanceDetails = (key,value,index)=>{
    let cost = parseFloat(0);
    let newDetails = [...maintenanceData];
    newDetails[index][key] = value;
    newDetails = newDetails.filter((data)=> data.delSw === 'N');
    newDetails.forEach((item)=>{
        cost= (item.cost !== null && item.cost !== '') ? parseFloat(cost+parseFloat(item.cost)) : parseFloat(cost);
    });
    
    setMaintenanceData(newDetails);
    tripFuelData.forEach((item)=>{
        cost= (item.totalCost !== null && item.totalCost !== '') ? parseFloat(cost+parseFloat(item.totalCost)) : parseFloat(cost);
    });
    let updatedDetails = {...usageInfo};
    updatedDetails.totalCost = (updatedDetails.fuelCost !== null && updatedDetails.fuelCost !== '') ? parseFloat(parseFloat(updatedDetails.fuelCost) + parseFloat(cost)).toFixed(2) : parseFloat(cost).toFixed(2);
    setUsageInfo(updatedDetails);
}

const validateMileage = (key,value,index) =>{
    let newDetails = [...tripFuelData];
    if(key === 'costPerGallon' && (value !== ""))
    {
        //value.replace("$","");
        value = parseFloat(value.replace("$","")).toFixed(3);
    }
    if(key === 'vehMilAtFuel' && (value !== '') && ((usageInfo.beginMileage === null || usageInfo.beginMileage === '')) || ((usageInfo.endMileage === null || usageInfo.endMileage === '')))
    {
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"Please enter Beginning and Ending Vehicle Mileage before entering Vehicle Mileage at Fueling" });                                 
        },1000);
        //props.loader({loader:false,notification:true,message:"error",validationMessage:"Please enter Beginning and Ending Vehicle Mileage first" });
        value = null;
    }else if (key === 'vehMilAtFuel' && (value !== '') && ((parseInt(value) < parseInt(usageInfo.beginMileage)) || (parseInt(value) > parseInt(usageInfo.endMileage))))
    {
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"Please enter a Vehicle Mileage at Fueling between the Vehicle Beginning and Ending mileage" });                                 
        },1000);
        //props.loader({loader:false,notification:true,message:"error",validationMessage:"Vehicle Mileage at Fueling should be between Vehicle Beginning and Ending mileage" });
        value = null;
    }else if (key === 'vehMilAtFuel')
    {
        
        newDetails.map((el, i) => {
          newDetails.find((element, index) => {
                if (i !== index && parseInt(element.vehMilAtFuel) === parseInt(el.vehMilAtFuel)) {
                    value = null;
                }
            })
        });
        
        if(value === null)
        {
            setTimeout(()=>{                          
                props.loader({loader:false,notification:true,message:"error",validationMessage:"Cannot enter duplicate value in Vehicle Mileage at Fueling" });
            },1000);
        }        
        //props.loader({loader:false,notification:true,message:"error",validationMessage:"Vehicle Mileage at Fueling should be between Vehicle Beginning and Ending mileage" });
    }else if(key === 'gallonsPurchased' && (value !== '') && (parseFloat(value).toFixed(3) === parseFloat(0).toFixed(3)))
    {
        value = null;
        newDetails[index]['totalCost'] = null;
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"Gallons Purchased cannot be 00.000 gallons" });
        },1000);
    }
    else if(key === 'gallonsPurchased' && (value !== '') && (parseFloat(value).toFixed(3) >= parseFloat(100)))
    {
        value = null;
        newDetails[index]['totalCost'] = null;
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"Gallons Purchased cannot exceed 99.999 gallons" });
        },1000);
    }else if(key === 'costPerGallon' && (value !== '') && (parseFloat(value).toFixed(3) >= parseFloat(10)))
    {
        value = null;
        newDetails[index]['totalCost'] = null;
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"Cost Per Gallon cannot exceed $9.999" });
        },1000);
    }
    else if(key === 'costPerGallon' && (value !== '') && (parseFloat(value).toFixed(3) === parseFloat(0).toFixed(2)))
    {
        value = null;
        newDetails[index]['totalCost'] = null;
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"Cost Per Gallon cannot be $0.000" });
        },1000);
    }
    
    newDetails[index][key] = value;  
    setTripFuelData(newDetails);
}

const changeTripFuelDetails = (key,value,index)=>{
    let cost = parseFloat(0);
    let newDetails = [...tripFuelData];
    newDetails[index][key] = value;     

    // if((newDetails[index]['gallonsPurchased'] !== null && newDetails[index]['gallonsPurchased'] !== '') && (newDetails[index]['costPerGallon'] !== null && newDetails[index]['costPerGallon'] !== ''))
    // {
    //     newDetails[index]['totalCost'] = parseFloat( newDetails[index]['gallonsPurchased']*parseFloat(newDetails[index]['costPerGallon']));
       
    // }else{
    //     newDetails[index]['totalCost'] = null;
    // }
    newDetails = newDetails.filter((data)=> data.fueldelSw === 'N');
    
    newDetails.forEach((item)=>{
        cost= (item.totalCost !== null && item.totalCost !== '') ? parseFloat(cost+parseFloat(item.totalCost)) : parseFloat(cost);
    });    
    maintenanceData.forEach((item)=>{
        cost= (item.cost !== null && item.cost !== '') ? parseFloat(cost+parseFloat(item.cost)) : parseFloat(cost);
    });
    let updatedDetails = {...usageInfo};
    updatedDetails.totalCost = (updatedDetails.fuelCost !== null && updatedDetails.fuelCost !== '') ? parseFloat(parseFloat(updatedDetails.totalCost) + parseFloat(cost).toFixed(2)) : parseFloat(cost).toFixed(2);
    setUsageInfo(updatedDetails);
    setTripFuelData(newDetails);
   
}

const auditdialog = ()=>{
    return (<div>
      <Dialog
        open={auditDialog}
        onClose={()=>setAuditDialog(false)}
      >
        <DialogContent>
          <Gridtable columns={reservsAuditColumns} data={auditData} ></Gridtable>
        </DialogContent>
      </Dialog>
    </div>);
  }

  const showAudit = async (row,type)=>{
    const request= {  
      "requestId":row.id,
      "type":type
    }
      await postFetch("/audit/load",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false,message:"success" });                      
            },1000);
            setAuditData([...data]);
            setAuditDialog(true);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }

  const renderActionButton = (row)=>{
    return (<div id={"audit"+row.id} style={{cursor:'pointer'}}><AccessTimeRoundedIcon onClick={()=>showAudit(row,"search")} style={{fontSize:20}}/></div>);
  }

  const summaryColumns = [
    {
        name:"Select One",
        field:"action",
        type:"input",
        renderView: renderSelection
    },
    {
        name:"VIN",
        field:"vin",
        type:"text"
    },
    {
        name:"Make",
        field:"make",
        type:"text"
    },
    {
        name:"Model",
        field:"model",
        type:"text"
    },
    {
        name:"Year",
        field:"year",
        type:"text"
    },
    {
        name:"Division",
        field:"division",
        type:"text"
    },
    {
        name:"Pool Vehicle ?",
        field:"poolVehicle",
        type:"text"
    },
    
    {
        name:"Official Station",
        field:"station",
        type:"text"
    },
    {
        name:"Vehicle Type",
        field:"vehicleType",
        type:"text"
    },
    {
        name:"Capacity",
        field:"capacity",
        type:"text"
    },
    {
        name:"Action",
        field:"action1",
        type:"input",
        renderView: renderActionButton
    }
  ];

  const maintenanceColumns = [
    {
        name:"Maintenance",
        field:"maintenanceNum",
        type:"input",
        renderView:renderMaintenanceNum
    },
    {
        name:"Maintenance Item",
        field:"maintenanceItem",
        type:"input",
        renderView:renderMaintenanceItem
    },
    {
        name:"Cost of Maintenance Item",
        field:"cost",
        type:"input",
        renderView:renderCost
    },
    {
        name:"Action",
        field:"delSw",
        type:"input",
        renderView:renderActionDelete
    }
  ];

  const fuelDetailsColumns = [
    {
        name:"Vehicle Mileage at Fueling",
        field:"vehMilAtFuel",
        type:"input",
        renderView:renderVehicleMilAtFuel
    },
    {
        name:"Gallons Purchased",
        field:"gallonsPurchased",
        type:"input",
        renderView:renderGallonsPurchased
    },
    {
        name:"Cost Per Gallon",
        field:"costPerGallon",
        type:"input",
        renderView:renderCostPerGallon
    },
    {
        name:"Total Fuel Cost",
        field:"totalCost",
        type:"input",
        renderView:renderTotalFuelCost
    },
    {
        name:"Action",
        field:"fueldelSw",
        type:"input",
        renderView:renderActionFuelDelete
    }
  ];

  const reservsAuditColumns =[
    {
      name:"Created By",
      field:"createdBy",
      type:"text"
    },
    {
      name:"Created Date",
      field:"createdDate",
      type:"text"
    },
    {
      name:"Modified By",
      field:"modifiedBy",
      type:"text"
    },
    {
      name:"Modified Date",
      field:"modifiedDate",
      type:"text"
    },
    {
      name:"Reservation Id",
      field:"column3",
      type:"text"
    },
    {
      name:"Reservation Start Date",
      field:"column1",
      type:"date"
    },
    {
      name:"Reservation End Date",
      field:"column2",
      type:"date"
    }
  ];

  const uploadColumns = [
    {
        name:"File Name",
        field:"fileName",
        type:"text"
    },
    {
        name:"Size",
        field:"size",
        type:"text"
    },
    {
        name:"Action",
        field:"action",
        type:"input",
        renderView: documentActionButton
    }
  ];




  return (
    <div className={classes.root}>
    {!usageDetails ? <div>
        {auditdialog()}
     <Typography className={classes.header}>Vehicle Reservation</Typography>
            <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                      <span className="datepickerLabel">Reservation Start Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="reservation-start-date"
                            autoOk={true}
                            className = {readOnlyApp ? "disabled" : "required"}
                            disabled = {readOnlyApp}
                            size="small"
                            fullWidth
                            value={filters.startDate}
                            error={(filters.startDate === null) || (!moment(filters.startDate, 'MM/DD/YYYY').isValid())}
                            minDate={new Date()}
                            onChange={(date)=> changeSearchFilters('startDate',date)}
                            placeholder="MM/DD/YYYY"
                            KeyboardButtonProps={{
                                'aria-label': 'start date',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.startDate === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.startDate !== null && !moment(filters.startDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null}
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Reservation Start Time</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardTimePicker 
                            variant="inline"
                            placeholder="HH:MM ZZ"
                            mask="__:__ _M"
                            margin="normal"
                            id="start-time"
                            autoOk={false}
                            className = {readOnlyApp ? "disabled" : "required"}
                            disabled = {readOnlyApp}
                            size="small"
                            fullWidth
                            value={filters.startTime}
                            error={(filters.startTime === null) || (!moment(filters.startTime, 'MM/DD/YYYY').isValid())}
                            onChange={(date)=> changeSearchFilters('startTime',date)}
                            onClose={() => validateEndTime('startTime')}
                            KeyboardButtonProps={{
                                'aria-label': 'start time',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.startTime === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.startTime !== null && !moment(filters.startTime, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Time Format</FormHelperText>
                            : null}
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                      <span className="datepickerLabel">Reservation End Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="reservation-end-date"
                            autoOk={false}
                            className = {readOnlyApp ? "disabled" : "required"}
                            disabled = {readOnlyApp}
                            size="small"
                            fullWidth
                            value={filters.endDate}
                            error={(filters.endDate === null) || (!moment(filters.endDate, 'MM/DD/YYYY').isValid())}
                            minDate={filters.startDate === null ? new Date() : filters.startDate}
                            onChange={(date)=> changeSearchFilters('endDate',date)}
                            onClose={() => validateEndTime('endDate')}
                            placeholder="MM/DD/YYYY"
                            KeyboardButtonProps={{
                                'aria-label': 'start date',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.endDate === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.endDate !== null && !moment(filters.endDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null}
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Reservation End Time </span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardTimePicker 
                            variant="inline"
                            placeholder="HH:MM ZZ"
                            mask="__:__ _M"
                            margin="normal"
                            id="end-time"
                            autoOk={false}
                            className = {readOnlyApp ? "disabled" : "required"}
                            disabled = {readOnlyApp}
                            size="small"
                            fullWidth
                            value={filters.endTime}
                            error={(filters.endTime === null) || (!moment(filters.endTime, 'MM/DD/YYYY').isValid())}
                            onChange={(date)=> changeSearchFilters('endTime',date)}
                            onClose={() => validateEndTime('endTime')}
                            KeyboardButtonProps={{
                                'aria-label': 'end time',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.endTime === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.endTime !== null && !moment(filters.endTime, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Time Format</FormHelperText>
                            : null}
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={12} sm={5} className={classes.datepicker}>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={filters.station === 0 || filters.station === null} >
                            <span className={classes.textLabel}>Official Station</span>
                            <Select
                                id="station"
                                value={filters.station === null ? 0 : filters.station}
                                className = {readOnlyApp ? "disabled" : "required"}
                                disabled = {readOnlyApp}
                                onChange={(event)=>changeSearchFilters('station',event.target.value)}
                            >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.OfficialStations.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                            </Select>
                            {((filters.station === 0 || filters.station === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                               
                        </FormControl>                                                                 
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.datepicker}>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <span className={classes.textLabel}>Vehicle Type</span>
                            <Select
                                id="veh-type"
                                value={filters.vehicleType === null ? 0 : filters.vehicleType}
                                className = {readOnlyApp ? "disabled" : "required"}
                                disabled = {readOnlyApp}
                                onChange={(event)=>changeSearchFilters('vehicleType',event.target.value)}
                            >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.vehicleTypes.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                            </Select>                                               
                        </FormControl>                                                                 
                    </Grid>
                </Grid>
                <div style={{textAlign:'center',paddingBottom:10}}>
                    <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={(props.pageInfo.find((page)=> page.pageId === 6).permissionSw !== 'Y') || readOnlyApp} onClick={()=>search()}>Search Vehicles</Button>
                </div>
                <Box p={"10px"}>
                    <Gridtable columns={summaryColumns} data={summaryData}></Gridtable>
                </Box>
                {reservationDetails.status === null ? <Grid container className={classes.filters}>
                    <Grid item xs={12} sm={12} className={classes.textfield}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="sendsearch-checkbox"
                                    checked={saveSw}
                                    onChange={()=> setSaveSw(!saveSw)}
                                    name="Save Switch"
                                    color="primary"
                                    disabled={!(filters.startTime !== null && filters.startDate !== null && filters.station !== null && filters.endTime !== null && filters.endDate !== null && filters.vehicleId === null && isSearched)}
                                />
                            }
                            label="CERTIFICATION OF UNAVAILABILITY OF A STATE VEHICLE: By checking this box, I hereby certify that there were no state vehicles available for work-related travel, necessitating the use of a privately owned vehicle as valid state reimbursement."
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid> : null}
                {(saveSw) ? <div style={{textAlign:'center',paddingBottom:10}}>
                    <Button id="saveHistory" variant="contained" color="primary" className={classes.button} onClick={()=> saveSearch()}
                    disabled={!(filters.startTime !== null && filters.startDate !== null && filters.station !== null && filters.endTime !== null && filters.endDate !== null && filters.vehicleId === null && isSearched)}>
                    Save History for Reimbursement
                    </Button>
                </div> : null}
                {
                    filters.vehicleId !== null ? <div>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>First Name</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={props.travellerInfo.firstName}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                              <span className={classes.textLabel}>Last Name</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={props.travellerInfo.lastName}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Division</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={props.travellerInfo.divisionCode}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                              <span className={classes.textLabel}>AASIS Employee Number</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={props.travellerInfo.aasisempNum}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Official Station</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={props.travellerInfo.empAreaDescription}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                              <span className={classes.textLabel}>Phone Number</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={props.userInfo.phone}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Driver's License Number</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={props.travellerInfo.licenseNum}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                              <span className={classes.textLabel}>Driver's License Expiration Date</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={convertDateFromServer(props.travellerInfo.licenseExpirationDate)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={reservationDetails.destinationState === 0 || reservationDetails.destinationState === null}>
                                <span className={classes.textLabel}>Destination State</span>
                                <Select
                                    id="state-select"
                                    value={reservationDetails.destinationState === null ? 0 : reservationDetails.destinationState}
                                    className = {readOnly ? "disabled" : "required"}
                                    disabled = {readOnly}
                                    onChange={(e)=> changeReservationDetails('destinationState',e.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {states.sort().map((method)=>(
                                        <MenuItem  key={method} value={method}>{method}</MenuItem>
                                    ))}
                                </Select>
                                {((reservationDetails.destinationState === 0 || reservationDetails.destinationState === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                : null}                                               
                            </FormControl>                      
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={reservationDetails.destinationCity === 0 || reservationDetails.destinationCity === null}>
                                <span className={classes.textLabel}>Destination City</span>
                                <Select
                                    id="city-select"
                                    value={reservationDetails.destinationCity === null ? 0 : reservationDetails.destinationCity}
                                    className = {readOnly ? "disabled" : "required"}
                                    disabled = {readOnly}
                                    onChange={(e)=> changeReservationDetails('destinationCity',e.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {(reservationDetails.destinationState === 0 || reservationDetails.destinationState === null) ? null : cities.map((value)=>(
                                        <MenuItem  key={value} value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                                {((reservationDetails.destinationCity === 0 || reservationDetails.destinationCity === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                : null}                                               
                            </FormControl>                      
                        </Grid>
                    </Grid>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.datepicker}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Reason for Reservation</span>
                                <Select
                                    id="reserv-reason"
                                    value={reservationDetails.reason === null ? 0 : reservationDetails.reason}
                                    className = {readOnly ? "disabled" : null}
                                    disabled = {readOnly}
                                    onChange={(event)=>changeReservationDetails('reason',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.ReservationReasons.value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    ))}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                    </Grid>
                    <Grid container className={classes.filters}>
                        <Grid item xs={10} className={classes.textfield}>
                            <span className={classes.textLabel}>Notes</span>
                                <TextField
                                id="notes"
                                multiline
                                className = {readOnly ? "disabled" : "required"}
                                disabled = {readOnly}
                                error ={reservationDetails.decNotes === '' || reservationDetails.decNotes === null}
                                value={reservationDetails.decNotes === null ? '' : reservationDetails.decNotes}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    }}
                                size="small"
                                fullWidth
                                onChange={(e)=> changeReservationDetails('decNotes',e.target.value)}
                                inputProps={{
                                    maxLength: 250
                                }}
                                helperText={((reservationDetails.decNotes === '' || reservationDetails.decNotes === null) && error) ? "Required Field" : null}
                            />
                            <FormHelperText>{reservationDetails.decNotes === null ? 0 :reservationDetails.decNotes.length}/250 Characters</FormHelperText>
                        </Grid>
                    </Grid>
                    {(readOnlyApp || readOnly) ? <div>
                    <Grid container className={classes.filters}>
                        <Grid item xs={10} className={classes.textfield}>
                            <span className={classes.textLabel}>Reason for Cancellation</span>
                                <TextField
                                id="reasonForCan"
                                multiline
                                className = {((reservationDetails.status === null || reservationDetails.userId === props.userInfo.id) && reservationDetails.status !== null) ||  (reservationDetails.status === null || reservationDetails.userId === props.userInfo.id && reservationDetails.status == 'IP') ? "disabled" : "required"}
                                disabled={((reservationDetails.status === null || reservationDetails.userId === props.userInfo.id) && reservationDetails.status !== null) || (reservationDetails.status === null || reservationDetails.userId === props.userInfo.id && reservationDetails.status == 'IP') }
                                //error ={(reservationDetails.reasonForCan === '' || reservationDetails.reasonForCan === null) && readOnlyApp}
                                value={reservationDetails.reasonForCan === null ? '' : reservationDetails.reasonForCan}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    }}
                                size="small"
                                fullWidth
                                onChange={(e)=> changeReservationDetails('reasonForCan',e.target.value)}
                                inputProps={{
                                    maxLength: 250
                                }}
                                //helperText={((reservationDetails.reasonForCan === '' || reservationDetails.reasonForCan === null) && error && readOnlyApp) ? "Required Field" : null}
                            />
                            {/* <FormHelperText>{reservationDetails.reasonForCan === null ? 0 :reservationDetails.reasonForCan.length}/250 Characters</FormHelperText> */}
                        </Grid>
                    </Grid>
                    </div>:null}
                    {(readOnlyDpoc && reservationDetails.userId !== props.userInfo.id) ? <div style={{ margin: "10px", textAlign: "center" }}><span className={classes.textLabel} style={{ color: "red" }}>You cannot take any action on this reservation because this vehicle belongs to a different division.</span></div> : <div>
                    {(reservationDetails.status === null || reservationDetails.userId === props.userInfo.id) ? <div style={{textAlign:'center',paddingBottom:10}}>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== null} onClick={()=>reset()}>Reset</Button>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== null} onClick={()=>save('IP')}>Submit</Button>
                    </div> :
                    <div style={{textAlign:'center',paddingBottom:10}}>                        
                         {readOnlyApp ? <div> <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={()=>save('CA')}>Cancel Reservation</Button></div>:
                         <div><Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== 'IP'} onClick={()=>save('CA')}>Cancel Reservation</Button>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== 'IP'} onClick={()=>save('PC')}>Approve</Button></div> }
                    </div>}</div>}
                    </div> : null
                } 
        </div> : 
        <div>
            <Typography className={classes.header}>Vehicle Usage</Typography>
            <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>VIN</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={reservationDetails.vehicles.vin}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
            </Grid>
            <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                      <span className="datepickerLabel">Reservation Start Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="reservation-start-date"
                            autoOk={true}
                            className = "disabled"
                            disabled
                            size="small"
                            fullWidth
                            value={filters.startDate}
                            error={(filters.startDate === null) || (!moment(filters.startDate, 'MM/DD/YYYY').isValid())}
                            minDate={new Date()}
                            onChange={(date)=> changeSearchFilters('startDate',date)}
                            placeholder="MM/DD/YYYY"
                            KeyboardButtonProps={{
                                'aria-label': 'start date',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.startDate === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.startDate !== null && !moment(filters.startDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null}
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Reservation Start Time</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardTimePicker 
                            variant="inline"
                            placeholder="HH:MM ZZ"
                            mask="__:__ _M"
                            margin="normal"
                            id="start-time"
                            autoOk={true}
                            className = "disabled"
                            disabled
                            size="small"
                            fullWidth
                            value={filters.startTime}
                            error={(filters.startTime === null) || (!moment(filters.startTime, 'MM/DD/YYYY').isValid())}
                            onChange={(date)=> changeSearchFilters('startTime',date)}
                            KeyboardButtonProps={{
                                'aria-label': 'start time',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.startTime === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.startTime !== null && !moment(filters.startTime, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Time Format</FormHelperText>
                            : null}
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                      <span className="datepickerLabel">Reservation End Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="reservation-end-date"
                            autoOk={true}
                            className = {oldRes ? "disabled" : "required"}
                            disabled = {oldRes}
                            size="small"
                            fullWidth
                            value={filters.endDate}
                            error={(filters.endDate === null) || (!moment(filters.endDate, 'MM/DD/YYYY').isValid())}
                            minDate={filters.startDate === null ? new Date() : filters.startDate}
                            onChange={(date)=> changeSearchFilters('endDate',date)}
                            placeholder="MM/DD/YYYY"
                            KeyboardButtonProps={{
                                'aria-label': 'start date',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.endDate === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.endDate !== null && !moment(filters.endDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null}
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Reservation End Time</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardTimePicker 
                            variant="inline"
                            placeholder="HH:MM ZZ"
                            mask="__:__ _M"
                            margin="normal"
                            id="end-time"
                            autoOk={true}
                            className = {oldRes ? "disabled" : "required"}
                            disabled = {oldRes}
                            size="small"
                            fullWidth
                            value={filters.endTime}
                            error={(filters.endTime === null) || (!moment(filters.endTime, 'MM/DD/YYYY').isValid())}
                            onChange={(date)=> changeSearchFilters('endTime',date)}
                            KeyboardButtonProps={{
                                'aria-label': 'end time',
                            }}
                            inputVariant="outlined"
                            helperText={(filters.endTime === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(filters.endTime !== null && !moment(filters.endTime, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Time Format</FormHelperText>
                            : null}
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Place Parked</span>
                        <TextField
                            id="place-parked"
                            className = {readOnly ? "disabled" : "required"}
                            disabled = {readOnly}
                            error ={usageInfo.placeParked === '' || usageInfo.placeParked === null}
                            value={usageInfo.placeParked === null ? '' :usageInfo.placeParked}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                              maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=> changeUsageInfo('placeParked',e.target.value)}
                            helperText={((usageInfo.placeParked === '' || usageInfo.placeParked === null) && error) ? "Required Field" : null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Parking Space Number</span>
                        <TextField
                            id="parked-space-num"
                            value={usageInfo.parkingSpaceNum === null ? '' :usageInfo.parkingSpaceNum}
                            variant="outlined"
                            className = {readOnly ? "disabled" : null}
                            disabled = {readOnly}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                              maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=> changeUsageInfo('parkingSpaceNum',e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Beginning Vehicle Mileage</span>
                        <TextField
                            id="beg-mileage"
                            className = {"required"}
                            error ={usageInfo.beginMileage === '' || usageInfo.beginMileage === null}
                            value={usageInfo.beginMileage === null ? '' :usageInfo.beginMileage}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: NumberCustom,
                                maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=> changeUsageInfo('beginMileage',e.target.value)}
                            helperText={((usageInfo.beginMileage === '' || usageInfo.beginMileage === null) && error) ? "Required Field" : null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Ending Vehicle Mileage</span>
                        <TextField
                            id="end-mil"
                            className = {"required"}
                            error ={usageInfo.endMileage === '' || usageInfo.endMileage === null}
                            value={usageInfo.endMileage === null ? '' :usageInfo.endMileage}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: NumberCustom,
                                maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=> changeUsageInfo('endMileage',e.target.value)}
                            helperText={((usageInfo.endMileage === '' || usageInfo.endMileage === null) && error) ? "Required Field" : null}
                            onBlur={(e)=> calculateMileage('endMileage',e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={12} sm={5} className={classes.textfield}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={usageInfo.remFuel === 0 || usageInfo.remFuel === null} >
                                <span className={classes.textLabel}>Remaining Fuel</span>
                                <Select
                                    id="rem-fuel"
                                    value={usageInfo.remFuel === null ? 0 : usageInfo.remFuel}
                                    className = {readOnly ? "disabled" : "required"}
                                    disabled = {readOnly}
                                    onChange={(e)=>changeUsageInfo('remFuel',e.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    <MenuItem value="QR">Quarter</MenuItem>
                                    <MenuItem value="HF">Half</MenuItem>
                                    <MenuItem value="TQ">Three Quarter</MenuItem>
                                    <MenuItem value="FU">Full</MenuItem>
                                </Select>
                                {((usageInfo.remFuel === 0 || usageInfo.remFuel === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                               
                            </FormControl>                                                                 
                        </Grid>
                    <Grid item xs={12} sm={5} className={classes.textfield}>                    
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gasPurchase-sw" name="gasPurchase-sw"  value={usageInfo.isGasPurchased} onChange={(e)=> changeUsageInfo('isGasPurchased',e.target.value)}>
                                <FormLabel component="legend" className={classes.radioLabel}>Did you purchase gas?</FormLabel>
                                <div>
                                    <FormControlLabel value="Y" control={<Radio disabled = {readOnly} color="primary" />} label="Yes" />
                                    <FormControlLabel value="N" control={<Radio disabled = {readOnly} color="primary" />} label="No" />
                                </div>
                            </RadioGroup>
                        </FormControl>                    
                    </Grid>

                </Grid>
                {usageInfo.isGasPurchased === 'Y' ? <div>
                <Subheader>Fuel Details</Subheader>
                {/* <Box style={{float: 'right'}} m={2}>
                        <Button  variant="outlined" color="primary" size="small" disabled={readOnly} onClick={addTripFuel}>Add Trip</Button>
                </Box>
                <Paper elevation={0}>
                <Box p={"10px"}>
                    <Gridtable columns={fuelDetailsColumns} data={tripFuelData}></Gridtable>
                </Box>   
                </Paper> */}

                <Grid container className={classes.filters}>
                    <Grid item xs={12}>
                        <Box style={{float:'right',margin:"20px 10px 10px 20px"}}>
                            <Button  variant="outlined" color="primary" size="small" className = {readOnly ? "disabled" : null}
                            disabled = {readOnly} onClick={()=>addTripFuel()}>Add Trip</Button>
                        </Box>
                        <Box pr={"10px"}>
                            <Gridtable columns={fuelDetailsColumns} data={tripFuelData}></Gridtable>
                        </Box>
                    </Grid>
                </Grid>
                </div>:null}
                
                <Subheader>Maintenance Details</Subheader>
                <Grid container className={classes.filters}>
                    <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Vehicle Mileage at Maintenance</span>
                        <TextField
                            id="mil-main"
                            value={usageInfo.maintMileage === null ? '' :usageInfo.maintMileage}
                            className = {readOnly ? "disabled" : null}
                            disabled = {readOnly}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: NumberCustom,
                                maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=> changeUsageInfo('maintMileage',e.target.value)}
                            onBlur={(e)=> calculateMileage('maintMileage',e.target.value)}
                        />
                    </Grid>
                </Grid>
                {/* <Box style={{float: 'right'}} m={2}>
                        <Button  variant="outlined" color="primary" size="small" disabled={readOnly} onClick={addMaintenance}>Add New</Button>
                </Box>
                <Paper elevation={0}>
                <Box p={"10px"}>
                    <Gridtable columns={maintenanceColumns} data={maintenanceData}></Gridtable>
                </Box>            
                </Paper> */}
                <Grid container className={classes.filters}>
                    <Grid item xs={12}>
                        <Box style={{float:'right',margin:"20px 10px 10px 20px"}}>
                            <Button  variant="outlined" color="primary" size="small" className = {readOnly ? "disabled" : null}
                            disabled = {readOnly} onClick={()=>addMaintenance()}>Add New</Button>
                        </Box>
                        <Box pr={"10px"}>
                            <Gridtable columns={maintenanceColumns} data={maintenanceData}></Gridtable>
                        </Box>
                    </Grid>
                </Grid>

                <Subheader>Upload Documents</Subheader>
                    <Grid container style={{padding:"0 10px"}}>
                        <Grid item xs={12}>
                            <Box style={{float:'right',margin:"20px 10px 10px 20px"}}>
                                <Button variant="outlined" color="primary" size="small" className = {readOnly ? "disabled" : null}
                                    disabled = {readOnlyDoc}>
                                    <span style={{position:'absolute' , maxWidth: '210px' }}>Select FIle & Upload</span>
                                    <input
                                        color="primary"
                                        type="file"
                                        onChange={(event)=> upload(event.target)}
                                        id="upload-file"
                                        style={{opacity:0, maxWidth: '210px',cursor:'pointer'}}
                                        />
                                </Button>
                            </Box>
                            <Box pr={"10px"}>
                                <Gridtable columns={uploadColumns} data={documents.filter((doc)=> doc.delSw !== 'Y')}></Gridtable>
                            </Box>
                        </Grid>
                    </Grid>
                <Subheader>Reservation Summary</Subheader>
                <Grid container className={classes.filters}>
                    <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Total Trip Cost</span>
                        <TextField
                            id="total-cost"
                            className="disabled"
                            disabled
                            value={usageInfo.totalCost === null ? '' :usageInfo.totalCost}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: AmountFormatCustom,
                                maxLength: 25
                            }}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                {(reservationDetails.status !== 'SC' || reservationDetails.userId === props.userInfo.id) ? <div style={{textAlign:'center',paddingBottom:10}}>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== 'PC'} onClick={()=>resetUsage()}>Reset</Button>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== 'PC' || submitDisabled} onClick={()=>saveReservationDetails('SC')}>Submit</Button>
                    </div> :
                    <div style={{textAlign:'center',paddingBottom:10}}>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== 'SC' || readOnly} onClick={()=>resetUsage()}>Reset</Button>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled={reservationDetails.status !== 'SC' || readOnly} onClick={()=>saveReservationDetails('CO')}>Approve & Close Reservation</Button>
                    </div>}
        </div>
        }
    </div>
  );
}