import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Typography from '@material-ui/core/Typography';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from '../assets/config';*/
import {postFetch} from '../http';
import {  Switch, Route } from "react-router-dom";
import { useHistory  } from "react-router-dom";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import '../App.css';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected":{
        fontWeight:600
    },
    "& .MuiSelect-selectMenu":{
        whiteSpace:'pre-wrap'
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'20px 0',
    padding:'0 10px'
  },
  textfield:{
      padding:'10px 10px 10px 0'
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  datepicker:{
    position: 'relative',
    bottom: 6,
    padding:'0 10px 0 0'
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

export default function Announcements(props) {
  const classes = useStyles();
  const history = useHistory();
  const [summaryData, setSummaryData]=React.useState([]);
  const [onInit, setOnInit] = React.useState(true);
  //const { transcript, resetTranscript } = useSpeechRecognition();
  const [ancmntDetails, setAncmntDetails] = React.useState({audience:[],division:[],description:null,fromDate:null,toDate:null,priority:null,title:null,id:null,appId:localStorage.getItem('appId')});
  const [error,setError] = React.useState(false);
  const [resetDetails, setResetDetails] = React.useState(null);
  const [multiSelect, setMultiselect] = React.useState(false);
  const [multiSelect2, setMultiselect2] = React.useState(false);
  



  React.useEffect(() => {
    window.scrollTo(0,0);
    const loadAnnouncements =  async ()=>{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setOnInit(false);
        history.push("/config/announcements");
        const request = {
          appId:parseInt(localStorage.getItem('appId'))
        } 
       await postFetch("/announcements/loadByAppId",request).then((data)=>{ 
            if(!data.message){
                data.announcements.forEach((ancmnt)=>{
                  let audience = '';
                  ancmnt.audience.split(',').forEach((aud)=> audience+=props.staticData.reference.ROLES.value.find((status)=>status.value === aud).name+',');
                  ancmnt.audDesc = audience.slice(0, -1);
                  ancmnt.priorDesc = props.staticData.reference.PRIORITIES.value.find((status)=>status.value === ancmnt.priority).name;
                });
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });
                    (props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? setSummaryData(data.announcements.filter((anc)=> anc.division === props.userInfo.division)) : setSummaryData(data.announcements);                                
                },1000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
    if(onInit){
        loadAnnouncements();
    }
  },[props,onInit,history]);
  
  const renderActionButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/></div>);
  }
  const viewFunction = (value)=>{
      let details = {...value};
      details.fromDate = new Date(convertDateFromServer(details.fromDate));
      details.toDate = new Date(convertDateFromServer(details.toDate));
      details.audience = details.audience.split(',');
      details.division = (props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? props.userInfo.division.split(',') : details.division.split(',');
    setAncmntDetails(details);
    setResetDetails(details);
    history.push("/config/announcements/details");
  }
  const changeView = ()=>{
      setAncmntDetails({audience:[],division:(props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? props.userInfo.division.split(',') : [],description:null,fromDate:null,toDate:null,priority:null,title:null,id:null,appId:localStorage.getItem('appId')});
      setResetDetails({audience:[],division:(props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? props.userInfo.division.split(',') : [],description:null,fromDate:null,toDate:null,priority:null,title:null,id:null,appId:localStorage.getItem('appId')});
    history.push("/config/announcements/details");
  }

  const changeAncmntDetails = (key,value)=>{
      if((key === 'fromDate') && (value < new Date(new Date().toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'From Date cannot be prior to Current Date' });                                 
        },1000);
      }
      if((key === 'toDate') && (ancmntDetails.fromDate !== null) && (value < new Date(ancmntDetails.fromDate.toDateString()))){
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'To Date cannot be prior to From Date' });                                 
        },1000);
      }
        let newDetails = {...ancmntDetails};
        newDetails[key] = value;
        setAncmntDetails(newDetails);
  }

  const reset = ()=>{
    setAncmntDetails(resetDetails);
  }

  function convertDateFromServer(value){
    if(value !== null){
      let serverDateArray = value.split("-");
      if(serverDateArray.length > 1){
        return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
      }
      else{
        return serverDateArray[0];
      }
    }
    else{
      return null;
    }
    
  }


  const saveAnnouncements = ()=>{
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
      }
      else if((ancmntDetails.fromDate > ancmntDetails.toDate)){
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'To Date cannot be prior to From Date' });                                 
        },1000);
      }
    else{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        let request = {...ancmntDetails};
        request.audience = request.audience.join(",");
        request.division = request.division.join(",");
        request.fromDate = moment.utc(request.fromDate).local().format("YYYY-MM-DD");
        request.toDate = moment.utc(request.toDate).local().format("YYYY-MM-DD");
        postFetch("/announcements/save",request).then((data)=>{ 
            if(!data.message){
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"success",validationMessage:"Announcement saved successfully" });                                
                },1000);
                setTimeout(()=>{                         
                  setOnInit(true);                               
              },3000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
  }

  const audienceChange = (event) => {
    event.target.value = event.target.value.filter((e)=> e!== 0);
    if(event.target.value.indexOf("All") > -1){
      if((props.userInfo.role === 'SA' || props.userInfo.role === 'VA') && (ancmntDetails.audience.length === props.staticData.dropdown.ROLES.value.length)){
        let newDetails = {...ancmntDetails};
        newDetails.division = [];
        newDetails.audience = [];
        setAncmntDetails(newDetails);
      }
      else if (((props.userInfo.role !== 'SA' && props.userInfo.role !== 'VA')) && (ancmntDetails.audience.length === (props.staticData.dropdown.ROLES.value.length-1))){
        let newDetails = {...ancmntDetails};
        newDetails.division = [props.userInfo.division];
        newDetails.audience = [];
        setAncmntDetails(newDetails);
      }
      else{
        let newDetails = {...ancmntDetails};
        if((props.userInfo.role === 'SA' || props.userInfo.role === 'VA')){
          newDetails.division = props.staticData.dropdown.DIVISIONS.value.map((priority)=> {return priority.value});
          newDetails.audience = props.staticData.dropdown.ROLES.value.map((priority)=> {return priority.value});
        }
        else{
          newDetails.audience = props.staticData.dropdown.ROLES.value.map((priority)=> {return priority.value}).filter((val)=> val !== 'SA' && val !== 'VA');
        }
        setAncmntDetails(newDetails);
      }
    }
    else if(event.target.value.indexOf("SA") > -1 || event.target.value.indexOf("VA") > -1){
      let newDetails = {...ancmntDetails};
      newDetails.division = props.staticData.dropdown.DIVISIONS.value.map((priority)=> {return priority.value});
      newDetails.audience = event.target.value;
      setAncmntDetails(newDetails);
    }
    else{
      changeAncmntDetails('audience',event.target.value);
    }
    setTimeout(()=>{
      setMultiselect(true);
    },0)
  };

  const handleDeleteAud = (value) =>{
    setMultiselect(false);
    let selectedValues = [...ancmntDetails.audience];
    selectedValues = selectedValues.filter((selectedValue)=> {return selectedValue !== value;} );
    changeAncmntDetails('audience',selectedValues);
  }

  const divisionChange = (event) => {
    event.target.value = event.target.value.filter((e)=> e!== 0);
    (event.target.value.indexOf("All") > -1) ? ((ancmntDetails.division.length === props.staticData.dropdown.DIVISIONS.value.length) ? changeAncmntDetails('division',[]) : 
    changeAncmntDetails('division', props.staticData.dropdown.DIVISIONS.value.map((priority)=> {return priority.value}))) :  changeAncmntDetails('division',event.target.value);
    setTimeout(()=>{
      setMultiselect2(true);
    },0)
  };

  const handleDeleteDiv = (value) =>{
    setMultiselect2(false);
    let selectedValues = [...ancmntDetails.division];
    selectedValues = selectedValues.filter((selectedValue)=> {return selectedValue !== value;} );
    changeAncmntDetails('division',selectedValues);
  }

  const summaryColumns = [
    {
        name:"Title",
        field:"title",
        type:"text"
    },
    {
        name:"Effective From",
        field:"fromDate",
        type:"date"
    },
    {
        name:"Effective To",
        field:"toDate",
        type:"date"
    },
    {
        name:"Priority",
        field:"priorDesc",
        type:"text"
    },
    {
        name:"Audience",
        field:"audDesc",
        type:"text"
    },
    {
        name:"Action",
        field:"action",
        type:"input",
        renderView: renderActionButton
    }
  ]

  return (
    <div className={classes.root}>
     <Switch>
            <Route exact path="/config/announcements">
                <div>
                    <Typography className={classes.header}>Announcements Summary</Typography>
                    <Box style={{float: 'right'}} m={2}>
                        <Button  variant="outlined" color="primary" size="small" onClick={changeView}>Add New</Button>
                    </Box>
                        <Paper elevation={0}>
                            <Box p={"10px"}>
                                <Gridtable columns={summaryColumns} data={summaryData}></Gridtable>
                            </Box> 
                            
                        </Paper>
                </div>
            </Route>
            <Route exact path="/config/announcements/details">
                <div>
                <Typography className={classes.header}>Announcement Details</Typography>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} className={classes.textfield}>
                    <span className={classes.textLabel}>Title</span>
                        <TextField
                            id="title"
                            className="required"
                            error ={ancmntDetails.title === '' || ancmntDetails.title === null}
                            value={ancmntDetails.title === null ? '' : ancmntDetails.title}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                              maxLength: 100
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=>changeAncmntDetails('title',e.target.value)}
                            helperText={((ancmntDetails.title === '' || ancmntDetails.title === null) && error) ? "Required Field" : null}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Valid From Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="effectiveFrom-date"
                            autoOk={true}
                            className="required"
                            value={ancmntDetails.fromDate}
                            error={(ancmntDetails.fromDate === null) || (!moment(ancmntDetails.fromDate, 'MM/DD/YYYY').isValid())}
                            size="small"
                            fullWidth
                            minDate={new Date()}
                            placeholder="MM/DD/YYYY"
                            onChange={(date)=> changeAncmntDetails('fromDate',date)}
                            invalidDateMessage = {"Invalid Date Format"}
                            KeyboardButtonProps={{
                                'aria-label': 'Effective From date',
                            }}
                            inputVariant="outlined"
                            helperText={((ancmntDetails.fromDate === null && error)) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(ancmntDetails.fromDate !== null && !moment(ancmntDetails.fromDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null} 
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Valid To Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                             variant="inline"
                             format="MM/dd/yyyy"
                             margin="normal"
                             id="effectiveTo-date"
                             autoOk={true}
                             className="required"
                             value={ancmntDetails.toDate}
                             error={(ancmntDetails.toDate === null) || (!moment(ancmntDetails.toDate, 'MM/DD/YYYY').isValid())}
                             size="small"
                             fullWidth
                             minDate={ancmntDetails.fromDate === null ? new Date() : ancmntDetails.fromDate}
                             placeholder="MM/DD/YYYY"
                             onChange={(date)=> changeAncmntDetails('toDate',date)}
                             KeyboardButtonProps={{
                                 'aria-label': 'Effective To date',
                             }}
                             inputVariant="outlined"
                             helperText={(ancmntDetails.toDate === null && error) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(ancmntDetails.toDate !== null && !moment(ancmntDetails.toDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null} 
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Priority</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={ancmntDetails.priority === 0 || ancmntDetails.priority === null} >
                            <Select
                                id="priority-cd"
                                value={ancmntDetails.priority === null ? 0 : ancmntDetails.priority}
                                className="required"
                                onChange={(e)=> changeAncmntDetails('priority',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.PRIORITIES.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((ancmntDetails.priority === 0 || ancmntDetails.priority === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Audience</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={ancmntDetails.audience.length === 0 || ancmntDetails.audience === null} >
                            <Select
                                id="audience-cd"
                                multiple
                                value={ancmntDetails.audience.length < 1 ? [0]: ancmntDetails.audience}
                                variant="outlined"
                                onChange={audienceChange}
                                className="required"
                                open={multiSelect}
                                fullWidth
                                onClick={e => setMultiselect(!multiSelect)}
                                renderValue={(selected) => (
                                  ancmntDetails.audience.length === 0 ?
                                <div>Pick an Option</div> :
                                  <div className={classes.chips}>
                                    {selected.map((value) => (
                                      <Chip key={value} 
                                      label={props.staticData.reference.ROLES.value.find((status)=>status.value === value).name} 
                                      className={classes.chip} onDelete={()=>handleDeleteAud(value)}  color="primary"/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                <MenuItem key="All" value="All">
                                    <Checkbox checked={(props.userInfo.role !== 'SA' && props.userInfo.role !== 'VA') ? (ancmntDetails.audience.length === (props.staticData.dropdown.ROLES.value.length -1)) : (ancmntDetails.audience.length === props.staticData.dropdown.ROLES.value.length)}  color="primary"/>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                {(props.userInfo.role !== 'SA' && props.userInfo.role !== 'VA') ? props.staticData.dropdown.ROLES.value.filter((role)=> role.value !== 'SA' && role.value !== 'SA').map((status) => (
                                  <MenuItem key={status.name} value={status.value}>
                                    <Checkbox checked={ancmntDetails.audience.indexOf(status.value) > -1}  color="primary"/>
                                    <ListItemText primary={status.name} />
                                  </MenuItem>
                                )) : props.staticData.dropdown.ROLES.value.map((status) => (
                                  <MenuItem key={status.name} value={status.value}>
                                    <Checkbox checked={ancmntDetails.audience.indexOf(status.value) > -1}  color="primary"/>
                                    <ListItemText primary={status.name} />
                                  </MenuItem>))}
                                </Select>
                                {((ancmntDetails.audience.length === 0 || ancmntDetails.audience === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                              
                        </FormControl>
                    </Grid>          
            </Grid>
            <Grid container className={classes.filters}>
            <Grid item xs={10} sm={10} className={classes.textfield}>
                    <span className={classes.textLabel}>Division</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={ancmntDetails.division.length === 0 || ancmntDetails.division === null}>
                            <Select
                                id="division-cd"
                                multiple
                                value={(ancmntDetails.division.length < 1 ? [0]: ancmntDetails.division)}
                                variant="outlined"
                                onChange={divisionChange}
                                className="required"
                                open={((props.userInfo.role ==='DA' || ancmntDetails.audience.indexOf("SA") > -1) || (props.userInfo.role ==='DP' || ancmntDetails.audience.indexOf("VA") > -1)) ? false: multiSelect2}
                                fullWidth
                                onClick={() => setMultiselect2(!multiSelect2)}
                                renderValue={(selected) => (
                                  ancmntDetails.division.length === 0 ?
                                <div>Pick an Option</div> :
                                  <div className={classes.chips}>
                                    {selected.map((value) => (
                                      <Chip key={value} 
                                      label={props.staticData.reference.DIVISIONS.value.find((status)=>status.value === value).name} 
                                      className={classes.chip} onDelete={()=>(ancmntDetails.audience.indexOf("SA") > -1 || ancmntDetails.audience.indexOf("VA") > -1) ? null : handleDeleteDiv(value)}  color="primary"/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                <MenuItem key="All" value="All">
                                    <Checkbox checked={ancmntDetails.division.length === props.staticData.dropdown.DIVISIONS.value.length}  color="primary"/>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                {props.staticData.dropdown.DIVISIONS.value.map((status) => (
                                  <MenuItem key={status.name} value={status.value}>
                                    <Checkbox checked={ancmntDetails.division.indexOf(status.value) > -1}  color="primary"/>
                                    <ListItemText primary={status.name} />
                                  </MenuItem>
                                ))}
                                </Select>
                                {((ancmntDetails.division.length === 0 || ancmntDetails.division === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                              
                        </FormControl>
                    </Grid>
            </Grid>
           
            <Grid container className={classes.filters}>
            <Grid item xs={10} className={classes.textfield}>
              <span className={classes.textLabel}>Description</span>
                <TextField
                   id="description"
                   multiline
                   className="required"
                   error ={ancmntDetails.description === '' || ancmntDetails.description === null}
                   value={ancmntDetails.description === null ? '' : ancmntDetails.description}
                   variant="outlined"
                   InputLabelProps={{
                       shrink: true,
                     }}
                   size="small"
                   fullWidth
                   onChange={(e)=> changeAncmntDetails('description',e.target.value)}
                   inputProps={{
                       maxLength: 250
                     }}
                   helperText={((ancmntDetails.description === '' || ancmntDetails.description === null) && error) ? "Required Field" : null}
               />
               <FormHelperText>{ancmntDetails.description === null ? 0 :ancmntDetails.description.length}/250 Characters</FormHelperText>
            </Grid>
            </Grid>
            <div style={{textAlign:'center',paddingBottom:10}}>
                <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={reset}>Reset</Button>
                <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={saveAnnouncements}>Save</Button>
            </div>
                </div>
            </Route>
        </Switch>
    </div>
  );
}
