import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from './assets/config';

 export  async function postFetch (url,data)
   {
            (data.DocumentID !== undefined) ? (url = Config.edoctusUrl+url) : (url=Config.url+url);
            const cookies = (Cookies.get("state") === undefined) ? "": JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).token;
           const res = await  fetch(url,{
            method:'POST',
            headers:{
              'Content-Type':'application/json',
              'Access-Control-Allow-Origin':'*',
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              "Authorization":"Bearer "+ cookies
            },
            body:JSON.stringify(data)
          });
          const json = await res.json();
          return json
    }

   export  async function postFetch2 (url,data)
   {
            (url = Config.edoctusUrl+url);
            const cookies = (Cookies.get("state") === undefined) ? "": JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).token;
           const res = await  fetch(url,{
            method:'POST',
            headers:{
              'Content-Type':'application/json',
              'Access-Control-Allow-Origin':'*',
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              "Authorization":"Bearer "+ cookies
            },
            body:JSON.stringify(data)
          });
          const json = await res.json();
          return json
    }

     export  async function postFileDownload (url)
    {
            url = Config.edoctusUrl+url
             const cookies = (Cookies.get("state") === undefined) ? "": JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).token;
            const res = await  fetch(url,{
             method:'GET',
             headers:{
              'Content-Type':'application/json',
               'Access-Control-Allow-Origin':'*',
               "Authorization":"Bearer "+ cookies
             }
          });
          const json = await res.json();
          return json
     }

export default {postFetch};


