import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import './App.css';
import {postFetch} from './http';
import Loader from './loader';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AnnouncementIcon from './imgs/announcements.svg';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from './assets/config';
import Button from '@material-ui/core/Button';
import GA4React from "ga-4-react";

 class WorkerDashboard extends React.Component {
    constructor(props) {
      super(props);
      this.state={
          loader:{loader:false,notification:false,message:"success",validationMessage:"Success"},
          announcementData:[],
          reRender:true
    };
    this.announcementcolumns=[{
        name:"Title",
        field:"title",
        type:"text"
    },{
        name:"Date",
        field:"effectiveFromDt",
        type:"date"
    },{
        name:"Priority",
        field:"priorityCd",
        type:"text"
    }];
    //this.myRef = React.createRef();
    }
   

     convertDateFromServer(value){
        if(value !== null){
          let serverDateArray = value.split("-");
          return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
        }
        else{
          return null;
        }
        
      }

      componentDidMount  = function() {
        window.scrollTo(0,0);
        this.setState(prevState => {
            let loader = Object.assign({}, prevState.loader);   
            loader.loader = true;
            loader.notification = false                                  
            return { loader };                                 
          });
          setTimeout(() => {
            if(this.state.reRender && this.props.userDetails.id !== undefined){
                this.onLoad();
            }
          }, 3000);
                
          try {
            setTimeout(_ => {
              const ga4react = new GA4React("G-511XD17SWX");
              ga4react.initialize().then((ga4) => {
                ga4.pageview(window.location.pathname)        
                //ga4.gtag('event','pageview','path') // or your custom gtag event
              },(err) => {
                console.error(err)
              })
            }, 4000);
          } catch (err) {
                console.error(err);
          }
        }
     onLoad = async()=>{
        this.setState(prevState => {
            let loader = Object.assign({}, prevState.loader);   
            loader.loader = true;
            loader.notification = false                                  
            return { loader };                                 
          });
          const data= {...this.props.userDetails};
         await postFetch("/dashboard/load",data).then((data)=>{
             this.setState({reRender:false});
              if(!data.messages){
                  data.announcements.forEach((ancmnt)=>{
                      ancmnt.fromDate = this.convertDateFromServer(ancmnt.fromDate)
                  });
                this.setState({announcementData:data.announcements});
                this.setState({rrAll:data.rrAll});
                this.setState({rrIP:data.rrIP});
                this.setState({rrSB:data.rrSB});
                this.setState({rrAP:data.rrAP});
                this.setState({rrPP:data.rrPP});
                this.setState({rrPO:data.rrPO});
                this.setState({rrRR:data.rrRR});
                this.setState({rrPenReview:data.rrPenReview});
                this.setState({taskOver:data.taskOver});
                this.setState({taskToday:data.taskToday});
                this.setState({taskUpcoming:data.taskUpcoming});
                this.setState({ppPenReview:data.ppPenReview});
                this.setState({ppSB:data.ppSB});
                this.setState({ppAP:data.ppAP});
                this.setState({ppRR:data.ppRR});
                this.setState({ppIP:data.ppIP});

                  setTimeout(()=>{
                    this.setState(prevState => {
                        let loader = Object.assign({}, prevState.loader);   
                        loader.loader = false;
                        loader.notification = true;
                        loader.message="success";
                        loader.validationMessage = "Loaded Successfully"                                
                        return { loader };                                 
                      });
                      //setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message});
                  },1000);
              }
              else{
                  setTimeout(()=>{
                    this.setState(prevState => {
                        let loader = Object.assign({}, prevState.loader);   
                        loader.loader = false;
                        loader.notification = true;
                        loader.message="error";
                        loader.validationMessage = data.message                                
                        return { loader };                                 
                      });
                      //setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message});
                  },1000);
              }
          }).catch((e)=>{
            setTimeout(()=>{
                this.setState(prevState => {
                    let loader = Object.assign({}, prevState.loader);   
                    loader.loader = false;
                    loader.notification = true;
                    loader.message="error";
                    loader.validationMessage = e.message                                
                    return { loader };                                 
                  });
            },1000);
          }); 
      }
    render() {
        const { classes } = this.props;
        const loaderAction = async()=>{
            this.setState(prevState => {
                let loader = Object.assign({}, prevState.loader);   
                loader.notification = false;                              
                return { loader };                                 
              });
        };
        function generate(data) {
            if(data !== undefined && data.length > 0){
                return data.map((ancmnt) =>
                React.cloneElement( <ListItem button divider>
                  <ListItemIcon>
                      <img src={AnnouncementIcon} alt="announcementsIcon" width="30px" height="30px"/>
                  </ListItemIcon>
                  <span className={classes.announcementDate}>{ancmnt.fromDate} </span>
                  <span className={classes.announcementText}> {ancmnt.description}</span>
              </ListItem>,{key:ancmnt.id}),
              );
            }
            else{
                return React.cloneElement( <ListItem button>
                  <span className="norowsText"> No rows to show</span>
              </ListItem>,{key:"Norows"});
            }
               
          }

          const newReimbursement=()=>{
            localStorage.removeItem("readOnly");
            localStorage.removeItem("requestId");
            localStorage.removeItem("preApproval");
            localStorage.removeItem("dates");
            this.props.history.push('/tr1');
          }

          const newPreApproval=()=>{
            localStorage.removeItem("preApproval");
            localStorage.removeItem("requestId");
            this.props.history.push('/preApproval');
          }

      return (          
      <div>
          <Loader loader={this.state.loader.loader} notification = {this.state.loader.notification} message={this.state.loader.message} action={loaderAction} validationMessage = {this.state.loader.validationMessage}/>
          <Grid container direction="row" spacing={2} className={classes.root}>
            <Grid item xs={12} sm={6} style={{margin:0}}>
                <span  className="dashboardWelcome" tabIndex="0">
                    Welcome to {JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).firstName}'s Dashboard     
                </span>
            </Grid>
            <Grid item xs={12} sm={6} style={{margin:0}}>
                <div style={{textAlign:'right',paddingBottom:5}}>
                    <Button style={{margin:"20px 5px 5px 20px"}}  variant="contained" color="primary" size="small" onClick={()=> newReimbursement()} >New Reimbursement</Button>
                    { (['EM','SA','DA','TS'].indexOf(this.props.userDetails.role) > -1) ? <Button style={{margin:"20px 8px 5px 20px"}}  variant="contained" color="primary" size="small" onClick={()=> newPreApproval()} >New Pre-approval</Button> : null}
                </div>
            </Grid>
          </Grid>
           
          <Grid container direction="row" spacing={2} className={classes.root}>
          <Grid item xs={12} sm={6} style={{height:"100%"}}>
          <Grid container direction="row" style={{marginBottom:20}}>
          { (['SA','DA','TS'].indexOf(this.props.userDetails.role) > -1) ? <Grid item xs={12} sm={6} style={{margin:0,padding:"0 8px 8px 0"}}>
                        <Paper elevation={3} style={{height:"100%"}}>
                            <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                                Requests Pending Review&nbsp;&nbsp;
                            </Typography>
                            <Typography style={{color:"#131937", cursor:"pointer"}} className={classes.cardText} onClick={()=> this.props.onSearch("requests",1,(this.props.info.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === this.props.userDetails.id.toString()).length > 0) ? ["AP"] : ["SB","AP"])} tabIndex="0">
                            {this.state.rrPenReview}
                            </Typography>
                        </Paper>
                    </Grid> : null}
                    <Grid item xs={12} sm={ (['SA','DA','TS'].indexOf(this.props.userDetails.role) > -1) ? 6 : 12} style={{margin:0,padding:"0 8px 8px 0"}}>
                    <Paper elevation={3} style={{height:"100%"}}>
                        <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                            Reimbursement Requests&nbsp;&nbsp;&nbsp;
                            
                        </Typography>
                        <List component="nav" aria-label="reimbursementRequests" >
                            <ListItem button divider>
                                <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onSearch("requests",2,["IP","SB","AP","PP","PO","RR"])}>
                                    <span>All</span>
                                    <span className={classes.badges} style={{color:'#283032'}}>{this.state.rrAll}</span>
                                </div>
                            </ListItem>
                            <ListItem button divider>
                                <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onSearch("requests",2,["IP"])}>
                                    <span >In Progress</span>
                                    <span className={classes.badges} style={{color:'#283032'}}>{this.state.rrIP}</span>
                                </div>
                            </ListItem>
                            <ListItem button divider>
                                <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onSearch("requests",2,["SB"])}>
                                    <span >Submitted for Approval</span>
                                    <span className={classes.badges} style={{color:'#283032'}}>{this.state.rrSB}</span>
                                </div>
                            </ListItem>
                            <ListItem button divider>
                                <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onSearch("requests",2,["AP"])}>
                                    <span >Approved Requests</span>
                                    <span className={classes.badges} style={{color:'#283032'}}>{this.state.rrAP}</span>
                                </div>
                            </ListItem>
                            <ListItem button divider>
                                <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onSearch("requests",2,["PP"])}>
                                    <span>Payment Pending</span>
                                    <span className={classes.badges} style={{color:'#283032'}}>{this.state.rrPP}</span>
                                </div>
                            </ListItem>
                            <ListItem button divider>
                                <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onSearch("requests",2,["PO"])}>
                                    <span >Paid</span>
                                    <span className={classes.badges} style={{color:"#283032"}}>{this.state.rrPO}</span>
                                </div>
                            </ListItem>
                            <ListItem button>
                                <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onSearch("requests",2,["RR"])}>
                                    <span >Revision Requested</span>
                                    <span className={classes.badges} style={{color:'#283032'}}>{this.state.rrRR}</span>
                                </div>
                            </ListItem>
                        </List>
                    </Paper>
                    </Grid>
                    </Grid>
         
                    <Grid container direction="row" >
                    { (['SA','DA','TS'].indexOf(this.props.userDetails.role) > -1) ? <Grid item xs={12} sm={6} style={{margin:0,padding:"0 8px 8px 0"}}>
                        <Paper elevation={3} style={{height:"100%"}}>
                            <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                                Pre-Approvals Review
                            </Typography>
                            <Typography style={{color:"#131937", cursor:"pointer"}} className={classes.cardText} onClick={()=> this.props.onPreApprovalSearch(1,["SB"])} tabIndex="0">
                                {this.state.ppPenReview}
                            </Typography>
                        </Paper>
                    </Grid> : null}
                    { (['EM','SA','DA','TS'].indexOf(this.props.userDetails.role) > -1) ? <Grid item xs={12} sm={(['SA','DA','TS'].indexOf(this.props.userDetails.role) > -1) ? 6 : 12} style={{margin:0,padding:"0 8px 8px 0"}}>
                        <Paper elevation={3} style={{height:"100%"}}>
                            <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                                Pre-Approval Requests
                            </Typography>
                            <List component="nav" aria-label="preApprovalRequests" >
                            <ListItem button divider>
                                    <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onPreApprovalSearch(2,["IP"])}>
                                        <span className={classes.announcementText}>In Progress</span>
                                        <span className={classes.badges} style={{color:'#283032'}}>{this.state.ppIP}</span>
                                    </div>
                                </ListItem>
                                <ListItem button divider>
                                    <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onPreApprovalSearch(2,["SB"])}>
                                        <span >Submitted for Approval </span>
                                        <span className={classes.badges} style={{color:'#283032'}}>{this.state.ppSB}</span>
                                    </div>
                                </ListItem>
                                <ListItem button divider>
                                    <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onPreApprovalSearch(2,["AP"])}>
                                        <span className={classes.announcementText}>Approved</span>
                                        <span className={classes.badges} style={{color:'#283032'}}>{this.state.ppAP}</span>
                                    </div>
                                </ListItem>
                                
                                <ListItem button>
                                    <div className={classes.announcementText} style={{cursor:"pointer"}} onClick={()=> this.props.onPreApprovalSearch(2,["RR"])} >
                                        <span className={classes.announcementText}>Revision Requested</span>
                                        <span className={classes.badges} style={{color:'#283032'}}>{this.state.ppRR}</span>
                                    </div>
                                </ListItem>
                                
                                
                            </List>
                        </Paper>
                    </Grid> : null}
                    </Grid>
              </Grid>
              <Grid item xs={12} sm={6} style={{height:"100%"}}>
                  <Grid container direction="row" >
                    <Grid item xs={12} sm={4} style={{margin:0,padding:"0 8px 8px 0"}}>
                        <Paper elevation={3} style={{height:"100%",minHeight:"200px"}}>
                            <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                            Overdue Tasks&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            <Typography style={{color:"#d51204",cursor:"pointer"}} className={classes.cardText} onClick={()=> this.props.onSearch("tasks",1,["IP","OP"])} tabIndex="0">
                                {this.state.taskOver}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{margin:0,padding:"0 8px 8px 0"}}>
                        <Paper elevation={3} style={{height:"100%",minHeight:"200px"}}>
                            <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                            Tasks Due Today
                            </Typography>
                            <Typography style={{color:"#faae00",cursor:"pointer"}} className={classes.cardText} onClick={()=> this.props.onSearch("tasks",2,["IP","OP"])} tabIndex="0">
                                {this.state.taskToday}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{margin:0,padding:"0 8px 8px 0"}}>
                        <Paper elevation={3} style={{height:"100%",minHeight:"200px"}}>
                            <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                            Upcoming Tasks&nbsp;
                            </Typography>
                            <Typography style={{color:"#3f51b5",cursor:"pointer"}} className={classes.cardText} onClick={()=> this.props.onSearch("tasks",3,["IP","OP"])} tabIndex="0">
                                {this.state.taskUpcoming}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid container direction="row">
                    <Grid item xs={12} style={{marginTop:"20px",paddingRight:"8px"}}>
                    <Paper elevation={3} style={{minHeight:"314px"}}>
                        <Typography variant="h6" color="inherit" className={classes.title} tabIndex="0">
                            Announcements
                        </Typography>
                    <List component="nav" aria-label="Announcements" >
                        {generate(this.state.announcementData)}
                    </List>
                    </Paper>
                    </Grid>
                    
                    </Grid>
                  </Grid>
                </Grid>
                </Grid>
    </div>)
    }
  }

  const useStyles = ((theme) => ({
    root: {
        "& .MuiGrid-item":{
            margin: '15px 0 15px 0'
        },
        "& .MuiPaper-elevation3":{
            borderBottom: '3px solid #2f2a47'
        },
        "& .MuiListItem-root":{
            paddingTop:4,
            paddingBottom:2
        }
    },
    badges:{
        padding: '3px',
        fontSize: '17px',
        float: 'right',
        position: 'relative',
        bottom: '4px'
    },
    cardText:{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        fontSize: '100px',
        [theme.breakpoints.down('sm')]:{
            fontSize:'60px'
        }
      },
    fabIcon:{
        textTransform:"Capitalize",
        float:"right"
    },
    title:{
        borderBottom: '3px solid #1a75c9',
        padding: '10px 16px',
        fontSize: '18px'
    },
    announcementText:{
        color: '#000',
        fontSize: '14px',
        fontWeight: '500',
        width:'100%'
    },
    grid:{
        [theme.breakpoints.up('sm')]:{
            flexGrow: 0,
            maxWidth: '56%',
            flexBasis: '56%',
            paddingLeft:'45px'
        }
    },
    announcementDate:{
        color: '#000',
        fontSize: '14px',
        fontWeight: '500',
        paddingRight:"20px"
    }
  }));

  export default withRouter((withStyles(useStyles)(WorkerDashboard)))
  