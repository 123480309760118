import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Typography from '@material-ui/core/Typography';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from '../assets/config';*/
import { postFetch } from '../http';
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import '../App.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: 600
    },
    "& .MuiSelect-selectMenu": {
      whiteSpace: 'pre-wrap'
    }
  },
  header: {
    backgroundColor: "#1a75c9",
    color: "#fff",
    padding: 10,
    fontWeight: 900,
    fontSize: 15
  },
  filters: {
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
      color: "#000",
      fontSize: 17
    },
    margin: '20px 0',
    padding: '0 10px'
  },
  datepicker: {
    position: 'relative',
    bottom: 5,
    padding: '0 10px 0 0'
  },
  textfield: {
    padding: '10px 10px 10px 0'
  },
  textLabel: {
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  chip: {
    width: '90%'
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###)-###-####"
      mask="_"
    />
  );
}

function LicenseFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##########"
      mask="_"
    />
  );
}

function PhoneExtFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="######"
      mask="_"
    />
  );
}

const Subheader = ({ children }) => {
  return (
    <div className="wrapper">
      <p>{children}</p>
      <div className="line"></div>
    </div>
  )
}

export default function StaffManagement(props) {
  const classes = useStyles();
  const history = useHistory();
  const [summaryData, setSummaryData] = React.useState([]);
  const [onInit, setOnInit] = React.useState(true);
  //const { transcript, resetTranscript } = useSpeechRecognition();
  const [error, setError] = React.useState(false);
  const [resetDetails, setResetDetails] = React.useState(null);
  const [multiSelect, setMultiselect] = React.useState(false);
  const [multiSelect2, setMultiselect2] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState('');
  const [filterSw, setFilterSw] = React.useState('Active');
  const [user, setUser] = React.useState({
    beginDate: null, endDate: null, firstName: null, lastName: null, county: [], vendorNum: null,
    password: null, passwordExp: null, tempPassword: null, username: null, phone: null, email: '', modifySw: false, supervisor: null, id: null, phoneExt: null, role: null, division: [], appId: localStorage.getItem('appId'), officialStation: [], licenseNum: null, licenseExpirationDate: null, dcfsSw: false,
    isApproveReimb: 'N',userType: null,
    isEligibleDrive: 'N', isOfficeDirector: false
  });
  const [emailError, setEmailError] = React.useState(false);
  const SpecialCharformat = /[`!@#$%^&*()_+\\=[\]{};:"\\|,.<>/?~]/;


  const checkFormat = (value) => {
    let len = 0;
    value !== null ? len = value.toString().length : len = 0;
    return len;
  }


  React.useEffect(() => {
    window.scrollTo(0, 0);
    const loadStaffManagement = async () => {
      props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
      setOnInit(false);
      history.push("/config/usermanagement");
      const request = {
        appId: localStorage.getItem('appId')
      }
      await postFetch("/userManagement/loadByAppId", request).then((data) => {
        if (!data.message) {
          data.userManagement.forEach((user) => {
            user.fullName = user.firstName + ' ' + user.lastName;
            
            user.division = user.division.split(',');
            user.county = (user.county === null) ? [] : user.county.split(',');
            let division = '';
            user.division.forEach((div) => {
              const temp = props.staticData.reference.DIVISIONS.value.find((status) => status.value === div);
              if(temp){
                division += temp.name + ','
              }
              
            });

            user.officialStation = user.officialStation && user.officialStation.split(',');

            user.divisionDesc = division.slice(0, -1);
            user.filterData = (user.fullName + user.username +user.divisionDesc).toLowerCase();
            user.roleDesc = props.staticData.reference.ROLES.value.find((status) => status.value === user.role).name;
            (user.endDate === null || (new Date() < new Date(convertDateFromServer(user.endDate)))) ? user.status = 'Active' : user.status = 'Inactive';
            user.dcfsSw = (user.dcfsSw === 'Y' ? true : false);
            user.isOfficeDirector = (user.isOfficeDirector === 'Y' ? true : false);

          })
          setTimeout(() => {
            props.loader({ loader: false, notification: false });
            (props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? setSummaryData(data.userManagement.filter((user) => user.division.toString().trim() === props.userInfo.division.toString().trim())) : setSummaryData(data.userManagement);
          }, 1000);
        }
        else {
          setTimeout(() => {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
          }, 1000);
        }
      }).catch((e) => {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
        }, 1000);
      });
    }
    if (onInit) {
      loadStaffManagement();
    }
  }, [props, onInit, history]);


  const saveStaff = () => {
    if (document.getElementsByClassName("Mui-error").length > 0) {
      setError(true);
      return;
    } else if (localStorage.getItem("appId") === '1' && (!user.officialStation || user.officialStation?.length ==0)){
      setError(true);
      return;
    }
    else {
      saveUser();
    }
  }

  const saveUser = async () => {
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    let request = {};
    request = { ...user };
    request.division = request.division.join(',');
    if(localStorage.getItem("appId") === '1')
    {
      request.officialStation = request.officialStation.join(',');
    }
    else
    {
      request.officialStation = null;
    }
    request.county = request.county.join(',');
    request.licenseExpirationDate = (request.licenseExpirationDate === null) ? null : moment(request.licenseExpirationDate).format("YYYY-MM-DD");
    request.modifySw = (request.modifySw ? 'Y' : 'N');
    request.dcfsSw = (request.dcfsSw ? 'Y' : 'N');
    request.isApproveReimb = request.role === 'SA' ? request.isApproveReimb : null;
    request.isOfficeDirector =  request.isOfficeDirector ? 'Y' : 'N';
    await postFetch("/userManagement/save", request).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "success", validationMessage: "User saved successfully" });
        }, 1000);
        setTimeout(() => {
          setOnInit(true);
        }, 3000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
      }, 1000);
    });
  }

  const renderActionButton = (row) => {
    return (<div style={{ cursor: 'pointer' }}><EditRoundedIcon onClick={() => viewFunction(row)} style={{ fontSize: 20 }} /></div>);
  }
  const viewFunction = (value) => {
    setEmailError(false);
    (value.modifySw === "Y" ? (value.modifySw = true) : (value.modifySw = false));
    value.division = (props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? props.userInfo.division.split(',') : value.division;
    if(value.beginDate != null  && !(value.beginDate instanceof Date)){
      value.beginDate = new Date(convertDateFromServer(value.beginDate));
    }
    
    if(value.licenseExpirationDate != null  && !(value.licenseExpirationDate instanceof Date)){
      value.licenseExpirationDate = new Date(convertDateFromServer(value.licenseExpirationDate));
    }
    if(value.endDate != null  && !(value.endDate instanceof Date)){
      value.endDate = new Date(convertDateFromServer(value.endDate));
    }
    value.isApproveReimb = !value.isApproveReimb ? 'N' : value.isApproveReimb;
    //value.isOfficeDirector = value.isOfficeDirector ? 'Y' : 'N';
    setUser({...value});
    setResetDetails(value);
    history.push("/config/usermanagement/details");
  }
  const changeView = () => {
    //loadUserDetails(0);
    setUser({
      beginDate: null, endDate: null, firstName: null, lastName: null, county: [], vendorNum: null,
      password: null, passwordExp: null, tempPassword: null, username: null, phone: null, email: '', modifySw: false, supervisor: null, id: null, phoneExt: null, role: null, division: (props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? props.userInfo.division.split(',') : [], appId: localStorage.getItem('appId'), officialStation: [], licenseNum: null, licenseExpirationDate: null,
      isApproveReimb: 'N', userType: null, isOfficeDirector: false
    })
    setResetDetails({
      beginDate: null, endDate: null, firstName: null, lastName: null, county: [], vendorNum: null,
      password: null, passwordExp: null, tempPassword: null, username: null, phone: null, email: '', modifySw: false, supervisor: null, id: null, phoneExt: null, role: null, division: (props.userInfo.role === 'DA' || props.userInfo.role === 'DP') ? props.userInfo.division.split(',') : [], appId: localStorage.getItem('appId'), officialStation: [], licenseNum: null, licenseExpirationDate: null,
      isApproveReimb: 'N', userType: null, isOfficeDirector: false
    });
    setEmailError(true);
    history.push("/config/usermanagement/details");
  }

  const changeUserDetails = (key, value) => {
    let newDetails = { ...user };
    if ((key === 'role' && value === 'PR')) {
      newDetails.username = newDetails.email;
    }
    if ((newDetails.role === 'PR') && (key === 'email')) {
      newDetails.username = value;
    }
    if ((key === 'role' && (value === 'SA' || value === 'VA'))) {
      newDetails.division = props.staticData.dropdown.DIVISIONS.value.map((priority) => { return priority.value });
      (value === 'VA') ? newDetails.county = [] : newDetails.county = props.staticData.dropdown.counties.value.filter((priority) => priority.value != "90").map((priority) =>  priority.value);
    }
    if (((newDetails.role === 'SA') && (key === 'role' && value !== 'SA')) || ((newDetails.role === 'VA') && (key === 'role' && value !== 'VA'))) {
      newDetails.division = [];
      newDetails.county = [];
    }
    if((key === 'userType'))
    {
      newDetails.dcfsSw = value === 'FP' ? true : false;
    }

    if((key === 'isOfficeDirector'))
    {
      newDetails.isOfficeDirector = value;
    }

    if((key === 'role'))
    {
      newDetails.isOfficeDirector = false;
    }

    newDetails[key] = value;
    setUser(newDetails);
  }
  const reset = () => {
    setUser(resetDetails)
  }

  function convertDateFromServer(value) {
    if (value !== null) {
      let serverDateArray = value.split("-");
      if (serverDateArray.length > 1) {
        return serverDateArray[1] + "/" + serverDateArray[2] + "/" + serverDateArray[0];
      }
      else {
        return serverDateArray[0];
      }
    }
    else {
      return null;
    }

  }

  const changeDivision = (event) => {
    let value = event.target.value.filter((e) => e !== 0);
    (event.target.value.indexOf("All") > -1) ? ((user.division.length === props.staticData.dropdown.DIVISIONS.value.length) ? changeUserDetails('division', []) :
      changeUserDetails('division', props.staticData.dropdown.DIVISIONS.value.map((priority) => { return priority.value }))) : changeUserDetails('division', value);
    setTimeout(() => {
      setMultiselect(true);
    }, 0)
  };


  const deleteDivisions = (value) => {
    setMultiselect(false);
    let selectedValues = [...user.division];
    selectedValues = selectedValues.filter((selectedValue) => { return selectedValue !== value; });
    let updatedUser = { ...user };
    user.division = selectedValues;
    setUser(updatedUser);
  }

  const changeOfficialStation = (event) => {
    let value = event.target.value.filter((e) => e !== 0);
    (event.target.value.indexOf("All") > -1) ? ((user.officialStation.length === props.staticData.dropdown.OfficialStations.value.length) ? changeUserDetails('officialStation', []) :
      changeUserDetails('officialStation', props.staticData.dropdown.OfficialStations.value.map((priority) => { return priority.value }))) : changeUserDetails('officialStation', value);
    setTimeout(() => {
      setMultiselect(true);
    }, 0)
  };


  const deleteOfficialStation = (value) => {
    setMultiselect(false);
    let selectedValues = [...user.officialStation];
    selectedValues = selectedValues.filter((selectedValue) => { return selectedValue !== value; });
    let updatedUser = { ...user };
    user.officialStation = selectedValues;
    setUser(updatedUser);
  }

  const summaryColumns = [
    {
      name: "Name",
      field: "fullName",
      type: "text"
    },
    {
      name: "Username",
      field: "username",
      type: "text"
    },
    {
      name: "Division",
      field: "divisionDesc",
      type: "text"
    },
    {
      name: "Role",
      field: "roleDesc",
      type: "text"
    },
    {
      name: "Status",
      field: "status",
      type: "text"
    },
    {
      name: "Action",
      field: "action",
      type: "input",
      renderView: renderActionButton
    }
  ]

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path="/config/usermanagement">
          <div>
            <Typography className={classes.header}>User Management</Typography>
            <Grid container className={classes.filters}>
              <Grid item xs={12} className={classes.textfield}>
                <span className={classes.textLabel}>User Search</span>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchFilter === null ? '' : searchFilter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Search by Name, Division, or Username"
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" >
                  <RadioGroup row aria-label="status-sw" name="status-sw" value={filterSw} onChange={(e) => setFilterSw(e.target.value)}>
                    <div>
                      <FormControlLabel value="Active" control={<Radio color="primary" />} label="Active" />
                      <FormControlLabel value="Inactive" control={<Radio color="primary" />} label="Inactive" />
                      <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Box style={{ float: 'right' }} mb={2} mr={2}>
              <Button variant="outlined" color="primary" size="small" onClick={changeView}>Add New</Button>
            </Box>
            <Paper elevation={0}>
              <Box p={"10px"}>
                <Gridtable columns={summaryColumns} data={(filterSw === "All") ? summaryData.filter((data) => (data.filterData.includes(searchFilter.toLowerCase()))) : summaryData.filter((data) => (data.filterData.includes(searchFilter.toLowerCase())) && (data.status === filterSw))}></Gridtable>
              </Box>
            </Paper>
          </div>
        </Route>
        <Route exact path="/config/usermanagement/details">
          <div>
            <Typography className={classes.header}>User Management Details</Typography>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>First Name</span>
                <TextField
                  id="staff_first_name"
                  className="required"
                  error={user.firstName === '' || user.firstName === null}
                  value={user.firstName === null ? '' : user.firstName}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 25
                  }}
                  size="small"
                  fullWidth
                  onChange={(e) => (SpecialCharformat.test(e.target.value)) ? null : changeUserDetails('firstName', e.target.value)}
                  helperText={((user.firstName === '' || user.firstName === null) && error) ? "Required Field" : null}
                />
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>Last Name</span>
                <TextField
                  id="staff_last_name"
                  className="required"
                  error={user.lastName === '' || user.lastName === null}
                  value={user.lastName === null ? '' : user.lastName}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 25
                  }}
                  size="small"
                  fullWidth
                  onChange={(e) => (SpecialCharformat.test(e.target.value)) ? null : changeUserDetails('lastName', e.target.value)}
                  helperText={((user.lastName === '' || user.lastName === null) && error) ? "Required Field" : null}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <ValidatorForm onSubmit={(e) => changeUserDetails("email", e.target.value)}>
                  <span className={classes.textLabel}>Email</span>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    value={user.email === null ? '' : user.email}
                    onChange={(e) => changeUserDetails("email", e.target.value)}
                    id="user_email"
                    name="email"
                    autoComplete="email"
                    size="small"
                    className="required"
                    error={user.email === null || user.email === ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validators={['required', 'isEmail']}
                    errorMessages={['Required field', 'Email is not valid']}
                    helperText={((user.email === null || user.email === '') && error) ? "Required Field" : null}
                  />
                </ValidatorForm>
                {(summaryData.filter((data) => data.username.toString().toLowerCase() === user.email.toLowerCase()).length > 0 && emailError) ? <FormHelperText error={true}>Email is already in use</FormHelperText>
                  : null}
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>Supervisor Name</span>
                <TextField
                  id="supervisor_name"
                  className="required"
                  error={user.supervisor === '' || user.supervisor === null}
                  value={user.supervisor === null ? '' : user.supervisor}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50
                  }}
                  size="small"
                  fullWidth
                  onChange={(e) => (SpecialCharformat.test(e.target.value)) ? null : changeUserDetails('supervisor', e.target.value)}
                  helperText={((user.supervisor === '' || user.supervisor === null) && error) ? "Required Field" : null}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>Contact Phone Number</span>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="required"
                  error={user.phone === '' || user.phone === null}
                  value={user.phone === null ? '' : user.phone}
                  onChange={(e) => changeUserDetails("phone", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  placeholder="(___)-___-____"
                  helperText={((user.phone === '' || user.phone === null) && error) ? "Required Field" : null}
                />
                {((checkFormat(user.phone) !== 10 && checkFormat(user.phone) !== 0) && error) ? <FormHelperText error={true}>Invalid Phone Number/Required Field</FormHelperText> : null}
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>Phone Number Ext</span>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={user.phoneExt === null ? '' : user.phoneExt}
                  onChange={(e) => changeUserDetails("phoneExt", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: PhoneExtFormat,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <FormControl variant="outlined" size="small" fullWidth error={user.role === 0 || user.role === null} >
                  <span className={classes.textLabel}>Select Role</span>
                  <Select
                    id="role"
                    value={user.role === null ? 0 : user.role}
                    className="required"
                    onChange={(e) => changeUserDetails('role', e.target.value)}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {((props.userInfo.role !== 'SA') && (props.userInfo.role !== 'VA')) ? props.staticData.dropdown.ROLES.value.filter((role) => role.value !== 'SA' && role.value !== 'VA').map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    )) : props.staticData.dropdown.ROLES.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((user.role === 0 || user.role === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
              {((user.role !== 'SA') && (user.role !== 'VA')) ? <Grid item xs={12} sm={5} className={classes.textfield}>
                <FormControl variant="outlined" size="small" fullWidth error={user.division.length === 0} disabled={props.userInfo.role === 'DA' || props.userInfo.role === 'DP'}>
                  <span className={classes.textLabel}>Select Division</span>
                  <Select
                    id="division"
                    value={(user.division.length === 0 ? 0 : user.division[0])}
                    className="required"
                    onChange={(e) => changeUserDetails('division', [e.target.value])}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.staticData.dropdown.DIVISIONS.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((user.division.length === 0) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid> : null}

              {((user.role === 'SA') || (user.role === 'VA')) ? <Grid item xs={12} sm={5} className={classes.textfield}>
                <FormControl className={classes.formControl} variant="outlined" size="small" fullWidth error={(user.division.length === 0)} disabled>
                  <span className={classes.textLabel}>Select Division</span>
                  <Select
                    labelId="mutiple-checkbox-div"
                    id="mutiple-checkbox"
                    multiple
                    fullWidth
                    className="required"
                    onClick={() => setMultiselect(!multiSelect)}
                    value={user.division.length < 1 ? [0] : user.division}
                    onChange={changeDivision}
                    open={false}
                    renderValue={(selected) => (
                      user.division.length === 0 ?
                        <div>Pick an Option</div> :
                        (selected.length > 3) ?
                          <div className={classes.chips}>
                            {selected.slice(0, 3).map((value) => (
                              <Chip key={value}
                                label={props.staticData.reference.DIVISIONS.value.find((div) => div.value.toString() === value.toString()).name}
                                className={classes.chip} onDelete={() => deleteDivisions(value)} color="primary" />
                            ))} + {selected.length - 3}
                          </div> :
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={value}
                                label={props.staticData.reference.DIVISIONS.value.find((div) => div.value.toString() === value.toString()).name}
                                className={classes.chip} onDelete={() => deleteDivisions(value)} color="primary" />
                            ))}
                          </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="All" value="All">
                      <Checkbox checked={user.division.length === props.staticData.dropdown.DIVISIONS.value.length} color="primary" />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {props.staticData.dropdown.DIVISIONS.value.map((status) => (
                      <MenuItem key={status.name} value={status.value}>
                        <Checkbox checked={user.division.indexOf(status.value) > -1} color="primary" />
                        <ListItemText primary={status.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {((user.division.length === 0) && error) ? <FormHelperText>Required Field</FormHelperText> : null}
                </FormControl>
              </Grid> : null}

            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
              <FormControl variant="outlined" size="small" fullWidth error={user.userType === 0 || user.userType === null}>
                  <span className={classes.textLabel}>User Type</span>
                  <Select
                    id="userType"
                    value={(user.userType === null ? 0 : user.userType)}
                    className="required"
                    onChange={(e) => changeUserDetails('userType', e.target.value)}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {(user.role === 'PR') ? props.staticData.dropdown.UserType.value.filter((i) => i.value !== 'EM').map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    )) : props.staticData.dropdown.UserType.value.filter((i) => i.value === 'EM').map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((user.userType === 0 || user.userType === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
            </Grid>
            {(user.role === 'SA') ?
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
              <FormControl variant="outlined" size="small" fullWidth error={user.isOfficeDirector === 0 || user.isOfficeDirector === null}>
                 <FormControlLabel
                control={
                <Checkbox
                  checked={user.isOfficeDirector}
                  onChange={(e) => changeUserDetails('isOfficeDirector', e.target.checked)}
                  name="officeDirector"
                  color="primary"
                />
              }
              label="Office Chief/Division Director"
              labelPlacement="end"
            />
                  {((user.isOfficeDirector === 0 || user.isOfficeDirector === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
            </Grid>: null}

            {(localStorage.getItem("appId") === '1') ?
              <div>
                <Grid container className={classes.filters}>
                  <Grid item xs={12} sm={5} className={classes.textfield}>
                    
                  <FormControl variant="outlined" size="small" fullWidth error={user.officialStation?.length === 0 || user.officialStation === null} >
                    <span className={classes.textLabel}>Select Official Station</span>
                      <Select
                    labelId="official-station-div"
                    id="official-station-select"
                    multiple
                    fullWidth
                    className="required"
                    onClick={() => setMultiselect(!multiSelect)}
                    value={user.officialStation && user.officialStation.length < 1 ? [0] : user.officialStation}
                    onChange={changeOfficialStation}
                    renderValue={(selected) => (
                      user.officialStation.length === 0?
                        <div>Pick an Option</div> :
                        (selected.length > 3) ?
                          <div className={classes.chips}>
                            {selected.slice(0, 3).map((value) => (
                              <Chip key={value}
                                label={props.staticData.reference.OfficialStations.value.find((div) => div.value.toString() === value.toString()).name}
                                className={classes.chip} onDelete={() => deleteOfficialStation(value)} color="primary" />
                            ))} + {selected.length - 3}
                          </div> :
                          (selected.length > 1)?
                          <div className={classes.chips}>
                            {selected.map((value) => (
                               value && <Chip key={value}
                                label={props.staticData.reference.OfficialStations.value.find((div) => div.value.toString() === value.toString()).name}
                                className={classes.chip} onDelete={() => deleteOfficialStation(value)} color="primary" />
                            ))}
                          </div>:
                          props.staticData.reference.OfficialStations.value.find((div) => div.value.toString() === selected[0].toString()) ? 
                          <div className={classes.chips}>
                          {selected.map((value) => (
                             value && <Chip key={value}
                              label={props.staticData.reference.OfficialStations.value.find((div) => div.value.toString() === value.toString()).name}
                              className={classes.chip} onDelete={() => deleteOfficialStation(value)} color="primary" />
                          ))}
                          </div>:
                          <div>Pick an Option</div>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="All" value="All">
                      <Checkbox checked={user.officialStation && user.officialStation.length === props.staticData.dropdown.OfficialStations.value.length} color="primary" />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {props.staticData.dropdown.OfficialStations.value.map((status) => (
                      <MenuItem key={status.name} value={status.value}>
                        <Checkbox checked={user.officialStation && user.officialStation.indexOf(status.value) > -1} color="primary" />
                        <ListItemText primary={status.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {((user.officialStation?.length === 0 || user.officialStation === null ) && error) ? <FormHelperText className={'Mui-error'}>Required Field</FormHelperText>
                        : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5} className={classes.textfield}>
                  <RadioGroup aria-label="drive-sw" name="drive-sw"  value={user.isEligibleDrive === 'Y'? 'Y':'N'} onChange={(e)=> changeUserDetails('isEligibleDrive',e.target.value)}>
                     <FormLabel component="legend" className={classes.radioLabel}>Eligible to Drive?</FormLabel>
                    <div>
                      <FormControlLabel value="Y" control={<Radio disabled={false} color="primary" />} label="Yes" />
                      <FormControlLabel value="N" control={<Radio disabled={false} color="primary" />} label="No" />
                    </div>
                  </RadioGroup>
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                  <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Driver's License Number</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      className="required"
                      error={user.licenseNum === '' || user.licenseNum === null}
                      value={user.licenseNum === null ? '' : user.licenseNum}
                      onChange={(e) => changeUserDetails("licenseNum", e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: LicenseFormatCustom,
                      }}
                      placeholder=""
                      helperText={((user.licenseNum === '' || user.licenseNum === null) && error) ? "Required Field" : null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">Driver's License Expiration Date</span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="licen_exp_date"
                        autoOk={true}
                        className="required"
                        value={user.licenseExpirationDate}
                        error={(user.licenseExpirationDate === null) || (!moment(user.licenseExpirationDate, 'MM/DD/YYYY').isValid())}
                        size="small"
                        fullWidth
                        placeholder="MM/DD/YYYY"
                        onChange={(date) => changeUserDetails('licenseExpirationDate', date)}
                        KeyboardButtonProps={{
                          'aria-label': 'License Expiration Date',
                        }}
                        inputVariant="outlined"
                        helperText={(user.licenseExpirationDate === null && error) ? "Required Field" : null}
                      />
                    </MuiPickersUtilsProvider>
                    {(user.licenseExpirationDate !== null && !moment(user.licenseExpirationDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                      : null}
                  </Grid>
                </Grid>
              </div> : null}
            {(user.role === 'PR') ? <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>AASIS Vendor Number</span>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="required"
                  error={user.vendorNum === '' || user.vendorNum === null}
                  value={user.vendorNum === null ? '' : user.vendorNum}
                  onChange={(e) => changeUserDetails("vendorNum", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder=""
                  helperText={((user.vendorNum === '' || user.vendorNum === null) && error) ? "Required Field" : null}
                />
              </Grid>
            </Grid> : null}
            {(user.role === 'TS') ? <Grid container className={classes.filters}>
              <Grid item xs={12} sm={6} className={classes.textfield}>
                <FormControlLabel
                  control={<Checkbox
                    checked={user.modifySw}
                    color="primary"
                    onChange={(e) => changeUserDetails('modifySw', e.target.checked)}
                  />}
                  label="Allowed to modify Cost Center and Internal Order"
                />
              </Grid>
            </Grid> : null}
            {(['PR', 'TS', 'DA'].indexOf(user.role) > -1) ? <Grid container className={classes.filters}>
              <Grid item xs={12} sm={6} className={classes.textfield}>
                <FormControl variant="outlined" size="small" fullWidth error={user.county.length === 0}>
                  <span className={classes.textLabel}>Select County</span>
                  <Select
                    id="county"
                    value={(user.county.length === 0 ? 0 : user.county[0])}
                    className="required"
                    onChange={(e) => changeUserDetails('county', [e.target.value])}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.staticData.dropdown.counties.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((user.county.length === 0) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
            </Grid> : null}
            {((user.role === 'SA')) ? <Grid container className={classes.filters}> <Grid item xs={12} sm={6} className={classes.textfield}>
              <FormControl className={classes.formControl} variant="outlined" size="small" fullWidth error={(user.county.length === 0)} disabled>
                <span className={classes.textLabel}>Select County</span>
                <Select
                  labelId="mutiple-checkbox-div"
                  id="mutiple-checkbox"
                  multiple
                  fullWidth
                  className="required"
                  onClick={() => setMultiselect(!multiSelect)}
                  value={user.county.length < 1 ? [] : user.county}
                  open={false}
                  renderValue={(selected) => (
                    user.division.length === 0 ?
                      <div>Pick an Option</div> :
                      (selected.length > 3) ?
                        <div className={classes.chips}>
                          {selected.slice(0, 3).map((value) => (
                            <Chip key={value}
                              label={props.staticData.reference.counties.value.find((div) => div.value.toString() === value.toString()).name}
                              className={classes.chip} color="primary" />
                          ))} + {selected.length - 3}
                        </div> :
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip key={value}
                              label={props.staticData.reference.counties.value.find((div) => div.value.toString() === value.toString()).name}
                              className={classes.chip} color="primary" />
                          ))}
                        </div>
                  )}
                  MenuProps={MenuProps}
                >
                  <MenuItem key="All" value="All">
                    <Checkbox checked={user.county.length === props.staticData.dropdown.counties.value.length} color="primary" />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {props.staticData.dropdown.counties.value.map((status) => (
                    <MenuItem key={status.name} value={status.value}>
                      <Checkbox checked={user.county.indexOf(status.value) > -1} color="primary" />
                      <ListItemText primary={status.name} />
                    </MenuItem>
                  ))}
                </Select>
                {((user.county.length === 0) && error) ? <FormHelperText>Required Field</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.textfield}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="approve-sw" name="approve-sw"  value={user.isApproveReimb} onChange={(e)=> changeUserDetails('isApproveReimb',e.target.value)}>
                    <FormLabel component="legend" className={classes.radioLabel}>Is this user able to approve reimbursements?</FormLabel>
                    <div>
                      <FormControlLabel value="Y" control={<Radio disabled={false} color="primary" />} label="Yes" />
                      <FormControlLabel value="N" control={<Radio disabled={false} color="primary" />} label="No" />
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>

            </Grid> : null}
            <Subheader>Account Details</Subheader>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>Username</span>
                <TextField
                  id="username"
                  className="required"
                  error={user.username === '' || user.username === null}
                  value={user.username === null ? '' : user.username}
                  disabled={user.role === 'PR'}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  fullWidth
                  onChange={(e) => changeUserDetails('username', e.target.value)}
                  helperText={((user.username === '' || user.username === null) && error) ? "Required Field" : null}
                />
              </Grid>
              <Grid item xs={12} sm={5} className={classes.datepicker}>
                <span className="datepickerLabel">Begin Date</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="staff_begin-date"
                    autoOk={true}
                    className="required"
                    value={user.beginDate}
                    error={(user.beginDate === null) || (!moment(user.beginDate, 'MM/DD/YYYY').isValid())}
                    size="small"
                    fullWidth
                    placeholder="MM/DD/YYYY"
                    onChange={(date) => changeUserDetails('beginDate', date)}
                    KeyboardButtonProps={{
                      'aria-label': 'Effective From date',
                    }}
                    inputVariant="outlined"
                    helperText={(user.beginDate === null && error) ? "Required Field" : null}
                  />
                </MuiPickersUtilsProvider>
                {(user.beginDate !== null && !moment(user.beginDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                  : null}
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.datepicker}>
                <span className="datepickerLabel">End Date</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="staff_end-date"
                    autoOk={true}
                    value={user.endDate}
                    size="small"
                    fullWidth
                    minDate={user.beginDate === null ? new Date() : user.beginDate}
                    minDateMessage="End Date should not be before Begin Date"
                    placeholder="MM/DD/YYYY"
                    onChange={(date) => changeUserDetails('endDate', date)}
                    KeyboardButtonProps={{
                      'aria-label': 'Effective To date',
                    }}
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {(user.role === 'PR') ?
                <Grid item xs={12} sm={5} className={classes.textfield}>
                  <FormControlLabel
                    control={<Checkbox
                      checked={user.dcfsSw}
                      color="primary"
                      disabled={true}
                      style={{ marginTop: "8px" }}
                    />}
                    label="Is DCFS Provider"
                  />
                </Grid>
                : null}
            </Grid>
            <div style={{ textAlign: 'center', paddingBottom: 10 }}>
              <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" onClick={reset}>Reset</Button>
              <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" onClick={saveStaff}>Save</Button>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
}
