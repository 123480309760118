import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Gridtable from '../gridtable';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {postFetch} from '../http';
import { useHistory,useLocation} from "react-router-dom";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  textCenter:{
    textAlign:"center"
  },
  formGroup:{
    flexDirection:"row"
  },
  formControl:{
    width:"81%"
  },
  formControlCheckB:{
    width:"81%",
    height:'auto',
    position:'relative',
    top:'11px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'10px 0',
    padding:'0 10px'
  },
  datePicker:{
    position: 'relative',
    bottom: 6,
    padding:'0 10px 0 0',
    "& .MuiFormControl-fullWidth":{
      width:"81%"
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    marginBottom: 15
  },
  button:{
    marginBottom:10
  },
  card:{
    padding: '15px',
    width:'100%',
    borderBottom:'3px solid #5278ff',
    height:'55vh',
    "& .MuiTypography-body1":{
      fontWeight:"bold"
      },
      [theme.breakpoints.down('sm')]:{
          "& .MuiTypography-body1":{
              fontWeight:"bold",
              fontSize:'2vh'
              }
      }
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500',
    display:"block"
  },
  textLabelB:{
    position: 'relative',
    bottom: '0px',
    fontSize: '14px',
    fontWeight: '500',
    display:"block"
  },
  textLabelC:{
    position: 'relative',
    bottom: '6px',
    fontSize: '14px',
    fontWeight: '500',
    display:"block"
  },
  textfield:{
    padding:'10px 10px 10px 0'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

export default function VMSearch(props) {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(min-width:600px)');
  const [onInit, setOnInit] = React.useState(true);
  const [value, setValue] = React.useState(props.searchCriteria);
  const [filters, setFilters] = React.useState(props.preFilters);
  const [taskStatus, setTaskStatus] = React.useState(props.preTaskStatus);
  const [resStatus, setResStatus] = React.useState(props.preResStatus);
  const [multiSelect,setMultiselect] = React.useState(false);
  const [auditDialog,setAuditDialog] = React.useState(false);
  const [auditData,setAuditData] = React.useState([]);
  const [auditColumns,setAuditColumns] = React.useState([]);  
  const [reservData,setReservData] = React.useState(props.searchData.resData);
  const [taskData,setTaskData] = React.useState(props.searchData.taskData);
  const location = useLocation();
  const [showSearchData, setShowSearchData] = React.useState(true);

  


  const searchChange = (event)=>{
      setValue(event.target.value);
  }


  const navigateFunction = (row)=>{
    return (<div style={{cursor:'pointer',color:'rgb(51, 51, 255)'}} onClick={()=>viewFunction(row)}>{row.id}</div>);
  }

  const taskTypeNavigation = (row)=>{
    return (<div style={{cursor:'pointer',color:'rgb(51, 51, 255)'}} onClick={()=>viewFunction(row)}>{row.taskTypeDes}</div>);
  }

  const viewFunction = (value)=>{
    if(value.taskType !== undefined && ["RA","RC","RD","CL"].indexOf(value.taskType) > -1){
        closeTask(value);
      }
    else if(value.taskType !== undefined && ["MD"].indexOf(value.taskType) > -1){
        localStorage.setItem('maintId',value.reimbId);
        history.push('/config/vehiclemaintenance');
      }
    else if(value.taskType !== undefined && ["RA","RC","RD"].indexOf(value.taskType) === -1){
      localStorage.setItem("reservationId",value.reimbId);
      history.push('/reservation');
    }
    else{
      localStorage.setItem("reservationId",value.id);
      history.push('/reservation');
    }
  }

  const closeTask = async (row)=>{
    const request= {
      id:row.id,
      reimbursementId:row.reimbId,
      taskType:row.taskType
    };
    request.taskStatus = 'CO';
    request.completionDate = moment(new Date()).format("YYYY-MM-DD");
      await postFetch("/tasks/updateStatus",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:false,message:"success"});                      
            },1000);
            localStorage.setItem("reservationId",row.reimbId);
            history.push('/reservation');
        }
        else{
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }

  React.useEffect(()=>{  
    
    return history.listen((location)=>{
      if((location.state !== undefined ) && location.state.from === "search")
      {
        setResStatus([]);
        setTaskStatus([]);
        setReservData([]);
        setTaskData([]);
        setShowSearchData(false);
        let updatedFilters = {...props.preFilters};       
        (['VA','DP'].indexOf(props.userInfo.role) > -1) ? updatedFilters.submittedBy = null : updatedFilters.submittedBy = props.userInfo.id;
        if((['DP'].indexOf(props.userInfo.role) > -1)){
           updatedFilters.division = 0;
        }
        if(updatedFilters.fromDate !== null)
        {
          updatedFilters.fromDate = null;
        }
        if(updatedFilters.toDate !== null)
        {
          updatedFilters.toDate = null;
        }
        setFilters(updatedFilters);
      }
     });
   },[history]); 

 React.useEffect(()=>{
    if(onInit){
      let updatedFilters = {...props.preFilters};
      if((['DR'].indexOf(props.userInfo.role) > -1)){
        updatedFilters.submittedBy = props.userInfo.id;
      }
      setFilters(updatedFilters);
      setTaskStatus(props.preTaskStatus);
      setReservData(props.searchData.resData);
      setOnInit(false);
    }
    if(showSearchData)
    {
      setReservData(props.searchData.resData);
      setTaskData(props.searchData.taskData);
    }else
    {
      setReservData([]);
      setTaskData([]);
    }
    //showSearchData ? setReservData(props.searchData.resData) : setReservData([]);
    
  },[props,onInit]);


  
  const onFiltersChange =(value,type)=>{
    let filterData = {...filters};
    filterData[type] = value;
    setFilters(filterData);
  }
  const taskStatusChange = (event) => {
    event.target.value = event.target.value.filter((e)=> e!== 0);
    (event.target.value.indexOf("All") > -1) ? ((taskStatus.length === props.info.dropdown.taskStatus.value.length) ? setTaskStatus([]) : 
    setTaskStatus(props.info.dropdown.taskStatus.value.map((status)=> {return status.value}))) :  setTaskStatus(event.target.value);
    setTimeout(()=>{
      setMultiselect(true);
    },0);
  };


  const resStatusChange = (event) => {
    event.target.value = event.target.value.filter((e)=> e!== 0);
    (event.target.value.indexOf("All") > -1) ? ((resStatus.length === props.info.dropdown.ReservationStats.value.length) ? setResStatus([]) : 
    setResStatus(props.info.dropdown.ReservationStats.value.map((status)=> {return status.value}))) :  setResStatus(event.target.value);
    setTimeout(()=>{
      setMultiselect(true);
    },0);
  };

  const handleTaskStatusDelete = (value) =>{
    setMultiselect(false);
    let selectedValues = [...taskStatus];
    selectedValues = selectedValues.filter((selectedValue)=> {return selectedValue !== value;} );
    setTaskStatus(selectedValues);
  }

  const handleResStatusDelete = (value) =>{
    setMultiselect(false);
    let selectedValues = [...resStatus];
    selectedValues = selectedValues.filter((selectedValue)=> {return selectedValue !== value;} );
    setResStatus(selectedValues);
  }

  const auditdialog = ()=>{
    return (<div>
      <Dialog
        open={auditDialog}
        onClose={()=>setAuditDialog(false)}
      >
        <DialogContent>
          <Gridtable columns={auditColumns} data={auditData} ></Gridtable>
        </DialogContent>
      </Dialog>
    </div>);
  }

  const showAudit = async (row,type)=>{
    const request= {  
      "requestId":row.id,
      "type":type
    }
      await postFetch("/audit/load",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:false,message:"success" });                      
            },1000);
            setAuditColumns(type==="tasks" ? [...taskAuditColumns] : [...reservsAuditColumns]);
            if(type === "reservations"){
              data.forEach((result)=>{
                result.column3 = props.info.reference.ReservationStats.value.find((status)=>status.value === result.column3).name;
              });
            }
            else {
              data.forEach((result)=>{
                result.column3 = props.info.reference.taskStatus.value.find((status)=>status.value === result.column3).name;
                result.column2 = props.info.reference.taskTypes.value.find((status)=>status.value === result.column2).name;
              });
            }
            setAuditData([...data]);
            setAuditDialog(true);
        }
        else{
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }

   
  
  const renderWarningButton = (row)=>{
    return (new Date(row.dueDate) < new Date()) ? <WarningRoundedIcon style={{fontSize:20}}/> : null;
  }
  const renderActionButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/> <AccessTimeRoundedIcon onClick={()=>showAudit(row,"tasks")} style={{fontSize:20}}/></div>);
  }

  const renderViewButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/> <AccessTimeRoundedIcon onClick={()=>showAudit(row,"reservations")} style={{fontSize:20}}/></div>);
  }

  const taskAuditColumns =[
    {
      name:"Task Id",
      field:"column1",
      type:"text"
    },
    {
      name:"Task Type",
      field:"column2",
      type:"text"
    },
    {
      name:"Task Status",
      field:"column3",
      type:"text"
    },
    {
      name:"Created By",
      field:"createdBy",
      type:"text"
    },
    {
      name:"Created Date",
      field:"createdDate",
      type:"text"
    },
    {
      name:"Modified By",
      field:"modifiedBy",
      type:"text"
    },
    {
      name:"Modified Date",
      field:"modifiedDate",
      type:"text"
    }
  ];

  const reservsAuditColumns =[
    {
      name:"Created By",
      field:"createdBy",
      type:"text"
    },
    {
      name:"Created Date",
      field:"createdDate",
      type:"text"
    },
    {
      name:"Modified By",
      field:"modifiedBy",
      type:"text"
    },
    {
      name:"Modified Date",
      field:"modifiedDate",
      type:"text"
    },
    {
      name:"Reservation Start Date",
      field:"column1",
      type:"date"
    },
    {
      name:"Reservation End Date",
      field:"column2",
      type:"date"
    },
    {
      name:"Reservation Status",
      field:"column3",
      type:"text"
    }
  ];

  const taskColumns=[{
    name:"Task Type",
    field:"taskTypeDes",
    type:"input",
    renderView:taskTypeNavigation
  },
  {
    name:"Reservation Id",
    field:"reimbId",
    type:"text"
  },
  {
    name:"Creation Date",
    field:"createdDate",
    type:"date"
  },
  {
    name:"Due Date",
    field:"dueDate",
    type:"date"
  },
  {
    name:"Completion Date",
    field:"completionDate",
    type:"date"
  },
  {
    name:"Division",
    field:"division",
    type:"text"
  },
  {
    name:"Task Status",
    field:"taskStatusDes",
    type:"text"
  },
  {
    name:"Overdue",
    field:"overdue",
    type:"input",
    renderView: renderWarningButton
  },
  {
    name:"Action",
    field:"action",
    type:"input",
    renderView: renderActionButton
  }];

  const reservationColumns = [{
    name:"Reservation Id",
    field:"id",
    type:"input",
    renderView:navigateFunction
  },
  {
    name:"Submitted By",
    field:"submittedBy",
    type:"text"
  },
  {
    name:"Submitted Date",
    field:"date",
    type:"date"
  },
  {
    name:"VIN",
    field:"vin",
    type:"text"
  },
  {
    name:"Reservation Start Date",
    field:"startDate",
    type:"text"
  },
  {
    name:"Reservation End Date",
    field:"endDate",
    type:"text"
  },
  {
    name:"Division",
    field:"division",
    type:"text"
  },
  {
    name:"Official Station",
    field:"station",
    type:"text"
  },
  {
    name:"Request Status",
    field:"status",
    type:"text"
  },
  {
    name:"Action",
    field:"action",
    type:"input",
    renderView:renderViewButton
  }];


  return (
    <div>
    {auditdialog()}
    {(value === "1") ? <Box pt={8} px={2} style={{zIndex:1800,height:'93%'}} >
      <div className={classes.textCenter}>
        <Typography variant="h6" gutterBottom className={classes.header}>
        {history.location.state.from === 'dashboard' ? 'Search':'Global Search'}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="searchCriteria" name="searchCriteria" value={value} onChange={(e)=>searchChange(e)}  className={matches ? classes.formGroup:null}
          disabled = {history.location.state.from === 'dashboard' ? true:false}>
            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Tasks" disabled = {history.location.state.from === 'dashboard' ? true:false}/>
            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Vehicle Reservations" disabled = {history.location.state.from === 'dashboard' ? true:false}/>
          </RadioGroup>
        </FormControl>
      </div>
      <Grid container spacing={2} className={classes.filters}>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">From Due Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                id="start-date"
                value={filters.fromDate}
                disabled = {history.location.state.from === 'dashboard' ? true:false}
                inputVariant="outlined"
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"fromDate")}
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">To Due Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                inputVariant="outlined"
                id="end-date"
                value={filters.toDate}
                disabled = {history.location.state.from === 'dashboard' ? true:false}
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"toDate")}
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.textfield}>
                    <span className={classes.textLabelB}>Task Status</span>
                        <FormControl variant="outlined" size="small" fullWidth style={{position:"relative",top:"8px",width:"80.9%"}}>
                            <Select
                                id="task-status"
                                multiple
                                value={taskStatus.length < 1 ? [0]: taskStatus}
                                variant="outlined"
                                onChange={taskStatusChange}
                                open={multiSelect}
                                fullWidth
                                onClick={e => setMultiselect(!multiSelect)}
                                disabled = {history.location.state.from === 'dashboard' ? true:false}
                                renderValue={(selected) => (
                                  taskStatus.length === 0 ?
                                <div>Pick an Option</div> :
                                  <div className={classes.chips}>
                                    {selected.map((value) => (
                                      <Chip key={value} 
                                      label={props.info.reference.taskStatus.value.find((status)=>status.value === value).name} 
                                      disabled = {history.location.state.from === 'dashboard' ? true:false}
                                      className={classes.chip} onDelete={()=>handleTaskStatusDelete(value)}  color="primary"/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                 <MenuItem key="All" value="All">
                                    <Checkbox checked={taskStatus.length === props.info.dropdown.taskStatus.value.length}  color="primary"/>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                {props.info.dropdown.taskStatus.value.map((status) => (
                                  <MenuItem key={status.name} value={status.value}>
                                    <Checkbox checked={taskStatus.indexOf(status.value) > -1}  color="primary"/>
                                    <ListItemText primary={status.name} />
                                  </MenuItem>
                                ))}
                                </Select>                                            
                        </FormControl>
          </Grid>
       </Grid>
       <Grid container spacing={2} className={classes.filters}>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                <span className={classes.textLabel}>Task Type</span>
                <Select
                    id="task-select"
                    value={filters.taskType}
                    disabled = {history.location.state.from === 'dashboard' ? true:false}
                    onChange={(event)=>onFiltersChange(event.target.value,"taskType")}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.info.dropdown.taskTypes.value.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((method)=>(
                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>                                               
            </FormControl>                                                                 
        </Grid>
        <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
              <span className={classes.textLabel}>Division</span>
              <Select
                  id="division-select"
                  value={(['DR','VA'].indexOf(props.userInfo.role) > -1) ? filters.division : (props.userInfo.division)}
                  onChange={(event)=>onFiltersChange(event.target.value,"division")}
                  disabled={history.location.state.from === 'dashboard' ? true: (['DR','VA'].indexOf(props.userInfo.role) > -1) ? false: true}
                >
                  <MenuItem value={0}>Pick an Option</MenuItem>
                  {props.info.dropdown.DIVISIONS.value.map((method)=>(
                      <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                  ))}
                </Select>                                               
            </FormControl>                      
          </Grid>
          </Grid>
      {history.location.state.from === 'dashboard' ? null :
      <div className={classes.textCenter}>
        <Button variant="contained" color="primary" className={classes.button} onClick={()=> {props.search(filters,taskStatus,1);setShowSearchData(true);}}>
          Search
        </Button>
      </div>}
        <Box mb={5}></Box>
        <Paper elevation={6}>
          <Gridtable columns={taskColumns} data={taskData} ></Gridtable>
        </Paper>
        <Box mb={5}></Box>
     </Box>: null }

 { (value === "2") ? <Box pt={8} px={2} style={{backgroundColor:'#f6f9fd',zIndex:1800,height:'93%'}} >
      <div className={classes.textCenter}>
        <Typography variant="h6" gutterBottom className={classes.header}>
        {history.location.state.from === 'dashboard' ? 'Search':'Global Search'}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="searchCriteria" name="searchCriteria" value={value} onChange={(e)=>searchChange(e)}  className={matches ? classes.formGroup:null}
          disabled = {history.location.state.from === 'dashboard' ? true:false}>
            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Tasks" disabled = {history.location.state.from === 'dashboard' ? true:false}/>
            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Vehicle Reservations" disabled = {history.location.state.from === 'dashboard' ? true:false}/>
          </RadioGroup>
        </FormControl>
      </div>
      <Grid container spacing={2} className={classes.filters}>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">Reservation Start Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker 
                disabled = {history.location.state.from === 'dashboard' ? true:false}
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                id="start-date"
                value={filters.fromDate}
                inputVariant="outlined"
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"fromDate")}
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">Reservation End Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker 
                //disableToolbar
                disabled = {history.location.state.from === 'dashboard' ? true:false}
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                inputVariant="outlined"
                id="end-date"
                value={filters.toDate}
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"toDate")}
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.textfield}>
                    <span className={classes.textLabelB}>Reservation Status</span>
                        <FormControl variant="outlined" size="small" fullWidth style={{position:"relative",top:"8px",width:"80.9%"}}>
                            <Select
                                id="res-status"
                                multiple
                                value={resStatus.length < 1 ? [0]: resStatus}
                                variant="outlined"
                                onChange={resStatusChange}
                                open={multiSelect}
                                disabled = {history.location.state.from === 'dashboard' ? true:false}
                                fullWidth
                                onClick={history.location.state.from === 'dashboard' ? undefined : e => setMultiselect(!multiSelect)}
                                renderValue={(selected) => (
                                    resStatus.length === 0 ?
                                <div>Pick an Option</div> :
                                  <div className={classes.chips}>
                                    {selected.map((value) => (
                                      <Chip key={value} 
                                      label={props.info.reference.ReservationStats.value.find((status)=>status.value === value).name} 
                                      disabled = {history.location.state.from === 'dashboard' ? true:false}
                                      className={classes.chip} onDelete={()=>handleResStatusDelete(value)}  color="primary"/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                 <MenuItem key="All" value="All">
                                    <Checkbox checked={resStatus.length === props.info.dropdown.ReservationStats.value.length}  color="primary" disabled = {history.location.state.from === 'dashboard' ? true:false}/>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                {props.info.dropdown.ReservationStats.value.map((status) => (
                                  <MenuItem key={status.name} value={status.value}>
                                    <Checkbox checked={resStatus.indexOf(status.value) > -1}  color="primary" disabled = {history.location.state.from === 'dashboard' ? true:false}/>
                                    <ListItemText primary={status.name} />
                                  </MenuItem>
                                ))}
                                </Select>                                            
                        </FormControl>
          </Grid>
       </Grid>
       <Grid container spacing={2} className={classes.filters}>
       <Grid item xs={12} sm={4} className={classes.textfield}>
            <span className={classes.textLabel}>Submitted By</span>
              <Autocomplete
                id="submitted-by"
                options={props.supervisorData.sort((a, b) => a.details > b.details ? 1:-1)}
                getOptionLabel={(option) => option.details}
                value={(filters.submittedBy !== null) ? props.supervisorData.find((sd)=> sd.id === filters.submittedBy) : {details:'',id:null}}
                onChange={(event,value)=> value !== null ? onFiltersChange(value.id,'submittedBy') : onFiltersChange(null,'submittedBy')}
                renderOption={(option)=>
                    <span style={{fontWeight:600}}>{option.details}</span>
                }
                size="small"
                disabled={history.location.state.from === 'dashboard' ? true: (['DR'].indexOf(props.userInfo.role) > -1) ? true: false}
                renderInput={(params) => <TextField id="req-submitted-by"  variant="outlined" size="small" {...params}
                placeholder = "Search Employee"
                className={`${classes.formControl}`}
                value={filters.submittedBy === null ? '' : filters.submittedBy}
                onChange={(event)=> onFiltersChange(event.target.value,"submittedBy")}              
                />}
              /> 
            </Grid>
        <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
              <span className={classes.textLabel}>Division</span>
              <Select
                  id="division-select"
                  value={history.location.state.from === 'dashboard' ? filters.division: (['DR','DP','VA'].indexOf(props.userInfo.role) > -1) ? filters.division : (props.userInfo.division)}
                  onChange={(event)=>onFiltersChange(event.target.value,"division")}
                  disabled={history.location.state.from === 'dashboard' ? true:(['DR','DP','VA'].indexOf(props.userInfo.role) > -1) ? false: true}
                >
                  <MenuItem value={0}>Pick an Option</MenuItem>
                  {props.info.dropdown.DIVISIONS.value.map((method)=>(
                      <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                  ))}
                </Select>                                               
            </FormControl>                      
          </Grid>
          <Grid item xs={12} sm={4} className={classes.textfield}>
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
              <span className={classes.textLabel}>Official Station</span>
              <Select
                  id="off-station"
                  value={filters.station}
                  disabled = {history.location.state.from === 'dashboard' ? true:false}
                  onChange={(event)=>onFiltersChange(event.target.value,"station")}
                >
                  <MenuItem value={0}>Pick an Option</MenuItem>
                  {props.info.dropdown.OfficialStations.value.map((method)=>(
                      <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                  ))}
                </Select>                                               
            </FormControl>  
            </Grid>
          </Grid>
       {history.location.state.from === 'dashboard' ?   null :
      <div className={classes.textCenter}>
        <Button variant="contained" color="primary" className={classes.button} onClick={()=> {props.search(filters,resStatus,3);setShowSearchData(true);}}>
          Search
        </Button>
      </div>}
        <Box mb={5}></Box>
        <Paper elevation={6}>
          <Gridtable columns={reservationColumns} data={reservData} ></Gridtable>
        </Paper>
        <Box mb={5}></Box>
     </Box> : null }
<Box p={8}></Box>
</div>     
     
  );
}

