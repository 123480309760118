import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Typography from '@material-ui/core/Typography';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {postFetch} from '../http';
import {  Switch, Route } from "react-router-dom";
import { useHistory  } from "react-router-dom";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import '../App.css';
import moment from "moment";
import NumberFormat from 'react-number-format';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected":{
        fontWeight:600
    },
    "& .MuiSelect-selectMenu":{
        whiteSpace:'pre-wrap'
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },

  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'20px 0',
    padding:'0 20px'
  },
  textfield:{
      padding:'10px 10px 10px 0'
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  datepicker:{
    position: 'relative',
    bottom: 3,
    padding:'0 10px 0 0'
  },
  datepicker2:{
    position: 'relative',
    bottom: 5,
    padding:'0 10px 0 0'
  },
  radioLabel:{
    position: 'relative',
    top: 13,
    marginRight: 20
  }
}));

function PhoneExtFormat(props){
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        mask="_"
      />
    );
  }

  function AmountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
        prefix="$"
      />
    );
  }

const Subheader = ({children}) =>{
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
  }

export default function VehicleMaintenance(props) {
  const classes = useStyles();
  const history = useHistory();
  const [summaryData, setSummaryData]=React.useState([]);
  const [onInit, setOnInit] = React.useState(true);
  const [filters, setFilters] = React.useState({vin:null,make:null,model:null,year:null,vehicleType:null,status:null,division:null,station:null});
  const [vehicleDetails, setVehicleDetails] = React.useState({id:null,division:null,station:null,delSw:'N',status:null,make:null,model:null,year:null,color:null,licenseState:null,licenseNum:null,licenseExpirationDate:null,vehicleType:null,capacity:null,vin:null,poolSw:'Y',poolDivision:null});
  const [error,setError] = React.useState(false);
  const [resetDetails, setResetDetails] = React.useState(null);
  const [maintenanceData, setMaintenanceData] = React.useState([]);
  const [auditDialog,setAuditDialog] = React.useState(false);
  const [auditData,setAuditData] = React.useState([]);
  



  React.useEffect(() => {
    const loadVehicles =  async ()=>{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setOnInit(false);
        history.push("/config/vehiclemaintenance");
        const request = {...filters}
       await postFetch("/vehicleManagement/load",request).then((data)=>{ 
            if(!data.message){
                data.forEach((vehicle)=>{
                    vehicle.make = props.staticData.reference.makes.value.find((config)=> config.value.toString() === vehicle.make.toString()).name;
                    vehicle.year = props.staticData.reference.VehicleYears.value.find((config)=> config.value.toString() === vehicle.year.toString()).name;
                    vehicle.division = props.staticData.reference.DIVISIONS.value.find((config)=> config.value.toString() === vehicle.division.toString()).name;
                    vehicle.station = props.staticData.reference.OfficialStations.value.find((config)=> config.value.toString() === vehicle.station.toString()).name;
                    vehicle.status = props.staticData.reference.vehicleStatus.value.find((config)=> config.value.toString() === vehicle.status.toString()).name;
                    vehicle.model = props.staticData.reference[vehicle.make].value.find((config)=> config.value.toString() === vehicle.model.toString()).name;
                });
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });
                    setSummaryData(data);
                },1000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
    const loadDetails = async ()=>{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setOnInit(false);
        const request = {
            id:parseInt(localStorage.getItem('maintId'))
        };
        await postFetch("/vehicleManagement/findById",request).then((data)=>{ 
            if(!data.message){
                data.maintenances.forEach((item)=>{
                    item.dueDate = convertDateFromServer(item.dueDate);
                });
                setMaintenanceData(data.maintenances);
                data.licenseExpirationDate = convertDateFromServer(data.licenseExpirationDate);
                setVehicleDetails(data);
                setResetDetails(data);
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });
                    history.push("/config/vehiclemaintenance/details");                              
                },1000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch(()=>{
        setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
        });
    }
    if(onInit && (localStorage.getItem('maintId') === undefined || localStorage.getItem('maintId') === null)){
        loadVehicles();
    }
    if(onInit && (localStorage.getItem('maintId') !== undefined && localStorage.getItem('maintId') !== null)){
        loadDetails();
    }
  },[props,onInit,history,filters]);

  const renderMaintenanceItem = (row,column,index)=>{
    return (<div>
                <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={row.maintenanceItem === 0 || row.maintenanceItem === null}>
                    <Select
                        id={"maintenanceItem"+row.id}
                        className="required"
                        value={row.maintenanceItem === null ? 0 : row.maintenanceItem}
                        onChange={(event)=>changeMaintenanceDetails(column,event.target.value,index)}
                    >
                        <MenuItem value={0}>Pick an Option</MenuItem>
                        {props.staticData.dropdown.maintenanceItems.value.map((method)=>(
                            <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                        ))}
                    </Select>
                    {((row.maintenanceItem === 0 || row.maintenanceItem === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}                                               
                </FormControl> 
    </div>);
  }
  const renderStatus = (row,column,index)=>{
    return (<div>
                <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} error={row.status === 0 || row.status === null}>
                    <Select
                        id={"status"+row.id}
                        className="required"
                        value={row.status === null ? 0 : row.status}
                        onChange={(event)=>changeMaintenanceDetails(column,event.target.value,index)}
                    >
                        <MenuItem value={0}>Pick an Option</MenuItem>
                        {props.staticData.dropdown.maintenanceStatus.value.map((method)=>(
                            <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                        ))}
                    </Select>
                    {((row.status === 0 || row.status === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}                                                
                </FormControl> 
    </div>);
  }
  const renderdueDate = (row,column,index)=>{
    return (<div className={classes.datepicker2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id={"mainDueDate"+row.id}
                            autoOk={true}
                            className="required"
                            value={row.dueDate}
                            error={(row.dueDate === null) || (!moment(row.dueDate, 'MM/DD/YYYY').isValid())}
                            size="small"
                            fullWidth
                            minDate={new Date()}
                            placeholder="MM/DD/YYYY"
                            onChange={(date)=> changeMaintenanceDetails(column,date,index)}
                            invalidDateMessage = {"Invalid Date Format"}
                            KeyboardButtonProps={{
                                'aria-label': 'Maintenance Due Date',
                            }}
                            inputVariant="outlined"
                            helperText={((row.dueDate === null && error)) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(row.dueDate !== null && !moment(row.dueDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null} 
    </div>);
  }
  const renderCost = (row,column,index)=>{
    return (<div className={classes.datepicker2}>
                <TextField
                            id="maint-cost"
                            value={(row.cost === null || row.cost === '') ? '' :parseFloat(row.cost).toFixed(2)}
                            className = "required"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: AmountFormatCustom,
                                maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=> changeMaintenanceDetails(column,e.target.value,index)}
                />
                            {((row.cost === '' || row.cost === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null} 
    </div>);
  }
  const renderActionDelete = (row,column,index)=>{
    return (<div id={row.id+"delSw"} style={{cursor:'pointer'}}><DeleteIcon onClick={()=>changeMaintenanceDetails('delSw','Y',index)} style={{fontSize:20}}/></div>);
  }

  const changeMaintenanceDetails = (key,value,index)=>{
      let newDetails = [...maintenanceData];
      newDetails[index][key] = value;
      newDetails = newDetails.filter((data)=> data.delSw === 'N');
      setMaintenanceData(newDetails);
  }

  
  const renderActionButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/> <AccessTimeRoundedIcon onClick={()=>showAudit(row,"vehicles")} style={{fontSize:20}}/></div>);
  }
  const viewFunction = async (value)=>{
    props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
    const request = {...value};
    await postFetch("/vehicleManagement/findById",request).then((data)=>{ 
        if(!data.message){
            data.maintenances.forEach((item)=>{
                item.dueDate = convertDateFromServer(item.dueDate);
            });
            setMaintenanceData(data.maintenances);
            data.licenseExpirationDate = convertDateFromServer(data.licenseExpirationDate);
            setVehicleDetails(data);
            setResetDetails(data);
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false });
                history.push("/config/vehiclemaintenance/details");                              
            },1000);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch(()=>{
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
    });
  }
  const changeView = ()=>{
    setMaintenanceData([]);
    setVehicleDetails({id:null,division:null,station:null,delSw:'N',status:null,make:null,model:null,year:null,color:null,licenseState:null,licenseNum:null,licenseExpirationDate:null,vehicleType:null,capacity:null,vin:null,poolSw:'Y',poolDivision:null});
    setResetDetails({id:null,division:null,station:null,delSw:'N',status:null,make:null,model:null,year:null,color:null,licenseState:null,licenseNum:null,licenseExpirationDate:null,vehicleType:null,capacity:null,vin:null,poolSw:'Y',poolDivision:null});
    history.push("/config/vehiclemaintenance/details");
  }

  const changeVehicleDetails = (key,value)=>{
        let newDetails = {...vehicleDetails};
        newDetails[key] = value;
        setVehicleDetails(newDetails);
  }

  const changeSearchFilters = (key,value)=>{
      let newDetails = {...filters};
      newDetails[key] = value;
      setFilters(newDetails);
  }

  const reset = ()=>{
    setVehicleDetails(resetDetails);
  }

  function convertDateFromServer(value){
    if(value !== null){
      let serverDateArray = value.split("-");
      if(serverDateArray.length > 1){
        return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
      }
      else{
        return serverDateArray[0];
      }
    }
    else{
      return null;
    }
    
  }


  const saveVehicleManagement = async ()=>{
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
      }
    else{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        const request = {...vehicleDetails};
        request.licenseExpirationDate = moment(request.licenseExpirationDate).format('YYYY-MM-DD');
        request.maintenances = JSON.parse(JSON.stringify(maintenanceData));
        request.maintenances.forEach((item)=>{
            item.dueDate = moment(item.dueDate).format('YYYY-MM-DD');
        });
        await postFetch("/vehicleManagement/save",request).then((data)=>{ 
            if(!data.message){
                data.maintenances.forEach((item)=>{
                    item.dueDate = convertDateFromServer(item.dueDate);
                });
                setMaintenanceData(data.maintenances);
                data.licenseExpirationDate = convertDateFromServer(data.licenseExpirationDate);
                setVehicleDetails(data);
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"success",validationMessage:"Vehicle saved successfully." });
                    history.push("/config/vehiclemaintenance");                             
                },1000);
                setTimeout(()=>{                         
                    setOnInit(true);                              
                },3000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch(()=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
  }

  const addMaintenance = ()=>{
      let newDetails = [...maintenanceData];
      newDetails.push({id:null,vehicleId:vehicleDetails.id,maintenanceItem:null,dueDate:null,status:null,delSw:'N',cost:null});
      setMaintenanceData(newDetails);
  }

  const search = async ()=>{
    props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
    const request = {...filters}
    await postFetch("/vehicleManagement/load",request).then((data)=>{ 
        if(!data.message){
            data.forEach((vehicle)=>{
                vehicle.make = props.staticData.reference.makes.value.find((config)=> config.value.toString() === vehicle.make.toString()).name;
                vehicle.year = props.staticData.reference.VehicleYears.value.find((config)=> config.value.toString() === vehicle.year.toString()).name;
                vehicle.division = props.staticData.reference.DIVISIONS.value.find((config)=> config.value.toString() === vehicle.division.toString()).name;
                vehicle.station = props.staticData.reference.OfficialStations.value.find((config)=> config.value.toString() === vehicle.station.toString()).name;
                vehicle.status = props.staticData.reference.vehicleStatus.value.find((config)=> config.value.toString() === vehicle.status.toString()).name;
                vehicle.model = props.staticData.reference[vehicle.make].value.find((config)=> config.value.toString() === vehicle.model.toString()).name;
            });
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false });
                setSummaryData(data);
            },1000);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch(()=>{
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
    });
  }

  const auditdialog = ()=>{
    return (<div>
      <Dialog
        open={auditDialog}
        onClose={()=>setAuditDialog(false)}
      >
        <DialogContent>
          <Gridtable columns={vehiclesAuditColumns} data={auditData} ></Gridtable>
        </DialogContent>
      </Dialog>
    </div>);
  }

  const showAudit = async (row,type)=>{
    const request= {  
      "requestId":row.id,
      "type":type
    }
      await postFetch("/audit/load",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false,message:"success" });                      
            },1000);
              data.forEach((vehicle)=>{
                vehicle.column1 = props.staticData.reference.vehicleStatus.value.find((config)=> config.value.toString() === vehicle.column1.toString()).name;
                vehicle.column3 = props.staticData.reference.DIVISIONS.value.find((config)=> config.value.toString() === vehicle.column3.toString()).name;
                vehicle.column2 = props.staticData.reference.OfficialStations.value.find((config)=> config.value.toString() === vehicle.column2.toString()).name;
              });
            setAuditData([...data]);
            setAuditDialog(true);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }


  const vehiclesAuditColumns =[
    {
      name:"Created By",
      field:"createdBy",
      type:"text"
    },
    {
      name:"Created Date",
      field:"createdDate",
      type:"text"
    },
    {
      name:"Modified By",
      field:"modifiedBy",
      type:"text"
    },
    {
      name:"Modified Date",
      field:"modifiedDate",
      type:"text"
    },
    {
      name:"Status",
      field:"column1",
      type:"date"
    },
    {
      name:"Division",
      field:"column3",
      type:"date"
    },
    {
      name:"Official Station",
      field:"column2",
      type:"text"
    }
  ];

  const summaryColumns = [
    {
        name:"VIN",
        field:"vin",
        type:"text"
    },
    {
        name:"Vehicle Make",
        field:"make",
        type:"text"
    },
    {
        name:"Vehicle Model",
        field:"model",
        type:"text"
    },
    {
        name:"Vehicle Year",
        field:"year",
        type:"text"
    },
    {
        name:"Maintenance Due Date",
        field:"maintenanceDueDate",
        type:"date"
    },
    {
        name:"Division",
        field:"division",
        type:"text"
    },
    {
        name:"Official Station",
        field:"station",
        type:"text"
    },
    {
        name:"Vehicle Status",
        field:"status",
        type:"text"
    },
    {
        name:"Action",
        field:"action",
        type:"input",
        renderView: renderActionButton
    }
  ]

  const maintenanceColumns = [
    {
        name:"Maintenance Item",
        field:"maintenanceItem",
        type:"input",
        renderView:renderMaintenanceItem
    },
    {
        name:"Due Date",
        field:"dueDate",
        type:"input",
        renderView:renderdueDate
    },
    {
        name:"Maintenance Cost",
        field:"cost",
        type:"input",
        renderView:renderCost
    },
    {
        name:"Status",
        field:"status",
        type:"input",
        renderView:renderStatus
    },
    {
        name:"Action",
        field:"action",
        type:"input",
        renderView:renderActionDelete
    }
  ]

  return (
    <div className={classes.root}>
        {auditdialog()}
     <Switch>
            <Route exact path="/config/vehiclemaintenance">
                <div>
                    <Typography className={classes.header}>Vehicle Management</Typography>
                    <Grid container spacing={2} className={classes.filters}>
                        <Grid item xs={12} sm={4} className={classes.textfield}>
                            <span className={classes.textLabel}>VIN</span>
                            <TextField
                                id="unitnum"
                                value={filters.vin === null ? '' : filters.vin}
                                variant="outlined"
                                placeholder = "Search VIN"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                maxLength: 100
                                }}
                                size="small"
                                fullWidth
                                onChange={(e)=>changeSearchFilters('vin',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Vehicle Make</span>
                                <Select
                                    id="veh-make"
                                    value={filters.make === null ? 0 : filters.make}
                                    onChange={(event)=>changeSearchFilters('make',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.makes.value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    ))}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Vehicle Model</span>
                                <Select
                                    id="veh-model"
                                    value={filters.model === null ? 0 : filters.model}
                                    onChange={(event)=>changeSearchFilters('model',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {filters.make !== null && filters.make !== 0 ? props.staticData.dropdown[props.staticData.reference.makes.value.find((config)=> config.value === filters.make).name].value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    )) : null}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.filters}>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Vehicle Year</span>
                                <Select
                                    id="veh-year"
                                    value={filters.year === null ? 0 : filters.year}
                                    onChange={(event)=>changeSearchFilters('year',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.VehicleYears.value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    ))}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Vehicle Type</span>
                                <Select
                                    id="veh-type"
                                    value={filters.vehicleType === null ? 0 : filters.vehicleType}
                                    onChange={(event)=>changeSearchFilters('vehicleType',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.vehicleTypes.value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    ))}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Vehicle Status</span>
                                <Select
                                    id="veh-status"
                                    value={filters.status === null ? 0 : filters.status}
                                    onChange={(event)=>changeSearchFilters('status',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.vehicleStatus.value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    ))}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.filters}>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Division</span>
                                <Select
                                    id="div"
                                    value={filters.division === null ? 0 : filters.division}
                                    onChange={(event)=>changeSearchFilters('division',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.DIVISIONS.value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    ))}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <span className={classes.textLabel}>Official Station</span>
                                <Select
                                    id="station"
                                    value={filters.station === null ? 0 : filters.station}
                                    onChange={(event)=>changeSearchFilters('station',event.target.value)}
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.OfficialStations.value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    ))}
                                </Select>                                               
                            </FormControl>                                                                 
                        </Grid>
                    </Grid>
                    <div style={{textAlign:'center',paddingBottom:10}}>
                        <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={search}>Search</Button>
                    </div>
                    <Box style={{float: 'right'}} m={2}>
                        <Button  variant="outlined" color="primary" size="small" onClick={changeView}>Add New Vehicle</Button>
                    </Box>
                        <Paper elevation={0}>
                            <Box p={"10px"}>
                                <Gridtable columns={summaryColumns} data={summaryData}></Gridtable>
                            </Box> 
                            
                        </Paper>
                </div>
            </Route>
            <Route exact path="/config/vehiclemaintenance/details">
                <div>
                <Typography className={classes.header}>Vehicle Management Details</Typography>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>VIN</span>
                        <TextField
                            id="vin"
                            className="required"
                            error ={vehicleDetails.vin === '' || vehicleDetails.vin === null}
                            value={vehicleDetails.vin === null ? '' : vehicleDetails.vin}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                              maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=>changeVehicleDetails('vin',e.target.value)}
                            helperText={((vehicleDetails.vin === '' || vehicleDetails.vin === null) && error) ? "Required Field" : null}
                        />
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Division</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.division === 0 || vehicleDetails.division === null} >
                            <Select
                                id="veh-division"
                                value={vehicleDetails.division === null ? 0 : vehicleDetails.division}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('division',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.DIVISIONS.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((vehicleDetails.division === 0 || vehicleDetails.division === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <span className={classes.textLabel}>Official Station</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.station === 0 || vehicleDetails.station === null} >
                            <Select
                                id="veh-station"
                                value={vehicleDetails.station === null ? 0 : vehicleDetails.station}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('station',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.OfficialStations.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((vehicleDetails.station === 0 || vehicleDetails.station === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Vehicle Status</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.status === 0 || vehicleDetails.status === null} >
                            <Select
                                id="veh-status"
                                value={vehicleDetails.status === null ? 0 : vehicleDetails.status}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('status',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.vehicleStatus.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((vehicleDetails.status === 0 || vehicleDetails.status === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                </Grid>
                <Subheader>Vehicle Information</Subheader>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <span className={classes.textLabel}>Vehicle Make</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.make === 0 || vehicleDetails.make === null} >
                            <Select
                                id="veh-make"
                                value={vehicleDetails.make === null ? 0 : vehicleDetails.make}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('make',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.makes.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((vehicleDetails.make === 0 || vehicleDetails.make === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Vehicle Model</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.model === 0 || vehicleDetails.model === null} >
                            <Select
                                id="veh-model"
                                value={vehicleDetails.model === null ? 0 : vehicleDetails.model}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('model',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {vehicleDetails.make !== null && vehicleDetails.make !== 0 ? props.staticData.dropdown[props.staticData.reference.makes.value.find((config)=> config.value === vehicleDetails.make).name].value.map((method)=>(
                                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                    )) : null}
                                </Select> 
                            {((vehicleDetails.model === 0 || vehicleDetails.model === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <span className={classes.textLabel}>Vehicle Year</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.year === 0 || vehicleDetails.year === null} >
                            <Select
                                id="veh-year"
                                value={vehicleDetails.year === null ? 0 : vehicleDetails.year}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('year',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.VehicleYears.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((vehicleDetails.year === 0 || vehicleDetails.year === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Vehicle Color</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.color === 0 || vehicleDetails.color === null} >
                            <Select
                                id="veh-color"
                                value={vehicleDetails.color === null ? 0 : vehicleDetails.color}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('color',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.vehicleColors.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((vehicleDetails.color === 0 || vehicleDetails.color === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>License Plate State</span>
                        <TextField
                            id="licState"
                            className="required"
                            error ={vehicleDetails.licenseState === '' || vehicleDetails.licenseState === null}
                            value={vehicleDetails.licenseState === null ? '' : vehicleDetails.licenseState}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                              maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=>changeVehicleDetails('licenseState',e.target.value)}
                            helperText={((vehicleDetails.licenseState === '' || vehicleDetails.licenseState === null) && error) ? "Required Field" : null}
                        />
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>License Plate Number</span>
                        <TextField
                            id="licNum"
                            className="required"
                            error ={vehicleDetails.licenseNum === '' || vehicleDetails.licenseNum === null}
                            value={vehicleDetails.licenseNum === null ? '' : vehicleDetails.licenseNum}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                              maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=>changeVehicleDetails('licenseNum',e.target.value)}
                            helperText={((vehicleDetails.licenseNum === '' || vehicleDetails.licenseNum === null) && error) ? "Required Field" : null}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                    <span className="datepickerLabel">License Expiration Date</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker 
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="licExp-date"
                            autoOk={true}
                            className="required"
                            value={vehicleDetails.licenseExpirationDate}
                            error={(vehicleDetails.licenseExpirationDate === null) || (!moment(vehicleDetails.licenseExpirationDate, 'MM/DD/YYYY').isValid())}
                            size="small"
                            fullWidth
                            minDate={new Date()}
                            placeholder="MM/DD/YYYY"
                            onChange={(date)=> changeVehicleDetails('licenseExpirationDate',date)}
                            invalidDateMessage = {"Invalid Date Format"}
                            KeyboardButtonProps={{
                                'aria-label': 'License Expiration date',
                            }}
                            inputVariant="outlined"
                            helperText={((vehicleDetails.licenseExpirationDate === null && error)) ? "Required Field" : null}
                            />
                            </MuiPickersUtilsProvider>
                            {(vehicleDetails.licenseExpirationDate !== null && !moment(vehicleDetails.licenseExpirationDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null} 
                    </Grid>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Vehicle Type</span>
                        <FormControl variant="outlined" size="small" fullWidth  error={vehicleDetails.vehicleType === 0 || vehicleDetails.vehicleType === null} >
                            <Select
                                id="veh-vehicleType"
                                value={vehicleDetails.vehicleType === null ? 0 : vehicleDetails.vehicleType}
                                className="required"
                                onChange={(e)=> changeVehicleDetails('vehicleType',e.target.value)}
                                >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.vehicleTypes.value.sort((a, b) => a.name > b.name ? 1:-1).map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                                </Select> 
                            {((vehicleDetails.vehicleType === 0 || vehicleDetails.vehicleType === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                             
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <span className={classes.textLabel}>Capacity</span>
                        <TextField
                            id="capacity"
                            className="required"
                            error ={vehicleDetails.capacity === '' || vehicleDetails.capacity === null}
                            value={vehicleDetails.capacity === null ? '' : vehicleDetails.capacity}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: PhoneExtFormat,
                                maxLength: 25
                            }}
                            size="small"
                            fullWidth
                            onChange={(e)=>changeVehicleDetails('capacity',e.target.value)}
                            helperText={((vehicleDetails.capacity === '' || vehicleDetails.capacity === null) && error) ? "Required Field" : null}
                        />
                    </Grid>
                </Grid>
                <Subheader>Availability</Subheader>
                <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <FormControl component="fieldset" >
                            <RadioGroup row aria-label="pool-sw" name="pool-sw"  value={vehicleDetails.poolSw} onChange={(e)=> changeVehicleDetails('poolSw',e.target.value)}>
                                <FormLabel component="legend" className={classes.radioLabel}>Is this a pool vehicle ?</FormLabel>
                                <div>
                                    <FormControlLabel value="Y" control={<Radio   color="primary" />} label="Yes" />
                                    <FormControlLabel value="N" control={<Radio   color="primary" />} label="No" />
                                 </div>
                            </RadioGroup>
                        </FormControl>
                   </Grid>
                </Grid>
                <div>
                    <Subheader>Maintenance</Subheader>
                    <Box style={{float: 'right'}} m={2}>
                        <Button  variant="outlined" color="primary" size="small" onClick={addMaintenance}>Add New</Button>
                    </Box>
                    <Paper elevation={0}>
                    <Box p={"10px"}>
                        <Gridtable columns={maintenanceColumns} data={maintenanceData}></Gridtable>
                    </Box>            
                    </Paper>
                    <Box m={6}>
                    </Box>
                </div>
                <div style={{textAlign:'center',paddingBottom:10}}>
                    <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={reset}>Reset</Button>
                    <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" onClick={saveVehicleManagement}>Save</Button>
                </div>
                <Box m={12}>
                </Box>
                </div>
            </Route>
        </Switch>
    </div>
  );
}
