import React, {useState} from 'react';
import './App.css';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DenseAppBar from './header';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Loader from './loader';
import footer from './imgs/footer.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input:{
    width:'95%',
    textAlign:'center'
  },
  logo:{
    textAlign:'center'
  },
  forgotPass:{
    textDecoration: 'underline',
    marginLeft: '20px',
    cursor: 'pointer'
  },
  card:{
    height:'100%',
    padding:"10px 0"
  },
  list:{
    fontSize:'11px'
  },
  listItem:{
    padding:'5px'
  },
  icon:{
    fontSize: '20px',
    position: 'relative',
    top: '3px',
    marginRight: '5px'
  },
  footer:{
    minHeight: '80px',
    zIndex: 1350,
    bottom: 0,
    top: 'auto',
    textAlign:'center'
  },
  textLabel:{
    fontSize: '16px',
    fontWeight: '500'
  },
  texttitle: {
    fontWeight: "bold",
    color: "black",
  },
  faqtitle: {
    fontWeight: "bold",
    color: "black",
    textTransform: "none",
    textDecoration: "2px underline #c0effa"
  }, 
  linkTitle: {
    fontWeight: "normal",
    color: "black",
    textTransform: "none",
    textDecoration: "2px underline #c0effa"
  },
  smalllistItem:{
    width:'250px'
  },
}));


function Faqpg() {
  const classes = useStyles();
  const [loader, setLoader] = useState({loader:false,notification:false,message:"success",validationMessage:"Success"});
  
  const loaderAction = async()=>{
      setLoader({...loader,notification:false})
  }

  

const data = {
    rows: [
        {
            title: "1. I’m leaving from my residence to my go to a worksite can I use my personal vehicle? ",
            content: "To be eligible to receive reimbursement for mileage on your personal vehicle whether leaving from your residence or official workstation, you must provide verification that an agency or fleet vehicle was not available. That verification can be obtained by, first, searching for a vehicle in this application and saving your search history. This information will be automatically applied to your Travel Reimbursement as your verification that a suitable vehicle was not available, and you were required to take your personal vehicle.",
            position:300
        },
        {
            title: "2. Do I now have to provide receipt for my meal reimbursement?",
            content: "Receipts are now required for all reimbursements claims: meals, lodging, and commercial transportation and incidentals.  Note: Meals and lodging cannot exceed the federal per diem rate which depends on the destination location. ",
            position:600
          },
        {
            title: "3. Can I still claim, “vicinity miles”?",
            content: " No, you must enter an exact beginning and ending address for your trip. If an exact address cannot be found, please enter the next closest address available.",
            position:900
          },
        {
            title: "4. I’m a travel supervisor, but “too busy” to review all this travel, someone else will catch a mistake, right? ",
            content: " Travel Supervisors are required to examine and approve all travel expenses contained in a submitted Travel Reimbursement request.  In approving the reimbursement request submitted by their staff, they are assuring that they have reviewed the submitted documents and to the best of their knowledge, the submitted travel is correct and the expense was in the best interest of the state. ",
            position:1200
          },
        {
            title: "5. How long do I have to turn in my Travel reimbursement request?  ",
            content: " Travelers must submit their reimbursement request for approval no more than 30 days after the initial date of travel.  If the date exceeds 30 days, the travel must be submitted to the traveler’s director or their designee for review and approval.  Travel Supervisor have 14 days to approve or reject the submitted travel request.  ",
            position:1500
          },
          {
            title: "6. TRAINING VIDEOS ",
            content: " The following link will direct you to the TRIPS Training Videos, which includes overviews of how to submit a reimbursement, check the status of a reimbursement request, and reserve a DHS vehicle.",
           
            subcontent1 :"Link to TRIPS Training Videos",
            link1 : "https://flowcode.com/p/jYZ3cdMGg?fc=0",
            position:1800
          }
    ]
};



const scrollAction= (value)=>{
  window.scrollTo(0, value)
}


  return (

    
    <Box   style={{backgroundColor:"#fff"}}>
    <Loader loader={loader.loader} notification = {loader.notification} message={loader.message} action={loaderAction} validationMessage = {loader.validationMessage}/>
    <div>
      <DenseAppBar></DenseAppBar>
      <Grid container direction="row" spacing={2} className={classes.root}>
            <Grid item xs={12} sm={6} style={{margin:0}}>
                <span  className="dashboardWelcome">
                Frequently Asked Questions
                </span>
                <div>
                  
        </div>
            </Grid>
      </Grid>

      <Grid container direction="row"  spacing={0} style={{margin:"40px 0",minHeight:"calc(100vh - 246px)"}}>
        <Grid item xs={12} md={12}>
        <List>
              <ListItem key={1} className={classes.listItem}>
                
              <Typography variant="button" >
              {data.rows.map((rows) => {
        return (
        <ListItem button key={rows.title} onClick={()=> scrollAction(rows.position)}>
          <ListItemText key={rows.title} primary={rows.title} classes={{ primary: classes.faqtitle }} />
        </ListItem> 
             )
                })}  
              </Typography>
              </ListItem>
              </List>    

        <Box style={{backgroundColor:"#fff"}}>
            {data.rows.map((rows) => {
                return (
                  <Box key={rows.title}>
                    
                    <ListItem button>
                      <ListItemText key={rows.title} primary={rows.title} classes={{ primary: classes.texttitle }}/> 
                    </ListItem>
                    <ListItem button>
                      <ListItemText key={rows.content} primary={rows.content} />
                   </ListItem>
                  
                   {rows.subcontent1 !== null && rows.title === '6. TRAINING VIDEOS ' ? <ListItem className={classes.smalllistItem} button key={rows.subcontent1}  onClick={()=>window.open(rows.link1)}>
                      <ListItemText key={rows.subcontent1} primary={rows.subcontent1} classes={{ primary: classes.linkTitle }} />
                  </ListItem>
                   : null}
                   </Box>                   
             )
                })}
        </Box>  
        </Grid>

      </Grid>
      <AppBar position="relative"  className={classes.footer}>
          <Toolbar style={{padding:0}}>
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <img className="footer" src={footer} height='60px' alt="footer"/>
                </Grid>
                <Grid item xs={12} sm={10}>
                <Grid container direction="row" style={{paddingTop:20}}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="button" className="decoratedfooterText" onClick={()=>window.open(window.location.origin+"/policy.pdf")} tabIndex="0">
                        Travel Policy Guidelines
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#privacy")} tabIndex="0">
                        Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#security")} tabIndex="0">
                        Security Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use")} tabIndex="0">
                        Acceptable Use
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://transparency.arkansas.gov/")} tabIndex="0">
                        Transparency
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:10}}>
                    <Typography variant="button" className="footerText" tabIndex="0">
                      Ⓒ{new Date().getFullYear()} State of Arkansas.All Rights Reserved.
                    </Typography>
                  </Grid>
                  
              </Grid>
              </Grid>
              </Grid>
          </Toolbar>
      </AppBar>
    </div>
    </Box>
  );
  
}

export default Faqpg;
