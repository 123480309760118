import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Gridtable from './gridtable';
import {postFetch} from './http';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useHistory} from "react-router-dom";
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme) => ({
  textCenter:{
    textAlign:"center"
  },
  formGroup:{
    flexDirection:"row"
  },
  formControl:{
    width:"81%"
  },
  formControlCheckB:{
    width:"81%",
    height:'auto',
    position:'relative',
    top:'11px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'10px 0',
    padding:'0 10px'
  },
  datePicker:{
    position: 'relative',
    bottom: 6,
    padding:'0 10px 0 0',
    "& .MuiFormControl-fullWidth":{
      width:"81%"
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    marginBottom: 15
  },
  button:{
    marginBottom:10
  },
  card:{
    padding: '15px',
    width:'100%',
    borderBottom:'3px solid #5278ff',
    height:'55vh',
    "& .MuiTypography-body1":{
      fontWeight:"bold"
      },
      [theme.breakpoints.down('sm')]:{
          "& .MuiTypography-body1":{
              fontWeight:"bold",
              fontSize:'2vh'
              }
      }
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500',
    display:"block"
  },
  textLabelB:{
    position: 'relative',
    bottom: '0px',
    fontSize: '14px',
    fontWeight: '500',
    display:"block"
  },
  textLabelC:{
    position: 'relative',
    bottom: '6px',
    fontSize: '14px',
    fontWeight: '500',
    display:"block"
  },
  textfield:{
    padding:'10px 10px 10px 0'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};


export default function Search(props) {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(min-width:600px)');
  const [onInit, setOnInit] = React.useState(true);
  const [value, setValue] = React.useState(props.searchCriteria);
  const [filters, setFilters] = React.useState(props.preFilters);
  const [taskStatus, setTaskStatus] = React.useState(props.preTaskStatus);
  const [reimbStatus, setReimbStatus] = React.useState(props.preReimbStatus);
  const [multiSelect,setMultiselect] = React.useState(false);
  const [reassignFilters, setReassignFilters] = React.useState({ startDate: null, endDate: null, division: 0, fromSupervisor: null, toSupervisor: null, reassignEndDate: null, requestId: null });
  const [reAssignData,setReAssignData] = React.useState([]);
  const [error,setError] = React.useState(false);
  const [auditDialog,setAuditDialog] = React.useState(false);
  const [auditData,setAuditData] = React.useState([]);
  const [auditColumns,setAuditColumns] = React.useState([]);
  const [updatedSupervisorData,setUpdatedSupervisorData] = React.useState([]);

  

  


  const searchChange = (event)=>{
      setValue(event.target.value);
      if(event.target.value === "3"){
        searchReassignment();
      }
  }
  const searchReassignment = async ()=>{
    const request= {  
      "travelStartDate":(reassignFilters.startDate !== null) ? moment.utc(reassignFilters.startDate).local().format("YYYY-MM-DD") : null,
      "travelEndDate":(reassignFilters.endDate !== null) ? moment.utc(reassignFilters.endDate).local().format("YYYY-MM-DD") : null,
      "status":["SB"].toString(),
      "division":null,
      "assignedTo":(["TS"].indexOf(props.userInfo.role) > -1) ? props.userInfo.id : reassignFilters.fromSupervisor,
      "costCenter":null,
      "internalOrder":null,
      "userId":null,
      "requestId": reassignFilters.requestId
    };
    (['SA'].indexOf(props.userInfo.role) > -1) ? request.division = null : request.division = props.userInfo.division;
    if(reassignFilters.division !== 0){
      request.division = reassignFilters.division;
    }
      await postFetch("/dashboard/searchRemb",request).then((data)=>{
            if(!data.message){
              data.forEach((result)=>{
                result.status = props.info.reference.ReimbursementStatus.value.find((status)=>status.value === result.status).name;
              });
              setReAssignData([...data]);
              let newFilters = {...reassignFilters};
              (["TS"].indexOf(props.userInfo.role) > -1) ? (newFilters.fromSupervisor = props.userInfo.id) : (newFilters.fromSupervisor = reassignFilters.fromSupervisor);
              (props.userInfo.reassignEndDate === null) ? newFilters.reassignEndDate = null : newFilters.reassignEndDate = convertDateFromServer(props.userInfo.reassignEndDate);
              setReassignFilters(newFilters);
            }
            else{
                setTimeout(()=>{                         
                  props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
                },1000);
            }
            }).catch((e)=>{
              setTimeout(()=>{                          
                props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
              },1000);
            });
  }

  function convertDateFromServer(value){
    if((value !== null) && !(value instanceof Date)){
      let serverDateArray = value.split("-");
      if(serverDateArray.length > 1){
        return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
      }
      else{
        return serverDateArray[0];
      }
    }
    else if (value instanceof Date){
        return value;
    }
    else{
      return null;
    }
    
  }

  const navigateFunction = (row)=>{
    return (<div style={{cursor:'pointer',color:'rgb(51, 51, 255)'}} onClick={()=>viewFunction(row)}>{row.id}</div>);
  }

  const taskTypeNavigation = (row)=>{
    return (<div style={{cursor:'pointer',color:'rgb(51, 51, 255)'}} onClick={()=>viewFunction(row)}>{row.taskTypeDes}</div>);
  }

  const viewFunction = (value)=>{
    localStorage.setItem("requestId",null);
    localStorage.setItem("preApproval",null);
    if(value.taskType !== undefined && ["PA","PD","TA","TD"].indexOf(value.taskType) > -1){
      closeTask(value);
    }
    else if(value.taskType !== undefined && ["PR"].indexOf(value.taskType) > -1){
      localStorage.setItem("preApproval",value.reimbId);
      history.push('/preApproval');
    }
    else if(value.taskType !== undefined && ["PR"].indexOf(value.taskType) === -1){
      localStorage.setItem("requestId",value.reimbId);
      history.push('/tr1');
    }
    else{
      if(filters.searchType === 'RM'){
        localStorage.setItem("requestId",value.id);
        history.push('/tr1'); 
      }
      else{
        localStorage.setItem("preApproval",value.id);
        history.push('/preApproval'); 
      }
    }
  }

  const closeTask = async (row)=>{
    const request= {
      id:row.id,
      reimbursementId:row.reimbId,
      taskType:row.taskType
    };
    request.taskStatus = 'CO';
    request.completionDate = moment(new Date()).format("YYYY-MM-DD");
      await postFetch("/tasks/updateStatus",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:false,message:"success"});                      
            },1000);
            if(["PA","PD"].indexOf(row.taskType) > -1){
              localStorage.setItem("preApproval",row.reimbId);
              history.push('/preApproval');
            }
            else{
              localStorage.setItem("requestId",row.reimbId);
              history.push('/tr1');
            }
        }
        else{
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }

  //React.useEffect(()=>{
    //localStorage.setItem('fil',JSON.stringify(filters));

  //},[filters]);

  React.useEffect(()=>{
    if(props.userInfo.id === undefined){
    const formData = localStorage.getItem('fil');
    setFilters(JSON.parse(formData));
    }
  },[]);
   

  React.useEffect(()=>{
   
    if(onInit && props.userInfo.id !== undefined){          
      let updatedFilters = {...props.preFilters};
      ((['TS','SA'].indexOf(props.userInfo.role) > -1) && updatedFilters.assignedTo !== null) ? (updatedFilters.assignedTo = props.userInfo.id) : (updatedFilters.assignedTo = null);
      if((['TS','PR','EM'].indexOf(props.userInfo.role) > -1) && updatedFilters.assignedTo === null && updatedFilters.userId === undefined)
      {
        updatedFilters.assignedTo = props.userInfo.id;        
        updatedFilters.division = props.userInfo.division;
      }
      if((['DA'].indexOf(props.userInfo.role) > -1) && updatedFilters.assignedTo === null && updatedFilters.userId === undefined)
      {
        updatedFilters.assignedTo = null;        
        //updatedFilters.division = props.userInfo.division;
      }
      const payApprover = props.info.dropdown.PaymentApprover.value;
      const medApprover = props.info.dropdown.MedicaidApprovers.value;
      var paymentArr = [];
      for ( var a = 0; a < payApprover.length; a++){
        var payObject = {};
        payObject['details'] = payApprover[a].name;
        payObject['id'] = payApprover[a].value;
        payObject['isApproveReimb'] =  'Y';
        paymentArr.push(payObject)
      }

      var medArr = [];
      for ( var a = 0; a < medApprover.length; a++){
        var medObject = {};
        medObject['details'] = medApprover[a].fullName;
        medObject['id'] = medApprover[a].value;
        medObject['isApproveReimb'] =  'Y';
        medArr.push(medObject)
      }
      const newSupervisorDataData = [...props.supervisorData, ...paymentArr, ...medArr]
      setUpdatedSupervisorData(newSupervisorDataData);
      localStorage.setItem('fil',JSON.stringify(updatedFilters));
      setFilters(updatedFilters);
      setTaskStatus(props.preTaskStatus);
      setOnInit(false);
    }

       
  },[props,onInit]);


  const onFiltersChange =(value,type)=>{
    let filterData = {...filters};
    filterData[type] = value;
    if(type === "searchType")
    {
      props.searchData.reimbData = [];
    }
    setFilters(filterData);
  }
  const taskStatusChange = (event) => {
    event.target.value = event.target.value.filter((e)=> e!== 0);
    (event.target.value.indexOf("All") > -1) ? ((taskStatus.length === props.info.dropdown.taskStatus.value.length) ? setTaskStatus([]) : 
    setTaskStatus(props.info.dropdown.taskStatus.value.map((status)=> {return status.value}))) :  setTaskStatus(event.target.value);
    setTimeout(()=>{
      setMultiselect(true);
    },0);
  };


  const reimbStatusChange = (event) => {
    event.target.value = event.target.value.filter((e)=> e!== 0);
    (event.target.value.indexOf("All") > -1) ? ((reimbStatus.length === props.info.dropdown.ReimbursementStatus.value.length) ? setReimbStatus([]) : 
    setReimbStatus(props.info.dropdown.ReimbursementStatus.value.map((status)=> {return status.value}))) :  setReimbStatus(event.target.value);
    setTimeout(()=>{
      setMultiselect(true);
    },0);
  };

  const handleTaskStatusDelete = (value) =>{
    setMultiselect(false);
    let selectedValues = [...taskStatus];
    selectedValues = selectedValues.filter((selectedValue)=> {return selectedValue !== value;} );
    setTaskStatus(selectedValues);
  }

  const handleReimbStatusDelete = (value) =>{
    setMultiselect(false);
    let selectedValues = [...reimbStatus];
    selectedValues = selectedValues.filter((selectedValue)=> {return selectedValue !== value;} );
    setReimbStatus(selectedValues);
  }



  const reAssign = async ()=>{
    const request= {  
      "reimbursementSearchVO":reAssignData.slice().map((data)=> {return data.id}),
      "toSupervisor":reassignFilters.toSupervisor,
      "userId":props.userInfo.id,
      "reassignEndDate":(reassignFilters.reassignEndDate === null) ? null : moment(reassignFilters.reassignEndDate).format("YYYY-MM-DD")
    }
    if(document.getElementsByClassName("Mui-error").length > 0){
      setError(true);
    }
    else{
      await postFetch("/dashboard/reassign",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:true,message:"success",validationMessage:"Reassignment Successful." });                      
            },1000);
            searchReassignment();
        }
        else{
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
    }
  }

  const changeReassignFilter=(key,value)=>{
    let newDetails = {...reassignFilters};
    newDetails[key] = value;
    setReassignFilters(newDetails);
  }

  const auditdialog = ()=>{
    return (<div>
      <Dialog
        open={auditDialog}
        onClose={()=>setAuditDialog(false)}
      >
        <DialogContent>
          <Gridtable columns={auditColumns} data={auditData} ></Gridtable>
        </DialogContent>
      </Dialog>
    </div>);
  }

  const showAudit = async (row,type)=>{
    const request= {  
      "requestId":row.id,
      "type":(type==="requests" && filters.searchType === 'RM') ? "reimbursements" : ((type==="requests" && filters.searchType !== 'RM') ? "preapprovals" : "tasks")
    }
      await postFetch("/audit/load",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:false,message:"success" });                      
            },1000);
            setAuditColumns(type==="tasks" ? [...taskAuditColumns] : [...requestsAuditColumns]);
            if(type === "requests"){
              data.forEach((result)=>{
                result.column3 = props.info.reference.ReimbursementStatus.value.find((status)=>status.value === result.column3).name;
              });
            }
            else {
              data.forEach((result)=>{
                result.column3 = props.info.reference.taskStatus.value.find((status)=>status.value === result.column3).name;
                result.column2 = props.info.reference.taskTypes.value.find((status)=>status.value === result.column2).name;
              });
            }
            setAuditData([...data]);
            setAuditDialog(true);
        }
        else{
            setTimeout(()=>{                         
                props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }

   
  
  const renderWarningButton = (row)=>{
    return (new Date(row.dueDate) < new Date()) ? <WarningRoundedIcon style={{fontSize:20}}/> : null;
  }
  const renderActionButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/> <AccessTimeRoundedIcon onClick={()=>showAudit(row,"tasks")} style={{fontSize:20}}/></div>);
  }

  const renderViewButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/> <AccessTimeRoundedIcon onClick={()=>showAudit(row,"requests")} style={{fontSize:20}}/>
    {row.status === "In Progress" ? <DeleteIcon onClick={()=> deleteRequests(row)} style={{fontSize:20}}/> : null}</div>);
  }


const deleteRequests = (value)=>{
  const request= {  
    "id":value.id
  }
  if(filters.searchType === 'RM'){
    postFetch("/reimbursement/deleteById",request).then((data)=>{ 
      if(!data.message){
        props.search(filters,reimbStatus,2)
      }
      else{
          setTimeout(()=>{ 
            props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                                         
          },1000);
      }
  }).catch((e)=>{
      setTimeout(()=>{                          
      props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
  });
  }
  else{
    postFetch("/preApproval/deleteById",request).then((data)=>{ 
      if(!data.message){
        props.search(filters,reimbStatus,2)
      }
      else{
          setTimeout(()=>{ 
            props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                                         
          },1000);
      }
  }).catch((e)=>{
      setTimeout(()=>{                          
      props.onLoaderUpdate({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
  }); 
  }
}
  const taskColumns=[{
    name:"Task Type",
    field:"taskTypeDes",
    type:"input",
    renderView:taskTypeNavigation
  },
  {
    name:"Reimbursement Request",
    field:"reimbId",
    type:"text"
  },
  {
    name:"Creation Date",
    field:"createdDate",
    type:"date"
  },
  {
    name:"Due Date",
    field:"dueDate",
    type:"date"
  },
  {
    name:"Completion Date",
    field:"completionDate",
    type:"date"
  },
  {
    name:"Assigned To",
    field:"assignedTo",
    type:"text"
  },
  {
    name:"Division",
    field:"division",
    type:"text"
  },
  {
    name:"Task Status",
    field:"taskStatusDes",
    type:"text"
  },
  {
    name:"Overdue",
    field:"overdue",
    type:"input",
    renderView: renderWarningButton
  },
  {
    name:"Action",
    field:"action",
    type:"input",
    renderView: renderActionButton
  }];

  const taskAuditColumns =[
    {
      name:"Task Id",
      field:"column1",
      type:"text"
    },
    {
      name:"Task Type",
      field:"column2",
      type:"text"
    },
    {
      name:"Task Status",
      field:"column3",
      type:"text"
    },
    {
      name:"Created By",
      field:"createdBy",
      type:"text"
    },
    {
      name:"Created Date",
      field:"createdDate",
      type:"text"
    },
    {
      name:"Modified By",
      field:"modifiedBy",
      type:"text"
    },
    {
      name:"Modified Date",
      field:"modifiedDate",
      type:"text"
    }
  ];

  const requestsAuditColumns =[
    {
      name:"Created By",
      field:"createdBy",
      type:"text"
    },
    {
      name:"Created Date",
      field:"createdDate",
      type:"text"
    },
    {
      name:"Modified By",
      field:"modifiedBy",
      type:"text"
    },
    {
      name:"Modified Date",
      field:"modifiedDate",
      type:"text"
    },
    {
      name:"Assigned To",
      field:"column2",
      type:"text"
    },
    {
      name:"Status",
      field:"column3",
      type:"text"
    },
    {
      name:"Request Type",
      field:"column1",
      type:"text"
    }
  ];


  const reimbursementColumns = [{
    name:"Request Id",
    field:"id",
    type:"input",
    renderView:navigateFunction
  },
  {
    name:"Submitted By",
    field:"submittedBy",
    type:"text"
  },
  {
    name:"Created Date",
    field:"date",
    type:"date"
  },
  {
    name:"Status Change Date",
    field:"statusChangeDate",
    type:"date"
  },
  {
    name:"Travel Start Date",
    field:"startDate",
    type:"date"
  },
  {
    name:"Travel End Date",
    field:"endDate",
    type:"date"
  },
  {
    name:"Assigned To",
    field:"assignedTo",
    type:"text"
  },
  {
    name:"Division",
    field:"division",
    type:"text"
  },
  {
    name:"Request Status",
    field:"status",
    type:"text"
  },
  {
    name:"Action",
    field:"action",
    type:"input",
    renderView:renderViewButton
  }];

  const preApprovalColumns = [{
    name:"Request Id",
    field:"id",
    type:"input",
    renderView:navigateFunction
  },
  {
    name:"Submitted By",
    field:"submittedBy",
    type:"text"
  },
  {
    name:"Submitted Date",
    field:"date",
    type:"date"
  },
  {
    name:"Travel Start Date",
    field:"startDate",
    type:"date"
  },
  {
    name:"Travel End Date",
    field:"endDate",
    type:"date"
  },
  {
    name:"Assigned To",
    field:"assignedTo",
    type:"text"
  },
  {
    name:"Division",
    field:"division",
    type:"text"
  },
  {
    name:"Request Status",
    field:"status",
    type:"text"
  },
  {
    name:"Action",
    field:"action",
    type:"input",
    renderView:renderViewButton
  }];

  const reassignColumns = [{
    name:"Request Id",
    field:"id",
    type:"text"
  },
  {
    name:"Submitted By",
    field:"submittedBy",
    type:"text"
  },
  {
    name:"Submitted Date",
    field:"date",
    type:"date"
  },
  {
    name:"Travel Start Date",
    field:"startDate",
    type:"date"
  },
  {
    name:"Travel End Date",
    field:"endDate",
    type:"date"
  },
  {
    name:"Assigned To",
    field:"assignedTo",
    type:"text"
  },
  {
    name:"Division",
    field:"division",
    type:"text"
  },
  {
    name:"Request Status",
    field:"status",
    type:"text"
  }]


  return (
    <div>
    {auditdialog()}
    {(value === "1") ? <Box pt={8} px={2} style={{zIndex:1800,height:'93%'}} >
      <div className={classes.textCenter}>
        <Typography variant="h6" gutterBottom className={classes.header}>
          Search
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="searchCriteria" name="searchCriteria" value={value} onChange={(e)=>searchChange(e)}  className={matches ? classes.formGroup:null}>
            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Tasks" />
            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Reimbursement Requests" />
            {(['SA','DA','TS'].indexOf(props.userInfo.role) > -1) ? <FormControlLabel value="3" control={<Radio color="primary"/>} label="Reassign Requests" /> : null }
          </RadioGroup>
        </FormControl>
      </div>
      <Grid container spacing={2} className={classes.filters}>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">From Due Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                id="start-date"
                value={filters.fromDate}
                inputVariant="outlined"
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"fromDate")}
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">To Due Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                inputVariant="outlined"
                id="end-date"
                value={filters.toDate}
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"toDate")}
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.textfield}>
                    <span className={classes.textLabelB}>Task Status</span>
                        <FormControl variant="outlined" size="small" fullWidth style={{position:"relative",top:"8px",width:"80.9%"}}>
                            <Select
                                id="task-status"
                                multiple
                                value={taskStatus.length < 1 ? [0]: taskStatus}
                                variant="outlined"
                                onChange={taskStatusChange}
                                open={multiSelect}
                                fullWidth
                                onClick={e => setMultiselect(!multiSelect)}
                                renderValue={(selected) => (
                                  taskStatus.length === 0 ?
                                <div>Pick an Option</div> :
                                  <div className={classes.chips}>
                                    {selected.map((value) => (
                                      <Chip key={value} 
                                      label={props.info.reference.taskStatus.value.find((status)=>status.value === value).name} 
                                      className={classes.chip} onDelete={()=>handleTaskStatusDelete(value)}  color="primary"/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                 <MenuItem key="All" value="All">
                                    <Checkbox checked={taskStatus.length === props.info.dropdown.taskStatus.value.length}  color="primary"/>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                {props.info.dropdown.taskStatus.value.map((status) => (
                                  <MenuItem key={status.name} value={status.value}>
                                    <Checkbox checked={taskStatus.indexOf(status.value) > -1}  color="primary"/>
                                    <ListItemText primary={status.name} />
                                  </MenuItem>
                                ))}
                                </Select>                                            
                        </FormControl>
          </Grid>
       </Grid>
       <Grid container spacing={2} className={classes.filters}>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                <span className={classes.textLabel}>Task Type</span>
                <Select
                    id="task-select"
                    value={filters.taskType}
                    onChange={(event)=>onFiltersChange(event.target.value,"taskType")}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.info.dropdown.taskTypes.value.map((method)=>(
                        <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>                                               
            </FormControl>                                                                 
        </Grid>
        <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
              <span className={classes.textLabel}>Division</span>
              <Select
                  id="division-select"
                  value={filters.division}//{(['SA'].indexOf(props.userInfo.role) > -1) ? filters.division : (props.userInfo.division)}
                  onChange={(event)=>onFiltersChange(event.target.value,"division")}
                  disabled={(['SA'].indexOf(props.userInfo.role) > -1) ? false: true}
                >
                  <MenuItem value={0}>Pick an Option</MenuItem>
                  {props.info.dropdown.DIVISIONS.value.map((method)=>(
                      <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                  ))}
                </Select>                                               
            </FormControl>                      
          </Grid>

          <Grid item xs={12} sm={4} className={classes.textfield}>
            <span className={classes.textLabel}>Assigned To</span>
              <Autocomplete
                id="assigned-to"
                options={updatedSupervisorData.sort((a, b) => a.details > b.details ? 1:-1).filter((ele, ind) => ind === updatedSupervisorData.findIndex( elem => elem.details === ele.details))}
                getOptionLabel={(option) => option.details}
                value={(filters.assignedTo !== null) ? updatedSupervisorData.find((sd)=> sd.id === filters.assignedTo) : {details:'',id:null}}
                //value={(filters.assignedTo !== null) ? props.supervisorData.find((sd)=> sd.id === filters.assignedTo) : props.supervisorData.find((sd)=> sd.id === props.userInfo.id)}
                onChange={(event,value)=> value !== null ? onFiltersChange(value.id,'assignedTo') : onFiltersChange(null,'assignedTo')}
                renderOption={(option)=>
                    <span style={{fontWeight:600}}>{option.details}</span>
                }
                size="small"
                disabled={(['TS','PR','EM'].indexOf(props.userInfo.role) > -1) ? true: false}
                renderInput={(params) => <TextField id="req-assigned-to"  variant="outlined" size="small" {...params}
                placeholder = "Search User"
                className={`${classes.formControl}`}
                value={filters.assignedTo === null ? '' : filters.assignedTo}
                onChange={(event)=> onFiltersChange(event.target.value,"assignedTo")}              
                />}
              /> 
            </Grid>
          </Grid>
      <div className={classes.textCenter}>
        <Button variant="contained" color="primary" className={classes.button} onClick={()=> props.search(filters,taskStatus,1)}>
          Search
        </Button>
      </div>
        <Box mb={5}></Box>
        <Paper elevation={6}>
          <Gridtable columns={taskColumns} data={props.searchData.taskData} ></Gridtable>
        </Paper>
        <Box mb={5}></Box>
     </Box>: null }

 { (value === "2") ? <Box pt={8} px={2} style={{backgroundColor:'#f6f9fd',zIndex:1800,height:'93%'}} >
      <div className={classes.textCenter}>
        <Typography variant="h6" gutterBottom className={classes.header}>
          Search
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="searchCriteria" name="searchCriteria" value={value} onChange={(e)=>searchChange(e)}  className={matches ? classes.formGroup:null}>
            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Tasks" />
            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Reimbursement Requests" />
            {(['SA','DA','TS'].indexOf(props.userInfo.role) > -1) ? <FormControlLabel value="3" control={<Radio color="primary"/>} label="Reassign Requests" /> : null }
          </RadioGroup>
        </FormControl>
      </div>
      <Grid container spacing={2} className={classes.filters}>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">Travel Start Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                id="start-date"
                value={filters.fromDate}
                inputVariant="outlined"
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"fromDate")}
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">Travel End Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                inputVariant="outlined"
                id="end-date"
                value={filters.toDate}
                placeholder="MM/DD/YYYY"
                onChange={(date)=> onFiltersChange(date,"toDate")}
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.textfield}>
                    <span className={classes.textLabelB}>Request Status</span>
                        <FormControl variant="outlined" size="small" fullWidth style={{position:"relative",top:"8px",width:"80.9%"}}>
                            <Select
                                id="request-status"
                                multiple
                                value={reimbStatus.length < 1 ? [0]: reimbStatus}
                                variant="outlined"
                                onChange={reimbStatusChange}
                                open={multiSelect}
                                fullWidth
                                onClick={e => setMultiselect(!multiSelect)}
                                renderValue={(selected) => (
                                  reimbStatus.length === 0 ?
                                <div>Pick an Option</div> :
                                  <div className={classes.chips}>
                                    {selected.map((value) => (
                                      <Chip key={value} 
                                      label={props.info.reference.ReimbursementStatus.value.find((status)=>status.value === value).name} 
                                      className={classes.chip} onDelete={()=>handleReimbStatusDelete(value)}  color="primary"/>
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                 <MenuItem key="All" value="All">
                                    <Checkbox checked={reimbStatus.length === props.info.dropdown.ReimbursementStatus.value.length}  color="primary"/>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                {props.info.dropdown.ReimbursementStatus.value.map((status) => (
                                  <MenuItem key={status.name} value={status.value}>
                                    <Checkbox checked={reimbStatus.indexOf(status.value) > -1}  color="primary"/>
                                    <ListItemText primary={status.name} />
                                  </MenuItem>
                                ))}
                                </Select>                                            
                        </FormControl>
          </Grid>
       </Grid>
       <Grid container spacing={2} className={classes.filters}>
        <Grid item xs={12} sm={4}>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <span className={classes.textLabel}>Cost Center</span>
                <TextField
                                  id="cost-center"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Cost Center"
                                  value={filters.costCenter}
                                  disabled={(['SA','DA','TS'].indexOf(props.userInfo.role) > -1) ? false: true}
                                  onChange={(event)=>onFiltersChange(event.target.value,"costCenter")}
                                  InputLabelProps={{
                                      shrink: true
                                  }}
                              />
            </FormControl>                                                                 
        </Grid>
        <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
              <span className={classes.textLabel}>Division</span>
              <Select
                  id="division-select"
                  value={filters.division}//{(['SA'].indexOf(props.userInfo.role) > -1) ? filters.division : (props.userInfo.division)}
                  onChange={(event)=>onFiltersChange(event.target.value,"division")}
                  disabled={(['SA'].indexOf(props.userInfo.role) > -1) ? false: true}
                >
                  <MenuItem value={0}>Pick an Option</MenuItem>
                  {props.info.dropdown.DIVISIONS.value.map((method)=>(
                      <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                  ))}
                </Select>                                               
            </FormControl>                      
          </Grid>
          <Grid item xs={12} sm={4} className={classes.textfield}>
            <span className={classes.textLabel}>Assigned To</span>
        
           
              <Autocomplete
                id="assigned-to"
                options={updatedSupervisorData.sort((a, b) => a.details > b.details ? 1:-1).filter( (ele, ind) => ind === updatedSupervisorData.findIndex( elem => elem.details === ele.details))}
                getOptionLabel={(option) => option.details}
                value={(filters.assignedTo !== null) ? updatedSupervisorData.find((sd)=> sd.id === filters.assignedTo) : {details:'',id:null}}
                //value={(filters.assignedTo !== null) ? props.supervisorData.find((sd)=> sd.id === filters.assignedTo) : props.supervisorData.find((sd)=> sd.id === props.userInfo.id)}
                onChange={(event,value)=> value !== null ? onFiltersChange(value.id,'assignedTo') : onFiltersChange(null,'assignedTo')}
                renderOption={(option)=>
                    <span style={{fontWeight:600}}>{option.details}</span>
                }
                size="small"
                disabled={(['TS','PR','EM'].indexOf(props.userInfo.role) > -1) ? true: false}
                renderInput={(params) => <TextField id="req-assigned-to"  variant="outlined" size="small" {...params}
                placeholder = "Search User"
                className={`${classes.formControl}`}
                value={filters.assignedTo === null ? '' : filters.assignedTo}
                onChange={(event)=> onFiltersChange(event.target.value,"assignedTo")}              
                />}
              /> 
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.filters}>
          <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" size="small" className={classes.formControl}>
          <span className={classes.textLabel}>Internal Order</span>
                <TextField
                                  id="internal-order"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={filters.internalOrder}
                                  placeholder="Internal Order"
                                  disabled={(['SA','DA','TS'].indexOf(props.userInfo.role) > -1) ? false: true}
                                  onChange={(event)=>onFiltersChange(event.target.value,"internalOrder")}
                                  InputLabelProps={{
                                      shrink: true
                                  }}
                              />  
            </FormControl>                                                 
        </Grid>
        <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
              <span className={classes.textLabel}>Request Type</span>
              <Select
                  id="request-select"
                  value={(['PR'].indexOf(props.userInfo.role) > -1) ? "RM" : filters.searchType}
                  onChange={(event)=>onFiltersChange(event.target.value,"searchType")}
                  disabled={(['PR'].indexOf(props.userInfo.role) > -1) ? true: false}
                >
                  <MenuItem key="RM" value="RM">Reimbursement Requests</MenuItem>
                  <MenuItem key="PA" value="PA">Pre-Approval Requests</MenuItem>
                </Select>                                               
            </FormControl>                      
          </Grid>
      </Grid>
      <div className={classes.textCenter}>
        <Button variant="contained" color="primary" className={classes.button} onClick={()=> props.search(filters,reimbStatus,2)}>
          Search
        </Button>
      </div>
        <Box mb={5}></Box>
        <Paper elevation={6}>
          
          {(filters.searchType == "RM") ? <Gridtable columns={reimbursementColumns} data={props.searchData.reimbData} fromevent={"r"} ></Gridtable> : <Gridtable columns={preApprovalColumns} data={props.searchData.reimbData} fromevent={"p"} ></Gridtable>}
          
        </Paper>
        <Box mb={5}></Box>
     </Box> : null }

 { (value === "3" && (['SA','DA','TS'].indexOf(props.userInfo.role) > -1)) ? <Box pt={8} px={2} style={{backgroundColor:'#f6f9fd',zIndex:1800,height:'93%'}} >
          <div className={classes.textCenter}>
        <Typography variant="h6" gutterBottom className={classes.header}>
          Search
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="searchCriteria" name="searchCriteria" value={value} onChange={(e)=>searchChange(e)}  className={matches ? classes.formGroup:null}>
            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Tasks" />
            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Reimbursement Requests" />
            <FormControlLabel value="3" control={<Radio color="primary"/>} label="Reassign Requests" />
          </RadioGroup>
        </FormControl>
      </div>
      <Grid container spacing={2} className={classes.filters}>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">Travel Start Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                id="start-date"
                value={reassignFilters.startDate}
                inputVariant="outlined"
                placeholder="MM/DD/YYYY"
                onChange={(date)=> changeReassignFilter("startDate",date)}
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">Travel End Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                inputVariant="outlined"
                id="end-date"
                value={reassignFilters.endDate}
                placeholder="MM/DD/YYYY"
                onChange={(date)=> changeReassignFilter("endDate",date)}
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} style={{position:"relative",top:9}}>
              <span className={classes.textLabelC}>Division</span>
              <Select
                  id="division-select"
                  value={(['SA'].indexOf(props.userInfo.role) > -1) ? reassignFilters.division : (props.userInfo.division)}
                  onChange={(event)=>changeReassignFilter("division",event.target.value)}
                  disabled={(['SA'].indexOf(props.userInfo.role) > -1) ? false: true}
                >
                  <MenuItem value={0}>Pick an Option</MenuItem>
                  {props.info.dropdown.DIVISIONS.value.map((method)=>(
                      <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                  ))}
                </Select>                                               
            </FormControl>                      
          </Grid>
       </Grid>
       <Grid container spacing={2} className={classes.filters}>
       <Grid item xs={12} sm={4} className={classes.textfield}>
            <span className={classes.textLabel}>From Supervisor</span>
              <Autocomplete
                id="from-supervisor"
                options={props.supervisorData}
                getOptionLabel={(option) => option.details}
                value={(reassignFilters.fromSupervisor !== null) ? props.supervisorData.find((sd)=> sd.id === reassignFilters.fromSupervisor) : {details:'',id:null}}
                onChange={(event,value)=> value !== null ? changeReassignFilter("fromSupervisor",value.id) : changeReassignFilter("fromSupervisor",null)}
                renderOption={(option)=>
                    <span style={{fontWeight:600}}>{option.details}</span>
                }
                size="small"
                disabled={(['TS'].indexOf(props.userInfo.role) > -1) ? true: false}
                renderInput={(params) => <TextField id="req-assigned-to"  variant="outlined" size="small" {...params}
                placeholder = "Search User"
                className={`${classes.formControl}`}
                value={reassignFilters.fromSupervisor === null ? '' : reassignFilters.fromSupervisor}
                onChange={(event)=> changeReassignFilter("fromSupervisor",event.target.value)}              
                />}
              /> 
            </Grid>
            <Grid item xs={12} sm={4} className={classes.textfield}>

            <FormControl variant="outlined" size="small" className={classes.formControl}>
              <span className={classes.textLabel}>Request Id</span>
              <TextField
                id="request-id"
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Request Id"
                value={reassignFilters.requestId}
                onChange={(event) => changeReassignFilter("requestId", event.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>
          </Grid>
          </Grid>
      <div className={classes.textCenter}>
        <Button variant="contained" color="primary" className={classes.button} onClick={()=>searchReassignment()}>
          Search
        </Button>
      </div>
      <Box mb={5}></Box>
        <Paper elevation={6}>
            <Gridtable columns={reassignColumns} data={reAssignData} ></Gridtable>
        </Paper>
        <Box mb={5}></Box>
        <Grid container spacing={2} className={classes.filters}>
        <Grid item xs={12} sm={4} className={classes.textfield} style={{position:"relative",top:10}}>
            <span className={classes.textLabel}>To Supervisor</span>
              <Autocomplete
                id="to-supervisor"
                options={props.supervisorData}
                getOptionLabel={(option) => option.details}
                value={(reassignFilters.toSupervisor !== null) ? props.supervisorData.find((sd)=> sd.id === reassignFilters.toSupervisor) : {details:'',id:null}}
                onChange={(event,value)=> value !== null ? changeReassignFilter("toSupervisor",value.id) : changeReassignFilter("toSupervisor",null)}
                renderOption={(option)=>
                    <span style={{fontWeight:600}}>{option.details}</span>
                }
                size="small"
                renderInput={(params) => <TextField id="req-assigned-to"  variant="outlined" size="small" {...params}
                placeholder = "Search User"
                className={`${classes.formControl} required`}
                error = {reassignFilters.toSupervisor === '' || reassignFilters.toSupervisor === null}
                value={reassignFilters.toSupervisor === null ? '' : reassignFilters.toSupervisor}
                onChange={(event)=> changeReassignFilter("toSupervisor",event.target.value)}
                helperText={((reassignFilters.toSupervisor === '' || reassignFilters.toSupervisor === null) && error) ? "Required Field" : null}              
                />}
              /> 
            </Grid>
            <Grid item xs={12} sm={4} className={classes.datePicker}>
          <span className="datepickerLabel">Reassignment End Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker 
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                fullWidth
                autoOk={true}
                size="small"
                inputVariant="outlined"
                id="reassign-end-date"
                value={reassignFilters.reassignEndDate}
                placeholder="MM/DD/YYYY"
                minDate={new Date()}
                onChange={(date)=> changeReassignFilter("reassignEndDate",date)}
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          </Grid>
          <div className={classes.textCenter}>
            <Button variant="contained" color="primary" disabled={reAssignData.length <= 0} className={classes.button} onClick={()=>reAssign()}>
              Reassign
            </Button>
          </div>  
</Box>:  null }
<Box p={8}></Box>
</div>     
     
  );
}

