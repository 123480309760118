import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Header from './imgs/loginHeader.png';
import HeaderLogo from './imgs/TRIPSMapLogo3.png';
import footer from './imgs/footer.png';
import Button from '@material-ui/core/Button';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from '@material-ui/core/Menu';
import WorkerDashboard from './workerDashboard';
import VehicleDashboard from './dashboardContent';
import Box from '@material-ui/core/Box';
import Search from './search';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Loader from './loader';
import {postFetch} from './http';
import { Redirect, Switch, Route } from "react-router-dom";
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useHistory,useLocation  } from "react-router-dom";
import TR1Info from './tr1/tr1Summary';
import Admin from './admin/admin';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Config from './assets/config';
import moment from "moment";
import ReservationSummary from './vm/summary';
import VMSearch from './vm/VMSearch';

const useStyles = makeStyles((theme) => ({
    root: {
      '@global': {
        '*::-webkit-scrollbar': {
          display:'block',
          height:10
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'grey',
          borderRadius: 2
        }
      }
      },
      header:{
        zIndex:1350,
        height:70
      },
      footer:{
        minHeight: '80px',
        zIndex: 1350,
        bottom: 0,
        top: 'auto',
        textAlign:'center'
      },
      menuButton: {
        marginRight: theme.spacing(1),
        paddingTop:0
      },
      title: {
        flexGrow: 1,
      },
      button:{
        textTransform: 'Capitalize',
        "& .MuiButton-startIcon":{
          margin:'2px'
        }
      },
      icon:{
        position: 'relative',
        top: '-2px'
      },
      icon2:{
        position: 'relative',
        top: '-1px'
      },
      menuList:{
        color: '#fff',
        backgroundColor: '#041d45',
        "& .MuiListItemIcon-root":{
          color:"#fff",
          minWidth: '36px'
        }
      },
      profile:{
        textTransform: 'Capitalize',
        "& .MuiButton-startIcon":{
          margin:'2px'
        },
        borderLeft:'1px solid #fff',
        borderRadius:0
      },
      profile2:{
        textTransform: 'Capitalize',
        "& .MuiButton-startIcon":{
          margin:'2px'
        },
        borderRadius:0
      },
      menu:{
        "& .MuiPopover-paper":{
          color: '#fff',
          backgroundColor: '#041d45'
        },
        "& .MuiListItemIcon-root":{
          color:"#fff",
          minWidth: '36px'
        }
      },
      hideSearch:{
        zIndex:-1,
        visibility:"hidden"
      },
      showSearch:{
        "& .MuiDrawer-paperAnchorTop":{
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)'
        }
      }
}));

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const matches = useMediaQuery('(min-width:1200px)');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [configurations, setConfigurations] = React.useState({dropdown:{DIVISIONS:{value:[]},taskStatus:{value:[]},taskTypes:{value:[]}},reference:{}});
  const [loader,setLoader] = React.useState({loader:false,notification:false,message:"success",validationMessage:"Success"});
  const [filters, setFilters] = React.useState({fromDate:null,toDate:null,taskType:0,assignedTo:null,division:0,costCenter:"",internalOrder:"",searchType:"RM",station:0,submittedBy:null});
  const [searchData,setSearchData] = React.useState({taskData:[],reimbData:[],resData:[]});
  const [taskStatus, setTaskStatus] = React.useState([]);
  const [reimbStatus, setReimbStatus] = React.useState([]);
  const [resStatus, setResStatus] = React.useState([]);
  const [searchCriteria, setSearchCriteria] = React.useState("1");
  const [supervisorData, setSupervisorData] = React.useState([]);
  const [onInit, setOnInit] = React.useState(0);
  const [userInfo, setUserInfo] = React.useState({role:null,email:null});
  const [pageInfo, setPageInfo] = React.useState([]);
  
  const handleToggle = () => {
    setOpen((open) => !open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  React.useEffect(()=>{  
    
     return history.listen((location)=>{
       if(location.pathname !== '/search')
       {
        setSearchData({taskData:[],reimbData:[],resData:[]});
        setFilters({fromDate:null,toDate:null,taskType:0,assignedTo:null,division:0,costCenter:"",internalOrder:"",searchType:"RM",station:0,submittedBy:null});
        setTaskStatus([]);
        setReimbStatus([]);    
        setResStatus([]);    
       }
       
      });
    },[history]); 

  const updateLoader = (newLoader) =>{
    setLoader({...loader, ...newLoader});
  }

  const searchPreapproval = (key,filter)=>{
      setSearchCriteria("2");
      let updatedFilters = {fromDate:null,toDate:null,taskType:0,assignedTo:null,division:0,costCenter:"",internalOrder:"",searchType:"PA"};
      (key === 1 ) ? updatedFilters.assignedTo = userInfo.id : updatedFilters.userId = userInfo.id;
      (['SA'].indexOf(userInfo.role) > -1) ? updatedFilters.division = 0 : updatedFilters.division = userInfo.division;
      if(key === 1)
      {
        (['SA','DA'].indexOf(userInfo.role) > -1) ? updatedFilters.division = 0 : updatedFilters.division = userInfo.division;
      }
      //(['SA'].indexOf(userInfo.role) > -1) ? updatedFilters.assignedTo = null : updatedFilters.assignedTo = userInfo.id;
      setFilters(updatedFilters);
      setReimbStatus(filter);
      getPreapprovals(updatedFilters,filter);
      navigate("/search");
  }

  const changeSearchFilters = (type,key,filter)=>{
    if(type === "requests"){
      setSearchCriteria("2");
      let updatedFilters = {fromDate:null,toDate:null,taskType:0,assignedTo:null,division:0,costCenter:"",internalOrder:"",searchType:"RM"};
      (key === 1 ) ? updatedFilters.assignedTo = userInfo.id : updatedFilters.userId = userInfo.id;
      (['SA'].indexOf(userInfo.role) > -1) ? updatedFilters.division = 0 : updatedFilters.division = userInfo.division;
      //(['SA'].indexOf(userInfo.role) > -1) ? updatedFilters.assignedTo = null : updatedFilters.assignedTo = userInfo.id;
      setFilters(updatedFilters);
      setReimbStatus(filter);
      searchReimb(updatedFilters,filter);
      navigate("/search");
    }
    else{
      setSearchCriteria("1");
      let updatedFilters = {fromDate:null,toDate:null,taskType:0,assignedTo:null,division:0,costCenter:"",internalOrder:"",searchType:"RM"};
      updatedFilters.assignedTo = userInfo.id;
      var d = new Date();
      if(key === 1){
        d.setDate(d.getDate() - 1);
        updatedFilters.toDate = d;
      }
      if(key === 2){
        updatedFilters.fromDate = d;
        updatedFilters.toDate = d;
      }
      if(key === 3){
        d.setDate(d.getDate() + 1);
        updatedFilters.fromDate = d;
      }
      (['SA','DA'].indexOf(userInfo.role) > -1) ? updatedFilters.division = 0 : updatedFilters.division = userInfo.division;
     //(['SA'].indexOf(userInfo.role) > -1) ? updatedFilters.assignedTo = null : updatedFilters.assignedTo = userInfo.id;
      setFilters(updatedFilters);
      setTaskStatus(filter);
      searchTasks(updatedFilters,filter);
      navigate("/search");
    }
  }

  const dashboardSearch = (type,key,filter)=>{
    if(type === "reservs"){
      setSearchCriteria("2");
      let updatedFilters = {fromDate:null,toDate:null,taskType:0,assignedTo:null,division:0,costCenter:"",internalOrder:"",searchType:"RM",station:0,submittedBy:null};
      let d = new Date();
      var d2 = new Date();
      if(key !== 1){
        updatedFilters.submittedBy = userInfo.id;
      }
      if(key === 6){
        //d.setDate(d.getDate() - 1);
        //updatedFilters.toDate = d;
      }
      if (key === 4){
        //d2.setDate(d2.getDate() + 1);
        //updatedFilters.fromDate = d2;
      }
      (['VA','DR'].indexOf(userInfo.role) > -1) ? updatedFilters.division = 0 : updatedFilters.division = userInfo.division;
      if(key !== 1 && key != 3)
      {
        (['DR','DP','VA'].indexOf(userInfo.role) > -1 ) ? updatedFilters.division = 0 : updatedFilters.division = userInfo.division;
      }
      setFilters(updatedFilters);
      setResStatus(filter);
      searchReservations(updatedFilters,filter,key);
      navigate("/search","dashboard");
    }
    else{
      setSearchCriteria("1");
      let updatedFilters = {fromDate:null,toDate:null,taskType:0,assignedTo:null,division:0,costCenter:"",internalOrder:"",searchType:"RM",station:0,submittedBy:null};
      updatedFilters.assignedTo = userInfo.id;
      let d = new Date();
      if(key === 1){
        d.setDate(d.getDate() - 1);
        updatedFilters.toDate = d;
      }
      if(key === 2){
        updatedFilters.fromDate = d;
        updatedFilters.toDate = d;
      }
      if(key === 3){
        d.setDate(d.getDate() + 1);
        updatedFilters.fromDate = d;
      }
      (['VA','DR'].indexOf(userInfo.role) > -1) ? updatedFilters.division = 0 : updatedFilters.division = userInfo.division;
      setFilters(updatedFilters);
      setTaskStatus(filter);
      searchTasks(updatedFilters,filter);
      navigate("/search","dashboard");
    }
  }


  /*const hideDrawerEvent = () =>{
    setHideDrawer(true);
    //setDrawer(false);
  }*/

  const handleMenuClick = (event) => {
    Boolean(anchorEl) ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  
  React.useEffect(() => {
    const loadUserInfo = async ()=>{
      const request= {
        "username":JSON.parse(CryptoJS.AES.decrypt(Cookies.get("state"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)).username,
        "appId":parseInt(localStorage.getItem('appId'))
      };
      await postFetch("/userManagement/userInfo",request).then((data)=>{
            setOnInit(1);
            if(!data.message && data.message !== null){
              setUserInfo(data.userInfo);
              let configdata = {dropdown:{},reference:{}};
              data.configurations.forEach((config)=>{
                config.value = JSON.parse(config.value);
                configdata.reference[config.configName] = config;
                let filteredConfig ={...config};
                filteredConfig.value = filteredConfig.value.filter((value)=> value.activeSW.toString().trim() === 'Y' && (new Date(value.activeBeginDt) <= new Date()));
                configdata.dropdown[filteredConfig.configName] = filteredConfig;
              });
              let updatedFilters = {...filters};
              if(data.userInfo.role === 'EM'){
                updatedFilters.costCenter = data.travellerInfo.empCostCenter.toString().trim();
                updatedFilters.internalOrder = data.travellerInfo.empInternalOrder.toString().trim();
              }
              setFilters(updatedFilters);
              setConfigurations({...configdata});
              setSupervisorData([...data.userSummary]);
              setPageInfo([...data.pageInfo]);              
            }
            else{
              history.push("/appManager");
                setTimeout(()=>{                         
                    setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message });                                 
                },1000);
            }
            }).catch((e)=>{
              setOnInit(1);
              setTimeout(()=>{                          
                  setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
              },1000);
            });
    }

    if(onInit === 0){
      loadUserInfo();
    }

  }, [open, loader,onInit]);

  const loaderAction = async()=>{
    setLoader(prevState => {
      return {...prevState.loader,loader : false, notification:false}                                 
      });
  };

  /*function convertDateTimeFromServer(value){
    if(value !== null){
      let serverDateTimeArray = value.split("T");
      return serverDateTimeArray[0];
    }
    else{
      return null;
    }
  }*/

  function navigate(route, fromLoc = "search"){
    history.push(route, { from: fromLoc });
  }
  const logout =()=>{
    Cookies.remove('state');
    localStorage.removeItem("requestId");  
    localStorage.clear();
    if(userInfo.role === 'PR'){
      history.push('/logout');
    }
    else{
      history.push('/logout');
    }
  }

  const onSearch = (searchFilters,searchTaskStatus,key) =>{

    if(key === 1){
      delete searchFilters.userId;
      searchTasks(searchFilters,searchTaskStatus);
    }
    if(key === 2){
      //delete searchFilters.userId;
      if(searchFilters.searchType === "RM"){
        searchReimb(searchFilters,searchTaskStatus);
      }
      else{
        getPreapprovals(searchFilters,searchTaskStatus);
      }
    }
    if(key === 3){
      //delete searchFilters.userId;
      searchReservations(searchFilters,searchTaskStatus,key);
    }
  }

  const searchTasks = async (searchFilters,searchTaskStatus)=>{
    setLoader({loader:true,notification:false,message:"success",validationMessage:"success" });
    const request= {  
      "startDueDate": (searchFilters.fromDate !== null) ? moment.utc(searchFilters.fromDate).local().format("YYYY-MM-DD") : null,
      "endDueDate":(searchFilters.toDate !== null) ? moment.utc(searchFilters.toDate).local().format("YYYY-MM-DD") : null,
      "taskStatus":searchTaskStatus.toString(),
      "taskType":null,
      "division":null,
      "assignedTo":searchFilters.assignedTo,
      "userId":null,
      "appId":parseInt(localStorage.getItem('appId'))
    };
    
    (['SA','DA'].indexOf(userInfo.role) > -1) ? request.division = null : request.division = userInfo.division;

    if(parseInt(localStorage.getItem('appId')) === 1)
    {
      (['DR','VA'].indexOf(userInfo.role) > -1) ? request.division = null : request.division = userInfo.division;
    }
    if(searchFilters.division !== 0){
      request.division = searchFilters.division;
    }
    if(searchTaskStatus.length === 0){
      request.taskStatus = null;
    }
    if(searchFilters.taskType !== 0){
      request.taskType = searchFilters.taskType;
    }
    (['SA','DA','TS','DP','VA'].indexOf(userInfo.role) > -1) ? request.userId = null : request.userId = userInfo.id;
      await postFetch("/dashboard/searchTasks",request).then((data)=>{
            if(!data.message){
              data.forEach((result)=>{
                result.taskStatusDes = configurations.reference.taskStatus.value.find((status)=>status.value === result.taskStatus).name;
                result.taskTypeDes = configurations.reference.taskTypes.value.find((status)=>status.value === result.taskType).name;
              });
              let updatedData = {...searchData};
              updatedData.taskData = [...data];
              setSearchData(updatedData);
              setTimeout(()=>{                         
                setLoader({loader:false,notification:false,message:"success"});                                 
              },1000);
            }
            else{
                setTimeout(()=>{                         
                  setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
                },1000);
            }
            }).catch((e)=>{
              setTimeout(()=>{                          
                setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
              },1000);
            });
  };
  const searchReservations = async (searchFilters,searchTaskStatus,key)=>{
    setLoader({loader:true,notification:false,message:"success",validationMessage:"success" });
    const request= {  
      "reservationStartDate":(searchFilters.fromDate !== null) ? moment.utc(searchFilters.fromDate).local().format("YYYY-MM-DD") : null,
      "reservationEndDate":(searchFilters.toDate !== null) ? moment.utc(searchFilters.toDate).local().format("YYYY-MM-DD") : null,
      "status":searchTaskStatus.toString(),
      "division":null,
      "station":null,
      "userId":userInfo.id,
      "dashboardCriteria":null
    };
    if(key === 6)
    {
      request.dashboardCriteria = 'PC';
      request.reservationEndDate = null;
    }else if(key === 4)
    {
      request.dashboardCriteria = 'UP';
      request.reservationStartDate = null;
    }else if(key === 5)
    {
      request.dashboardCriteria = 'IP';
    }

    (['DR','DP','VA'].indexOf(userInfo.role) > -1 ) ? request.division = null : request.division = userInfo.division;
    if(searchFilters.division !== 0 ){
      request.division = searchFilters.division;
    }
    if(searchTaskStatus.length === 0){
      request.status = null;
    }
    if(searchFilters.station !== 0){
      request.station = searchFilters.station;
    }
    if(key !== 1 && key !== 3)
    {
      (['DR','DP','VA'].indexOf(userInfo.role) > -1 ) ? request.division = null : request.division = userInfo.division;
    }
    
    (searchFilters.submittedBy === undefined || searchFilters.submittedBy === null) ? request.userId = null : request.userId = searchFilters.submittedBy;
    //((['DP','VA'].indexOf(userInfo.role) > -1) && (searchFilters.userId === undefined)) ? request.userId = null : request.userId = searchFilters.submittedBy;
      await postFetch("/dashboard/searchReservations",request).then((data)=>{
            if(!data.message){
              data.forEach((result)=>{
                result.station = configurations.reference.OfficialStations.value.find((status)=>status.value === result.station).name;
                result.status = configurations.reference.ReservationStats.value.find((status)=>status.value === result.status).name;
              });
              let updatedData = {...searchData};
              // if(key === 5){
              //   data = data.filter((res)=> moment(res.startDate)<= moment(new Date().setHours(0,0,0,0)) &&  moment(res.endDate)>= moment(new Date().setHours(0,0,0,0)));
              // }
              updatedData.resData = [...data];
              setSearchData(updatedData);
              setTimeout(()=>{                         
                setLoader({loader:false,notification:false,message:"success"});                                 
              },1000);
            }
            else{
                setTimeout(()=>{                         
                  setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
                },1000);
            }
            }).catch((e)=>{
              setTimeout(()=>{                          
                setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
              },1000);
            });
  }


  const searchReimb = async (searchFilters,searchTaskStatus)=>{
    const request= {  
      "travelStartDate":(searchFilters.fromDate !== null) ? moment.utc(searchFilters.fromDate).local().format("YYYY-MM-DD") : null,
      "travelEndDate":(searchFilters.toDate !== null) ? moment.utc(searchFilters.toDate).local().format("YYYY-MM-DD") : null,
      "status":searchTaskStatus.toString(),
      "division":null,
      "assignedTo":searchFilters.assignedTo,
      "costCenter":searchFilters.costCenter,
      "internalOrder":searchFilters.internalOrder,
      "userId":null,
    };

    if(['EM'].indexOf(userInfo.role) > -1){ 
      request.assignedTo = null;
    }
    if(['SA'].indexOf(userInfo.role) > -1)//  || userInfo.division === 'DMS')
    { 
      request.division = null;
      //request.assignedTo = null;
    }
    else {
       request.division = userInfo.division;
    }
    if(searchFilters.division !== 0 && userInfo.division !== 'DMS'){
      request.division = searchFilters.division;
    }
    if(searchTaskStatus.length === 0){
      request.status = null;
    }
    if(searchFilters.internalOrder === ""){
      request.internalOrder = null;
    }
    if(searchFilters.costCenter === ""){
      request.costCenter = null;
    }
    if(['SA','DA','TS'].indexOf(userInfo.role) > -1){
      request.excludeNull = true;
    }
    ((['SA','DA','TS'].indexOf(userInfo.role) > -1) && (searchFilters.userId === undefined)) ? request.userId = null : request.userId = userInfo.id;
      await postFetch("/dashboard/searchRemb",request).then((data)=>{
            if(!data.message){
              data.forEach((result)=>{
                result.status = configurations.reference.ReimbursementStatus.value.find((status)=>status.value === result.status).name;
                result.assignedTo = result.assignedTo === null ? '' : result.assignedTo;
              });
              let updatedData = {...searchData};
              updatedData.reimbData = [...data];
              setSearchData(updatedData);
            }
            else{
                setTimeout(()=>{                         
                  setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
                },1000);
            }
            }).catch((e)=>{
              setTimeout(()=>{                          
                setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
              },1000);
            });
  }

  const getPreapprovals = async (searchFilters,searchTaskStatus)=>{
    const request= {  
      "travelStartDate":(searchFilters.fromDate !== null) ? moment.utc(searchFilters.fromDate).local().format("YYYY-MM-DD") : null,
      "travelEndDate":(searchFilters.toDate !== null) ? moment.utc(searchFilters.toDate).local().format("YYYY-MM-DD") : null,
      "status":searchTaskStatus.toString(),
      "division":null,
      "assignedTo":searchFilters.assignedTo,
      "costCenter":null,
      "internalOrder":null,
      "userId":null,
    };
    if(['EM'].indexOf(userInfo.role) > -1){ 
    request.assignedTo = null;
    }
    (['SA','DA'].indexOf(userInfo.role) > -1) ? request.division = null : request.division = userInfo.division;
    if(searchFilters.division !== 0){
      request.division = searchFilters.division;
    }
    if(searchTaskStatus.length === 0){
      request.status = null;
    }
    ((['SA','DA','TS'].indexOf(userInfo.role) > -1) && (searchFilters.userId === undefined)) ? request.userId = null : request.userId = userInfo.id;
      await postFetch("/dashboard/searchPreApproval",request).then((data)=>{
            if(!data.message){
              data.forEach((result)=>{
                result.status = configurations.reference.ReimbursementStatus.value.find((status)=>status.value === result.status).name;
              });
              let updatedData = {...searchData};
              updatedData.reimbData = [...data.filter((pre)=> pre.assignedTo !== null)];
              updatedData.reimbData.forEach((remData)=>{
                if(remData.status === 'In Progress'){
                  remData.assignedTo = '';
                }
              });
              setSearchData(updatedData);
            }
            else{
                setTimeout(()=>{                         
                  setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
                },1000);
            }
            }).catch((e)=>{
              setTimeout(()=>{                          
                setLoader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
              },1000);
            });
  }

  const faqOpenNewTab = () => {
    window.open("/faq");}

  return (
    <div className={classes.root}>
      <Loader loader={loader.loader} notification = {loader.notification} message={loader.message} action={loaderAction} validationMessage = {loader.validationMessage}/>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar style={{padding:0}}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="logo">
          <img className="headerImageDashboard" src = {matches ? Header : HeaderLogo} height='55px' alt="header"/>                   
          </IconButton>
          {matches ? (<div className="globalNav">
          <Button className={classes.button} color="inherit" startIcon={<HomeRoundedIcon className={classes.icon} />} onClick={()=>navigate("/dashboard")}>Home</Button>
          <Button  onClick={()=>{sessionStorage.setItem("isNavigFrmDash", "false");navigate("/search")}} className={classes.button} color="inherit" startIcon={<SearchRoundedIcon className={classes.icon2}/>}>Search</Button>
          { (['SA','DA','TS','DP','VA'].indexOf(userInfo.role) > -1) ? <Button className={classes.button} color="inherit" startIcon={<AssessmentRoundedIcon className={classes.icon2}/>} onClick={()=> window.open(Config.reportUrl)}>Reports</Button> : null}
          { (['SA','DA','DP','VA'].indexOf(userInfo.role) > -1) ?<Button className={classes.button} color="inherit" startIcon={<SettingsRoundedIcon className={classes.icon2}/>} onClick={()=>navigate("/config")}>Admin</Button> : null}
          <Button className={[classes.profile, "alignWelcomedrop"]} color="inherit" 
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle} 
          startIcon={<AccountCircleRoundedIcon className={classes.icon2}/>}
          endIcon={open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}>Welcome </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex:1600}}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.menuList}>
                      { (['EM','SA','DA','TS','DR','DP','VA'].indexOf(userInfo.role) > -1) ? <MenuItem onClick={()=>navigate("/appManager")}>
                          <ListItemIcon>
                            <ContactMailIcon fontSize="small"/>
                          </ListItemIcon>
                          App Manager
                        </MenuItem> : null}
                        { (['PR'].indexOf(userInfo.role) > -1) ? <MenuItem onClick={()=>navigate("/resetPassword")}>
                          <ListItemIcon>
                            <VpnKeyIcon fontSize="small"/>
                          </ListItemIcon>
                          Reset Password
                        </MenuItem> : null}
                        <MenuItem onClick={()=>faqOpenNewTab()}>
                          <ListItemIcon>
                            <HelpOutlineIcon fontSize="small" />
                          </ListItemIcon>
                          Help</MenuItem>
                        <MenuItem onClick={()=>logout()}>
                          <ListItemIcon>
                            <ExitToAppIcon fontSize="small" />
                          </ListItemIcon>
                          Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>) : (
            <div className="globalNav">
            <div color="inherit" className="globalNavButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
            <Button className={[classes.profile2, "alignWelcomedrop"]} color="inherit" 
              startIcon={<AccountCircleRoundedIcon className={classes.icon2}/>}
              endIcon={Boolean(anchorEl) ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}>Welcome </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                className={classes.menu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                style={{zIndex:1600}}
              >
                <MenuItem onClick={()=>navigate("/dashboard")}><ListItemIcon>
                        <HomeRoundedIcon fontSize="small"/>
                      </ListItemIcon>Home</MenuItem>
                <MenuItem onClick={()=>navigate("/search")}>
                <ListItemIcon>
                        <SearchRoundedIcon fontSize="small"/>
                      </ListItemIcon>Search</MenuItem>
                
                      { (['SA','DA','TS','DP','VA'].indexOf(userInfo.role) > -1) ? <MenuItem onClick={()=> window.open(Config.reportUrl)}><ListItemIcon>
                        <AssessmentRoundedIcon fontSize="small"/>
                      </ListItemIcon>Reports</MenuItem> : null }
                      { (['SA','DA','VA'].indexOf(userInfo.role) > -1) ?<MenuItem onClick={()=>navigate("/config")}><ListItemIcon>
                        <SettingsRoundedIcon fontSize="small"/>
                      </ListItemIcon>Admin</MenuItem> : null}
                      { (['EM','SA','DA','TS','DR','DP','VA'].indexOf(userInfo.role) > -1) ? <MenuItem onClick={()=>navigate("/appManager")}><ListItemIcon>
                        <ContactMailIcon fontSize="small"/>
                      </ListItemIcon>App Manager</MenuItem> : null }
                      { (['PR'].indexOf(userInfo.role) > -1) ? <MenuItem onClick={()=>navigate("/resetPassword")}>
                          <ListItemIcon>
                            <VpnKeyIcon fontSize="small"/>
                          </ListItemIcon>
                          Reset Password
                        </MenuItem> : null}
                <MenuItem onClick={()=>faqOpenNewTab()}>
                  <ListItemIcon>
                    <HelpOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  Help</MenuItem>
                <MenuItem onClick={()=>logout()}><ListItemIcon>
                        <ExitToAppIcon fontSize="small"/>
                </ListItemIcon>Logout</MenuItem>
              </Menu>
            </div>
            
          </div>
          ) }
        </Toolbar>
      </AppBar>
      {(localStorage.getItem("appId") === '2') ?
        <Switch>
          <Route exact path="/dashboard">
            <Box pt={8} px={2} style={{minHeight:"calc(100vh - 90px)"}}>
            {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <WorkerDashboard info = {configurations} onSearch={changeSearchFilters} userDetails={userInfo} onPreApprovalSearch={searchPreapproval}/>}
            </Box>
          </Route>
          <Route exact path="/search">
            <Box pt={2} px={2} style={{minHeight:"calc(100vh - 90px)"}}>
            {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <Search info = {configurations} onLoaderUpdate={updateLoader}  searchData={searchData} userInfo={userInfo} 
                          search={onSearch}
                          preFilters={filters}
                          supervisorData={supervisorData}
                          preReimbStatus={reimbStatus}
                          searchCriteria = {searchCriteria}
                          preTaskStatus={taskStatus}/>}
            </Box>
          </Route>
          <Route  path="/tr1">
            <Box pt="80px" px={2} style={{minHeight:"calc(100vh - 90px)"}}>
            {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <TR1Info pageInfo={pageInfo} userDetails={userInfo}/>}
            </Box>
          </Route>
          <Route  path="/preApproval">
            <Box pt="80px" px={2} style={{minHeight:"calc(100vh - 90px)"}}>
            {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <TR1Info userDetails={userInfo} supervisorData={supervisorData}/>}
            </Box>
          </Route>
          <Route  path="/config">
            <Box pt="80px" px={2} style={{minHeight:"calc(100vh - 90px)"}}>
            {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <Admin userDetails={userInfo}/>}
            </Box>
          </Route>
        </Switch> : 
         <Switch>
         <Route exact path="/dashboard">
           <Box pt={8} px={2} style={{minHeight:"calc(100vh - 90px)"}}>
           {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> :<VehicleDashboard onSearch={dashboardSearch} userDetails={userInfo} />}
           </Box>
         </Route>
         <Route exact path="/search">
           <Box pt={2} px={2} style={{minHeight:"calc(100vh - 90px)"}}>
           {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <VMSearch info = {configurations} onLoaderUpdate={updateLoader}  searchData={searchData} userInfo={userInfo} 
                          search={onSearch}
                          preFilters={filters}
                          supervisorData={supervisorData}
                          preResStatus={resStatus}
                          searchCriteria = {searchCriteria}
                          preTaskStatus={taskStatus}/>}
           </Box>
         </Route>
         <Route  path="/reservation">
            <Box pt="80px" px={2} style={{minHeight:"calc(100vh - 90px)"}}>
            {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <ReservationSummary pageInfo={pageInfo} userDetails={userInfo}/>}
            </Box>
          </Route>
         <Route  path="/config">
           <Box pt="80px" px={2} style={{minHeight:"calc(100vh - 90px)"}}>
           {(Cookies.get("state") === undefined) ? <Redirect to ="/workerportal"/> : <Admin userDetails={userInfo}/>}
           </Box>
         </Route>
       </Switch>}

        <AppBar position="relative" className={classes.footer}>
          <Toolbar style={{padding:0}}>
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <img className="footer" src={footer} height='60px' alt="footer"/>
                </Grid>
                <Grid item xs={12} sm={10}>
                <Grid container direction="row" style={{paddingTop:20}}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="button" className="decoratedfooterText" onClick={()=>window.open(window.location.origin+"/policy.pdf")} tabIndex="0">
                        Travel Policy Guidelines
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#privacy")} tabIndex="0">
                        Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#security")} tabIndex="0">
                        Security Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use")} tabIndex="0">
                        Acceptable Use
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://transparency.arkansas.gov/")} tabIndex="0">
                        Transparency
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:10}}>
                    <Typography variant="button" className="footerText" tabIndex="0">
                      Ⓒ{new Date().getFullYear()} State of Arkansas.All Rights Reserved.
                    </Typography>
                  </Grid>
                  
              </Grid>
              </Grid>
              </Grid>
          </Toolbar>
      </AppBar>
    </div>
  );
}