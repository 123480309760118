import React, {useState} from 'react';
import './App.css';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SecurityIcon from '@material-ui/icons/Security';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import DenseAppBar from './header';
import login from './imgs/login.png';
import loginProv from './imgs/login_prov.png';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {postFetch} from './http';
import Loader from './loader';
import {useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import logo from './imgs/logo2.png';
import footer from './imgs/footer.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

import Config from './assets/config';
import config from './assets/config';



function OTPFormat(props){
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="####-####"
      mask="_"
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input:{
    width:'95%',
    textAlign:'center'
  },
  logo:{
    textAlign:'center'
  },
  forgotPass:{
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  card:{
    height:'100%',
    padding:"10px 0"
  },
  list:{
    fontSize:'11px'
  },
  listItem:{
    padding:'5px'
  },
  icon:{
    fontSize: '20px',
    position: 'relative',
    top: '3px',
    marginRight: '5px'
  },
  footer:{
    minHeight: '80px',
    zIndex: 1350,
    bottom: 0,
    top: 'auto',
    textAlign:'center'
  },
  textLabel:{
    fontSize: '16px',
    fontWeight: '500'
  }
}));

const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;



function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    formData: {
      
        email: (Cookies.get("travelworks-email") === undefined) ? '' : JSON.parse(CryptoJS.AES.decrypt(Cookies.get("travelworks-email"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)),
        username: (Cookies.get("travelworks-username") === undefined) ? '' : JSON.parse(CryptoJS.AES.decrypt(Cookies.get("travelworks-username"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)),
        password: (Cookies.get("travelworks-password") === undefined) ? '' : JSON.parse(CryptoJS.AES.decrypt(Cookies.get("travelworks-password"),Config.secretPhrase).toString(CryptoJS.enc.Utf8)),
        otp:''
    },
    remember: false,
  });
  const [loader, setLoader] = useState({loader:false,notification:false,message:"success",validationMessage:"Success"});
  const [authenticated, setAuthenticated] = useState(false);
  const [otp, setOtp] = useState('');

  const handleChange=(event)=>{
    const { formData } = state;
    formData[event.target.name] = event.target.value;
    setState({...state, formData });
  }
  const setremember=(event)=>{
    setState({...state, remember:event.target.checked });
  }
  const rememberUser = async () => {
    try {
      //await localStorage.setItem('travelworks-email', state.formData.email);
      //await localStorage.setItem('travelworks-username', state.formData.username);
      //await localStorage.setItem('travelworks-password', state.formData.password);
      Cookies.set('travelworks-email',CryptoJS.AES.encrypt(JSON.stringify(state.formData.email),Config.secretPhrase));
      Cookies.set('travelworks-username',CryptoJS.AES.encrypt(JSON.stringify(state.formData.username),Config.secretPhrase));
      Cookies.set('travelworks-password',CryptoJS.AES.encrypt(JSON.stringify(state.formData.password),Config.secretPhrase));
    } catch (error) {
      // Error saving data
      setTimeout(()=>{
        setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"Unable to set Remember"});
      },1000);
    }
    };
    const removeUser = async () => {
        //localStorage.removeItem('travelworks-email');
        //localStorage.removeItem('travelworks-username');
        //localStorage.removeItem('travelworks-password');
        Cookies.remove('travelworks-email');
        Cookies.remove('travelworks-username');
        Cookies.remove('travelworks-password');
      }
  
  const handleSubmit= async ()=>{
    setLoader({...loader,loader:true,notification:false});
    //setNotification(true);
    const request= {
      "username":(window.location.pathname === '/providerportal') ? state.formData.email : state.formData.username,"password":state.formData.password,
      "providerSw":(window.location.pathname === '/providerportal') ? "Y":"N"
    };
    if(window.location.pathname === '/workerportal'){
      let key1 = CryptoJS.enc.Utf8.parse('49346A3CD8BFD3F9100B4CE9DAED72B1');
      let iv = CryptoJS.enc.Utf8.parse('9836565498764147');
      let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(state.formData.password), key1,
          {
              keySize: 128 / 8,
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
          });
      //console.log(encrypted.toString());
      request.password = encrypted.toString();
    }
    await postFetch("/auth/genToken",request).then((data)=>{
        if(data.token){
          if(data.isPasswordExpired){
            setTimeout(()=>{
              setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"This password has expired. Please reset your password by clicking 'Forgot Password'"});
            },1000);
          }
          else if(data.temPasswordSw === 'Y'){
            Cookies.set('state',CryptoJS.AES.encrypt(JSON.stringify(data),Config.secretPhrase));
            history.push("/resetPassword",data);
          }
          else{
            Cookies.set('state',CryptoJS.AES.encrypt(JSON.stringify(data),Config.secretPhrase));
            if(request.providerSw === 'Y'){
              //history.push("/dashboard",data);
              localStorage.setItem('appId', 2);
              if(Config.CheckMFA.toUpperCase() === "YES")
              {
                setAuthenticated(true);
                generateOtp();
              }else
              {
                history.push("/dashboard");
              }
              
            }
            else{
              history.push("/appManager",data);
            }
            if(state.remember){
              rememberUser();
            }
            else{
              removeUser();
            }
          }
        }
        else{
            setTimeout(()=>{
                setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message});
            },1000);
        }
    }).catch((e)=>{
      setTimeout(()=>{
        setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk."});
      },1000);
    });
  }

  const loaderAction = async()=>{
      setLoader({...loader,notification:false})
  }

  const validateOtp =()=>{
    if(state.formData.otp === otp){
      history.push("/dashboard");
    }
    else{
      setTimeout(()=>{
        setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"Invalid Passcode"});
      },0);
    }
  }

  const generateOtp = async()=>{
    setLoader({...loader,loader:true,notification:false});
    let otp = Array(8).fill("0123456789").map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
    console.log(otp);
    setOtp(otp);
    const request= {
      "username":(window.location.pathname === '/providerportal') ? state.formData.email : state.formData.username,"password":window.location.pathname,
      "providerSw":(window.location.pathname === '/providerportal') ? "Y":"N",
      "otp":otp.substring(0,4) + "-" + otp.slice(-4)
    };
    await postFetch("/auth/sendPasscode",request).then((data)=>{
      if(data){
        setTimeout(()=>{
          setLoader({...loader,loader:false,notification:false,message:"success"});
        },1000);
      }
      else{
          setTimeout(()=>{
              setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message});
          },1000);
      }
  }).catch((e)=>{
    setTimeout(()=>{
      setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk."});
    },1000);
  });
  }
  return (
    <Box   style={{backgroundColor:"#fff"}}>
    <Loader loader={loader.loader} notification = {loader.notification} message={loader.message} action={loaderAction} validationMessage = {loader.validationMessage}/>
    <div>
      <DenseAppBar></DenseAppBar>
      <Grid container direction="row"  spacing={0} style={{margin:"40px 0",minHeight:"calc(100vh - 246px)"}}>
          <Grid item xs={12} md={4} style={{textAlign:"Center",backgroundColor:'#fff'}}>
          <img  src={(window.location.pathname === '/providerportal') ? loginProv : login} height='calc(34vw)' width='75%' alt="Travel_login"/>
            
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper elevation={0} square className={classes.card}>
            <Box p={1}>
              <Typography variant="h6" className={classes.input}>
              <SecurityIcon className={classes.icon} tabIndex="0" />Security Usage Disclaimer
              </Typography>
            </Box>
            <List>
              <ListItem className={classes.listItem} tabIndex="0">
                <ListItemText
                  classes={{primary:classes.list}}
                  primary="This is a government computer system and is the property of the Arkansas Department of Human Services. It is for authorized use only. Users (authorized or unauthorized) have no explicit or implicit expectation of privacy. Any or all uses of this system and all files on this system may be intercepted, monitored, recorded, copied, audited, inspected, and disclosed to authorized site, Department of Human Services, and law enforcement personnel, as well as authorized officials of other agencies, both domestic and foreign."
                />
              </ListItem>
              <ListItem className={classes.listItem} tabIndex="0">
                <ListItemText
                  classes={{primary:classes.list}}
                  primary="By using this system, the user consents to such interception, monitoring, recording, copying, auditing, inspection, and disclosure at the discretion of authorized site or Department of Human Services personnel. Unauthorized or improper use of this system may result in administrative disciplinary action and civil and criminal penalties."
                />
              </ListItem>
              <ListItem className={classes.listItem} tabIndex="0">
                <ListItemText
                  classes={{primary:classes.list}}
                  primary="Unauthorized access is prohibited by Public Law 99-474 “The Computer Fraud and Abuse Act of 1986”. Unauthorized access, use, misuse, or modification of this computer system or of the data contained herein or in transit to/from this system constitutes a violation of Title 18, United States Code, Section 1030, and may subject the individual to Criminal and Civil penalties pursuant to Title 26, United States Code, Sections 7213, 7213A (the Taxpayer Browsing Protection Act), and 7431."
                />
              </ListItem>
              <ListItem className={classes.listItem} tabIndex="0">
                <ListItemText
                  classes={{primary:classes.list}}
                  primary="By continuing to use this system you indicate your awareness of and consent to these terms and conditions of use. Close Browser session IMMEDIATELY if you do not agree to the conditions stated in this warning."
                />
              </ListItem>
            </List>
            </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={2} style={{padding:"10px 5px",margin:"0 5px"}}>
        <ValidatorForm className={classes.root} onSubmit={ !authenticated ? handleSubmit : validateOtp}>
              <Box className={classes.input}>
               <img src={logo} height='50px' width='50px' alt="Logo"/>
               { !authenticated ? <Typography variant="h6" style={{color:'#2f2a47'}}>
                   Sign In to your account
                </Typography> :
                <Typography className={classes.textLabel}>Enter One Time Passcode sent to {state.formData.email.split('@')[0].substring(0,4)}....@..{state.formData.email.split('@')[1].slice(-3)}</Typography>
                }
              </Box>
              { !authenticated ? <div>
                {(window.location.pathname === '/providerportal') ? <span className={classes.textLabel}>Email Address</span> : <span className={classes.textLabel}>Username</span> }
              {(window.location.pathname === '/providerportal') ? <TextValidator
                variant="outlined"
                margin="normal"
                InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
                onChange={handleChange}
                id="email"
                name="email"
                autoComplete="email"
                size="small"
                error={!emailFormat.test(state.formData.email.toLowerCase())}
                className={`${classes.input} required`}
                value={state.formData.email}
                validators={['required', 'isEmail']}
                errorMessages={['Required field', 'Email is not valid']}
              /> : <TextValidator
              variant="outlined"
              margin="normal"
              InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
              onChange={handleChange}
              id="username"
              name="username"
              autoComplete="username"
              size="small"
              error={state.formData.username === null || state.formData.username === ''}
              className={`${classes.input} required`}
              value={state.formData.username}
              validators={['required']}
              errorMessages={['Required field']}
            />}
              <span className={classes.textLabel}>Password</span>
              <TextValidator
                variant="outlined"
                margin="normal"
                InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
                onChange={handleChange}
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                size="small"
                error={!(state.formData.password.length > 0)}
                className={`${classes.input} required`}
                value={state.formData.password}
                validators={['required']}
                errorMessages={['Required field']}
              />
              <FormControlLabel
                control={<Checkbox checked={state.remember} onChange={setremember} color="primary" />}
                label="Remember me"
                className={classes.input}
              />
              {(window.location.pathname === '/providerportal') ? <Typography variant="button" className={classes.forgotPass} onClick={()=> history.push("/resetPassword")}>
                Forgot Password ?
              </Typography> : null}
              <Button
                type="submit"
                
                variant="contained"
                color="primary"
                className={classes.input}
              >
                Sign In
              </Button>
              </div> : <div>
              <span className={classes.textLabel}>Passcode</span>
              <TextValidator
                variant="outlined"
                margin="normal"
                InputLabelProps={{ className:classes.floatingLabelFocusStyle}}
                InputProps={{
                  inputComponent: OTPFormat,
                }}
                onChange={handleChange}
                name="otp"
                id="otp"
                size="small"
                placeholder="____-____"
                error={!(state.formData.otp.length > 0)}
                className={`${classes.input} required`}
                value={state.formData.otp}
                validators={['required']}
                errorMessages={['Required field']}
              />
              <Typography variant="button" className={classes.forgotPass} onClick={()=>generateOtp()}>
                Resend Code
              </Typography>
              <Grid container direction="row" spacing={0} style={{maxWidth:"98%",marginTop:10}}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.input}
                    onClick={()=> setAuthenticated(false)}
                  >
                      Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.input}
                  >
                      Submit
                  </Button>
                </Grid>
              </Grid>
              </div>}
              
              </ValidatorForm>
              </Paper>
        </Grid>
      </Grid>
      <AppBar position="relative"  className={classes.footer}>
          <Toolbar style={{padding:0}}>
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <img className="footer" src={footer} height='60px' alt="footer"/>
                </Grid>
                <Grid item xs={12} sm={10}>
                <Grid container direction="row" style={{paddingTop:20}}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="button" className="decoratedfooterText" onClick={()=>window.open(window.location.origin+"/policy.pdf")} tabIndex="0">
                        Travel Policy Guidelines
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#privacy")} tabIndex="0">
                        Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use/#security")} tabIndex="0">
                        Security Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://portal.arkansas.gov/acceptable-use")} tabIndex="0">
                        Acceptable Use
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                  <Typography variant="button" className="decoratedfooterText" onClick={()=> window.open("https://transparency.arkansas.gov/")} tabIndex="0">
                        Transparency
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} style={{paddingTop:10}}>
                    <Typography variant="button" className="footerText" tabIndex="0">
                      Ⓒ{new Date().getFullYear()} State of Arkansas.All Rights Reserved.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} style={{paddingTop:10}}>
                    <Typography variant="button" className="footerText">
                      Version:R1.{config.version}
                    </Typography>
                  </Grid>
                  
              </Grid>
              </Grid>
              </Grid>
          </Toolbar>
      </AppBar>
    </div>
    </Box>
  );
}

export default Login;
