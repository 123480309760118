import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';*/
import Typography from '@material-ui/core/Typography';
/*import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from '../assets/config';*/
import { postFetch } from '../http';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
//import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
//import MileageExp from './mileageExp';
//import LodgingExpense from './lodgingExp';
//import MealsExp from './mealsExp';
//import CommonCarrierExp from './carrierExp';
//import IncidentalExp from './incidentalExp';
//import SummaryReconcile from './summaryReconcile';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import Table from '@material-ui/core/Table';
//import TableBody from '@material-ui/core/TableBody';
//import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NumberFormat from 'react-number-format';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Box from '@material-ui/core/Box';
import '../App.css';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: 600
    }
  },
  header: {
    backgroundColor: "#1a75c9",
    color: "#fff",
    padding: 10,
    fontWeight: 900,
    fontSize: 15
  },
  formControl: {
    width: "100%",
    marginTop: 2
  },
  filters: {
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
      color: "#000",
      fontSize: 17
    },
    margin: '10px 0',
    padding: '0 10px'
  },
  textfield: {
    padding: '10px 10px 10px 0',
    position: 'relative'
  },
  textLabel: {
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  datepicker: {
    position: 'relative',
    bottom: 7,
    padding: '0 10px 0 0'
  }
}));

const Subheader = ({ children }) => {
  return (
    <div className="wrapper">
      <p>{children}</p>
      <div className="line"></div>
    </div>
  )
}

function AmountFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      //maxLength='9' 
      allowNegative={false}
      // isAllowed={(values) => {
      //   const { floatValue } = values;
      //   return parseFloat(floatValue).toFixed(2) >= parseFloat(0).toFixed(0) && parseFloat(floatValue).toFixed(2) <= parseFloat(10000).toFixed(2);
      // }}
      prefix="$"
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      format="####"
    />
  );
}


export default function PreApproval(props) {
  const classes = useStyles();
  const [preApprovalDetails, setPreApprovalDetails] = React.useState({
    name: props.travellerInfo.firstName + ' ' + props.travellerInfo.lastName, purposeOfTrip: null, startDate: null, endDate: null, approvalType: [], travelType: null, destination: null,
    daysOfLodging: null, fullDaysMeals: null, partialDaysMeals: null, regAmount: null, orgSw: null, orgName: null, lodgingReason: null, workOutsideSchedule: null, workScheduleConsequences: null,
    assignedUser1: null, assignedUser2: null, assignedUser3: null, assignedUser4: null, assignedUser5: null, user1ApprovalDate: null, user2ApprovalDate: null, user3ApprovalDate: null, user4ApprovalDate: null,
    user5ApprovalDate: null, user1Status: null, user2Status: null, user3Status: null, user4Status: null, user5Status: null,
    assignedDate: null, status: 'IP', approvalDate: null, userId: null, division: null, state: null, city: null, expectedExpense: null, lodgingExpense:null, lodgingRate: null, fullMealRate: null, partialMealRate: null, user1approvalNote: null, user2approvalNote: null, user3approvalNote: null, user4approvalNote: null, user5approvalNote: null,
    carrierCost: null, orgReimbAmt: null, orgTravelReimbSw: null
  });
  const [error, setError] = React.useState(false);
  const history = useHistory();
  const [approvalData, setApprovalData] = React.useState([]);
  const [onInit, setOnInit] = React.useState(true);
  const [readOnly, setReadOnly] = React.useState(false);
  const [resetDetails, setResetDetails] = React.useState({
    name: props.travellerInfo.firstName + ' ' + props.travellerInfo.lastName, purposeOfTrip: null, startDate: null, endDate: null, approvalType: [], travelType: null, destination: null,
    daysOfLodging: null, fullDaysMeals: null, partialDaysMeals: null, regAmount: null, orgSw: null, orgName: null, lodgingReason: null, workOutsideSchedule: null, workScheduleConsequences: null,
    assignedUser1: null, assignedUser2: null, assignedUser3: null, assignedUser4: null, assignedUser5: null, user1ApprovalDate: null, user2ApprovalDate: null, user3ApprovalDate: null, user4ApprovalDate: null,
    user5ApprovalDate: null, user1Status: null, user2Status: null, user3Status: null, user4Status: null, user5Status: null,
    assignedDate: null, status: 'IP', approvalDate: null, userId: null, division: null, state: null, city: null, expectedExpense: null, lodgingExpense: null,lodgingRate: null, fullMealRate: null, partialMealRate: null, user1approvalNote: null, user2approvalNote: null, user3approvalNote: null, user4approvalNote: null, user5approvalNote: null,
    carrierCost: null, orgReimbAmt: null, orgTravelReimbSw: null
  });
  const [approvalError, setApprovalError] = React.useState(false);
  const [supervisorDetails, setSupervisorDetails] = React.useState([]);
  const [officeDirectorDetails, setOfficeDirectorDetails] = React.useState([]);
  const [approversDetails, setApproversDetails] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [showChiefOfStaff, setShowChiefOfStaff] = React.useState(false);
  const [cfoDivisions, setCfoDivisions] = React.useState([]);

  React.useEffect(() => {
    const loadPreApproval = async () => {
      props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
      setOnInit(false);
      const request = {
        id: parseInt(localStorage.getItem("preApproval"))
      }

      if (request.id != null) {
        await postFetch("/preApproval/findById", request).then((data) => {
          if (!data.message) {
            getCities(data.state);
            let updatedApprovalData = [];
            data.approvalType = data.approvalType.split(",");
            if (data.approvalType.indexOf('OS') > -1) {
              updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
              updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2 });
              updatedApprovalData.push({ title: "Division CFO", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DC', value: 3 });
              
              if((props.staticData.reference.ChiefOfStaffDivisions.value.filter((config)=> config.name.toString() === data.division.toString() && config.activeSW === "Y").length > 0))
              {
                updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
                setShowChiefOfStaff(true);
              }else
              {
                setShowChiefOfStaff(false);
              }
              // if(showChiefOfStaff)
              // {
              //   updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
              // }
              
            }
            if (data.approvalType.indexOf('EM') > -1) {
              updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1, isDisabled:false });
              updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2, isDisabled: false });
              updatedApprovalData.push({ title: "DHS CFO", submittedTo: null, submittedDate: null, approve: null, approvalDate: null, note: null, key: 'CF', value: 3, isDisabled: false });
            }
            if (data.approvalType.indexOf('OW') > -1) {
              updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
            }
            updatedApprovalData = [...new Map(updatedApprovalData.map(item => [item['key'], item])).values()];
            updatedApprovalData = updatedApprovalData.sort((a, b) => a.value - b.value);
            if ((data.status === 'RR') && (data.userId === props.userInfo.id)) {
              updatedApprovalData.forEach((approval, index) => {
                approval.submittedTo = data["assignedUser" + (index + 1)];
                approval.approve = data["user" + (index + 1) + "Status"];
                approval.note = data["user" + (index + 1) + "approvalNote"];
                approval.approvalDate = data["user" + (index + 1) + "ApprovalDate"];
                approval.assignedDate = data.assignedDate;
                
              });
              
            }
            else {
              let disabled = false;
              updatedApprovalData.forEach((approval, index) => {
                approval.submittedTo = data["assignedUser" + (index + 1)];
                approval.note = data["user" + (index + 1) + "approvalNote"];
                approval.approve = data["user" + (index + 1) + "Status"];
                approval.approvalDate = data["user" + (index + 1) + "ApprovalDate"];
                approval.assignedDate = data.assignedDate;
                if(index > 0 ) {
                  disabled = updatedApprovalData.filter(appr => approval.submittedTo == appr.submittedTo && !appr.approve).length > 1
                }else{
                  disabled = approval.approve != null
                }
                approval.isDisabled = disabled;
                
              });
            }
            data.startDate = convertDateFromServer(data.startDate);
            data.endDate = convertDateFromServer(data.endDate);
            setApprovalData([...updatedApprovalData]);
            setPreApprovalDetails({ ...data });
            setResetDetails({ ...data });
            if (data.status === 'SB' || data.status === 'AP') {
              setReadOnly(true);
            }
            
            setTimeout(() => {
              props.loader({ loader: false, notification: false, message: "success" });
            }, 1000);
          }
          else {
            setTimeout(() => {
              props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
            }, 1000);
          }

        }).catch((e) => {
          setTimeout(() => {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk.System Error: Please contact help desk." });
          }, 1000);
        });
      }
    }
    if (onInit && (localStorage.getItem("preApproval") !== undefined) && (localStorage.getItem("preApproval") !== null)) {
      getStates();
      getDivSupervisors();
      //getCFODivision();
      getOfficeDirectors();
      getApprovers();
      loadPreApproval();
      
    }
    if((localStorage.getItem("preApproval") === null || localStorage.getItem("preApproval") === undefined) && (props.staticData.reference.ChiefOfStaffDivisions.value.filter((config)=> config.name.toString() === props.travellerInfo.divisionCode.toString() && config.activeSW === "Y").length > 0))
    {
      setShowChiefOfStaff(true);
    }

  }, [props, onInit]);

  React.useEffect(() => {
    var inside = 0;
    //console.log('State updated:', preApprovalDetails.lodgingRate);

  },[preApprovalDetails])

  const getDivSupervisors = async () => {
    const details = props.userInfo.id;
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    await postFetch("/preApproval/getSupervisors", details).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: false, message: "success" });
          setSupervisorDetails([...data]);
        }, 1000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk."  });
      }, 1000);
    });
  }

  const getOfficeDirectors = async () => {
    const details = props.userInfo.id;
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    await postFetch("/preApproval/getDirectors", details).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: false, message: "success" });
          setOfficeDirectorDetails([...data]);
        }, 1000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk."  });
      }, 1000);
    });
  }

  const getApprovers = async () => {
    const details = props.travellerInfo.divisionCode;
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    await postFetch("/preApproval/getApproversByDivision", details).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: false, message: "success" });
          setApproversDetails([...data]);
        }, 1000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk."  });
      }, 1000);
    });
  }

  const getCFODivision = async () => {
    const details = props.userInfo.id;
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    await postFetch("/preApproval/getCFOUsersDivision", details).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: false, message: "success" });
          setCfoDivisions([...data]);
        }, 1000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk."  });
      }, 1000);
    });
  }

  const renderNotesData = (row, column, index) => {
    return (<div>
      {(row.submittedTo !== null && row.submittedTo !== undefined) ? <FormControl component="fieldset">
        <TextField
          id="notes"
          variant="outlined"
          size="small"
          style={{ width: 350 }}
          inputProps={{
            maxLength: 80,
          }}
          //className = {readOnly ? "disabled" : "required"}
          disabled={((row.submittedTo.toString() !== props.userInfo.id.toString()) || (preApprovalDetails.userId === props.userInfo.id))}
          value={row.note === null ? '' : row.note}
          onChange={(e) => changeApprovalDetails(column, e.target.value, index)}

        />

      </FormControl> : <TextField
          id="notes"
          variant="outlined"
          size="small"
          style={{ width: 350 }}
          disabled

        />}
      {((row.submittedTo === props.userInfo.id) &&(!row.isDisabled) &&(row.approve === 'N') && (row.note === '' || row.note === null) && readOnly && approvalError) ? <FormHelperText error>Required Field</FormHelperText>
        : null}

    </div>);
  }

  const renderSupervisorData = (row, column, index) => {
    return (<div>      
       {row.title === 'Immediate/Travel Supervisor' ? 
        <Autocomplete
          id="assigned-to"
          options={preApprovalDetails.status === 'IP' ? supervisorDetails.slice().filter((user) => user.id !== props.userInfo.id) : supervisorDetails}
          getOptionLabel={(option) => option.details}
          value={(row.submittedTo  !== null) ? props.supervisorData.find((sd)=> sd.id === row.submittedTo ) : {details:'',id:null}}
          //value={(row.submittedTo  !== null && (row.submittedTo === props.userInfo.id && preApprovalDetails.status === 'IP')) ? {details:'',id:null} : row.submittedTo  !== null  ? props.supervisorData.find((sd)=> sd.id === row.submittedTo ) : {details:'',id:null}}
          onChange={(event, value) => value !== null ? changeApprovalDetails(column, value.id, index) : changeApprovalDetails(column, null, index)}
          className={readOnly ? `${classes.formControl} required` : "required"}
          disabled={readOnly}
          renderOption={(option) =>
            <span style={{ fontWeight: 600 }}>{option.details}</span>
          }
          size="small"
          renderInput={(params) => <TextField id="re-assign" variant="outlined" size="small" {...params}
            placeholder="Search User"
            //error={row.submittedTo === '' || row.submittedTo === null || row.submittedTo === undefined}
            className={readOnly ? `${classes.formControl} required` : "required"}
            disabled={readOnly}
            value={row.submittedTo === null ? '' : row.submittedTo}
            onChange={(value) => changeApprovalDetails(column, value.id, index)}
            helperText={((row.submittedTo === '' || row.submittedTo === null || row.submittedTo === undefined)  && approvalError) ? "Required Field" : null}
          />}
        /> : null}
        {/* {row.title === 'Office Chief/Division Director' ?
        <Autocomplete
        id="assigned-to"
        options={preApprovalDetails.status === 'IP' ? officeDirectorDetails.slice().filter((user) => user.id !== props.userInfo.id && user.role === 'SA' && user.isOfficeDirector === 'Y') : officeDirectorDetails.slice().filter((user) =>  user.isOfficeDirector === 'Y')}
        getOptionLabel={(option) => option.details}
        value={officeDirectorDetails.slice().filter((sd) => sd.id.toString() === row.submittedTo.toString() )}
        //value={(row.submittedTo  !== null) ? officeDirectorDetails.slice().filter((sd) => sd.id.toString() === row.submittedTo.toString() ) : {details:'',id:null}}
        //value={(row.submittedTo  !== null && (row.submittedTo === props.userInfo.id && preApprovalDetails.status === 'IP')) ? {details:'',id:null} : row.submittedTo  !== null  ? props.supervisorData.find((sd)=> sd.id === row.submittedTo ) : {details:'',id:null}}
        //value={row.submittedTo === null ? 0 : row.submittedTo}
        onChange={(event, value) => value !== null ? changeApprovalDetails(column, value.id, index) : changeApprovalDetails(column, null, index)}
        className={readOnly ? `${classes.formControl} required` : "required"}
        disabled={readOnly}
        renderOption={(option) =>
          <span style={{ fontWeight: 600 }}>{option.details}</span>
        }
        size="small"
        renderInput={(params) => <TextField id="re-assign" variant="outlined" size="small" {...params}
          placeholder="Search User"
          //error={row.submittedTo === '' || row.submittedTo === null || row.submittedTo === undefined}
          className={readOnly ? `${classes.formControl} required` : "required"}
          disabled={readOnly}
          value={row.submittedTo === null ? '' : row.submittedTo}
          onChange={(value) => changeApprovalDetails(column, value.id, index)}
          helperText={((row.submittedTo === '' || row.submittedTo === null || row.submittedTo === undefined)  && approvalError) ? "Required Field" : null}
        />}
      /> : null}  */}

      {row.title === 'Office Chief/Division Director' ?
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <Select
            id="assigned-to"
            value={row.submittedTo === null ? 0 : row.submittedTo}
            //value={(row.submittedTo  !== null && (row.submittedTo === props.userInfo.id && preApprovalDetails.status === 'IP')) ? 0 : row.submittedTo  !== null ? row.submittedTo : 0}
            className={readOnly ? "disabled" : "required"}
            disabled={readOnly}
            onChange={(e) => changeApprovalDetails(column, e.target.value, index)}
          >
            <MenuItem value={0}>Pick an Option</MenuItem>
            {officeDirectorDetails.map((method) => preApprovalDetails.status === 'IP' && method.id === props.userInfo.id ? null : (
              
              <MenuItem key={method.id} value={method.id}>{method.details}</MenuItem>
            ))}
          </Select>
          {((row.submittedTo === 0 || row.submittedTo === null) && approvalError) ? <FormHelperText>Required Field</FormHelperText>
            : null}
        </FormControl> : null}

      {row.title === 'DHS CFO' ?
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <Select
            id="cfo-sup"
            value={row.submittedTo === null ? 0 : row.submittedTo}
            //value={(row.submittedTo  !== null && (row.submittedTo === props.userInfo.id && preApprovalDetails.status === 'IP')) ? 0 : row.submittedTo  !== null ? row.submittedTo : 0}
            className={readOnly ? "disabled" : "required"}
            disabled={readOnly}
            onChange={(e) => changeApprovalDetails(column, e.target.value, index)}
          >
            <MenuItem value={0}>Pick an Option</MenuItem>
            {props.staticData.dropdown.DHSCFOStaff.value.map((method) => (
              <MenuItem key={method.value} value={method.value}>{method.fullName}</MenuItem>
            ))}
          </Select>
          {((row.submittedTo === 0 || row.submittedTo === null) && approvalError) ? <FormHelperText>Required Field</FormHelperText>
            : null}
        </FormControl> : null}

        {row.title === 'Division CFO' ?
          <FormControl variant="outlined" size="small" className={classes.formControl}>
          <Select
            id="cfo-sup"
            value={row.submittedTo === null ? 0 : row.submittedTo}
            //value={(row.submittedTo  !== null && (row.submittedTo === props.userInfo.id && preApprovalDetails.status === 'IP')) ? 0 : row.submittedTo  !== null ? row.submittedTo : 0}
            className={readOnly ? "disabled" : "required"}
            disabled={readOnly}
            onChange={(e) => changeApprovalDetails(column, e.target.value, index)}
          >
            {/* {console.log(cfoDivisions.slice().filter((user) => user.id.toString() === "27").map((m => m.division)))} */}
            <MenuItem value={0}>Pick an Option</MenuItem>
            {props.staticData.dropdown.DivisionCFO.value.map((method) => (
              
              <MenuItem key={method.value} value={method.value}>{method.fullName} - {props.staticData.dropdown.CFOAssignedDivisions.value.slice().filter((user) => user.value.toString() === method.value).map((m => m.name))}</MenuItem>
            ))}
          </Select>
          {((row.submittedTo === 0 || row.submittedTo === null) && approvalError) ? <FormHelperText>Required Field</FormHelperText>
            : null}
        </FormControl> : null}
        {row.title === 'Chief of Staff' ?
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <Select
            id="cfo-sup"
            value={row.submittedTo === null ? 0 : row.submittedTo}
            //value={(row.submittedTo  !== null && (row.submittedTo === props.userInfo.id && preApprovalDetails.status === 'IP')) ? 0 : row.submittedTo  !== null ? row.submittedTo : 0}
            className={readOnly ? "disabled" : "required"}
            disabled={readOnly}
            onChange={(e) => changeApprovalDetails(column, e.target.value, index)}
          >
            <MenuItem value={0}>Pick an Option</MenuItem>
            {props.staticData.dropdown.ChiefOfStaff.value.map((method) => (
              <MenuItem key={method.value} value={method.value}>{method.fullName}</MenuItem>
            ))}
          </Select>
          {((row.submittedTo === 0 || row.submittedTo === null) && approvalError) ? <FormHelperText>Required Field</FormHelperText>
            : null}
        </FormControl> : null}
    </div>);
  }

  const renderCheckBox = (row, column, index) => {
    return (<div>
      {(row.submittedTo !== null && row.submittedTo !== undefined) ? <FormControl component="fieldset">
        <RadioGroup row value={row.approve} onChange={(e) => changeApprovalDetails(column, e.target.value, index)}>
          <div className={classes.radioButton}>
            <FormControlLabel value="Y" control={<Radio disabled={((row.submittedTo.toString() !== props.userInfo.id.toString()) || (preApprovalDetails.userId === props.userInfo.id)) || row.isDisabled} color="primary" />} label="Yes" />
            <FormControlLabel value="N" control={<Radio disabled={((row.submittedTo.toString() !== props.userInfo.id.toString()) || (preApprovalDetails.userId === props.userInfo.id)) || row.isDisabled} color="primary" />} label="No" />
          </div>
        </RadioGroup>
      </FormControl> : <span>
          <RadioGroup row value={row.approve} onChange={(e) => changeApprovalDetails(column, e.target.value, index)}>
            <div className={classes.radioButton}>
              <FormControlLabel value="Y" control={<Radio checked={(index === 0 ? preApprovalDetails.user1Status : index === 1 ? preApprovalDetails.user2Status : index === 2 ? preApprovalDetails.user3Status : index === 3 ? preApprovalDetails.user4Status : preApprovalDetails.user5Status) === 'Y'} disabled color="primary" />} label="Yes" />
              <FormControlLabel value="N" control={<Radio checked={(index === 0 ? preApprovalDetails.user1Status : index === 1 ? preApprovalDetails.user2Status : index === 2 ? preApprovalDetails.user3Status : index === 3 ? preApprovalDetails.user4Status : preApprovalDetails.user5Status) === 'N'} disabled color="primary" />} label="No" />
            </div>
          </RadioGroup>


        </span>}
      {((row.submittedTo === props.userInfo.id) && (!row.isDisabled) && (row.approve === '' || row.approve === null) && readOnly && approvalError) ? <FormHelperText error>Required Field</FormHelperText>
        : null}
    </div>);
  }

  const findSuperisorName = () => {

  }

  const changeApprovalDetails = (key, value, index) => {
    let newDetails = [...approvalData];
    newDetails[index][key] = value;
    if (key === 'approve' && value === 'Y') {
      newDetails[index]["approvalDate"] = moment().format("YYYY-MM-DD");
    }
    else if (key === 'approve' && value === 'N') {
      newDetails[index]["approvalDate"] = moment().format("YYYY-MM-DD");
    }
    setApprovalData(newDetails);
  }

  const approvalColumns = [
    {
      name: "Title",
      field: "title",
      type: "text"
    },
    {
      name: "Submitted To",
      field: "submittedTo",
      type: "input",
      renderView: renderSupervisorData
    },
    {
      name: "Approve",
      field: "approve",
      type: "input",
      renderView: renderCheckBox
    },
    {
      name: "Approval/Returned Notes (80 Character Limit)",
      field: "note",
      type: "input",
      renderView: renderNotesData
    }
  ];

  function convertDateFromServer(value) {
    if ((value !== null) && !(value instanceof Date)) {
      let serverDateArray = value.split("-");
      if (serverDateArray.length > 1) {
        return serverDateArray[1] + "/" + serverDateArray[2] + "/" + serverDateArray[0];
      }
      else {
        return serverDateArray[0];
      }
    }
    else if (value instanceof Date) {
      return value;
    }
    else {
      return null;
    }

  }

  const reset = async (key, value) => {
    setError(false);
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    setOnInit(false);
    const request = {
      id: parseInt(localStorage.getItem("preApproval"))
    }
    if (request.id !== null && !isNaN(parseFloat(request.id))) {
      await postFetch("/preApproval/findById", request).then((data) => {
        if (!data.message) {
          let updatedApprovalData = [];
          data.approvalType = data.approvalType.split(",");
          if (data.approvalType.indexOf('OS') > -1) {
            updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
            updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2 });
            updatedApprovalData.push({ title:
              "Division CFO", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DC', value: 3 });
            //updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
            if((props.staticData.reference.ChiefOfStaffDivisions.value.filter((config)=> config.name.toString() === data.division.toString() && config.activeSW === "Y").length > 0))
            {
              updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
            }
          }
          if (data.approvalType.indexOf('EM') > -1) {
            updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 , isDisabled: false});
            updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2, isDisabled:false });
            updatedApprovalData.push({ title: "DHS CFO", submittedTo: null, submittedDate: null, approve: null, note: null, approvalDate: null, key: 'CF', value: 3, isDisabled:false });
          }
          if (data.approvalType.indexOf('OW') > -1) {
            updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
          }
          updatedApprovalData = [...new Map(updatedApprovalData.map(item => [item['key'], item])).values()];
          updatedApprovalData = updatedApprovalData.sort((a, b) => a.value - b.value);
          if ((data.status === 'RR') && (data.userId === props.userInfo.id)) {
            console.log("pre assigned is cleared");
          }
          else {
            updatedApprovalData.forEach((approval, index) => {
              approval.submittedTo = data["assignedUser" + (index + 1)];
              approval.note = data["user" + (index + 1) + "approvalNote"];
              approval.approve = data["user" + (index + 1) + "Status"];
              approval.approvalDate = data["user" + (index + 1) + "ApprovalDate"];
              approval.assignedDate = data.assignedDate;
              if(index > 0 ) {
                const disabled = updatedApprovalData.filter(appr => approval.submittedTo == appr.submittedTo).length > 1
                approval.isDisabled = disabled;
              }
            
            });
          }
          data.startDate = convertDateFromServer(data.startDate);
          data.endDate = convertDateFromServer(data.endDate);
          setApprovalData([...updatedApprovalData]);
          setPreApprovalDetails({ ...data });
          setResetDetails({ ...data });
          setTimeout(() => {
            props.loader({ loader: false, notification: false, message: "success" });
          }, 1000);
        }
        else {
          setTimeout(() => {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
          }, 1000);
        }
      }).catch((e) => {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
        }, 1000);
      });
    }
    else {
      let ap = [];
      let updatedApprovalData = [];
      setApprovalData([]);
      ap = value.toString().split(',');
      if (ap.indexOf('OS') > -1){
        updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
        updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2 });
        updatedApprovalData.push({ title: "Division CFO", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DC', value: 3 });
       // updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
       if(showChiefOfStaff)
        {
          updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
        }
      }
      if (ap.indexOf('EM') > -1){
        updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
        updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2 });
        updatedApprovalData.push({ title: "DHS CFO", submittedTo: null, submittedDate: null, approve: null, note: null, approvalDate: null, key: 'CF', value: 3 });
     
      }
      if (ap.indexOf('OW') > -1){ 
        updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
      }
      updatedApprovalData = [...new Map(updatedApprovalData.map(item => [item['key'], item])).values()];
      updatedApprovalData = updatedApprovalData.sort((a, b) => a.value - b.value);
      
     //updatedApprovalData.push({ title: "DHS CFO", submittedTo: null, submittedDate: null, approve: null, note: null, approvalDate: null, key: 'CF', value: 3 });
      setApprovalData(updatedApprovalData);
      let newDet = { ...resetDetails };
      newDet.approvalType = ap;
      newDet.lodgingRate = 0;
      setPreApprovalDetails(newDet);
      setTimeout(() => {
        props.loader({ loader: false, notification: false, message: "error", validationMessage: "System Error: Please contact help desk." });
      }, 500);
    }
  }
  const changePreapprovalDetails = (key, value) => {
    let newDetails = { ...preApprovalDetails };
    let updatedApprovalData = [];
    newDetails.userId = props.userInfo.id;
    newDetails.division = props.travellerInfo.divisionCode;

    if (key === 'startDate' && (value !== null) && (newDetails.endDate !== null) && (value.setHours(0, 0, 0, 0) > new Date(new Date(newDetails.endDate).toDateString()).setHours(0, 0, 0))) {
      value = null;
      setTimeout(() => {
      props.loader({ loader: false, notification: true, message: "error", validationMessage: 'The Travel Start Date cannot be past the Travel End Date.' });
      }, 1000);
    }
    if (key === 'endDate' && (value !== null) && (newDetails.startDate !== null) && (value.setHours(0, 0, 0, 0) < new Date(new Date(newDetails.startDate).toDateString()).setHours(0, 0, 0))) {
        value = null;
        setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: 'The Travel End Date cannot be prior to Travel Start Date.' });
      }, 1000);
    }
    if (key === 'approvalType' && (newDetails.approvalType.indexOf(value) === -1)) {
      newDetails.approvalType.push(value);
    }
    else if (key === 'approvalType' && (value.indexOf('OS') > -1)) {
      newDetails.approvalType = newDetails.approvalType.filter((type) => type !== value);
      newDetails.state = 0;
      newDetails.city = 0;
      newDetails.daysOfLodging = '0';
      newDetails.lodgingRate = null;
      newDetails.fullDaysMeals = null;
      newDetails.fullMealRate = 0;
      newDetails.partialDaysMeals = null;
      newDetails.partialMealRate = 0;
      newDetails.regAmount = null;
      newDetails.orgSw = 0;
      newDetails.regFeeAmt = '';
      newDetails.travelType = 0;
      newDetails.expectedExpense = 0;
      newDetails.orgReimbAmt = null;
      newDetails.orgTravelReimbSw = 0;
    } else if (key === 'approvalType' && (value.indexOf('EM') > -1)) {
      newDetails.approvalType = newDetails.approvalType.filter((type) => type !== value);
      newDetails.lodgingReason = 0;
      newDetails.lodgingExpense = null;
    } else if (key === 'approvalType' && (value.indexOf('OW') > -1)) {
      newDetails.approvalType = newDetails.approvalType.filter((type) => type !== value);
      newDetails.workOutsideSchedule = '';
      newDetails.workScheduleConsequences = '';
    }else {
      newDetails[key] = value;
    }

    if(key === 'approvalType' || key === 'city' || key === 'orgSw') {
      getStates();
      } 

    if (key === 'state' && value !== null) {
      newDetails.city = null;
      getCities(value);
    }

    // if(key === 'orgTravelReimbSw' && value === 'N')
    // {
    //   newDetails.orgReimbAmt = null;
    // }
    
    //newDetails.startDate = new Date(new Date(newDetails.startDate).toDateString());
    //newDetails.endDate = new Date(new Date(newDetails.endDate).toDateString());
    //newDetails.daysOfLodging = (newDetails.endDate !== null && newDetails.startDate !== null) ? (moment(new Date(new Date(newDetails.endDate).toDateString()).setHours(0,0,0,0)).diff(moment(new Date(new Date(newDetails.startDate).toDateString()).setHours(0,0,0,0)), "days") === 0 ? 1 : moment(new Date(new Date(newDetails.endDate).toDateString())).diff(moment(new Date(new Date(newDetails.startDate).toDateString())), "days")) : 0;
    //newDetails.fullDaysMeals = newDetails.daysOfLodging === null ? 0 : newDetails.daysOfLodging === 0 || newDetails.daysOfLodging === 1 ? 0 :newDetails.daysOfLodging - 1;

    newDetails.daysOfLodging = (newDetails.endDate !== null && newDetails.startDate !== null) ? (moment(new Date(new Date(newDetails.endDate).toDateString()).setHours(0, 0, 0, 0)).diff(moment(new Date(new Date(newDetails.startDate).toDateString()).setHours(0, 0, 0, 0)), "days") === 0 ? 0 : moment(new Date(new Date(newDetails.endDate).toDateString())).diff(moment(new Date(new Date(newDetails.startDate).toDateString())), "days")) : 0;
    newDetails.fullDaysMeals = newDetails.daysOfLodging === null ? 0 : newDetails.daysOfLodging === 0 || newDetails.daysOfLodging === 1 ? 0 : newDetails.daysOfLodging - 1;
    let isZerodays = false;
    if ((newDetails.endDate !== null && newDetails.startDate !== null)) {
      isZerodays = moment(new Date(new Date(newDetails.endDate).toDateString()).setHours(0, 0, 0, 0)).diff(moment(new Date(new Date(newDetails.startDate).toDateString()).setHours(0, 0, 0, 0)), "days") === 0;
    }

    //newDetails.partialDaysMeals = newDetails.daysOfLodging === null ? 0 : isZerodays ? 1 : newDetails.daysOfLodging >= 2 ? 2 : newDetails.daysOfLodging + 1;
    newDetails.partialDaysMeals = newDetails.daysOfLodging === null || newDetails.daysOfLodging === 0 ? 0 : isZerodays ? 0 : 2;
    
    let lodgingRate, fullMealRate, partialMealRate;
    if (newDetails.startDate !== null && newDetails.endDate !== null && newDetails.state !== null && newDetails.city !== null && ["startDate", "endDate","state", "city"].indexOf(key) > -1 && moment(newDetails.startDate, 'MM/DD/YYYY').isValid() && moment(newDetails.endDate, 'MM/DD/YYYY').isValid()) {
      let request = {
        expenseStartDate: moment(newDetails.startDate).format("YYYY-MM-DD"),
        expenseEndDate: moment(newDetails.endDate).format("YYYY-MM-DD"),
        internalOrder: newDetails.state,
        costCenter: newDetails.city
      }
      props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
      postFetch("/reimbursement/getLodRates", request).then((data) => {
        if (!data.message) {
          props.loader({ loader: false, notification: false, message: "success" });
          //newDetails.allowedRateWoTax = parseFloat(data.lodgingRate) * parseFloat(newDetails.noDays);
          lodgingRate = parseFloat(data.lodingRatePerNight).toFixed(2);
          fullMealRate = parseFloat(data.mealRatePerDay).toFixed(2);
          partialMealRate = (parseFloat(0.75) * fullMealRate).toFixed(2);
          newDetails.lodgingRate = lodgingRate * 1;
          newDetails.fullMealRate = fullMealRate * 1;
          newDetails.partialMealRate = partialMealRate * 1;
          //setPreApprovalDetails(newDetails);
          let regFeeAmt = 0;
          if (newDetails.orgSw === 'N' && (newDetails.regAmount !== null || newDetails.regAmount !== undefined)) {
            if (isNaN(parseFloat(newDetails.regAmount))) {
              regFeeAmt = 0;
            } else {
              regFeeAmt = newDetails.regAmount * 1;
            }
          }
    
          let carrierAmt = 0;
          if (newDetails.carrierCost !== null && newDetails.carrierCost !== undefined) {
            if (isNaN(parseFloat(newDetails.carrierCost))) {
              carrierAmt = 0;
            } else {
              carrierAmt = newDetails.carrierCost * 1;
            }
          }
    
          let totalOrgReimbAmt = 0;          
          if (newDetails.orgTravelReimbSw === 'Y' && (newDetails.orgReimbAmt !== null && newDetails.orgReimbAmt !== undefined)) {
            if (isNaN(parseFloat(newDetails.orgReimbAmt))) {
              totalOrgReimbAmt = 0;
            } else {
              totalOrgReimbAmt = newDetails.orgReimbAmt * 1;
            }
          }  

        

          newDetails.expectedExpense = parseFloat((newDetails.daysOfLodging * lodgingRate) + (newDetails.fullDaysMeals * fullMealRate) + (newDetails.partialDaysMeals * partialMealRate) + regFeeAmt + carrierAmt - totalOrgReimbAmt).toFixed(2);
          
          //preApprovalDetails["expectedExpense"] = totalExpense;
          //this.setPreApprovalDetails({expectedExpense: newDetails.expectedExpense});
          if (newDetails.orgSw === 'N') {
            newDetails.orgName = '';
          }
          
        }
        else {
          setTimeout(() => {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
          }, 1000);
        }
      }).catch((e) => {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
        }, 1000);
      });
      
    }


    if(( key === "orgReimbAmt" || key === "carrierCost" || key === "regAmount" || key === "orgTravelReimbSw" || key === "orgSw" || key === "startDate" || key === "endDate" || key === "city"))
    {
      let regFeeAmt = 0;
      if (newDetails.orgSw === 'N' && (newDetails.regAmount !== null || newDetails.regAmount !== undefined)) {
        if (isNaN(parseFloat(newDetails.regAmount))) {
          regFeeAmt = 0;
        } else {
          regFeeAmt = newDetails.regAmount * 1;
        }
      }

      let carrierAmt = 0;
      if (newDetails.carrierCost !== null && newDetails.carrierCost !== undefined) {
        if (isNaN(parseFloat(newDetails.carrierCost))) {
          carrierAmt = 0;
        } else {
          carrierAmt = newDetails.carrierCost * 1;
        }
      }

      let totalOrgReimbAmt = 0;          
      if (newDetails.orgTravelReimbSw === 'Y' && (newDetails.orgReimbAmt !== null && newDetails.orgReimbAmt !== undefined)) {
        if (isNaN(parseFloat(newDetails.orgReimbAmt))) {
          totalOrgReimbAmt = 0;
        } else {
          totalOrgReimbAmt = newDetails.orgReimbAmt * 1;
        }
      }  
      
      let totalLessThanZero = parseFloat((newDetails.daysOfLodging * newDetails.lodgingRate) + (newDetails.fullDaysMeals * newDetails.fullMealRate) + (newDetails.partialDaysMeals * newDetails.partialMealRate) + regFeeAmt + carrierAmt - totalOrgReimbAmt).toFixed(2) < 0;
      if(key === "orgTravelReimbSw" && value === 'Y' && totalLessThanZero)
      {
        totalOrgReimbAmt = 0;
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "Entered Total Expense Amount Reimbursed by Sponsoring Organization cannot exceed Total Expected Expense Amount" });
  
        }, 1000);
        newDetails.orgReimbAmt = null;
      }
      if(key === "orgReimbAmt" && value !== null && newDetails.orgTravelReimbSw === 'Y' && totalLessThanZero)
      {
        totalOrgReimbAmt = 0;
      }
      newDetails.expectedExpense = parseFloat((newDetails.daysOfLodging * newDetails.lodgingRate) + (newDetails.fullDaysMeals * newDetails.fullMealRate) + (newDetails.partialDaysMeals * newDetails.partialMealRate) + regFeeAmt + carrierAmt - totalOrgReimbAmt).toFixed(2);
      
      if(newDetails.orgTravelReimbSw === 'N')
      {
        newDetails.orgReimbAmt = null;  
      }

      if (newDetails.orgSw === 'N') {
        newDetails.orgName = '';
      }
      
      // if(newDetails.orgTravelReimbSw === 'Y')
      // {
      //   newDetails.expectedExpense = parseFloat(newDetails.expectedExpense) - totalOrgReimbAmt;
      // }else
      // {
      //   newDetails.orgReimbAmt = null;
      //   newDetails.expectedExpense = parseFloat(newDetails.expectedExpense) + totalOrgReimbAmt;
      // }
    }

    // if((key === "orgTravelReimbSw"))
    // {
    //   let totalOrgReimbAmt = 0;          
    //    if (newDetails.orgReimbAmt !== null && newDetails.orgReimbAmt !== undefined) {
    //     if (isNaN(parseFloat(newDetails.orgReimbAmt))) {
    //       totalOrgReimbAmt = 0;
    //     } else {
    //       totalOrgReimbAmt = newDetails.orgReimbAmt * 1;
    //     }
    //   }
    //   if(newDetails.orgTravelReimbSw === 'Y')
    //   {
    //     newDetails.expectedExpense = parseFloat(newDetails.expectedExpense) - totalOrgReimbAmt;
    //   }else
    //   {
    //     newDetails.orgReimbAmt = null;
    //     newDetails.expectedExpense = parseFloat(newDetails.expectedExpense) + totalOrgReimbAmt;
    //   }
      
      
    // }


    if (key === 'approvalType') {
      getDivSupervisors();
      getOfficeDirectors();
      getApprovers();
      //getCFODivision();
      
      if (newDetails.approvalType.indexOf('OS') > -1) {
        updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
        updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2 });
        updatedApprovalData.push({ title: "Division CFO", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DC', value: 3 });
        //updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
        if(showChiefOfStaff)
        {
          updatedApprovalData.push({ title: "Chief of Staff", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'CS', value: 4 });
        }
      }
      if (newDetails.approvalType.indexOf('EM') > -1) {
        updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
        updatedApprovalData.push({ title: "Office Chief/Division Director", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'DD', value: 2 });
        updatedApprovalData.push({ title: "DHS CFO", submittedTo: null, submittedDate: null, approve: null, note: null, approvalDate: null, key: 'CF', value: 3 });
      }
      if (newDetails.approvalType.indexOf('OW') > -1) {
        updatedApprovalData.push({ title: "Immediate/Travel Supervisor", submittedTo: null, assignedDate: null, approve: null, note: null, approvalDate: null, key: 'IS', value: 1 });
      }
      updatedApprovalData = [...new Map(updatedApprovalData.map(item => [item['key'], item])).values()];
      updatedApprovalData = updatedApprovalData.sort((a, b) => a.value - b.value);
      setApprovalData(updatedApprovalData);
    }

    setPreApprovalDetails(newDetails);


  }

  const validatePreApproval = (key, value) => {
    let newDetails = { ...preApprovalDetails };
    
    if (key === 'regAmount' && (value !== "")) {
      //value.replace("$","");
      value = value.replace("$", "").replace(",", "");
      if(((value.length > 7) || parseFloat(value).toFixed(2) >= parseFloat(10000)))
      {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "Registration Fee cannot exceed $9999.99" });

        }, 1250);
        value = null;
      }
    }
    if (key === 'carrierCost' && (value !== "")) {
      //value.replace("$","");
      value = value.replace("$", "").replace(",", "");
      if(((value.length > 8) || parseFloat(value).toFixed(2) >= parseFloat(100000)))
      {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "Estimated Carrier Costs can not exceed $99,999.99" });

        }, 1250);
        value = null;
      }
    }
    if (key === 'orgReimbAmt' && (value !== "")) {
      //value.replace("$","");
      value = value.replace("$", "").replace(",", "");
      if((value.length > 8) || parseFloat(value).toFixed(2) >= parseFloat(100000))
      {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "Total Expense Amount Reimbursed by Sponsoring Organization can not exceed $99,999.99" });

        }, 1250);
        value = null;
      }
    }
    if (key === 'lodgingExpense' && (value !== "")) {
      //value.replace("$","");
      value = parseFloat(value.replace("$", "").replace(",", "")).toFixed(2);
    }
   
    if (key === 'lodgingExpense' && (value !== '') && (parseFloat(value).toFixed(2) >= parseFloat(10000))) {

      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Lodging expense cannot exceed $9999.99" });

      }, 1250);
      value = null;

    } 

    if(( key === "orgReimbAmt" || key === "carrierCost" || key === "regAmount"))
    {
      let regFeeAmt = 0;
      if (newDetails.orgSw === 'N' && (newDetails.regAmount !== null || newDetails.regAmount !== undefined)) {
        if (isNaN(parseFloat(newDetails.regAmount))) {
          regFeeAmt = 0;
        } else {
          regFeeAmt = newDetails.regAmount * 1;
        }
      }

      let carrierAmt = 0;
      if (newDetails.carrierCost !== null && newDetails.carrierCost !== undefined) {
        if (isNaN(parseFloat(newDetails.carrierCost))) {
          carrierAmt = 0;
        } else {
          carrierAmt = newDetails.carrierCost * 1;
        }
      }

      let totalOrgReimbAmt = 0;          
      if (newDetails.orgTravelReimbSw === 'Y' && (newDetails.orgReimbAmt !== null && newDetails.orgReimbAmt !== undefined)) {
        if (isNaN(parseFloat(newDetails.orgReimbAmt))) {
          totalOrgReimbAmt = 0;
        } else {
          totalOrgReimbAmt = newDetails.orgReimbAmt * 1;
        }
      }
      if(key === "orgReimbAmt" && newDetails.orgTravelReimbSw === 'Y' &&  parseFloat((newDetails.daysOfLodging * newDetails.lodgingRate) + (newDetails.fullDaysMeals * newDetails.fullMealRate) + (newDetails.partialDaysMeals * newDetails.partialMealRate) + regFeeAmt + carrierAmt - totalOrgReimbAmt).toFixed(2) < 0)
      {
        totalOrgReimbAmt = 0;
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "Entered Total Expense Amount Reimbursed by Sponsoring Organization cannot exceed Total Expected Expense Amount" });
  
        }, 1250);
        value = null;
      }

      newDetails.expectedExpense = parseFloat((newDetails.daysOfLodging * newDetails.lodgingRate) + (newDetails.fullDaysMeals * newDetails.fullMealRate) + (newDetails.partialDaysMeals * newDetails.partialMealRate) + regFeeAmt + carrierAmt - totalOrgReimbAmt).toFixed(2);

    }
    newDetails[key] = value !== null ?  value = parseFloat(value).toFixed(2) : value;
    setPreApprovalDetails(newDetails);
  }

  const saveApproval = async (value, key) => {
    let request = { ...preApprovalDetails };
    approvalData.forEach((approval, index) => {
     // request["assignedUser" + (index + 1)] = approval.submittedTo === null ? props.userInfo.id : approval.submittedTo;
      request["assignedUser" + (index + 1)] = approval.submittedTo;
      request["user" + (index + 1) + "approvalNote"] = approval.note;
      if (request.status === 'RR') {
        request["user" + (index + 1) + "Status"] = null;
      } else {
        request["user" + (index + 1) + "Status"] = approval.approve;
      }

      request["user" + (index + 1) + "ApprovalDate"] = approval.approvalDate;
    });
    request.approvalType = request.approvalType.toString();
    request.startDate = moment(request.startDate).format("YYYY-MM-DD");
    request.endDate = moment(request.endDate).format("YYYY-MM-DD");
    request.assignedDate = (request.assignedDate === null) ? moment().format("YYYY-MM-DD") : request.assignedDate;
    if ((key === 2 && value === 'SB') && request.status === 'IP' && ([...approvalData].filter((approval) => (approval.submittedTo === props.userInfo.id)).length > 0)) {
      props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please select the approvers" });
    }
    if (key !== 1) {
      request.status = ([...approvalData].filter((approval) => approval.approve === 'Y').length === [...approvalData].length) ? 'AP' :
        ((([...approvalData].filter((approval) => approval.approve === 'N').length > 0) && (request.status != 'RR') && (request.userId !== props.userInfo.id)) ? 'RR' : 'SB');
    } else {
      request.status = ([...approvalData].filter((approval) => approval.approve === 'Y').length === [...approvalData].length) ? 'AP' :
        ((([...approvalData].filter((approval) => approval.approve === 'N').length > 0) && (request.status != 'RR') && (request.userId !== props.userInfo.id)) ? 'RR' : 'IP');
    }
   
    if (document.getElementsByClassName("Mui-error").length > 0) {
      setError(true);
    }
    else if ((key === 2 && value === 'SB') && ([...approvalData].filter((approval) => (approval.submittedTo === null || approval.submittedTo === ''|| approval.submittedTo === 0)).length > 0)) {
      props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please select the approvers" });
    }
    else if ([...approvalData].filter(
        (approval) => (!approval.isDisabled && approval.submittedTo === props.userInfo.id && (approval.approve === null || approval.approve === '')))
        .length > 0 && (request.status != 'IP')) {
      setApprovalError(true);
    }
    else if ([...approvalData].filter(
      (approval) => (!approval.isDisabled && approval.submittedTo === props.userInfo.id && approval.approve === 'N' && approval.note=== null))
      .length > 0 && (request.status != 'IP')) {
    setApprovalError(true);
  }
    else if (request.approvalType.length === 0) {
      props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please select at least one Approval Type." });
    }
    else if (request.orgTravelReimbSw === "Y" && (request.orgReimbAmt === null || request.orgReimbAmt  === '')) {
      props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please enter the Total Expense Amount Reimbursed By Sponsoring Organization." });
    }
    else {
      props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
      await postFetch("/preApproval/save", request).then((data) => {
        if (!data.message) {
          setTimeout(() => {
            props.loader({ loader: false, notification: true, message: "success", validationMessage: data.status === 'IP' ? "Pre-Approval Form Saved Successfully" : "Pre-Approval Form Submitted Successfully" });
            localStorage.setItem('preApproval', data.id);
          }, 1000);
          setTimeout(() => {
            history.push("/dashboard");
          }, 3000);
        }
        else {
          setTimeout(() => {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
          }, 1000);
        }
      }).catch((e) => {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
        }, 1000);
      });
    }
  }


  const getCities = async (value) => {
    if( null !== value){
      props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    await postFetch("/reimbursement/loadCities", value).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: false, message: "success" });
          setCities([...data]);
        }, 1000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
      }, 1000);
    });
  }
  }

  const getStates = async () => {
    let details = { id: null };
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    await postFetch("/reimbursement/loadStates", details).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: false, message: "success" });
          setStates([...data]);
        }, 1000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
      }, 1000);
    });
  }

  return (
    <>
      <div>
        <Typography className={classes.header}>Pre Approval</Typography>
        <Subheader>Traveler Information</Subheader>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Traveler Name</span>
            <TextField
              id="name"
              variant="outlined"
              size="small"
              fullWidth
              className={"disabled"}
              disabled={true}
              //error ={preApprovalDetails.name === '' || preApprovalDetails.name === null}
              value={preApprovalDetails.name === null ? '' : preApprovalDetails.name}
            //onChange={(e)=>changePreapprovalDetails('name',e.target.value,error)}
            //helperText={((preApprovalDetails.name === '' || preApprovalDetails.name === null) && error) ? "Required Field" : null}
            />
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Purpose of Trip</span>
            <TextField
              id="purposeOfTrip"
              variant="outlined"
              size="small"
              fullWidth
              className={readOnly ? "disabled" : "required"}
              disabled={readOnly}
              value={preApprovalDetails.purposeOfTrip === null ? '' : preApprovalDetails.purposeOfTrip}
              error={preApprovalDetails.purposeOfTrip === '' || preApprovalDetails.purposeOfTrip === null}
              onChange={(e) => changePreapprovalDetails('purposeOfTrip', e.target.value)}
              helperText={((preApprovalDetails.purposeOfTrip === '' || preApprovalDetails.purposeOfTrip === null) && error) ? "Required Field" : null}
            />
          </Grid>
          <Grid item xs={10} sm={5} className={classes.datepicker}>
            <span className="datepickerLabel">Travel Start Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date"
                autoOk={true}
                className={readOnly ? "disabled" : "required"}
                disabled={readOnly}
                size="small"
                fullWidth
                value={preApprovalDetails.startDate}
                error={(preApprovalDetails.startDate === null) || (!moment(preApprovalDetails.startDate, 'MM/DD/YYYY').isValid())}
                //onChange={(date)=> {changePreapprovalDetails('startDate',date); getNumberofNights('startDate',date)}}
                onChange={(date) => changePreapprovalDetails('startDate', date)}
                placeholder="MM/DD/YYYY"
                KeyboardButtonProps={{
                  'aria-label': 'start-date',
                }}
                inputVariant="outlined"
                helperText={(preApprovalDetails.startDate === null && error) ? "Required Field" : null}
              />
            </MuiPickersUtilsProvider>
            {(preApprovalDetails.startDate !== null && !moment(preApprovalDetails.startDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
              : null}
          </Grid>
          <Grid item xs={10} sm={5} className={classes.datepicker}>
            <span className="datepickerLabel">Travel End Date</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker
                //disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="end-date"
                autoOk={true}
                className={readOnly ? "disabled" : "required"}
                disabled={readOnly}
                size="small"
                fullWidth
                required
                value={preApprovalDetails.endDate}
                error={(preApprovalDetails.endDate === null) || (!moment(preApprovalDetails.endDate, 'MM/DD/YYYY').isValid())}
                //minDate={preApprovalDetails.startDate !== null ? preApprovalDetails.startDate : null}
                //onChange={(date)=> {changePreapprovalDetails('endDate',date,error);  getNumberofNights('endDate',date)}}
                onChange={(date) => changePreapprovalDetails('endDate', date, error)}
                placeholder="MM/DD/YYYY"
                KeyboardButtonProps={{
                  'aria-label': 'end-date',
                }}
                inputVariant="outlined"
                helperText={(preApprovalDetails.endDate === null && error) ? "Required Field" : null}
              />
            </MuiPickersUtilsProvider>
            {(preApprovalDetails.endDate !== null && !moment(preApprovalDetails.endDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
              : null}
          </Grid>
        </Grid>
      </div>
      <div>
        <Subheader>Approval Type</Subheader>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={4} className={classes.textfield}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preApprovalDetails.approvalType.indexOf("OS") > -1}
                  value="OS"
                  onChange={(e) => changePreapprovalDetails('approvalType', e.target.value)}
                  name="Out of State Travel"
                  color="primary"
                  disabled={readOnly}
                />
              }
              label="Out of State Travel"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={10} sm={4} className={classes.textfield}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preApprovalDetails.approvalType.indexOf("EM") > -1}
                  value="EM"
                  onChange={(e) => changePreapprovalDetails('approvalType', e.target.value)}
                  name="Request to Exceed Maximum Lodging Rate"
                  color="primary"
                  disabled={readOnly}
                />
              }
              label="Request to Exceed Maximum Lodging Rate"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={10} sm={4} className={classes.textfield}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preApprovalDetails.approvalType.indexOf("OW") > -1}
                  value="OW"
                  onChange={(e) => changePreapprovalDetails('approvalType', e.target.value)}
                  name="Request to travel outside normal work schedule"
                  color="primary"
                  disabled={readOnly}
                />
              }
              label="Request to Travel Outside Normal Work Schedule"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </div>
      {preApprovalDetails.approvalType.indexOf("OS") > -1 ? <div>
        <Subheader>Out of State Travel</Subheader>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <FormControl variant="outlined" size="small" className={classes.formControl} error={preApprovalDetails.state === 0 || preApprovalDetails.state === null}>

              <span className={classes.textLabel}>State</span>
              <Select
                id="state-select"
                value={preApprovalDetails.state === null ? 0 : preApprovalDetails.state}
                className={readOnly ? "disabled" : "required"}
                disabled={readOnly}
                onChange={(e) => changePreapprovalDetails('state', e.target.value)}
              >
                <MenuItem value={0}>Pick an Option</MenuItem>
                {states.sort().map((method) => (
                  <MenuItem key={method} value={method}>{method}</MenuItem>
                ))}
              </Select>
              {((preApprovalDetails.state === 0 || preApprovalDetails.state === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                : null}
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <FormControl variant="outlined" size="small" className={classes.formControl} error={preApprovalDetails.city === 0 || preApprovalDetails.city === null}>
              <span className={classes.textLabel}>City</span>
              <Select
                id="city-select"
                value={preApprovalDetails.city === null ? 0 : preApprovalDetails.city}
                className={readOnly ? "disabled" : "required"}
                disabled={readOnly}
                onChange={(e) => changePreapprovalDetails('city', e.target.value)}
              >
                <MenuItem value={0}>Pick an Option</MenuItem>
                {(preApprovalDetails.state === 0 || preApprovalDetails.state === null) ? null : cities.map((value) => (
                  <MenuItem key={value} value={value}>{value}</MenuItem>
                ))}
              </Select>
              {((preApprovalDetails.city === 0 || preApprovalDetails.city === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                : null}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Nights of Lodging</span>
            <TextField
              id="Days of Lodging"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              className="disabled"
              //value={totalNights === null ? 0 : totalNights}
              value={(preApprovalDetails.daysOfLodging === null) ? '' : preApprovalDetails.daysOfLodging}
            />
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Lodging Rate Per Night</span>
            <TextField
              id="Lodging Rate Per day"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              className="disabled"
              //value={parseFloat(props.staticData.dropdown.PerDiemRates.value.find((config)=> config.value === 'LO').name)}
              //value={preApprovalDetails.lodgingRate}
              value={(preApprovalDetails.lodgingRate === null) ? '0' : preApprovalDetails.lodgingRate}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Full Days of Meals</span>
            <TextField
              id="Full Day of Meals"
              variant="outlined"
              size="small"
              fullWidth
              className="disabled"
              //value={totalNights === null ? 0 : totalNights === 0 || totalNights === 1 ? 0 :totalNights - 1}
              value={(preApprovalDetails.fullDaysMeals === null) ? '' : preApprovalDetails.fullDaysMeals}
            />
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Rate for Full Day Meals</span>
            <TextField
              id="Rate for full day meals"
              variant="outlined"
              size="small"
              fullWidth
              className="disabled"
              disabled
              //value={parseFloat(props.staticData.dropdown.PerDiemRates.value.find((config)=> config.value === 'ME').name)}
              value={(preApprovalDetails.fullMealRate === null) ? '0' : preApprovalDetails.fullMealRate}

            />
          </Grid>
        </Grid>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Partial Days of Meals</span>
            <TextField
              id="Partial Days of Meals"
              variant="outlined"
              size="small"
              fullWidth
              className="disabled"
              //value={totalNights === null ? 0 : totalNights >= 2 ? 2 : totalNights + 1}
              value={(preApprovalDetails.partialDaysMeals === null) ? '' : preApprovalDetails.partialDaysMeals}
            />
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Rate for Partial Day Meals</span>
            <TextField
              id="Rate for Partial Day Meals"
              variant="outlined"
              size="small"
              fullWidth
              className="disabled"
              disabled
              //value={(parseFloat(0.75) * parseFloat(props.staticData.dropdown.PerDiemRates.value.find((config)=> config.value === 'ME').name)).toFixed(2)}
              value={(preApprovalDetails.partialMealRate === null) ? '0' : preApprovalDetails.partialMealRate}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.filters}>
        <Grid item xs={10} sm={5} className={classes.textfield}>
            <FormControl variant="outlined" size="small" className={classes.formControl} error={preApprovalDetails.travelType === 0 || preApprovalDetails.travelType === null}>
              <span className={classes.textLabel}>Travel Type</span>
              <Select
                id="travel-type-select"
                value={preApprovalDetails.travelType === null ? 0 : preApprovalDetails.travelType}
                className={readOnly ? "disabled" : "required"}
                disabled={readOnly}
                onChange={(e) => changePreapprovalDetails('travelType', e.target.value)}
              >
                <MenuItem value={0}>Pick an Option</MenuItem>
                {props.staticData.dropdown.ReasonForExpense.value.map((method) => (
                  <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                ))}
              </Select>
              {((preApprovalDetails.travelType === 0 || preApprovalDetails.travelType === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                : null}
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Estimated Carrier Costs</span>
            <TextField
              id="estimated_Carrier_Costs"
              variant="outlined"
              size="small"
              fullWidth
              className={readOnly ? "disabled" : "required"}
              disabled={readOnly}
              value={preApprovalDetails.carrierCost === null ? '' : parseFloat(preApprovalDetails.carrierCost).toFixed(2)}
              error={preApprovalDetails.carrierCost === '' || preApprovalDetails.carrierCost === null}
              //onChange={(e)=> {changePreapprovalDetails('regAmount',e.target.value !== '' ? parseFloat(e.target.value) : null); getTotalExpectedExpense(totalNights,preApprovalDetails.orgSw, parseFloat(e.target.value))} }
              onChange={(e) => changePreapprovalDetails('carrierCost', e.target.value !== '' ? parseFloat(e.target.value) : null)}
              onBlur={(e) => validatePreApproval('carrierCost', e.target.value)}
              InputProps={{
                inputComponent: AmountFormatCustom,
                maxLength: 6

              }}
              helperText={((preApprovalDetails.carrierCost === '' || preApprovalDetails.carrierCost === null) && error) ? "Required Field" : null}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.filters}>
        <Grid item xs={10} sm={5} className={classes.textfield}>
            <FormControl variant="outlined" size="small" style={{ width: "100%" }} error={preApprovalDetails.orgSw === 0 || preApprovalDetails.orgSw === null}>
              <span className={classes.textLabel}>Registration Fees Reimbursed by Sponsoring Organization?</span>
              <Select
                id="reason-select"
                variant="outlined"
                size="small"
                className={readOnly ? "disabled" : "required"}
                disabled={readOnly}
                value={preApprovalDetails.orgSw === null ? 0 : preApprovalDetails.orgSw}
                //onChange={(e)=> {changePreapprovalDetails('orgSw',e.target.value); getTotalExpectedExpense(totalNights,e.target.value, preApprovalDetails.regAmount) }}
                onChange={(e) => changePreapprovalDetails('orgSw', e.target.value)}
              >
                <MenuItem key={0} value={0}>Pick an Option</MenuItem>
                <MenuItem key="Y" value="Y">Yes</MenuItem>
                <MenuItem key="N" value="N">No</MenuItem>
              </Select>
              {((preApprovalDetails.orgSw === 0 || preApprovalDetails.orgSw === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                : null}
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Registration Fees Amount</span>
            <TextField
              id="Registration Fees Amount"
              variant="outlined"
              size="small"
              fullWidth
              className={readOnly ? "disabled" : "required"}
              disabled={readOnly}
              value={preApprovalDetails.regAmount === null ? '' : parseFloat(preApprovalDetails.regAmount).toFixed(2)}
              error={preApprovalDetails.regAmount === '' || preApprovalDetails.regAmount === null}
              //onChange={(e)=> {changePreapprovalDetails('regAmount',e.target.value !== '' ? parseFloat(e.target.value) : null); getTotalExpectedExpense(totalNights,preApprovalDetails.orgSw, parseFloat(e.target.value))} }
              onChange={(e) => changePreapprovalDetails('regAmount', e.target.value !== '' ? parseFloat(e.target.value) : null)}
              onBlur={(e) => validatePreApproval('regAmount', e.target.value)}
              InputProps={{
                inputComponent: AmountFormatCustom,
                maxLength: 6

              }}
              helperText={((preApprovalDetails.regAmount === '' || preApprovalDetails.regAmount === null) && error) ? "Required Field" : null}
            />
          </Grid>
         
        </Grid>

        <Grid container className={classes.filters}>
         
          <Grid item xs={10} sm={5} className={classes.textfield}>
            {preApprovalDetails.orgSw === 'Y' ?
              <div>
                <span className={classes.textLabel}>Sponsoring Organization Name</span>
                <TextField
                  id="orgName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={readOnly ? "disabled" : "required"}
                  disabled={readOnly}
                  value={preApprovalDetails.orgName === null ? '' : preApprovalDetails.orgName}
                  error={preApprovalDetails.orgName === '' || preApprovalDetails.orgName === null}
                  onChange={(e) => changePreapprovalDetails('orgName', e.target.value)}
                  helperText={((preApprovalDetails.orgName === '' || preApprovalDetails.orgName === null) && error) ? "Required Field" : null}
                />
              </div>
              : null}
          </Grid>
        </Grid>

        <Grid container className={classes.filters}>
         
          <Grid item xs={10} sm={5} className={classes.textfield}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="travel_Org_reimb-sw" name="travel_Org_reimb-sw" 
              className={readOnly ? "disabled" : "required"}
              value={preApprovalDetails.orgTravelReimbSw === null ? 0 : preApprovalDetails.orgTravelReimbSw}
              onChange={(e) => changePreapprovalDetails('orgTravelReimbSw', e.target.value)}
              error={preApprovalDetails.orgTravelReimbSw === 0 || preApprovalDetails.orgTravelReimbSw === null}>
              <FormLabel component="legend" className={classes.radioLabel}>Total Travel Reimbursed by Sponsoring Organization?</FormLabel>
              <div>
                <FormControlLabel value="Y" control={<Radio disabled={readOnly} color="primary" />} label="Yes" />
                <FormControlLabel value="N" control={<Radio disabled={readOnly} color="primary" />} label="No" />
              </div>
            </RadioGroup>
            </FormControl>
            {((preApprovalDetails.orgTravelReimbSw === 0 || preApprovalDetails.orgTravelReimbSw === null) && error) ? <FormHelperText error>Required Field</FormHelperText>
              : null}
          </Grid>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Total Expense Amount Reimbursed by Sponsoring Organization</span>
            <TextField
              id="amt_reimb__By_Org"
              variant="outlined"
              size="small"
              fullWidth
              className={readOnly ? "disabled" : "required"}
              disabled={readOnly}
              value={preApprovalDetails.orgReimbAmt === null ? '' : parseFloat(preApprovalDetails.orgReimbAmt).toFixed(2)}
              //error={preApprovalDetails.orgReimbAmt === '' || preApprovalDetails.orgReimbAmt === null}
              //onChange={(e)=> {changePreapprovalDetails('regAmount',e.target.value !== '' ? parseFloat(e.target.value) : null); getTotalExpectedExpense(totalNights,preApprovalDetails.orgSw, parseFloat(e.target.value))} }
              onChange={(e) => changePreapprovalDetails('orgReimbAmt', e.target.value !== '' ? parseFloat(e.target.value) : null)}
              onBlur={(e) => validatePreApproval('orgReimbAmt', e.target.value)}
              InputProps={{
                inputComponent: AmountFormatCustom,
                maxLength: 6

              }}
              //helperText={((preApprovalDetails.orgReimbAmt === '' || preApprovalDetails.orgReimbAmt === null) && error) ? "Required Field" : null}
            />
          </Grid>
        </Grid>
       

        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={5} className={classes.textfield}>
            <span className={classes.textLabel}>Total Expected Expense</span>
            <TextField
              id="Total Expected Expense"
              variant="outlined"
              size="small"
              fullWidth
              className="disabled"
              // value={preApprovalDetails.totalExpectedExpense === null ? '$0' : preApprovalDetails.totalExpectedExpense }
              value={"$" + (preApprovalDetails.expectedExpense === null ? 0 : parseFloat(preApprovalDetails.expectedExpense).toFixed(2))}
            />
          </Grid>

        </Grid>

      </div> : null}
      {preApprovalDetails.approvalType.indexOf("EM") > -1 ? <div>
        <Subheader>Exceed Maximum Lodging Rate</Subheader>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={6} className={classes.textfield}>
            <span className={classes.textLabel}>
              This is a waiver that the employee be allowed to exceed the maximum lodging
              rate prescribed by the Federal Travel Directory when traveling on official business
               in the best interest of the state. This waiver is being made for the following reason:
            </span>
          </Grid>
          <Grid item xs={10} sm={6} className={classes.textfield}>
            <FormControl variant="outlined" size="small" className={classes.formControl} error={preApprovalDetails.lodgingReason === 0 || preApprovalDetails.lodgingReason === null}>
              <span className={classes.textLabel}>Select Reason</span>
              <Select
                id="reason-select"
                fullWidth
                className={readOnly ? "disabled" : "required"}
                disabled={readOnly}
                value={preApprovalDetails.lodgingReason === null ? 0 : preApprovalDetails.lodgingReason}
                onChange={(e) => changePreapprovalDetails('lodgingReason', e.target.value)}
              >
                <MenuItem value={0}>Pick an Option</MenuItem>
                {props.staticData.dropdown.ReasonForPreappExpense.value.map((method) => (
                  <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                ))}
              </Select>
              {((preApprovalDetails.lodgingReason === 0 || preApprovalDetails.lodgingReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                : null}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className={classes.filters}>
         <Grid item xs={10} sm={6} className={classes.textfield}>
            <span className={classes.textLabel}></span>
          </Grid>
          <Grid item xs={10} sm={6} className={classes.textfield}>
          <span className={classes.textLabel}>Total Expected Lodging Expense</span>
            <TextField
             id="Total Expected Lodging Expense"
              variant="outlined"
              size="small"
              fullWidth
              className={readOnly ? "disabled" : "required"}
              disabled={readOnly}
              value={preApprovalDetails.lodgingExpense === null ? '' : parseFloat(preApprovalDetails.lodgingExpense).toFixed(2)}
              error={preApprovalDetails.lodgingExpense === '' || preApprovalDetails.lodgingExpense === null}
              //onChange={(e)=> {changePreapprovalDetails('regAmount',e.target.value !== '' ? parseFloat(e.target.value) : null); getTotalExpectedExpense(totalNights,preApprovalDetails.orgSw, parseFloat(e.target.value))} }
               onChange={(e) => changePreapprovalDetails('lodgingExpense', e.target.value !== '' ? parseFloat(e.target.value) : null)}
               onBlur={(e) => validatePreApproval('lodgingExpense', e.target.value)}
              InputProps={{
                inputComponent: AmountFormatCustom,
                maxLength: 6

              }}
              helperText={((preApprovalDetails.lodgingExpense === '' || preApprovalDetails.lodgingExpense === null) && error) ? "Required Field" : null}
            />
            </Grid>
          </Grid>
      </div> : null}
      {preApprovalDetails.approvalType.indexOf("OW") > -1 ? <div>
        <Subheader>Travel Outside of Normal Work Schedule</Subheader>
        <Grid container className={classes.filters}>
          <Grid item xs={10} sm={12} className={classes.textfield}>
            <span className={classes.textLabel}>Justification for time traveled outside the normal work schedule(weekend, holiday, etc):</span>
            <TextField
              id="Justification"
              variant="outlined"
              size="small"
              fullWidth
              className={readOnly ? "disabled" : "required"}
              disabled={readOnly}
              value={preApprovalDetails.workOutsideSchedule === null ? '' : preApprovalDetails.workOutsideSchedule}
              error={preApprovalDetails.workOutsideSchedule === '' || preApprovalDetails.workOutsideSchedule === null}
              onChange={(e) => changePreapprovalDetails('workOutsideSchedule', e.target.value)}
              helperText={((preApprovalDetails.workOutsideSchedule === '' || preApprovalDetails.workOutsideSchedule === null) && error) ? "Required Field" : null}
            />
          </Grid>
          <Grid item xs={10} sm={12} className={classes.textfield}>
            <span className={classes.textLabel}>Consequences if the travel is not performed and why it is in the best interest of the state: </span>
            <TextField
              id="Consequences"
              variant="outlined"
              size="small"
              fullWidth
              className={readOnly ? "disabled" : "required"}
              disabled={readOnly}
              value={preApprovalDetails.workScheduleConsequences === null ? '' : preApprovalDetails.workScheduleConsequences}
              error={preApprovalDetails.workScheduleConsequences === '' || preApprovalDetails.workScheduleConsequences === null}
              onChange={(e) => changePreapprovalDetails('workScheduleConsequences', e.target.value)}
              helperText={((preApprovalDetails.workScheduleConsequences === '' || preApprovalDetails.workScheduleConsequences === null) && error) ? "Required Field" : null}
            />
          </Grid>
        </Grid>
      </div> : null}
      <div>
        <Subheader>Approvals</Subheader>
        <Paper elevation={0}>
          <Box p={"10px"}>
            {<Gridtable columns={approvalColumns} data={approvalData}></Gridtable>}
          </Box>
        </Paper>
      </div>
      {preApprovalDetails.status === 'AP' ? null :
        <div style={{ textAlign: 'center', paddingBottom: 10 }}>
          <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" onClick={() => reset("approvaltype", preApprovalDetails.approvalType)}>Reset</Button>
          {preApprovalDetails.status === 'SB' ? null : <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" onClick={() => saveApproval("IP", 1)}>Save</Button>}
          <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" onClick={() => saveApproval("SB", 2)}>Submit</Button>
        </div>
      }

    </>
  );
}
