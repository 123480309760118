import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Typography from '@material-ui/core/Typography';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from '../assets/config';*/
import { postFetch } from '../http';
import { Switch, Route, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import '../App.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from "moment";
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Config from '../assets/config';
import { set } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: 600
    },
    "& .MuiSelect-selectMenu": {
      whiteSpace: 'pre-wrap'
    }
  },
  header: {
    backgroundColor: "#1a75c9",
    color: "#fff",
    padding: 10,
    fontWeight: 900,
    fontSize: 15
  },
  filters: {
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
      color: "#000",
      fontSize: 17
    },
    margin: '20px 0',
    padding: '0 10px'
  },
  datepicker: {
    position: 'relative',
    bottom: 5,
    padding: '0 10px 0 0'
  },
  textfield: {
    padding: '10px 10px 10px 0'
  },
  textLabel: {
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  chip: {
    width: '90%'
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};



const Subheader = ({ children }) => {
  return (
    <div className="wrapper">
      <p>{children}</p>
      <div className="line"></div>
    </div>
  )
}

export default function FinancialAdministration(props) {
  const classes = useStyles();
  const history = useHistory();
  const [, updateState] = React.useState();
  const [summaryData, setSummaryData] = React.useState([]);
  const [onInit, setOnInit] = React.useState(true);
  //const { transcript, resetTranscript } = useSpeechRecognition();
  const [error, setError] = React.useState(false);
  const [isIOCCRequired, setisIOCCRequired] = React.useState(true);
  const [resetDetails, setResetDetails] = React.useState(null);
  const [searchFilter, setSearchFilter] = React.useState('');
  const [filterSw, setFilterSw] = React.useState('Active');
  const [financialAdmin, setfinancialAdmin] = React.useState({
    id:null,expense: null, expenseType: null, reasonForExpense: null,
    userType: null, division: null, startDate: null, endDate: null,generalLedgerCode: null, internalOrder: null, costCenter: null,
    active: 'Y'
  });
  const [iseditable, setIsEditable] = React.useState(false);
  //const [iseditable, setIsEditable] = React.useState(false);
  const [auditDialog,setAuditDialog] = React.useState(false);
  const [auditData,setAuditData] = React.useState([]);
  const [auditColumns,setAuditColumns] = React.useState([]);
 


  const checkFormat = (value) => {
    let len = 0;
    value !== null ? len = value.toString().length : len = 0;
    return len;
  }


  React.useEffect(() => {
    window.scrollTo(0, 0);
    setIsEditable(props.staticData.reference.FinancialAdmins.value.filter((config)=> config.value.toString() === props.userInfo.id.toString() && (config.activeSW === 'Y')).length > 0 ? true : false);
    const loadFinancialAdmins = async () => {
      props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
      setOnInit(false);
      history.push("/config/financialadministration");
      const request = (props.userInfo.role === 'DA' ? props.userInfo.division : 'All');
      await postFetch("/financialAdministration/loadByAppId", request).then((data) => {
        if (!data.message) {
          data.forEach((finance) => {       
            finance.expenseDesc = props.staticData.reference.Expense.value.find((config) => config.value === finance.expense).name;           
            finance.expenseTypeDesc = props.staticData.reference.TypeOfExpense.value.find((config) => config.value === finance.expenseType).name;
            finance.reasonForExpenseDesc = props.staticData.reference.ReasonForExpense.value.find((config) => config.value === finance.reasonForExpense).name;
            finance.userTypeDesc = props.staticData.reference.UserType.value.find((config) => config.value === finance.userType).name;
            //request.division = props.staticData.reference.DIVISIONS.value.find((config) => config.value === request.division).name;    
            finance.status = finance.active === "Y" ? "Active" : "Inactive";
            //finance.activeDesc = finance.active === "Y" ? "Active" : "Inactive";
            finance.filterData = (finance.expenseTypeDesc + finance.internalOrder + finance.costCenter + finance.generalLedgerCode +finance.division).toLowerCase();
            // if (finance.active !== 'Y') {
            //     summaryData.push(finance);
                
            // }
          })
         setTimeout(() => {
            props.loader({ loader: false, notification: false });
            setSummaryData(data);
          }, 1000);
        }
        else {
          setTimeout(() => {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
          }, 1000);
        }
      }).catch((e) => {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
        }, 1000);
      });
    }
    if (onInit) {
      loadFinancialAdmins();      
    }
  }, [props, onInit, history]);


  const saveFinance = () => {
    let newDetails = { ...financialAdmin }
    if (document.getElementsByClassName("Mui-error").length > 0) {
      setError(true);
    }
    else {
      saveFinanceRecord();
    }
  }

  const saveFinanceRecord = async () => {
    props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
    let request = {};
    request = { ...financialAdmin };
    request.startDate = new Date(request.startDate);
    request.endDate = new Date(request.endDate);   
    await postFetch("/financialAdministration/save", request).then((data) => {
      if (!data.message) {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "success", validationMessage: "Record saved successfully" });
        }, 1000);
        setTimeout(() => {
          setOnInit(true);
        }, 3000);
      }
      else {
        setTimeout(() => {
          props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
        }, 1000);
      }
    }).catch((e) => {
      setTimeout(() => {
        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
      }, 1000);
    });
  }

  const renderActionButton = (row) => {
    return iseditable ? (<div style={{ cursor: 'pointer' }}><EditRoundedIcon onClick={() => viewFunction(row)} style={{ fontSize: 20 }} /><AccessTimeRoundedIcon onClick={()=>showAudit(row,"tasks")} style={{fontSize:20}}/></div>) : 
    (<div><EditRoundedIcon disabled style={{ fontSize: 20 }} /><AccessTimeRoundedIcon disabled style={{fontSize:20}}/></div>);
  }
  const viewFunction = (value) => {
    let details = {...value};
    //value.expense = props.staticData.reference.Expense.value.find((config) => config.name === value.expense).value;
    //value.expenseType = props.staticData.reference.TypeOfExpense.value.find((config) => config.name === value.expenseType).value;
    //value.reasonForExpense = props.staticData.reference.ReasonForExpense.value.find((config) => config.name === value.reasonForExpense).value;
    //value.userType = props.staticData.reference.UserType.value.find((config) => config.name === value.userType).value;
    details.startDate = details.startDate !== null ? new Date(convertDateFromServer(details.startDate)) : null;
    details.endDate = details.endDate !== null ? new Date(convertDateFromServer(details.endDate)) : null;
    //value.active = value.active === 'Active' ? 'Y':'N';
    //value.activeDesc = value.active === "Y" ? "Active" : "Inactive";
    if(details.expenseType !== null && details.userType !== null && (details.userType === 'EM' && details.expenseType !== 'ME'))
    {
      setisIOCCRequired(false);   
    }else{
      setisIOCCRequired(true);
    }
    setfinancialAdmin(details);
    setResetDetails(details);
    history.push("/config/financialadministration/details");
  }

  const auditdialog = ()=>{
    return (<div>
      <Dialog
        open={auditDialog}
        onClose={()=>setAuditDialog(false)}
      >
        <DialogContent>
          <Gridtable columns={auditColumns} data={auditData} ></Gridtable>
        </DialogContent>
      </Dialog>
    </div>);
  }

  const showAudit = async (row,type)=>{
    const request= {  
      "requestId":row.id,
      "type":"financialAdmin"
    }
      await postFetch("/audit/load",request).then((data)=>{ 
        if(!data.message){
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false,message:"success" });                      
            },1000);
            setAuditColumns([...requestsAuditColumns]);
           
            setAuditData([...data]);
            setAuditDialog(true);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
        setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
        },1000);
    });
  }


  const changeView = () => {
    //loadUserDetails(0);
    setError(false);
    setfinancialAdmin({
      id:null, expense: null, expenseType: null, reasonForExpense: null,
        userType: null, division: null, startDate: null, endDate: null,generalLedgerCode: null, internalOrder: null, costCenter: null,
        active: 'Y'
    })
    setResetDetails({
      id:null, expense: null, expenseType: null, reasonForExpense: null,
        userType: null, division: null, startDate: null, endDate: null,generalLedgerCode: null, internalOrder: null, costCenter: null,
        active: 'Y'
    });
    history.push("/config/financialadministration/details");
  }

  const changeFinanceDetails = (key, value) => {
    let newDetails = {...financialAdmin};       
    newDetails[key] = value;
    if(newDetails.expenseType !== null && newDetails.userType !== null && (newDetails.userType === 'EM' && newDetails.expenseType !== 'ME'))
    {
      setisIOCCRequired(false);
      newDetails.internalOrder = null;
      newDetails.costCenter = null;     
    }else{
      setisIOCCRequired(true);
    }
    if (key === 'startDate' && (value !== null) && (newDetails.endDate !== null) && (value.setHours(0, 0, 0, 0) > new Date(new Date(newDetails.endDate).toDateString()).setHours(0, 0, 0))) {
      newDetails[key] = null;
      props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Start Date must be before the entered End Date.' });
    }

    if (key === 'endDate' && (value !== null) && (newDetails.startDate !== null) && (value.setHours(0, 0, 0, 0) < new Date(new Date(newDetails.startDate).toDateString()).setHours(0, 0, 0))) {
      newDetails[key] = null;
        props.loader({ loader: false, notification: true, message: "error", validationMessage: 'End Date must be after the entered Start Date.' });
    }
    setfinancialAdmin(newDetails);
  }
  const reset = () => {
    setError(false);
    setisIOCCRequired(true);
    setfinancialAdmin(resetDetails)
  }

  function convertDateFromServer(value) {
    if (value !== null) {
      let serverDateArray = value.split("-");
      if (serverDateArray.length > 1) {
        return serverDateArray[1] + "/" + serverDateArray[2] + "/" + serverDateArray[0];
      }
      else {
        return serverDateArray[0];
      }
    }
    else {
      return null;
    }

  }


  const summaryColumns = [
    {
      name: "Expense",
      field: "expenseDesc",
      type: "text"
    },
    {
      name: "Expense Type",
      field: "expenseTypeDesc",
      type: "text"
    },
    {
      name: "Reason For Expense",
      field: "reasonForExpenseDesc",
      type: "text"
    },
    {
      name: "Division",
      field: "division",
      type: "text"
    },
    {
      name: "User Type",
      field: "userTypeDesc",
      type: "text"
    },
    {
      name: "Start Date",
      field: "startDate",
      type: "date"
    },
    {
      name: "End Date",
      field: "endDate",
      type: "date"
    },
    {
      name: "General Ledger Code",
      field: "generalLedgerCode",
      type: "text"
    },
    {
        name: "Internal Order",
        field: "internalOrder",
        type: "text"
    },
    {
        name: "Cost Center",
        field: "costCenter",
        type: "text"
    },
    {
      name: "Action",
      field: "action",
      type: "input",
      renderView: renderActionButton
    }
  ]

  const requestsAuditColumns =[
    {
      name:"Created By",
      field:"createdBy",
      type:"text"
    },
    {
      name:"Created Date",
      field:"createdDate",
      type:"text"
    },
    {
      name:"Modified By",
      field:"modifiedBy",
      type:"text"
    },
    {
      name:"Modified Date",
      field:"modifiedDate",
      type:"text"
    },
    {
      name:"Internal Order",
      field:"column2",
      type:"text"
    },
    {
      name:"Cost Center",
      field:"column3",
      type:"text"
    }
    ,
    {
      name:"Status",
      field:"column1",
      type:"text"
    }
  ];


  return (
    <div>
    {auditdialog()}
    <div className={classes.root}>
      <Switch>
        <Route exact path="/config/financialadministration">
          <div>
            <Typography className={classes.header}>Financial Administration </Typography>
            <Typography  className ="decoratedText" onClick={()=> window.open(Config.cvtUrl)} tabIndex="0">
              Link to Coding Validation Table App
            </Typography>
            <Grid container className={classes.filters}>
              <Grid item xs={12} className={classes.textfield}>
                <span className={classes.textLabel}>Search</span>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchFilter === null ? '' : searchFilter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Search by Division,Expense Type,GL Code,IO,CC"
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" >
                  <RadioGroup row aria-label="status-sw" name="status-sw" value={filterSw} onChange={(e) => setFilterSw(e.target.value)}>
                    <div>
                      <FormControlLabel value="Active" control={<Radio color="primary" />} label="Active" />
                      <FormControlLabel value="Inactive" control={<Radio color="primary" />} label="Inactive" />
                      <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {iseditable ?
            <Box style={{ float: 'right' }} mb={2} mr={2}>
              <Button variant="outlined" color="primary" size="small" onClick={changeView}>Add New</Button>
            </Box> : null}
            <Paper elevation={0}>
              <Box p={"10px"}>
                <Gridtable columns={summaryColumns} data={(filterSw === "All") ? summaryData.filter((data) => (data.filterData.includes(searchFilter.toLowerCase()))) : summaryData.filter((data) => (data.filterData.includes(searchFilter.toLowerCase())) && (data.status === filterSw))}></Gridtable>
                {/* <Gridtable columns={summaryColumns} data={summaryData}></Gridtable> */}
              </Box>
            </Paper>
          </div>
        </Route>
        <Route exact path="/config/financialadministration/details">
          <div>
            <Typography className={classes.header}>Financial Administration Details</Typography>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
              <FormControl variant="outlined" size="small" fullWidth error={financialAdmin.expense === 0 || financialAdmin.expense === null}>
                  <span className={classes.textLabel}>Expense</span>
                  <Select
                    id="expense"
                    value={(financialAdmin.expense === null ? 0 : financialAdmin.expense)}
                    className="required"
                    onChange={(e) => changeFinanceDetails('expense', e.target.value)}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.staticData.dropdown.Expense.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((financialAdmin.expense === 0 || financialAdmin.expense === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>            
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <FormControl variant="outlined" size="small" fullWidth error={financialAdmin.expenseType === 0 || financialAdmin.expenseType === null}>
                  <span className={classes.textLabel}>Expense Type</span>
                  <Select
                    id="expenseType"
                    value={(financialAdmin.expenseType === null ? 0 : financialAdmin.expenseType)}
                    className="required"
                    onChange={(e) => changeFinanceDetails('expenseType', e.target.value)}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.staticData.dropdown.TypeOfExpense.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((financialAdmin.expenseType === 0 || financialAdmin.expenseType === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
              <FormControl variant="outlined" size="small" fullWidth error={financialAdmin.reasonForExpense === 0 || financialAdmin.reasonForExpense === null}>
                  <span className={classes.textLabel}>Reason For Expense</span>
                  <Select
                    id="reasonForExpense"
                    value={(financialAdmin.reasonForExpense === null ? 0 : financialAdmin.reasonForExpense)}
                    className="required"
                    onChange={(e) => changeFinanceDetails('reasonForExpense', e.target.value)}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.staticData.dropdown.ReasonForExpense.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((financialAdmin.reasonForExpense === 0 || financialAdmin.reasonForExpense === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textfield}>
              <FormControl variant="outlined" size="small" fullWidth error={financialAdmin.division === 0 || financialAdmin.division === null}>
                  <span className={classes.textLabel}>Division</span>
                  <Select
                    id="division"
                    value={(financialAdmin.division === null ? 0 : financialAdmin.division)}
                    className="required"
                    onChange={(e) => changeFinanceDetails('division', e.target.value)}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.staticData.dropdown.DIVISIONS.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((financialAdmin.division === 0 || financialAdmin.division === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
              <FormControl variant="outlined" size="small" fullWidth error={financialAdmin.userType === 0 || financialAdmin.userType === null}>
                  <span className={classes.textLabel}>User Type</span>
                  <Select
                    id="userType"
                    value={(financialAdmin.userType === null ? 0 : financialAdmin.userType)}
                    className="required"
                    onChange={(e) => changeFinanceDetails('userType', e.target.value)}
                  >
                    <MenuItem value={0}>Pick an Option</MenuItem>
                    {props.staticData.dropdown.UserType.value.map((method) => (
                      <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                    ))}
                  </Select>
                  {((financialAdmin.userType === 0 || financialAdmin.userType === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                    : null}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className="datepickerLabel">Start Date</span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="startDate"
                        autoOk={true}
                        className="required"
                        value={financialAdmin.startDate}
                        error={(financialAdmin.startDate === null) || (!moment(financialAdmin.startDate, 'MM/DD/YYYY').isValid())}
                        size="small"
                        fullWidth
                        placeholder="MM/DD/YYYY"
                        onChange={(date) => changeFinanceDetails('startDate', date)}
                        KeyboardButtonProps={{
                        'aria-label': 'Effective From Date',
                        }}
                        inputVariant="outlined"
                        helperText={(financialAdmin.startDate === null && error) ? "Required Field" : null}
                    />
                    </MuiPickersUtilsProvider>
                    {(financialAdmin.startDate !== null && !moment(financialAdmin.startDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                    : null}
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className="datepickerLabel">End Date</span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="endDate"
                        autoOk={true}
                        className="required"
                        value={financialAdmin.endDate}
                        error={(financialAdmin.endDate === null) || (!moment(financialAdmin.endDate, 'MM/DD/YYYY').isValid())}
                        size="small"
                        fullWidth
                        placeholder="MM/DD/YYYY"
                        onChange={(date) => changeFinanceDetails('endDate', date)}
                        KeyboardButtonProps={{
                        'aria-label': 'Effective End Date',
                        }}
                        inputVariant="outlined"
                        helperText={(financialAdmin.endDate === null && error) ? "Required Field" : null}
                    />
                    </MuiPickersUtilsProvider>
                    {(financialAdmin.endDate !== null && !moment(financialAdmin.endDate, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                    : null}
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>General Ledger Code</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="generalLedgerCode"
                      fullWidth
                      className="required"
                      error={financialAdmin.generalLedgerCode === '' || financialAdmin.generalLedgerCode === null}
                      value={financialAdmin.generalLedgerCode === null ? '' : financialAdmin.generalLedgerCode}
                      onChange={(e) => changeFinanceDetails("generalLedgerCode", e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    //   InputProps={{
                    //     inputComponent: LicenseFormatCustom,
                    //   }}
                      placeholder=""
                      helperText={((financialAdmin.generalLedgerCode === '' || financialAdmin.generalLedgerCode === null) && error) ? "Required Field" : null}
                    />
                    {((checkFormat(financialAdmin.generalLedgerCode) > 10 || isNaN(financialAdmin.generalLedgerCode))) ? <FormHelperText error={true}>Maximum of 10 numeric characters allowed.</FormHelperText> : null}
              </Grid>
              <Grid item xs={12} sm={5} className="hideElement">
                <FormControl component="fieldset">
                  <RadioGroup aria-label="active-sw" name="active-sw"  value={financialAdmin.active} onChange={(e)=> changeFinanceDetails('active',e.target.value)}>
                    <FormLabel component="legend" className={classes.radioLabel}></FormLabel>
                    <div>
                      <FormControlLabel value="Y" control={<Radio disabled={false} color="primary" />} label="Active" />
                      <FormControlLabel value="N" control={<Radio disabled={false} color="primary" />} label="Inactive" />
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.filters}>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                    <span className={classes.textLabel}>Internal Order</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="internalOrder"
                      fullWidth
                     //className={((financialAdmin.expenseType !== null && financialAdmin.userType !== null) && (financialAdmin.userType === 'EM' && financialAdmin.expenseType !== 'ME')) ? "disabled" : "required"}
                     //disabled={((financialAdmin.expenseType !== null && financialAdmin.userType !== null) && (financialAdmin.userType === 'EM' && financialAdmin.expenseType !== 'ME')) ? true : false}
                      className={!isIOCCRequired ? "disabled" : "required"}
                      disabled={!isIOCCRequired}
                      error={(financialAdmin.internalOrder === '' || financialAdmin.internalOrder === null) && isIOCCRequired}
                      value={financialAdmin.internalOrder === null ? '' : financialAdmin.internalOrder}
                      onChange={(e) => changeFinanceDetails("internalOrder", e.target.value)}                      
                      helperText={((financialAdmin.internalOrder === '' || financialAdmin.internalOrder === null) && error && isIOCCRequired) ? "Required Field" : null}
                    />
                    {((checkFormat(financialAdmin.internalOrder) > 8)) ? <FormHelperText error={true}>Maximum of 8 characters allowed.</FormHelperText> : null}
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textfield}>
                <span className={classes.textLabel}>Cost Center</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="costCenter"
                      fullWidth
                      //className={((financialAdmin.expenseType !== null && financialAdmin.userType !== null) && (financialAdmin.userType === 'EM' && financialAdmin.expenseType !== 'ME')) ? "disabled" : "required"}
                      //disabled={((financialAdmin.expenseType !== null && financialAdmin.userType !== null) && (financialAdmin.userType === 'EM' && financialAdmin.expenseType !== 'ME')) ? true : false}
                      className={!isIOCCRequired ? "disabled" : "required"}
                      disabled={!isIOCCRequired}
                      error={(financialAdmin.costCenter === '' || financialAdmin.costCenter === null) && isIOCCRequired}
                      value={financialAdmin.costCenter === null ? '' : financialAdmin.costCenter}
                      onChange={(e) => changeFinanceDetails("costCenter", e.target.value)}                     
                      helperText={((financialAdmin.costCenter === '' || financialAdmin.costCenter === null) && error && isIOCCRequired) ? "Required Field" : null}
                    />
                     {((checkFormat(financialAdmin.costCenter) > 10)) ? <FormHelperText error={true}>Maximum of 10 characters allowed.</FormHelperText> : null}
              </Grid>
            </Grid>
      
            <div style={{ textAlign: 'center', paddingBottom: 10 }}>
              <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" onClick={reset}>Reset</Button>
              <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" onClick={saveFinance}>Save</Button>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
    </div>
  );
}
