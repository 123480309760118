import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Config from './assets/config';
import { postFetch } from './http';
import Loader from './loader';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Redirecting() {
  const classes = useStyles();
  const history = useHistory();
    const [open, setOpen] = React.useState(true);
    const [onInit, setOnInit] = React.useState(true);
    const [loader, setLoader] = React.useState({ loader: false, notification: false, message: "success", validationMessage: "Success" });
  const handleClose = () => {
    setOpen(false);
    };

    const loaderAction = async () => {
        setLoader({ ...loader, notification: false })
    }

    React.useEffect(() => {
        const redirectUser = async () => {
            let encryptedText = window.location.href.split('?val=')[1];
            var rawData = CryptoJS.enc.Base64.parse(encryptedText);
            var key = CryptoJS.enc.Latin1.parse("49346A3CD8BFD3F9100B4CE9DAED72B1");
            var iv = CryptoJS.enc.Latin1.parse("9836565498764147");
            var plaintextData = CryptoJS.AES.decrypt(
                { ciphertext: rawData },
                key,
                { iv: iv });
            var plaintext = plaintextData.toString(CryptoJS.enc.Latin1);
            let request = JSON.parse(plaintext);
            request.vendorNum = request.id;
            setOnInit(false);
            await postFetch("/userManagement/saveProvider", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        Cookies.set('state', CryptoJS.AES.encrypt(JSON.stringify(data), Config.secretPhrase));
                        localStorage.setItem('appId', 2);
                        history.push("/dashboard");
                    }, 3000);
                }
                else {
                    setTimeout(() => {
                        setLoader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    setLoader({ ...loader, loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
        if (onInit) {
            redirectUser();
        }
  })

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
              <CircularProgress color="inherit" />
              <Loader loader={loader.loader} notification={loader.notification} message={loader.message} action={loaderAction} validationMessage={loader.validationMessage} />
      </Backdrop>
    </div>
  );
}
