import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import {postFetch} from '../http';
import Loader from '../loader';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import DesktopAccessDisabledRoundedIcon from '@material-ui/icons/DesktopAccessDisabledRounded';
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded';
import DriveEtaRoundedIcon from '@material-ui/icons/DriveEtaRounded';
import {  Switch, Route } from "react-router-dom";
import { useHistory  } from "react-router-dom";
import Announcements from './announcements';
import Configuration from './configuration';
import AccessControl from './accessControl';
import StaffManagment from './staffManagement';
import VehicleMaintenance from './vehicleMaintenance';
import FinancialAdministration from './financialadministration';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    "& .MuiListItem-button:hover":{
      borderLeft:'5px solid #3f51b5',
      borderRadius:2,
      backgroundColor: '#e3e8f2'
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover":{
      borderLeft:'5px solid #3f51b5',
      borderRadius:2,
      backgroundColor: '#e3e8f2'
    },
  },
  list:{
    [theme.breakpoints.down('sm')]:{
      display:'none'
    }
  },
  leftMenu:{
    zIndex:800,
    marginTop:55,
    boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.up('xs')]:{
      width:300
    },
    [theme.breakpoints.down('sm')]:{
      width:50
    }
  },
  toggleLeft:{
    zIndex:800,
    marginTop:55,
    width:300
  },
  menuItem:{
    borderRadius: 5,
    padding: '5px 15px',
    margin:'10px 0',
    "& .MuiTypography-body1":{
      fontWeight:500
    },
    "& .MuiListItemIcon-root":{
      minWidth:45,
      color:'#444'
    },
    transition:'border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]:{
      marginLeft:310
    },
    [theme.breakpoints.down('sm')]:{
      marginLeft:60
    },
    boxShadow:'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0.12)',
    marginBottom:50
  },
  menuIcon:{
    cursor:'pointer',
    color:'#3f51b5',
    [theme.breakpoints.up('sm')]:{
      display:'none'
    },
    [theme.breakpoints.down('sm')]:{
     display:'inline-block'
    }
  },
  toggleMenu:{
    display:'none'
  },
  navContent:{
    width:'100%',
    height:'100%'
  },
  profile:{
    textAlign: 'center',
    fontWeight: 900,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: '#3d4977',
    padding: '15px 0',
    fontSize:14,
    color: '#fff'
  },
  percentage:{
    position:'absolute',
    right:10,
    top:106
  }
}));

export default function Admin(props) {
  const classes = useStyles();
  const history = useHistory();
  const [leftNav, setLeftNav] = React.useState(true);
  const [onInit, setOnInit] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [loader,setLoader] = React.useState({loader:false,notification:false,message:"success",validationMessage:"Success"});
  const [referenceData, setReferenceData] = React.useState({});
  const [view, setView] = React.useState(false);

  const leftNavigation = (index, path)=>{
    setSelectedIndex(index);
    history.push("/redirect");
    history.push("/config/"+path.toLowerCase().replace(/\s/g, ''));
  }

  const changeLeftNav = (value)=>{
    (value === 0) ?
    setLeftNav(true):
    setLeftNav(false);
  }
  React.useEffect(() => {
    window.scrollTo(0,0);
    const loadConfigInfo =  ()=>{
      setLoader({...loader,loader:true,notification:false,message:"success",validationMessage:"success" });
      setOnInit(false);
      history.push("/config/announcements");
      const request = {
        appId:parseInt(localStorage.getItem('appId'))
      } 
       postFetch("/configurations/loadByAppId",request).then((data)=>{
            if(data.configurations){

                let configdata = {dropdown:{},reference:{}};
                data.configurations.forEach((config)=>{
                  config.value = JSON.parse(config.value);
                  configdata.reference[config.configName] = config;
                  let filteredConfig ={...config};
                  filteredConfig.value = filteredConfig.value.filter((value)=> value.activeSW.toString().trim() === 'Y' && (new Date(value.activeBeginDt) <= new Date()));
                  configdata.dropdown[filteredConfig.configName] = filteredConfig;
                })
                setReferenceData({...configdata});
                setTimeout(()=>{                      
                  setLoader({...loader,loader:false,notification:false });
                  setView(true);
                  if(localStorage.getItem('maintId') !== undefined && localStorage.getItem('maintId') !== null){
                    history.push("/config/vehiclemaintenance");
                  }                                 
              },1000);
            }
            else{
                setTimeout(()=>{                         
                    setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message });                                 
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
              setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
    if(onInit){
      loadConfigInfo();
    }
  }, [onInit,loader,history]);

  const loaderAction = async()=>{
    setLoader(prevState => {
      return {...prevState.loader,loader : false, notification:false}                                 
      });
  };

  const changeLoader = (value)=>{
    setLoader(value);
  }

  const icons = [<NotificationImportantIcon />,<SettingsRoundedIcon />,<DesktopAccessDisabledRoundedIcon/>,<RecentActorsRoundedIcon/>,<GroupAddRoundedIcon/>,<DriveEtaRoundedIcon/>,<AccountBalanceIcon/>];

  return (
    <div className={classes.root}>
      <Loader loader={loader.loader} notification = {loader.notification} message={loader.message} action={loaderAction} validationMessage = {loader.validationMessage}/>
      <div className={classes.navContent}>
        <Drawer
          variant={leftNav ? "permanent" : "temporary"}
          anchor={"left"}
          open={true}
          classes={{
            paper:leftNav ? classes.leftMenu : classes.toggleLeft
          }}
          onClose={()=>changeLeftNav(0)}
          //className={classes.leftMenu}
        >
          <div className={ leftNav ? classes.list : null}>
            <List style={{margin:'10px'}}>
                <ListItem button key={1} className={classes.menuItem} selected={selectedIndex === 1} onClick={()=>leftNavigation(1,"announcements")}>
                  <ListItemIcon>{icons[0]}</ListItemIcon>
                  <ListItemText primary="Announcements" />
                </ListItem>
                { (['SA','VA'].indexOf(props.userDetails.role) > -1) ?<ListItem button key={2} className={classes.menuItem} selected={selectedIndex === 2} onClick={()=>leftNavigation(2,"reference")}>
                  <ListItemIcon>{icons[1]}</ListItemIcon>
                  <ListItemText primary="Application Configuration" />
                </ListItem> : null}
                { (['SA','VA'].indexOf(props.userDetails.role) > -1) ?<ListItem button key={3} className={classes.menuItem} selected={selectedIndex === 3} onClick={()=>leftNavigation(3,"accesscontrol")}>
                  <ListItemIcon>{icons[2]}</ListItemIcon>
                  <ListItemText primary="Access Control" />
                </ListItem> : null}
                <ListItem button key={5} className={classes.menuItem} selected={selectedIndex === 5} onClick={()=>leftNavigation(5,"usermanagement")}>
                  <ListItemIcon>{icons[4]}</ListItemIcon>
                  <ListItemText primary="User Management" />
                </ListItem>
                {parseInt(localStorage.getItem('appId')) === 1 ? <ListItem button key={6} className={classes.menuItem} selected={selectedIndex === 6} onClick={()=>leftNavigation(6,"vehiclemaintenance")}>
                  <ListItemIcon>{icons[5]}</ListItemIcon>
                  <ListItemText primary="Vehicle Management" />
                </ListItem> : null}
                {(parseInt(localStorage.getItem('appId')) === 2 && (['SA','DA'].indexOf(props.userDetails.role) > -1)) ? <ListItem button key={7} className={classes.menuItem} selected={selectedIndex === 7} onClick={()=>leftNavigation(7,"financialadministration")}>
                  <ListItemIcon>{icons[6]}</ListItemIcon>
                  <ListItemText primary="Financial Administration" />
                </ListItem> : null}
            </List>
          </div>
          <MenuIcon className={leftNav ? classes.menuIcon : classes.toggleMenu} onClick={()=>changeLeftNav(1)}/>
        </Drawer>
        {view ? <div className={classes.content}>
        <Switch>
            <Route path="/config/announcements">
              <Announcements staticData = {referenceData} loader={changeLoader} userInfo={props.userDetails}/>
            </Route>
            <Route path="/config/reference">
              <Configuration staticData = {referenceData} loader={changeLoader}/>
            </Route>
            <Route path="/config/accesscontrol">
              <AccessControl staticData = {referenceData} loader={changeLoader}/>
            </Route>
            <Route path="/config/usermanagement">
              <StaffManagment staticData = {referenceData} loader={changeLoader} userInfo={props.userDetails}/>
            </Route>
            {parseInt(localStorage.getItem('appId')) === 1 ? <Route path="/config/vehiclemaintenance">
              <VehicleMaintenance staticData = {referenceData} loader={changeLoader} userInfo={props.userDetails}/>
            </Route> : null }
            {(parseInt(localStorage.getItem('appId')) === 2 && ((['SA','DA'].indexOf(props.userDetails.role) > -1) || referenceData.reference.FinancialAdmins.value.filter((config)=> config.value.toString() === props.userDetails.id.toString() && (config.activeSW === 'Y')).length > 0))? <Route path="/config/financialadministration">
              <FinancialAdministration staticData = {referenceData} loader={changeLoader} userInfo={props.userDetails}/>
            </Route> : null }
        </Switch>
        </div> : null}
      </div>
    </div>
  );
}
