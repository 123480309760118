import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Typography from '@material-ui/core/Typography';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {postFetch} from '../http';
import {  Switch, Route } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory  } from "react-router-dom";
import '../App.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected":{
        fontWeight:600
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'20px 0',
    padding:'0 10px'
  },
  textfield:{
      padding:'10px 10px 10px 0'
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  datepicker:{
    position: 'relative',
    bottom: 6,
    padding:'0 10px 0 0'
  }
}));

const Subheader = ({children}) =>{
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
}

export default function Configuration(props) {
  const classes = useStyles();
  const [prevData, setPrevData]=React.useState('');
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  //const { transcript, resetTranscript } = useSpeechRecognition();
  const [configData, setConfigData] = React.useState({name:null,value:null,activeSW:'N',activeBeginDt:null});
  const [error,setError] = React.useState(false);
  const [onInit, setOnInit] = React.useState(true);
  const [searchFilter, setSearchFilter] = React.useState({configType:'',configName:''});
  const [options, setOptions] = React.useState([]);
  const [resetDetails, setResetDetails] = React.useState(null);
  const [newConfig, setNewConfig] = React.useState(false);
  const [staffData, setStaffData] = React.useState([]);
  const [disableSw, setCodeDisableSw] = React.useState(false);


  const openConfig = () => {
    changeView();
    setOpen(true);
    setNewConfig(true);
    setCodeDisableSw(false);
  };

  const closeConfig = () => {
    changeView();
    setOpen(false);
    setNewConfig(false);
  };

  React.useEffect(() => {
    window.scrollTo(0,0);
    const loadStaffManagement =  async ()=>{
        props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
        setOnInit(false);
        history.push("/config/reference");
        const request = {
          appId:localStorage.getItem('appId')
        } 
       await postFetch("/userManagement/loadByAppId",request).then((data)=>{ 
            if(!data.message){
                data.userManagement.forEach((user)=>{
                  user.fullName = user.firstName +' '+ user.lastName;
                  user.filterData = (user.fullName + user.username).toLowerCase();
                  user.division = user.division.split(',');
                  let division = '';
                  user.division.forEach((div)=> division+=props.staticData.reference.DIVISIONS.value.find((status)=>status.value === div).name+',');
                  user.divisionDesc = division.slice(0, -1);
                  user.roleDesc = props.staticData.reference.ROLES.value.find((status)=>status.value === user.role).name;
                  (user.endDate === null || (new Date() < new Date(convertDateFromServer(user.endDate)))) ? user.status = 'Active' : user.status = 'Inactive';
                })
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });
                    setStaffData(data.userManagement);
                },1000);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
    if(onInit){
        loadStaffManagement();
    }
  },[props,onInit,history]);

  
  const renderActionButton = (row)=>{
    return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewFunction(row)} style={{fontSize:20}}/></div>);
  }
  const viewFunction = (value)=>{
      let details = {...value};
      details.activeBeginDt = convertDateFromServer(details.activeBeginDt);
      setConfigData(details);
      setCodeDisableSw(true);
      setOpen(true);
  }
  const changeView = ()=>{
    setConfigData({name:null,value:null,activeSW:'N',activeBeginDt:null});
  }

  const changeDataDetails = (key,value)=>{
      setResetDetails({...configData});
      let newDetails = {...configData};
      if(key === 'activeBeginDt'){
        value = moment(value).format("MM/DD/YYYY");
      }
      (key === 'activeSW' && value === true) ? value = 'Y' : (key === 'activeSW' && value === false) ? value = 'N' : value = value.toString();
      newDetails[key] = value;
      setConfigData(newDetails);
  }

  function convertDateFromServer(value){
    if(value !== null && value !== undefined){
      let serverDateArray = [];
       value.indexOf('-') === -1 ?  serverDateArray = value.split("/") :  serverDateArray = value.split("-");
        if(serverDateArray.length > 1){
          return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
        }
        else{
          return serverDateArray[0];
        }
      }
      else{
        return null;
      }
    
  }

  const searchName = (value)=>{
    let names = Object.keys(props.staticData.reference);
    let newOptions = [];
    names.forEach((name)=>{
      newOptions.push(props.staticData.reference[name]);
    });
    setOptions(newOptions);
  }

  const changeGrid = (value)=>{
      if(value !== null && value !== undefined){
        setSearchFilter(value);
        setPrevData(value);
      }
  }

  const saveConfiguration = ()=>{
      let request = {...searchFilter};
      let referenceData = {...configData};    
    if(document.getElementsByClassName("Mui-error").length > 0){
        setError(true);
      }
      
    else if(request.value.filter((obj)=> obj.value.toUpperCase() === referenceData.value.toUpperCase()).length > 0 && !disableSw ){
        //setSearchFilter(prevData);
        props.loader({loader:false,notification:true,message:"error",validationMessage:"Code already exists" });
      
    }
    else{
        referenceData.activeBeginDt = moment(new Date(referenceData.activeBeginDt)).format("YYYY-MM-DD");
        if ((request.configName === 'PaymentApprover') && (staffData.slice().filter((data)=> data.fullName === referenceData.name).length === 0)){
          props.loader({loader:false,notification:true,message:"error",validationMessage:"Invalid User to Approve Payments" });
        }
        else if ((request.configName === 'DivisionCFO') && (referenceData.activeSW === 'Y' && ((staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length === 0) || (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length > 0 && staffData.slice().filter((data)=> data.username.trim() ===referenceData.name.trim())[0].role.toString() !== 'SA' && staffData.slice().filter((data)=> data.username.trim() ===referenceData.name.trim())[0].role.toString() !== 'DA')))){
          props.loader({loader:false,notification:true,message:"error",validationMessage:"The user you are attempting to add is not eligible. Only Division or System Admins can be added as Division CFOs" });
        }
        else if ((request.configName === 'DivisionCFO') && (referenceData.activeSW === 'N' && (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length === 0))){
          props.loader({loader:false,notification:true,message:"error",validationMessage:"The user you are attempting to add is not eligible. Only Division or System Admins can be added as Division CFOs" });
        }
        else if ((request.configName === 'MedicaidApprovers') && ((staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length === 0) || (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length > 0 && staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim())[0].role.toString() !== 'SA'))){
          props.loader({loader:false,notification:true,message:"error",validationMessage:"Invalid User to Approve Medicaids" });
        }
        else if ((request.configName === 'DHSCFOStaff') && (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length === 0)){
          props.loader({loader:false,notification:true,message:"error",validationMessage:"Invalid User for DHS CFO Staff" });
        }      
        else if ((request.configName === 'ChiefOfStaff') && (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length === 0)){
          props.loader({loader:false,notification:true,message:"error",validationMessage:"Invalid User for Cheif Of Staff" });
        }
        else if ((request.configName === 'CFOAssignedDivisions') && (props.staticData.reference.DIVISIONS.value.slice().filter((data)=> data.value.trim() === referenceData.name.trim()).length === 0)){
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The entered Division does not exist. Please enter a valid Division found in the "Divisions" configuration list' });
        }
        else if ((request.configName === 'CFOAssignedDivisions') && (staffData.slice().filter((data)=> data.id.toString().trim() === referenceData.value.toString().trim()).length === 0)){
          props.loader({loader:false,notification:true,message:"error",validationMessage:'The entered User ID does not exist. Please enter a valid User ID to proceed' });
        }
        else{
          if((request.configName === 'PaymentApprover' || request.configName === 'FinancialAdmins') && (staffData.slice().filter((data)=> data.fullName === referenceData.name).length > 0)){
            referenceData.value = (staffData.slice().filter((data)=> data.fullName === referenceData.name))[0].id.toString();
          }
          if((request.configName === 'MedicaidApprovers') && (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length > 0)){
            referenceData.value = (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()))[0].id.toString();
            referenceData.fullName = (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()))[0].fullName.toString();
          }
          if((request.configName === 'DHSCFOStaff' || request.configName === 'ChiefOfStaff' || request.configName === 'DivisionCFO') && (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()).length > 0)){
            referenceData.value = (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()))[0].id.toString();
            referenceData.fullName = (staffData.slice().filter((data)=> data.username.trim() === referenceData.name.trim()))[0].fullName.toString();
          }
         
          if(newConfig){
            request.value.push({...referenceData});
          }
          else{
            request.value = request.value.map(obj => (obj.value === resetDetails.value) ? {...referenceData} : obj);
          }
          request.value.forEach((data)=>{
            if(data.activeSW === 'N'){
              data.activeBeginDt = null;
            }
          });
          request.value = JSON.stringify(request.value);
          props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
          postFetch("/configurations/save",request).then((data)=>{ 
              if(!data.message){
                data.value = JSON.parse(data.value);
                setSearchFilter(data);
                setOpen(false);
                setNewConfig(false);
                  setTimeout(()=>{                         
                      props.loader({loader:false,notification:true,message:"success",validationMessage:"Application configuration saved successfully" });                                
                  },1000);
              }
              else{
                setSearchFilter(prevData);
                  setTimeout(()=>{                         
                      props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                  },1000);
              }
          }).catch((e)=>{
            setSearchFilter(prevData);
            setTimeout(()=>{                          
              props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
            },1000);
          });
      }
        }
  }

  const summarytaskColumns = [
    {
        name:"Code",
        field:"value",
        type:"text"
    },
    {
        name:"Description",
        field:"name",
        type:"text"
    },
    {
        name:"Is Active",
        field:"activeSW",
        type:"text"
    },
    {
        name:"Active Begin Date",
        field:"activeBeginDt",
        type:"date"
    },
    {
        name:"Action",
        field:"action",
        type:"input",
        renderView: renderActionButton
    }
  ]


  return (
    <div className={classes.root}>
     <Typography className={classes.header}>Application Configuration</Typography>
     <Switch>
            <Route exact path="/config/reference">
                <div>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} className={classes.textfield}>
                        <span className={classes.textLabel}>Search</span>
                            <Autocomplete
                                id="search-config-box"
                                options={options}
                                fullwidth="true"
                                getOptionLabel={(option) => option.configName}
                                onChange={(event,value)=> changeGrid(value)}
                                renderOption={(option)=>
                                    <span style={{fontWeight:600}}>{option.configName}</span>
                                  }
                                size="small"
                                renderInput={(params) => <TextField id="search-config" variant="outlined" size="small" {...params}
                                value={searchFilter.id === undefined ? '' : searchFilter.configName}
                                onChange={(event)=>(event.target.value.length >=3) ? searchName(event.target.value):setOptions([])}
                                InputLabelProps={{
                                    shrink: true,
                                }}/>}
                            />
                        </Grid>
                    </Grid>
                    <div>
                    <Subheader>Application Configuration Details</Subheader>
                    <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={4} className={classes.textfield}>
                                <span className={classes.textLabel}>Configuration Name</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={searchFilter.configName}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.textfield}>
                              <span className={classes.textLabel}>Configuration Type</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={searchFilter.configType}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </Grid>
                    </Grid>
                    <Box style={{float: 'right'}} m={2}>
                        <Button  variant="outlined" color="primary" size="small" disabled={searchFilter.id === undefined} onClick={openConfig}>Add New</Button>
                    </Box>
                    <Dialog open={open} onClose={closeConfig} aria-labelledby="config-dialog-title">
                      <DialogTitle id="config-dialog-title">Details</DialogTitle>
                      <DialogContent>
                        <Grid container className={classes.filters}>
                          <Grid item xs={12} sm={6} className={classes.textfield}>
                          <span className={classes.textLabel}>Code</span>
                              <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  className = {disableSw ? "disabled" : "required" }
                                  value={configData.value === null ? '' : configData.value}
                                  error={(configData.value === '' || configData.value === null)}
                                  onChange = {(e)=> changeDataDetails('value',e.target.value)}
                                  InputLabelProps={{
                                      shrink: true
                                  }}
                                  inputProps = {{
                                    maxLength : (searchFilter.configName === 'CFOAssignedDivisions') ? 10 : (searchFilter.configName !== 'DIVISIONS') ? 2 : 6
                                  }}
                                  helperText={((configData.value === '' || configData.value === null) && error) ? "Required Field" : null}
                                  disabled={disableSw}
                              />
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.textfield}>
                          <span className={classes.textLabel}>Description</span>
                              <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  className = "required"
                                  value={configData.name === null ? '' : configData.name}
                                  error={(configData.name === '' || configData.name === null)}
                                  onChange = {(e)=> changeDataDetails('name',e.target.value)}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  helperText={((configData.name === '' || configData.name === null) && error) ? "Required Field" : null}
                              />
                          </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                          <Grid item xs={12} sm={6} className={classes.textfield}>
                            <FormControlLabel
                              control = {<Checkbox
                                  checked={configData.activeSW === 'Y'}
                                  color="primary"
                                  onChange = {(e)=> changeDataDetails('activeSW',e.target.checked)}
                              /> }
                              label="Is Active"
                            />
                          </Grid>
                          {configData.activeSW === 'Y' ? <Grid item xs={12} sm={6} className={classes.datepicker}>
                          <span className="datepickerLabel">Active begin date</span>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker 
                                //disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="begin-date"
                                autoOk={true}
                                className={configData.activeSW === 'Y' ? "required" : null}
                                value={configData.activeBeginDt}
                                error={(configData.activeBeginDt === null && configData.activeSW === 'Y') || (!moment(configData.activeBeginDt, 'MM/DD/YYYY').isValid())}
                                size="small"
                                fullWidth
                                placeholder="MM/DD/YYYY"
                                onChange={(date)=> changeDataDetails('activeBeginDt',date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'closure date',
                                }}
                                inputVariant="outlined"
                                helperText={((configData.activeBeginDt === null && configData.activeSW === 'Y') && error) ? "Required Field" : null}
                                />
                                </MuiPickersUtilsProvider>
                                {(configData.activeBeginDt !== null && !moment(configData.activeBeginDt, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                            : null} 
                            </Grid> : null }
                        </Grid>
                        <div style={{textAlign:'center',paddingBottom:10}}>
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"   onClick={closeConfig}>Close</Button>
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"   onClick={saveConfiguration}>Submit</Button>
                        </div>
                      </DialogContent>
                    </Dialog>
                    <Paper elevation={0}>
                        <Box p={"10px"}>
                            <Gridtable columns={summarytaskColumns} data={searchFilter.id === undefined ? [] : searchFilter.value}></Gridtable>
                        </Box> 
                    </Paper>
                    </div>
                </div>
            </Route>
           
        </Switch>
    </div>
  );
}
